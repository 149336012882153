<template>
  <div class="searchBox">
    <el-form :model="search" :label-width="searchLabelWidth" ref="searchForm">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="安全模块编号" prop="cert_sn">
            <el-input
              v-model="search.cert_sn"
              autocomplete="off"
              placeholder="请输入 安全模块编号"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="类型" prop="type">
            <el-select
              v-model="search.type"
              placeholder="请选择 类型"
              clearable
            >
              <el-option label="平台申请更新" :value="1"></el-option>
              <el-option label="离线信任关系文件导入数据记录" :value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="状态" prop="status">
            <el-select
              v-model="search.status"
              placeholder="请选择 状态"
              clearable
            >
              <el-option label="下发发送成功" :value="1"></el-option>
              <el-option label="下发失败" :value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <!--              <el-form-item>-->
          <el-button plain @click="searchFormFn('searchForm')" class="searchBtn"
          >查询
          </el-button
          >
          <el-button plain @click="resetForm('searchForm')" class="resetBtn"
          >清空
          </el-button
          >

          <!--              </el-form-item>-->
        </el-col>
      </el-row>
    </el-form>
<!--    <div class="divide">-->
<!--      <img src="../../assets/divide.png" style="width: 100%" />-->
<!--    </div>-->
<!--    <div class="btnContainer">-->
<!--      <div class="btnLeft">-->
<!--        <el-button-->
<!--          icon="Plus"-->
<!--          @click="dialogFormVisible = true"-->
<!--          class="searchBtn"-->
<!--        >新增-->
<!--        </el-button-->
<!--        >-->
<!--      </div>-->
<!--    </div>-->
  </div>
  <basicTable
    :attrList="attrList"
    :tableData="tableData"
    ref="table"
    :page="page.current"
    :limit="page.limit"
    :total="page.total"
    @currentChange="handleCurrentChange"
    @sizeChange="handleSizeChange"
  >
  </basicTable>
</template>

<script>
import { reactive, toRefs, onMounted, ref } from "vue";
import { getList, certificatedown } from "@/api/safe/updatecertificate";
import { ElMessage } from "element-plus";

export default {
  name: "updatecertificate",
  setup() {
    const option = reactive({
      form: {},
      searchLabelWidth: "140px",
      search: {},
      page: {
        current: 1,
        limit: 10,
        total: 0
      },
      tableData: [],
      attrList: [
        {
          label: "安全模块编号",
          prop: "cert_sn",
          render: (row) => <div>{row.cert_sn}</div>
        },
        {
          label: "类型",
          prop: "type",
          render: (row) => {
            if (row.type == 1) return <div>平台申请更新</div>;
            else return <div>离线文件导入数据记录</div>;
          }
        },
        {
          label: "状态",
          prop: "status",
          render: (row) => {
            if (row.status == 1) return <div>下发发送成功</div>;
            else return <div>下发失败</div>;
          }
        },
        {
          prop: "",
          label: "操作",
          render: (row) => {
            return (
              <div>
                <el-button
                  type="text"
                  icon="CreditCard"
                  onClick={() => handlecertificate(row.id)}
                >证书下发更新
                </el-button>
              </div>
            );
          }
        }
      ]
    });
    const searchForm = ref();

    function getData(page) {
      getList(page.current, page.limit, option.search).then(res => {
        if (res.data.success) {
          option.tableData = res.data.data.list;
          option.page.total = res.data.data.count;
        }
      });

    }
    function handleCurrentChange(currentPage) {
      option.page.current = currentPage;
      getData(option.page);
    }

    function handleSizeChange(pageSize) {
      option.page.limit = pageSize;
      getData(option.page);
    }
    function searchFormFn() {
      option.page.current = 1;
      getData(option.page);
    }

    function resetForm() {
      searchForm.value.resetFields();
      option.page.current = 1;
      getData(option.page);
    }

    function handlecertificate(id) {
      certificatedown(id).then(res => {
        if (res.data.success) {
          getData(option.page);
          ElMessage({
            type: "success",
            message: "操作成功！"
          });
        }
      });
    }

    onMounted(() => {
      getData(option.page);
    });
    return {
      ...toRefs(option),
      getData,
      searchForm,
      searchFormFn,
      resetForm,
      handleCurrentChange,
      handleSizeChange
    };
  }
};
</script>

<style scoped>

</style>