<template>
  <div id="container"></div>
  <div class="top">
    <pageHeader></pageHeader>
  </div>
  <div class="side-fill-left">
    <div>
      <div class="tit">实时播发</div>
      <div class="tu bobao">
        <vue-seamless-scroll :class-option="optionScroll" :data="gbList" class="seamless-warp">
          <div class="list" v-for="(item,index) in gbList" :key="index">
            <div class="t">
              <div>{{codeList.length > 0 && codeList.filter(e=>e.code == item.bcastType)[0].name}}({{item.eventType}})</div>
              <div :class="item.eventLevel == 1 ? 'acitve1' : item.eventLevel == 2 ? 'acitve2' : item.eventLevel == 3 ? 'acitve3' : 'acitve4'">{{item.eventLevel == 1 ? '特别重大' : item.eventLevel == 2 ? '重大' : item.eventLevel == 3 ? '较大' : '一般'}}</div>
            </div>
            <div class="b">
              <div>{{item.instructionSource}}</div>
              <div>播发时间:{{item.startTimeFact}}</div>
            </div>
          </div>
        </vue-seamless-scroll>
      </div>
    </div>
    <div>
<!--      vue-seamless-scroll-->
      <div class="tit">今日广播统计</div>
      <div class="tu">
        <div class="hed">
          <div class="tip">
            <div>{{tongyiData.filter(item=>item.code == 1).map(item=>item.time0_4 + item.time4_8 + item.time8_12 + item.time12_16 + item.time16_20 + item.time20_24)[0]}}</div>
            <div>特别重大</div>
          </div>
          <div class="tip">
            <div>{{tongyiData.filter(item=>item.code == 2).map(item=>item.time0_4 + item.time4_8 + item.time8_12 + item.time12_16 + item.time16_20 + item.time20_24)[0]}}</div>
            <div>重大</div>
          </div>
          <div class="tip">
            <div>{{tongyiData.filter(item=>item.code == 3).map(item=>item.time0_4 + item.time4_8 + item.time8_12 + item.time12_16 + item.time16_20 + item.time20_24)[0]}}</div>
            <div>较大</div>
          </div>
          <div class="tip">
            <div>{{tongyiData.filter(item=>item.code == 4).map(item=>item.time0_4 + item.time4_8 + item.time8_12 + item.time12_16 + item.time16_20 + item.time20_24)[0]}}</div>
            <div>一般</div>
          </div>
          <div class="tip">
            <div>{{tongyiData.filter(item=>item.code == 0).map(item=>item.time0_4 + item.time4_8 + item.time8_12 + item.time12_16 + item.time16_20 + item.time20_24)[0]}}</div>
            <div>未知</div>
          </div>
        </div>
        <div id="tongji" style="height: calc(100% - 60px)"></div>
      </div>
    </div>
    <div>
      <div class="tit">广播设备数量</div>
      <div class="tu">
        <div class="num-t">
          <div class="num-t-l">
            <div class="num-t-l-in">
              <div>{{(onlineSum / (onlineSum  + offSum) * 100).toFixed(1)}}<span style="font-size: 16px">%</span></div>
              <div>在线率</div>
            </div>
          </div>
          <div class="num-t-r">
            <div class="num-t-r-top">
              <div class="num-t-r-top-text">终端总数</div>
              <div class="num-t-r-top-num" v-for="(item,index) in (Array(4).join(0) + (onlineSum + offSum + notNum)).slice(-4)" :key="index">{{item}}</div>
            </div>

            <div class="num-t-r-bom">
              <div class="num-t-r-bom-list">
                <div class="num-t-r-bom-list-l">
                  在线终端
                </div>
                <div class="num-t-r-bom-list-line"></div>
                <div class="num-t-r-bom-list-r">
                  {{onlineSum}}
                </div>
              </div>
              <div class="num-t-r-bom-list">
                <div class="num-t-r-bom-list-l">
                  离线终端
                </div>
                <div class="num-t-r-bom-list-line"></div>
                <div class="num-t-r-bom-list-r">
                  {{offSum}}
                </div>
              </div>
              <div class="num-t-r-bom-list">
                <div class="num-t-r-bom-list-l">
                  无回传终端
                </div>
                <div class="num-t-r-bom-list-line"></div>
                <div class="num-t-r-bom-list-r">
                  {{notNum}}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="num-b">
          <table class="num-b-table">
            <thead>
              <tr>
                <th>地区</th>
                <th>在线终端</th>
                <th>离线终端</th>
                <th>无回传终端</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item,index) in numberData" :key="index">
                <td>{{item.name}}</td>
                <td>{{item.onlineNum || 0}}</td>
                <td>{{item.returnNum - (item.onlineNum || 0)}}</td>
                <td>{{item.totalNum - item.returnNum}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="side-fill-right">
    <div>
      <div class="tit">视频播放</div>
      <div class="tu">
        <video class="video" muted="muted" autoplay controls :src="viodeUrl"></video>
      </div>
    </div>

    <div>
      <div class="tit">来源统计</div>
      <div class="tu laiyuan">
        <div class="laiyuan-top">
          <div>总数</div>
          <div class="laiyuan-top-bg">
            {{laiyuanTotal}}
          </div>
        </div>
        <div id="laiyuan"></div>
      </div>
    </div>

    <div>
      <div class="tit">播控数据</div>
      <div class="tu">
        <div class="bokong">
          <div class="bk-top">
            <img src="../../src/assets/richang.png">
            <div class="bk-top-text">
              <div>{{nameCount[0]?.num}}</div>
              <div>{{nameCount[0]?.name}}</div>
            </div>
          </div>

          <div class="bk-top">
            <img src="../../src/assets/yingji.png">
            <div class="bk-top-text">
              <div>{{nameCount[1]?.num}}</div>
              <div>{{nameCount[1]?.name}}</div>
            </div>
          </div>

          <div class="bk-top">
            <img src="../../src/assets/dingshi.png">
            <div class="bk-top-text">
              <div>{{nameCount[2]?.num}}</div>
              <div>{{nameCount[2]?.name}}</div>
            </div>
          </div>
        </div>

        <div id="bkChart" style="height: calc(100% - 90px)"></div>
      </div>

    </div>
  </div>

  <div class="map-tip">
    <div>
      <div class="dis">
        <img src="../assets/video.png" />
        <span>摄像头</span>
      </div>
      <div class="dis">
        <img src="../assets/mapIcon/IP.png" />
        <span>IP</span>
      </div>
      <div class="dis">
        <img src="../assets/mapIcon/4G.png" />
        <span>4G</span>
      </div>
      <div class="dis">
        <img src="../assets/mapIcon/dtmb.png" />
        <span>DTMB</span>
      </div>
      <div class="dis">
        <img src="../assets/mapIcon/DVB-C.png" />
        <span>DVB-C</span>
      </div>
      <div class="dis">
        <img src="../assets/mapIcon/FM.png" />
        <span>FM</span>
      </div>
<!--      <div class="dis">-->
<!--        <img src="../assets/video.png" />-->
<!--        <span>摄像头</span>-->
<!--      </div>-->
<!--      <div class="dis">-->
<!--        <img src="../assets/mapIcon/IP.png" />-->
<!--        <span>应急广播</span>-->
<!--      </div>-->
<!--      <div class="dis">-->
<!--        <img src="../assets/mapIcon/4G.png" />-->
<!--        <span>地震</span>-->
<!--      </div>-->
<!--      <div class="dis">-->
<!--        <img src="../assets/mapIcon/dtmb.png" />-->
<!--        <span>森林防火</span>-->
<!--      </div>-->
<!--      <div class="dis">-->
<!--        <img src="../assets/mapIcon/DVB-C.png" />-->
<!--        <span>防汛</span>-->
<!--      </div>-->
<!--      <div class="dis">-->
<!--        <img src="../assets/mapIcon/FM.png" />-->
<!--        <span>地质灾害</span>-->
<!--      </div>-->
      <div class="bom">
        <div>
          <img src="../assets/mapIcon/a.png" />
          <span>适配器</span>
        </div>

        <div>
          <img src="../assets/mapIcon/b.png" />
          <span>智能音柱</span>
        </div>

        <div>
          <img src="../assets/mapIcon/c.png" />
          <span>终端</span>
        </div>

        <div>
          <img src="../assets/mapIcon/d.png" />
          <span>IP话筒</span>
        </div>
      </div>
    </div>

    <div>
      <div class="title">设备颜色状态</div>
      <div class="fff">
        <div>
          <i></i>
          <span style="color: #5FF12B">空闲</span>
        </div>

        <div>
          <i></i>
          <span style="color: #4DBAF2">工作</span>
        </div>

        <div>
          <i></i>
          <span style="color: #F59F25">故障</span>
        </div>

        <div>
          <i></i>
          <span style="color: #F73F3F">离线</span>
        </div>

      </div>
    </div>
  </div>

  <div class="map-hed">
    <div class="map-hed-top">
      <div>空闲数量</div>
      <div>离线数量</div>
      <div>工作数量</div>
      <div>故障数量</div>
      <div>DTMB</div>
      <div>DVB-C</div>
      <div>FM</div>
    </div>

    <div class="line"></div>

    <div class="bom">
      <div>{{mapData.free}}</div>
      <div>{{mapData.offline}}</div>
      <div>{{mapData.work}}</div>
      <div>{{mapData.fault}}</div>
      <div>{{mapData.dtmb}}</div>
      <div>{{mapData.dvbc}}</div>
      <div>{{mapData.fm}}</div>
    </div>
  </div>

  <el-dialog title="摄像头" v-model="dialogVisible" width="30%" :before-close="handleClose">
    <div style="margin-bottom: 30px">
      <el-button plain  @click="isViode = true">实时监控</el-button>
      <el-button plain  @click="lslx">历史录像</el-button>
    </div>

    <div class="dialog">
      <div class="dialogtop">
        <div><span>名称：</span><span>{{infoWindow.cname || '暂无'}}</span></div>
        <div><span>型号：</span><span>{{infoWindow.cmodel || '暂无'}}</span></div>
        <div><span>类型：</span><span>{{infoWindow.title || '暂无'}}</span></div>
        <div><span>坐标：</span><span>{{infoWindow.location || '暂无'}}</span></div>
        <div><span>安装地址：</span><span>{{infoWindow.installAddress || '暂无'}}</span></div>
      </div>
      <div class="dialogbom">
        <iframe v-if="isViode" :src="infoWindow.serial" style="pointer-events: none" class="iframe"></iframe>

        <video v-else id="videoElement" style="width: 100%" controls preload="auto" type="rtmp/flv"></video>

      </div>
    </div>
  </el-dialog>
</template>

<script>
import { ref, reactive, toRefs, onUnmounted, onMounted, nextTick, computed } from "vue";
import pageHeader from "../components/pageHeader";
import * as echarts from 'echarts';
import { getData, broadcastStatisticsVO, getNumber, getVideo, getByNameCount, getByTimeCount, getCount, mapCount } from "../../src/api/index";
import { getCameraList } from "../api/screen/bigmap";
import { codeInfo } from "../api/devops/strategy";
import flvjs from "flv.js";
export default {
  name: "index",
  components: { pageHeader },
  setup() {
    let dialogVisible = ref(false);
    let isViode = ref(true);
    let issetCenter = ref(true);
    let laiyuanTotal = ref('');
    let notNum = ref('');
    let offSum = ref('');
    let onlineSum = ref('');
    let viodeUrl = ref('');
    let state = reactive({
      infoWindow:{},
      cluster:[],
      contextMenu:null,
      viaMarker:[],
      map:null,
      outTimer: null,
      mapData:{},
      codeStatus:[],
      tongyiData:[],
      codeList:[],
      gbList:[],
      numberData:[],
      nameCount:[]
    })

    let optionScroll = computed(()=>{
      return{
        openWatch: true,
        limitMoveNum: 4,
        singleHeight: 0,
        singleWidth: 0,
        waitTime: 1000
      }
    })

    function timer(){
      state.outTimer = setTimeout(async ()=>{
        state.viaMarker = [];
        let res = await mapCount();
        res.data.data.list.forEach((item,index)=>{
          state.viaMarker[index] = {
            lnglat: [item.longitude,item.latitude],
            data: item
          }
        });
        let res2 = await getCameraList();
        res2.data.data.forEach(item=>{
          if(item.location){
            let location = item.location.split(',')
            state.viaMarker.push({
              lnglat: [location[0],location[1]],
              data: item
            })
          }
        })
        state.cluster.setData(state.viaMarker);
        numberDataAjax();
        timer();
      },5000);
    }

    function numberDataAjax(){
      getNumber().then(res=>{
        state.numberData = res.data.data;
        onlineSum.value = state.numberData.filter( s => s.onlineNum || s.onlineNum == 0).map(item=>item.onlineNum).reduce((prev, curr)=>{
          return prev + curr;
        });

        offSum.value = state.numberData.map(item=>item.returnNum - (item.onlineNum || 0)).reduce((prev, curr)=>{
          return prev + curr;
        });

        notNum.value = state.numberData.map(item=>item.totalNum - item.returnNum).reduce((prev, curr)=>{
          return prev + curr;
        });
        console.log(onlineSum.value)
        console.log(offSum.value)
        console.log((onlineSum.value / (onlineSum.value  + offSum.value) * 100))
      })
    }

    function onceLoad(){
      if (window.location.href.indexOf("#reloaded") == -1) {
        window.location.href = window.location.href + "#reloaded";
        window.location.reload();
      }
    }

    onUnmounted(()=>{
      clearInterval(state.outTimer);
      state.outTimer = null;
    })

    onMounted(()=>{
      onceLoad();
      timer();
      mapPointDraw();
      numberDataAjax();

      codeInfo({type:1}).then(res=>{state.codeList = res.data.data})
      state.map = new AMap.Map("container", {
        resizeEnable: true,
        mapStyle: "amap://styles/924a0b80c02969b8a905eab4dd9f5431",
      });


      getData({page:1,limit:10}).then(res=>{
        state.gbList = res.data.data.list;
      })

      broadcastStatisticsVO().then(res=>{
        state.tongyiData = res.data.data;
        let chartData = state.tongyiData.map(item=>[item.time0_4,item.time4_8,item.time8_12,item.time12_16,item.time16_20,item.time20_24])
        tongyiChart(chartData);
      })

      //宣传视频
      getVideo().then(res=>{
        viodeUrl.value = res.data.data.fileUrl;
      })

      //播控数据
      getByNameCount({ type:1 }).then(res=>{
        state.nameCount = res.data.data;
      })
      getByTimeCount().then(res=>{
        bkChart(res.data.data);
      })

      //来源数据
      getCount().then(res=>{
        laiyuanTotal.value = res.data.data.total;
        laiyuanChart(res.data.data.list);
      })
    })



    function mapPointDraw(){
      mapCount().then(async res=>{
        state.mapData = res.data.data;
        let data = res.data.data.listMap;

        //只第一次进入页面时定位中心点
        if(issetCenter.value){
          state.map.setCenter([data[0].longitude, data[0].latitude]);
          data.forEach(item=>{
            let arr = eval(item.code);
            arr.map(val => {
              let code = eval(val);
              let polygon = new AMap.Polygon({
                map: state.map,
                path: code,
                strokeColor: "#00FFC6", //线颜色
                strokeOpacity: 1, //线透明度
                strokeWeight: 2,    //线宽
                fillColor: "#0D6854", //填充色
                fillOpacity: 0.35//填充透明度
              });
              state.map.add(polygon);
            })
          })
        }
        issetCenter.value = false;

        state.mapData.list.forEach((item,index)=>{
          state.viaMarker[index] = {
            lnglat: [item.longitude,item.latitude],
            data: item
          }
        })

        await getCameraList().then(res=>{
          res.data.data.forEach((item,index)=>{
            if(item.location){
              let location = item.location.split(',')
              state.viaMarker.push({
                lnglat: [location[0],location[1]],
                data: item
              })
            }
          })
        })

        function _renderMarker(context){
          let pub = new Map([
            ['townAdapter','a'],
            ['intelligent', 'b'],
            ['expander','c'],
            ['soundPost','c'],
            ['ipMIC','d'],
            ['countyAdapter','a'],
          ])
          let type = new Map([
            ['1', 'fm'],
            ['2', 'dvb-c'],
            ['3', 'dtmb'],
            ['4', 'ip'],
            ['5', '4g']
          ])
          let imgType = new Map([
            ['a_4g_2', 'gif'],
            ['a_dvb-c_2', 'gif'],
            ['a_ip_2', 'gif'],
            ['b_dtmb_2', 'gif'],
            ['b_fm_2', 'gif'],
            ['c_4g_2', 'gif'],
            ['c_dvb-c_2', 'gif'],
            ['c_ip_2', 'gif'],
            ['d_dtmb_2', 'gif'],
            ['d_fm_2', 'gif'],
            ['a_dtmb_2', 'gif'],
            ['a_fm_2', 'gif'],
            ['b_4g_2', 'gif'],
            ['b_dvb-c_2', 'gif'],
            ['b_ip_2', 'gif'],
            ['c_dtmb_2', 'gif'],
            ['c_fm_2', 'gif'],
            ['d_4g_2', 'gif'],
            ['d_dvb-c_2', 'gif'],
            ['d_ip_2', 'gif'],
            ['default', 'png'],
          ])
          let content = null;
          if(context.data[0].data.cname){
            content = `<image src="${require(`../assets/video.png`)}"></image>`
          }else {
            let ob = `${pub.get(context.data[0].data.type)}_${type.get(context.data[0].data.communicationMode)}_${context.data[0].data.workStatus}`;
            content = `<image src="${require(`../assets/homeIcon/${ob}.${imgType.get(ob) || imgType.get('default')}`)}"></image>`
          }
          context.marker.setContent(content)
        }

        function clearInfoWindow(){
          state.map.clearInfoWindow();
        }

        function createInfoWindow(mk){
          let info = document.createElement('div');
          info.style =  `width: 392px;
                           background: #0E7C5D;
                           opacity:.7;
                           border: 1px solid #0E7C5D;
                           position: relative;
                           border-radius: 5px;`;
          let closeX = document.createElement('div');
          closeX.onclick = clearInfoWindow;
          closeX.innerHTML = 'X';
          closeX.style = `  position: absolute;
                             right: 20px;
                             top: 10px;
                             color: #00D3A4;
                             font-size: 28px`;
          info.innerHTML = `
                      <div style="display: flex;flex-direction: column; padding:20px; color: #00FFC6; font-size: 18px;">
                        <div style="display:flex; justify-content:flex-start;margin-bottom: 2px;">
                          <span style="white-space:nowrap">设备名称：</span>
                          <span>${mk.name}</span>
                        </div>
                        <div style="display:flex; justify-content:flex-start;margin-bottom: 2px;">
                          <span style="white-space:nowrap">设备类型名称：</span>
                          <span>${mk.typeName}</span>
                        </div>
                        <div style="display:flex; justify-content:flex-start;margin-bottom: 2px;">
                          <span style="white-space:nowrap">资源编码：</span>
                          <span>${mk.code}</span>
                        </div>
                        <div style="display:flex; justify-content:flex-start;margin-bottom: 2px;">
                          <span style="white-space:nowrap">安装位置：</span>
                          <span>${mk.installAddr || '暂无位置'}</span>
                        </div>
                        <div style="display:flex; justify-content:flex-start;margin-bottom: 2px;">
                          <span style="white-space:nowrap">经纬度：</span>
                          <span>${mk.latitude},${mk.longitude}</span>
                        </div>
                        <div style="display:flex; justify-content:flex-start;margin-bottom: 2px;">
                          <span style="white-space:nowrap">时间：</span>
                          <span>${mk.createTime}</span>
                        </div>
                        <div style="display:flex; justify-content:flex-start;margin-bottom: 2px;">
                          <span style="white-space:nowrap">是否具有回传：</span>
                          <span>${mk.rebackFlag == 1 ? '是' : '否'}</span>
                        </div>
                        <div style="display:flex; justify-content:flex-start;margin-bottom: 2px;">
                          <span style="white-space:nowrap">所属区域：</span>
                          <span>${mk.regionName}</span>
                        </div>
                    </div>`;
          info.appendChild(closeX);
          return info;
        }
        state.cluster = new AMap.MarkerClusterer(state.map, state.viaMarker, { gridSize: 20, renderMarker : _renderMarker });
        state.cluster.on('click',(item)=>{
          let _cluster = item.cluster.p.data;
          if(_cluster.cname){
            dialogVisible.value = true;
            state.infoWindow = _cluster;
          }else {
            var infoWindow = new AMap.InfoWindow({
              isCustom: true,  //使用自定义窗体
              content: createInfoWindow(_cluster),
              offset: new AMap.Pixel(9, -9)
            });
            infoWindow.open(state.map, [_cluster.longitude,_cluster.latitude]);
          }
        })

        // 增加右键弹框
        if(!state.contextMenu) state.contextMenu = new AMap.ContextMenu();
        state.contextMenu.addItem("关闭聚合", ()=> {
          state.cluster.setMaxZoom(1);
          state.contextMenu.close();
        }, 0);
        state.contextMenu.addItem("开启聚合", ()=> {
          state.cluster.setMaxZoom(18);
          state.contextMenu.close();
        }, 1);
        state.map.on('rightclick', function (e) {
          state.contextMenu.open(state.map, [e.lnglat.lng, e.lnglat.lat]);
        });

        state.cluster.setMaxZoom(1);
      });
    }

    function bkChart(data){
      var chartDom = document.getElementById('bkChart');
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        tooltip: {},
        grid: {
          bottom: "15%",
          right: "5%",
          top: "15%",
          left: "10%"
        },
        xAxis: {
          boundaryGap: false,
          type: 'category',
          data: ['00-04时', '04-08时', '08-12时', '12-16时', '16-20时', '20-24时'],
          splitLine: {
            show: false
          },
        },
        yAxis: {
          type: 'value',
          max: 6,
          interval: 2,
          splitLine: {
            show: false
          },
        },
        series: [
          {
            data: data.num,
            type: 'bar',
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#00FFC6' },
                { offset: 1, color: '#041C18' }
              ])
            }
          }
        ]
      };

      option && myChart.setOption(option);
    }
    function laiyuanChart(data){
      var chartDom = document.getElementById('laiyuan');
      var myChart = echarts.init(chartDom);
      var option;
      var maxNum = ''
      data.map(item=>item.num).forEach(v => {
        maxNum = maxNum > v ? maxNum : v
      })
      var xDataMax = new Array(data.length).fill(maxNum * 1.4);
      let barColor = [];
      function initColor(){
        for (let i=0,len=data.length;i<len;i++){
          barColor[i] = '#00FFC6';
        }
      }
      initColor();
      option = {
        grid: {
          bottom: "4%",
          right: "10%",
          top: "4%",
          left: "15%"
        },
        xAxis: {
          type: 'value',
          axisLine: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(29,38,38,.8)"
            }
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: [
          {
            type: 'category',
            inverse: true,
            data: data.map(item=>item.name),
            axisLine: {
              show: true,
              lineStyle: {
                color: "rgba(110,139,98,.2)"
              }
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(29,38,38,.8)"
              }
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: '#00FFC6',
                fontSize: 14,
              },
            },
          },
        ],
        series: [
          {
            type: 'bar',
            barWidth: '40%',
            data: data.map(item=>item.num),
            itemStyle: {
              normal: {
                //这里是颜色
                color: function(params) {
                  return barColor[params.dataIndex]
                }
              }
            },
            z: 1,
          },
          {
            type: 'bar',
            barWidth: '80%',
            barGap: '-160%',
            label: {
              offset: [6, 3],
              show: true,
              position: 'right',
              fontSize: 14,
              formatter: (params) => {
                const dataIndex = params.dataIndex
                const showData = data.map(item=>item.num)[dataIndex]
                return `{z|${showData}}`
              },
              rich: {
                z: {
                  borderWidth: 0.5,
                  borderColor: '#8dffd9',
                  width: 30,
                  height: 20,
                  lineHeight: 20,
                  backgroundColor: '#102520',
                  align: 'center',
                  color: '#00FFC5'
                }
              }
            },
            itemStyle: {
              color: 'rgba(0,0,0,0)',
              borderWidth: 0,
            },
            data: xDataMax,
            z: 2,
          },
        ]
      };
      option && myChart.setOption(option);

      myChart.on('mouseover', function(e){
        barColor[e.dataIndex] = '#f5f576';
        myChart.setOption(option);
      })

      myChart.on('mouseout', function(e){
        initColor();
        myChart.setOption(option);
      })
    }
    function tongyiChart(data){
      var chartDom = document.getElementById('tongji');
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        tooltip: {},
        grid: {
          bottom: "15%",
          right: "5%",
          top: "10%",
          left: "10%"
        },
        xAxis: {
          type: 'category',
          show: true,
          data: ['00-04时', '04-08时', '08-12时', '12-16时', '16-20时', '20-24时'],
          splitLine: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: "#3b7c6d"
            }
          }
        },
        yAxis: {
          show: true,
          type: 'value',
          minInterval:1,
          splitLine: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: "#3b7c6d"
            }
          }
        },
        series: [
          {
            data: data[0],
            type: 'line',
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#FF3C22'
                },
                {
                  offset: 1,
                  color: '#554225'
                }
              ])
            },
            lineStyle: {
              color: "#FF3C22"
            }
          },
          {
            data: data[1],
            type: 'line',
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#FF7800'
                },
                {
                  offset: 1,
                  color: '#554225'
                }
              ])
            },
            lineStyle: {
              color: "#FF7800"
            }
          },
          {
            data: data[2],
            type: 'line',
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#F3E114'
                },
                {
                  offset: 1,
                  color: '#554225'
                }
              ])
            },
            lineStyle: {
              color: "#F3E114"
            }
          },
          {
            data: data[3],
            type: 'line',
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#0BE5FF'
                },
                {
                  offset: 1,
                  color: '#554225'
                }
              ])
            },
            lineStyle: {
              color: "#0BE5FF"
            }
          },
          {
            data: data[4],
            type: 'line',
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#FFFFFF'
                },
                {
                  offset: 1,
                  color: '#FFFFFF'
                }
              ])
            },
            lineStyle: {
              color: "#FFFFFF"
            }
          }
        ]
      };

      option && myChart.setOption(option);
    }
    function jibieChart(){
      var chartDom = document.getElementById('jibiechart');
      var myChart = echarts.init(chartDom);
      var option = {
        legend:[
          {
            top: "10%",
            right: "35%",
            textStyle: {
              color: "#ffffff",
              fontSize: 20,
              fontWeight: "bold"
            },
            data:['重大'],
            icon: "pin",
            formatter:(name)=>{
              return name + '\n\n' + '5%'
            }
          },
          {
            top: "10%",
            right: "15%",
            textStyle: {
              color: "#ffffff",
              fontSize: 20,
              fontWeight: "bold"
            },
            formatter:(name)=>{
              return name + '\n\n' + '5%'
            },
            data:['较大'],
            icon: "pin"
          },
          {
            top: "60%",
            right: "35%",
            textStyle: {
              color: "#ffffff",
              fontSize: 20,
              fontWeight: "bold"
            },
            formatter:(name)=>{
              return name + '\n\n' + '5%'
            },
            data:['一般'],
            icon: "pin"
          },
          {
            data:['日常'],
            top: "60%",
            right: "15%",
            icon: "pin",
            textStyle: {
              color: "#ffffff",
              fontSize: 20,
              fontWeight: "bold"
            },
            formatter:(name)=>{
              return name + '\n\n' + '5%'
            },
          }
        ],
        series: [
          {
            type: 'pie',
            radius: ['60%', '80%'],
            center: ["25%", "53%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '20',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 1048, name: '重大' },
              { value: 735, name: '较大' },
              { value: 580, name: '一般' },
              { value: 484, name: '日常' },
            ]
          }
        ]
      }
      option && myChart.setOption(option);
    }
    function handleClose(done){
      done()
      isViode.value = true;
      state.flvPlayer.pause();
    }
    function lslx(){
      isViode.value = false;
      nextTick(()=>{
        if(flvjs.isSupported()){
          let videoElement = document.getElementById('videoElement');
          state.flvPlayer = flvjs.createPlayer({type: 'flv', cors: true, url: state.infoWindow.history});
          state.flvPlayer.attachMediaElement(videoElement);
          state.flvPlayer.load(); //加载
          state.flvPlayer.play();//播放
        }
      })
    }
    return{
      optionScroll,
      issetCenter,
      lslx,
      isViode,
      handleClose,
      dialogVisible,
      laiyuanTotal,
      notNum,
      offSum,
      onlineSum,
      viodeUrl,
      ...toRefs(state)
    }
  },
  data() {
    return {
      height: "",
    };
  },
  created() {
    let height =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    this.height = height + "px";
  },
};
</script>

<style scoped lang="less">
.mapbox{
  width: 100px;
  height: 100px;
  background: red;
}

.map-hed{
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translate(-50%, 0);
  display: grid;
  grid-template-columns: 939px;
  grid-template-rows: 69px 1px 69px;
  .line{
    background: url("../assets/line.png") no-repeat center;
    background-size: 100% 100%;
  }
  .map-hed-top{
    background: linear-gradient(90deg, rgba(#093637,.25) 33%, rgba(#44A08D,.25) 66%,  rgba(#093637,.25) 100%);
    font-size: 24px;
    font-weight: normal;
    color: #FFFFFF;
    line-height: 69px;
    display: grid;
    grid-template-columns: repeat(7,134px);
    div:not(:first-child){
      position:relative;
      &::before{
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0,-50%);
        content: '';
        display: block;
        width: 2px;
        height: 28px;
        background-image: linear-gradient(#13484d, #2fcd8d, #13484d);
      }
    }
  }
  .bom{
    display: grid;
    grid-template-columns: repeat(7,134px);
    font-size: 17px;
    font-weight: bold;
    color: #F5C900;
    line-height: 69px;
    div:not(:first-child){
      position:relative;
      &::before{
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0,-50%);
        content: '';
        display: block;
        width: 2px;
        height: 28px;
        background-image: linear-gradient(#13484d, #2fcd8d, #13484d);
      }
    }
    div:nth-child(1){
      border: none;
    }
  }
}

.map-tip{
  width: 200px;
  //height: 470px;
  height: 523px;
  position: absolute !important;
  bottom: 20px;
  right: 480px;
  z-index: 99;
  display: grid;
  grid-template-columns: 1fr;
  //grid-template-rows: 330px 130px;
  grid-template-rows: 383px 130px;
  grid-row-gap: 10px;
  >div{
    border: 1px solid #0E7C5D;
    background: rgba(#071010,.5);
    .title{
      font-size: 20px;
      font-weight: bold;
      color: #00FFC6;
      margin: 11px 0 10px 16px;
      text-align: left;
    }
    .fff{
      display: flex;
      flex-wrap:wrap;
      >div{
        flex-basis: 50%;
        font-size: 18px;
        font-weight: 400;
        color: #5FF12B;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        i{
          display: block;
          width: 15px;
          height: 15px;
          border-radius: 100%;
          margin-right: 15px;
          margin-left: 17px;
        }
      }
      >div:nth-child(1) i{
        background: #5FF12B;
        box-shadow: 0px 0px 0px 5px #266124;
      }
      >div:nth-child(2) i{
        background: #4DBAF2;
        box-shadow: 0px 0px 0px 5px #204657;
      }
      >div:nth-child(3) i{
        background: #F59F25;
        box-shadow: 0px 0px 0px 5px #523e19;
      }
      >div:nth-child(4) i{
        background: #F73F3F;
        box-shadow: 0px 0px 0px 5px #532121;
      }
    }
    .dis{
      display: flex;
      align-items: center;
      height: 53px;
      img{
        margin-left: 26px;
        width: 30px;
        height: 30px;
      }
      span{
        font-size: 20px;
        font-weight: 400;
        color: #00FFC6;
        margin-left: 34px;
      }
    }
    .bom{
      display: flex;
      align-items: center;
      flex-wrap:wrap;
      font-size: 18px;
      font-weight: 400;
      color: #00FFC6;
      >div{
        flex-basis: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        span{
          width: 80px;
        }
      }
    }
  }
}
.fill {
  margin: 0;
  padding: 0;
  width: 100%;
}

#container {
  width: 100%;
  height: 100%;
}


.mainBlock {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 92%;
}

.top {
  width: 100%;
  height: 80px;
  position: absolute;
  left: 0;
  top: 0;
}
.side-fill-right{
  position: absolute;
  right: 0;
  top: 9%;
  width: 470px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 280px);
  grid-row-gap: 10px;
  grid-column-gap: 10px;
  >div{
    position: relative;
    .tit{
      width: 300px;
      height: 32px;
      line-height: 32px;
      position: absolute;
      top: 8px;
      left: 30px;
      font-size: 18px;
      font-weight: 400;
      color: #FFFFFF;
      z-index: 99;
      text-align: left;
    }
    .tu{
      font-size: 20px;
      z-index: 9;
      top: 60px;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      width: 100%;
      height: 265px;
      .bokong{
        width: 400px;
        height: 47px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .bk-top{
          display: flex;
          align-items: center;
          img{
            width: 47px;
            height: 47px;
            margin-right: 15px;
          }
          .bk-top-text{
            font-size: 18px;
            font-weight: 400;
            color: #00FFC6;
          }
        }
      }
      .video{
        width: calc(100% - 152px);
        height: 200px;
        object-fit: cover;
        margin: 0 76px;
      }
      &.laiyuan{
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 56px calc(100% - 100px);
        top: 45px;
        height: 260px;
        .laiyuan-top{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-items: center;
          font-size: 14px;
          font-weight: 400;
          color: #FFFFFF;
          .laiyuan-top-bg{
            width: 202px;
            height: 38px;
            background: url("../assets/laiyuan-bg.png") no-repeat center;
            background-size: 100% 100%;
            font-size: 24px;
            font-weight: 400;
            line-height: 38px;
            color: #FFFFFF;
          }
        }
      }
    }
    &::after {
      content: "";
      background: url("../assets/indexbg.png") no-repeat center;
      background-size: 100% 100%;
      width: 470px;
      height: 280px;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
    }
  }
}
.side-fill-left {
  position: absolute;
  left: 0;
  top: 9%;
  width: 470px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 280px);
  grid-row-gap: 10px;
  grid-column-gap: 10px;
  >div{
    position: relative;
    .tit{
      width: 300px;
      height: 32px;
      line-height: 32px;
      position: absolute;
      top: 8px;
      left: 30px;
      font-size: 18px;
      font-weight: 400;
      color: #FFFFFF;
      z-index: 99;
      text-align: left;
    }
    .tu{
      font-size: 20px;
      z-index: 9;
      top: 45px;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      width: 100%;
      height: 233px;
      .num-b{
        padding: 0 10px;
        margin-top: 10px;
        height: 88px;
        overflow: auto;
        &::-webkit-scrollbar {
          display: none;
        }
        .num-b-table{
          width: 100%;
          thead tr{
            background: #0e2822;
            height: 30px;
            font-size: 16px;
            font-weight: 300;
            color: #54A493;
          }
          tbody tr{
            font-size: 16px;
            font-weight: 300;
            color: #95D7C8;
          }
        }
      }
      .num-t{
        padding: 0 10px;
        width: 100%;
        height: 135px;
        display: flex;
        align-items: center;
        .num-t-l{
          background: url("../assets/circlebg.png") no-repeat center;
          background-size: 100% 100%;
          width: 135px;
          height: 135px;
          margin-right: 20px;
          position: relative;
          .num-t-l-in{
            display: flex;
            flex-direction: column;
            font-weight: 400;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            align-items: center;
            justify-items: center;
            div:nth-child(1){
              font-size: 30px;
              color: #FFFFFF;
            }
            div:nth-child(2){
              font-size: 16px;
              color: #95D7C8;
            }
          }
        }
        .num-t-r{
          width: calc(100% - 155px);
          height: 135px;
          .num-t-r-bom{
            margin-top: 13px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .num-t-r-bom-list{
              display: flex;
              align-items: center;
              justify-content: space-between;
              .num-t-r-bom-list-l{
                font-size: 18px;
                font-weight: 300;
                color: #95D7C8;
                display: flex;
                align-items: center;
                &:before{
                  content:"";
                  display: block;
                  width: 10px;
                  height: 10px;
                  background: #00FFC6;
                  border-radius: 50%;
                  margin-right: 10px;
                  box-shadow: 0px 0px 0px 5px #055a49;
                }
              }
              .num-t-r-bom-list-line{
                width: 113px;
                height: 1px;
                border: 1px #163B33 dashed;
              }
              .num-t-r-bom-list-r{
                font-size: 18px;
                font-weight: 400;
                color: #00FFC6;
              }
            }
          }
          .num-t-r-top{
            width: 100%;
            height: 38px;
            display: flex;
            align-items: center;
            justify-content: space-around;
            .num-t-r-top-text{
              height: 38px;
              background: #0E2722;
              border: 1px solid #327566;
              width: 106px;
              line-height: 38px;
              font-size: 18px;
              font-weight: 300;
              color: #00FFC6;
            }
            .num-t-r-top-num{
              width: 36px;
              height: 38px;
              background: #0E2722;
              border: 1px solid #327566;
              line-height: 38px;
              font-size: 30px;
              font-weight: 400;
              color: #00FFC6;
            }
          }
        }
      }
      .hed{
        display: flex;
        align-items: center;
        justify-content: space-around;
        .tip{
          display: flex;
          flex-direction: column;
          align-items: center;
          div:nth-child(1){
            font-size: 16px;
            font-weight: 400;
            color: #00FFC5;
          }
          div:nth-child(2){
            font-size: 14px;
            font-weight: 300;
            color: #95D7C8;
            display: flex;
            align-items: center;
            &:before{
              content:"";
              display: block;
              width: 3px;
              height: 12px;
              margin-right: 6px;
            }
          }
        }

        .tip:nth-child(1) div:nth-child(2){
          &:before{
            background: #FF3C22;
          }
        }

        .tip:nth-child(2) div:nth-child(2){
          &:before{
            background: #FF7800;
          }
        }

        .tip:nth-child(3) div:nth-child(2){
          &:before{
            background: #F3E114;
          }
        }

        .tip:nth-child(4) div:nth-child(2){
          &:before{
            background: #0BE5FF;
          }
        }

        .tip:nth-child(5) div:nth-child(2){
          &:before{
            background: #FFFFFF;
          }
        }
      }
      &.bobao{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: auto;
        scrollbar-width: none;
        .seamless-warp{
          height: 273px;
          overflow: hidden;
        }
        &::-webkit-scrollbar{
          display: none;
        }
        .list{
          width: 460px;
          height: 79px;
          border-top: 6px solid #0a403c;
          background: rgba(#0C352B,.2);
          display: grid;
          grid-template-columns: 450px;
          grid-template-rows: 33px 40px;
          justify-content: center;
          .t{
            display: flex;
            align-items: center;
            justify-content: space-between;
            div:nth-child(1){
              font-size: 18px;
              font-weight: 400;
              color: #00FFC6;
            }
            div:nth-child(2){
              padding: 2px 15px 2px 15px;
              font-size: 12px;
              font-weight: 300;
              &.acitve1{
                background: #1B0805;
                border: 1px solid #FF3C22;
                color: #FF3C22;
              }
              &.acitve2{
                border: 1px solid #FF7800;
                color: #FF7800;
              }
              &.acitve3{
                background: #1C1906;
                border: 1px solid #F3E114;
                color: #F0E316;
              }
              &.acitve4{
                background: #09142A;
                border: 1px solid #0BE5FF;
                color: #0BE5FF;
                font-weight: 300;
              }
            }
          }
          .b{
            display: flex;
            align-items: center;
            justify-content: space-between;
            >div{
              font-size: 14px;
              font-family: Source Han Sans SC;
              font-weight: 300;
              color: #95D7C8;
            }
          }
        }
      }
    }
    &::after {
      content: "";
      opacity:0.9;
      background: url("../assets/indexbg.png") no-repeat center;
      background-size: 100% 100%;
      width: 470px;
      height: 280px;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
    }
  }
}
</style>
