import axios from "@/api/axios"; // 引用axios
//获取预案列表
export const getList = (page, limit, params) => {
  const param = {
    ...params,
    page,
    limit
  };
  return axios({
    url: "/api/plan/listData",
    method: "post",
    params: param
  });
};
//新增预案
export const addData = (params) => {
  return axios({
    url: "/api/plan/addData",
    method: "post",
    params: params
  });
};
//修改预案
export const editData = (params) => {
  return axios({
    url: "/api/plan/editData",
    method: "post",
    params: params
  });
};
//预案详情
export const getDetail = (id) => {
  return axios({
    url: "/api/plan/getReservePlan",
    method: "post",
    params: {
      id
    }
  });
};
//更改预案状态(id和state)
export const changeStatus = (params) => {
  return axios({
    url: "/api/plan/upState",
    method: "post",
    params: params
  });
};