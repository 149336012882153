<template>
  <div class="main">
    <div class="line"></div>
    <div class="box">
        <div class="inbox" v-for="(item,index) in data" :key="index">
          <div class="img">
            <img :src="item.picture">
          </div>
          <div class="on">
            <span>{{item.name}}</span>
            <div>
              <div style="margin-right: 10px" @click="xg(item.address)">效果预览</div>
              <div @click="fz(item.address)">复制链接</div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { dataList } from "../../api/screen/dpzs";

import {onMounted, reactive, toRefs} from "vue";
import {ElMessage} from "element-plus";

export default {
  setup(){
    function xg(_url){
      window.location.href = _url;
    }
    function Copy(str) {
      var save = function (e) {
        //设置需要复制模板的内容
        e.clipboardData.setData('text/plain',str);
        //阻止默认行为
        e.preventDefault();
      }
      // h5监听copy事件，调用save函数保存到模板中
      document.addEventListener('copy',save);
      // 调用右键复制功能
      document.execCommand('copy');
      //移除copy事件
      document.removeEventListener('copy',save);
      ElMessage({
        message: '复制成功',
        type: 'success',
      })
    }
    function fz(url){
      Copy(url)
    }
    onMounted(()=>{
      dataList().then(res=>{
        state.data = res.data.data;
      })
    })
    let state = reactive({
      data:[]
    })
    return{
      fz,
      xg,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="scss" scoped>
.main{
  width: 100%;
  min-height: 870px;
  background: #0A2B28;
  border: 1px solid #2D6156;
  .line{
    width: 100%;
    height: 10px;
    background: #0E3D34;
  }
  .box{
    margin: 40px;
    width: calc(100% - 80px);
    height: auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px 30px;
    .inbox{
      height: 296px;
      width: 100%;
      .img{
        width: 100%;
        height: 240px;
        margin-bottom: 20px;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .on{
        width: 100%;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span{
          font-size: 20px;
          font-weight: 300;
          color: #95D7C8;
        }
        >div{
          display: flex;
          align-items: center;
          div{
            width: 81px;
            height: 36px;
            background: #091a15;
            font-size: 16px;
            font-weight: 300;
            color: #95D7C8;
            line-height:36px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>