<template>
  <div class="searchBox">
    <el-form :model="search" :label-width="searchLabelWidth" ref="searchForm">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="预案名称" prop="planName">
            <el-input
              v-model="search.planName"
              autocomplete="off"
              placeholder="请输入 预案名称"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <!--              <el-form-item>-->
          <el-button plain @click="searchFormFn('searchForm')" class="searchBtn"
          >查询
          </el-button
          >
          <el-button plain @click="resetForm('searchForm')" class="resetBtn"
          >清空
          </el-button
          >

          <!--              </el-form-item>-->
        </el-col>
      </el-row>
    </el-form>
    <div class="divide">
      <img src="../../assets/divide.png" style="width: 100%" />
    </div>
    <div class="btnContainer">
      <div class="btnLeft">
        <el-button
          icon="Plus"
          @click="dialogFormVisible = true;title='新增'"
          class="searchBtn"
        >新增
        </el-button
        >
      </div>
    </div>
  </div>
  <basicTable
    :attrList="attrList"
    :tableData="tableData"
    ref="table"
    :page="page.current"
    :limit="page.limit"
    :total="page.total"
    @currentChange="handleCurrentChange"
    @sizeChange="handleSizeChange"
  >
  </basicTable>
  <el-dialog
    width="60%"
    v-model="dialogFormVisible"
    :title="title"
    :close-on-click-modal="false"
    :before-close="handleClose"

  >
    <el-form
      label-position="right"
      :model="form"
      size="small"
      :label-width="formLabelWidth"
      ref="dialogForm"
    >
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            label="预案名称"
            :rules="[{ required: true, message: '预案名称 不能为空' }]"
            prop="planName"
          >
            <el-input
              v-model="form.planName"
              autocomplete="off"
              clearable
              placeholder="请输入 预案名称"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="广播类型" prop="bcastType" :rules="[{ required: true, message: '广播类型 不能为空' }]">
            <el-select
              v-model="form.bcastType"
              placeholder="请选择 广播类型"
              clearable
            >
              <el-option
                :label="item.name"
                :value="item.code"
                v-for="(item, index) in basicData.bcastType"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="事件类型" prop="eventType" :rules="[{ required: true, message: '事件类型 不能为空' }]">
            <el-select
              v-model="form.eventType"
              placeholder="请选择 事件类型"
              clearable
              filterable
            >
              <el-option
                :label="item.name"
                :value="item.code"
                v-for="(item, index) in basicData.eventType"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="事件级别" prop="eventLevel" :rules="[{ required: true, message: '事件级别 不能为空' }]">
            <el-select
              v-model="form.eventLevel"
              placeholder="请选择 事件级别"
              clearable
            >
              <el-option
                :label="item.name"
                :value="item.code"
                v-for="(item, index) in basicData.eventLevel"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="节目" prop="programId" :rules="[{ required: true, message: '节目 不能为空' }]">
            <el-select
              v-model="form.programId"
              placeholder="请选择 节目"
              clearable
            >
              <el-option
                :label="item.name"
                :value="item.id"
                v-for="(item, index) in basicData.programLibList"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="备注" prop="msg">
            <el-input
              type="textarea"
              v-model="form.msg"
              autocomplete="off"
              clearable
              placeholder="请输入 备注"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button
          type="primary"
          size="small"
          @click="handlesubmit('dialogForm')"
        >提交
        </el-button>
        <el-button @click="quxiao" size="small"
        >取消
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import {nextTick, onMounted, reactive, ref, toRefs} from "vue";
import { ElMessage } from "element-plus";
import { getList, addData, editData, getDetail, changeStatus } from "@/api/schedule/preplanManage";
import { getbasicData } from "@/api/basic/startBroadcast";

export default {
  name: "preplanManage",
  setup() {
    const option = reactive({
      form: {},
      searchLabelWidth: "140px",
      search: {},
      page: {
        current: 1,
        limit: 10,
        total: 0
      },
      tableData: [],
      attrList: [
        {
          label: "预案名称",
          prop: "planName",
          render: (row) => <div>{row.planName}</div>
        },
        {
          label: "广播类型",
          prop: "bcastType",
          render: (row) => {
            return <div>{row.bcastTypeName}</div>;
          }
        },
        {
          label: "事件类型",
          prop: "eventTypeName",
          render: (row) => <div>{row.eventTypeName}</div>

        }, {
          label: "事件等级",
          prop: "eventLevelName",
          render: (row) => <div>{row.eventLevelName}</div>
        }, {
          label: "状态",
          prop: "state",
          render: (row) => <div>{row.state == 1 ? "启用" : (row.state == 2 ? "禁用" : "删除")}</div>
        }, {
          label: "添加时间",
          prop: "addTime",
          render: (row) => <div>{row.addTime}</div>
        }, {
          label: "备注",
          prop: "msg",
          render: (row) => <div>{row.msg}</div>
        },
        {
          prop: "",
          label: "操作",
          width: 250,
          render: (row) => {
            return (
              <div>
                <el-button
                  type="text"
                  icon="Edit"
                  onClick={() => handleEdit(row.id)}
                >编辑</el-button>
                <el-button
                    type="text"
                    icon="Delete"
                    onClick={() => handleDel(row.id)}
                >删除</el-button>
                <el-button
                  type="text"
                  icon="Sort"
                  onClick={() => handleStatus(row.id, row.state)}
                >切换状态
                </el-button>
              </div>
            );
          }
        }
      ],
      basicData: {},
      title: "新增",
      dialogFormVisible: false,
      formLabelWidth: "120px"
    });
    const searchForm = ref();
    const dialogForm = ref(null);

    function getData(page) {
      getList(page.current, page.limit, option.search).then(res => {
        if (res.data.success) {
          option.tableData = getName(res.data.data.list);
          option.page.total = res.data.data.count;
        }
      });

    }

    function getName(data) {
      data.map(item => {
        let obj = option.basicData.bcastType.find(i => i.code == item.bcastType)||{};
        let obj1 = option.basicData.eventType.find(i => i.code == item.eventType)||{};
        let obj2 = option.basicData.eventLevel.find(i => i.code == item.eventLevel)||{};
        let obj3 = option.basicData.programLibList.find(i => i.id == item.programId)||{};
        item.bcastTypeName = obj.name;
        item.eventTypeName = obj1.name;
        item.eventLevelName = obj2.name;
        item.programName = obj3.name;
      });
      console.log(data);
      return data;
    }

    function searchFormFn() {
      option.page.current = 1;
      getData(this.page);
    }

    function resetForm() {
      searchForm.value.resetFields();
      option.page.current = 1;
      getData(this.page);
    }


    function handleEdit(id) {
      getDetail(id).then((res) => {
        if (res.data.success) {
          option.form = res.data.data;
          option.form.bcastType = option.form.bcastType + "";
          option.form.eventLevel = option.form.eventLevel + "";
          option.dialogFormVisible = true;
          option.title = "编辑";
        }
      });
    }

    function handleAdd() {
      option.title = "新增";
      option.dialogFormVisible = true;
    }

    function handleDel(id){
      changeStatus({ id, state:3 }).then(res => {
        if (res.data.success) {
          getData(option.page);
          ElMessage({
            type: "success",
            message: "操作成功！"
          });
        }
      });
    }

    function handleStatus(id, state) {
      changeStatus({ id, state }).then(res => {
        if (res.data.success) {
          getData(option.page);
          ElMessage({
            type: "success",
            message: "操作成功！"
          });
        }
      });
    }

    function handleCurrentChange(currentPage) {
      option.page.current = currentPage;
      getData(option.page);
    }

    function handleSizeChange(pageSize) {
      option.page.limit = pageSize;
      getData(option.page);
    }

    function handlesubmit() {
      dialogForm.value.validate((valid) => {
        if (valid) {
          let fn = option.title == "新增" ? addData : editData;
          let { planName, bcastType, eventType, eventLevel, programId, msg, id } = option.form;
          fn({ planName, bcastType, eventType, eventLevel, programId, msg, id }).then((res) => {
            if (res.data.success) {
              getData(option.page);
              nextTick(()=>{
                option.form = {};
                dialogForm.value.resetFields();
              })
              ElMessage({
                message: "操作成功",
                type: "success"
              });
              option.dialogFormVisible = false;
            } else {
              ElMessage({
                message: res.data.code.message,
                type: "error"
              });
            }
          });
        } else return false;
      });
    }

    function quxiao(){
      option.dialogFormVisible = false;
      nextTick(()=>{
        dialogForm.value.resetFields();
        option.form = {};
      })
    }

    function handleClose(done){
      option.form = {};
      dialogForm.value.resetFields();
      done();
    }

    onMounted(async () => {
      let res = await getbasicData(1);
      if (res.data.success) {
        option.basicData = res.data.data;
      }
      getData(option.page);
    });
    return {
      handleClose,
      quxiao,
      ...toRefs(option),
      getData,
      searchForm,
      dialogForm,
      searchFormFn,
      resetForm,
      handleAdd,
      handlesubmit,
      handleEdit,
      handleCurrentChange,
      handleSizeChange
    };
  }
};
</script>

<style scoped>

</style>