import axios from "@/api/axios"; // 引用axios

export const generateCode = (params) => {
    return axios({
        url: "/api/resource/point/generateCode",
        method: "post",
        params: params
    });
};

export const addData = (params) => {
    return axios({
        url: "/api/resource/point/addData",
        method: "post",
        params
    });
};

export const listData = (params) => {
    return axios({
        url: "/api/resource/point/listData",
        method: "post",
        params
    });
};

export const initData = () => {
    return axios({
        url: "/api/resource/point/initData",
        method: "get"
    });
};

export const initAdd = (params) => {
    return axios({
        url: "/api/resource/point/initAddData",
        method: "post",
        params
    });
};


export const editData = (params) => {
    return axios({
        url: "/api/resource/point/editData",
        method: "post",
        params
    });
};

export const dataDel = (params) => {
    return axios({
        url: "/api/resource/point/del",
        method: "post",
        params
    });
};

export const addPreinstallationImportExcel = (params) => {
    return axios({
        url: "/api/resource/point/pointImportExcel",
        method: "post",
        data:params
    });
};