<template>
  <el-row :gutter="24">
    <el-col :span="9">
      <el-form :model="instruction" :label-width="formLabelWidth" ref="instructForm" size="small">
        <el-form-item label="指令类型" prop="kind" :rules="[{ required: true, message: '指令类型 不能为空' }]">
          <el-radio-group v-model="instruction.kind">
            <div class="radioBox">
              <div>
                <div class="radio">
                  <el-radio :label="1">通用编码设置</el-radio>
                </div>
                <div class="radio">
                  <el-radio :label="2">通用音量设置</el-radio>
                </div>
                <div class="radio">
                  <el-radio :label="3">通用回传参数设置</el-radio>
                </div>
                <div class="radio">
                  <el-radio :label="4">通用被动回传查询指令</el-radio>
                </div>
                <div class="radio">
                  <el-radio :label="5">通用时钟校准</el-radio>
                </div>
              </div>
              <div>
                <div class="radio">
                  <el-radio :label="6">通用网络参数设置</el-radio>
                </div>
                <div class="radio">
                  <el-radio :label="7">通用功放开关指令</el-radio>
                </div>
                <div class="radio">
                  <el-radio :label="8">通用回传周期设置指令</el-radio>
                </div>
                <div class="radio">
                  <el-radio :label="9">指令回传设置</el-radio>
                </div>
              </div>
            </div>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="控制方式" prop="mold" :rules="[{ required: true, message: '控制方式 不能为空' }]"
                      v-if="instruction.kind !==5">
          <el-radio-group v-model="instruction.mold">
            <div class="radio">
              <el-radio :label="1">适配器</el-radio>
              <el-radio :label="2">终端</el-radio>
            </div>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="适配器" prop="adapter" :rules="[{ required: true, message: '适配器 不能为空' }]">
          <el-select
            clearable
            :fit-input-width="true"
            v-model="instruction.adapter"
            placeholder="请选择 适配器"
          >
            <el-option :label="item.name" :value="item.code" v-for="(item ,index) in adapterList"
                       :key="index"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="终端" prop="codesAndMacs" :rules="[{ required: true, message: '适配器 不能为空' }]"
                      v-if="terminalShow">
          <el-select
            :multiple="multiple"
            :fit-input-width="true"
            v-model="instruction.codesAndMacs"
            placeholder="请选择 终端"
          >
            <el-option :label="item.name" :value="item.name+'，'+item.code +'，'+item.macAddr"
                       v-for="(item ,index) in terminalList"
                       :key="index"></el-option>
          </el-select>
        </el-form-item>
        <!--        kind==2-->
        <transition name="allDescribe">
          <div v-if="instruction.kind==2">
            <el-form-item label="类型" prop="type" :rules="[{ required: true, message: '类型 不能为空' }]">
              <el-radio-group v-model="instruction.type">
                <div class="radio">
                  <el-radio :label="1">逻辑码寻址</el-radio>
                  <el-radio :label="2">物理码寻址</el-radio>
                </div>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="音量" prop="volume" :rules="[{ required: true, message: '音量 不能为空' }]">
              <el-radio-group v-model="instruction.volume">
                <div class="radio">
                  <el-radio :label="0">静音</el-radio>
                  <el-radio :label="null">不变</el-radio>
                  <el-radio :label="3">选值</el-radio>
                </div>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="音量值" prop="volumeNumber" :rules="[{ required: true, message: '音量 不能为空' }]"
                          v-if="instruction.volume==3">
              <el-slider v-model="instruction.volumeNumber"></el-slider>
            </el-form-item>
          </div>
        </transition>
        <!--        kind==3或4-->
        <transition name="allDescribe">
          <div v-if="instruction.kind==3||instruction.kind==4">
            <el-form-item label="类型" prop="type" :rules="[{ required: true, message: '类型 不能为空' }]">
              <el-radio-group v-model="instruction.type">
                <div class="radio">
                  <el-radio :label="1">逻辑码寻址</el-radio>
                  <el-radio :label="2">物理码寻址</el-radio>
                </div>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="回传地址类型"
              prop="state"
              :rules="[{ required: true, message: '回传地址类型 不能为空' }]"
            >
              <el-select
                :fit-input-width="true"
                v-model="instruction.state"
                placeholder="请选择 回传地址类型"
              >
                <el-option label="电话" :value="1"></el-option>
                <el-option label="ip+端口" :value="2"></el-option>
                <el-option label="域名+端口" :value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="instruction.state==1"
              label="电话"
              prop="rebackAddress"
              :rules="[{ required: true, message: '电话 不能为空' }]"
            >
              <el-input
                clearable
                autocomplete="off"
                v-model="instruction.rebackAddress"
                placeholder="请输入 电话"
              >
              </el-input>
            </el-form-item>
            <el-row v-if="instruction.state==3">
              <el-col :span="13">
                <el-form-item
                  label="域名"
                  prop="domain"
                  :rules="[{ required: true, message: '域名 不能为空' }]"
                >
                  <el-input
                    clearable
                    autocomplete="off"
                    v-model="instruction.domain"
                    placeholder="请输入 域名"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item
                  label="域名端口"
                  prop="domain_port"
                  :rules="[{ required: true, message: '域名端口 不能为空' }]"
                >
                  <el-input
                    clearable
                    autocomplete="off"
                    v-model="instruction.domain_port"
                    placeholder="请输入 域名端口"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row v-if="instruction.state==2">
              <el-col :span="13">
                <el-form-item
                  label="ip"
                  prop="ip"
                  :rules="[{ required: true, message: 'ip 不能为空' }]"
                >
                  <el-input
                    clearable
                    autocomplete="off"
                    v-model="instruction.ip"
                    placeholder="请输入 ip"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item
                  label="端口"
                  prop="ip_port"
                  :rules="[{ required: true, message: 'ip端口 不能为空' }]"
                >
                  <el-input
                    clearable
                    autocomplete="off"
                    v-model="instruction.ip_port"
                    placeholder="请输入 端口"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>

        </transition>
        <!--        kind==4-->
        <transition name="allDescribe">
          <div v-if="instruction.kind==4">
            <el-form-item
              label="查询信息类型"
              prop="param"
              :rules="[{ required: true, message: '查询信息类型 不能为空' }]"
            >
              <el-checkbox
                v-model="checkAll"
                :indeterminate="isIndeterminate"
                @change="handleCheckAllChange"
              >全部
              </el-checkbox
              >
              <el-checkbox-group v-model="instruction.param" @change="checkboxChange">
                <div class="checkbox">
                  <div>
                    <el-checkbox label="TERMINAL_VOLUME">终端音量</el-checkbox>
                    <el-checkbox label="LOCAL_ADDRESS">本地地址</el-checkbox>
                    <el-checkbox label="REBACK_ADDRESS">回传地址</el-checkbox>
                    <el-checkbox label="TERMINAL_RESOURCE_CODE">终端资源编码</el-checkbox>
                    <el-checkbox label="PHYSICAL_ADDRESS">物理地址编码</el-checkbox>

                    <el-checkbox label="WORK_STATUS">工作状态</el-checkbox>
                  </div>
                  <div>
                    <el-checkbox label="FAULT_TYPE">故障代码</el-checkbox>
                    <el-checkbox label="DEVICE_TYPE">设备类型</el-checkbox>
                    <el-checkbox label="HARDWARE_VERSION">硬件版本号</el-checkbox>
                    <el-checkbox label="SOFTWARE_VERSION">软件版本号</el-checkbox>

                    <el-checkbox label="FM_SIGNAL_STATUS">调频信号状态</el-checkbox>
                    <el-checkbox label="DVB_C_SIGNAL_STATUS">有线信号状态</el-checkbox>
                  </div>
                  <div>
                    <el-checkbox label="DVB_T_SIGNAL_STATUS">地面无线信号状态</el-checkbox>
                    <el-checkbox label="DVB_C_HZ">有线频率</el-checkbox>
                    <el-checkbox label="DVB_T_HZ">地面无线频率</el-checkbox>
                    <el-checkbox label="FM_HZ_VALUE_SCAN_LIST">FM频点扫描列表</el-checkbox>
                    <el-checkbox label="FM_CURRENT_HZ_VALUE">FM当前频点</el-checkbox>
                    <el-checkbox label="FM_KEEP_CMD_MODE">FM维持指令模式</el-checkbox>
                  </div>
                </div>
              </el-checkbox-group>
            </el-form-item>
          </div>

        </transition>
        <!--        kind==6-->
        <transition name="allDescribe">
          <div v-if="instruction.kind==6">
            <el-form-item label="类型" prop="type" :rules="[{ required: true, message: '类型 不能为空' }]">
              <el-radio-group v-model="instruction.type">
                <div class="radio">
                  <el-radio :label="1">逻辑码寻址</el-radio>
                  <el-radio :label="2">物理码寻址</el-radio>
                </div>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="IP地址"
              prop="ip"
              :rules="[{ required: true, message: 'IP地址 不能为空' }]"
            >
              <el-input
                clearable
                autocomplete="off"
                v-model="instruction.ip"
                placeholder="请输入 IP地址"
              >
              </el-input>
            </el-form-item>
            <el-form-item
              label="子网掩码"
              prop="subnetMask"
              :rules="[{ required: true, message: '子网掩码 不能为空' }]"
            >
              <el-input
                clearable
                autocomplete="off"
                v-model="instruction.subnetMask"
                placeholder="请输入 子网掩码"
              >
              </el-input>
            </el-form-item>
            <el-form-item
              label="网关"
              prop="gateway"
              :rules="[{ required: true, message: '网关 不能为空' }]"
            >
              <el-input
                clearable
                autocomplete="off"
                v-model="instruction.gateway"
                placeholder="请输入 网关"
              >
              </el-input>
            </el-form-item>
          </div>

        </transition>
        <!--        kind==7-->
        <transition name="allDescribe">
          <div v-if="instruction.kind==7">
            <el-form-item label="类型" prop="type" :rules="[{ required: true, message: '类型 不能为空' }]">
              <el-radio-group v-model="instruction.type">
                <div class="radio">
                  <el-radio :label="1">逻辑码寻址</el-radio>
                  <el-radio :label="2">物理码寻址</el-radio>
                </div>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="功放开关"
              prop="switchOption"
              :rules="[{ required: true, message: '功放开关 不能为空' }]"
            >
              <el-select
                clearable
                :fit-input-width="true"
                v-model="instruction.switchOption"
                placeholder="请选择 功放开关"
              >
                <el-option label="关闭" :value="1"></el-option>
                <el-option label="打开" :value="2"></el-option>
              </el-select>
            </el-form-item>
          </div>
        </transition>
        <!--        kind==8-->
        <transition name="allDescribe">
          <div v-if="instruction.kind==8">
            <el-form-item label="类型" prop="type" :rules="[{ required: true, message: '类型 不能为空' }]">
              <el-radio-group v-model="instruction.type">
                <div class="radio">
                  <el-radio :label="1">逻辑码寻址</el-radio>
                  <el-radio :label="2">物理码寻址</el-radio>
                </div>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="回传周期"
              prop="rebackCycle"
              :rules="[{ required: true, message: '回传周期（秒） 不能为空' }]"
            >
              <el-input-number
                :min="1"
                :max="60"
                clearable
                autocomplete="off"
                v-model="instruction.rebackCycle"
                placeholder="请输入 回传周期（秒）"
              >
              </el-input-number>
            </el-form-item>
          </div>
        </transition>
        <!--        kind==9-->
        <transition name="allDescribe">
          <div v-if="instruction.kind==9">
            <el-form-item
              label="回传地址类型"
              prop="state"
              :rules="[{ required: true, message: '回传地址类型 不能为空' }]"
            >
              <el-select
                :fit-input-width="true"
                v-model="instruction.state"
                placeholder="请选择 回传地址类型"
              >
                <el-option label="电话" :value="1"></el-option>
                <el-option label="ip+端口" :value="2"></el-option>
                <el-option label="域名+端口" :value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="instruction.state==1"
              label="电话"
              prop="rebackAddress"
              :rules="[{ required: true, message: '电话 不能为空' }]"
            >
              <el-input
                clearable
                autocomplete="off"
                v-model="instruction.rebackAddress"
                placeholder="请输入 电话"
              >
              </el-input>
            </el-form-item>
            <el-row v-if="instruction.state==3">
              <el-col :span="13">
                <el-form-item
                  label="域名"
                  prop="domain"
                  :rules="[{ required: true, message: '域名 不能为空' }]"
                >
                  <el-input
                    clearable
                    autocomplete="off"
                    v-model="instruction.domain"
                    placeholder="请输入 域名"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item
                  label="域名端口"
                  prop="domain_port"
                  :rules="[{ required: true, message: '域名端口 不能为空' }]"
                >
                  <el-input
                    clearable
                    autocomplete="off"
                    v-model="instruction.domain_port"
                    placeholder="请输入 域名端口"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row v-if="instruction.state==2">
              <el-col :span="13">
                <el-form-item
                  label="ip"
                  prop="ip"
                  :rules="[{ required: true, message: 'ip 不能为空' }]"
                >
                  <el-input
                    clearable
                    autocomplete="off"
                    v-model="instruction.ip"
                    placeholder="请输入 ip"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item
                  label="端口"
                  prop="ip_port"
                  :rules="[{ required: true, message: 'ip端口 不能为空' }]"
                >
                  <el-input
                    clearable
                    autocomplete="off"
                    v-model="instruction.ip_port"
                    placeholder="请输入 端口"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item
              label="回传周期"
              prop="rebackCycle"
              :rules="[{ required: true, message: '回传周期（秒） 不能为空' }]"
            >
              <el-input-number
                :min="1"
                :max="29"
                clearable
                autocomplete="off"
                v-model="instruction.rebackCycle"
                placeholder="请输入 回传周期（秒）"
              >
              </el-input-number>
            </el-form-item>
          </div>
        </transition>
        <!--        描述-->
        <transition name="allDescribe">
          <div class="allDescribe">
            <div v-if="instruction.adapter" class="describe">
              <el-tag class="ml-2" type="warning">{{ instruction.adapterName }} ，{{ instruction.code
                }}，{{ instruction.mac
                }}
              </el-tag>
            </div>
            <div v-if="instruction.codesAndMacs.length &&multiple">
              <div class="describe" v-for="(item,index) in  instruction.codesAndMacs" :key="index">
                <el-tag class="ml-2" type="success">{{ item }}</el-tag>
              </div>
            </div>
            <div v-else-if="!multiple&&instruction.codesAndMacs.length">
              <div class="describe">
                <el-tag class="ml-2" type="success">{{ instruction.codesAndMacs }}</el-tag>
              </div>
            </div>
          </div>
        </transition>


      </el-form>
      <el-button @click="sendInstruct('instructForm')" class="searchBtn"
      >发送指令
      </el-button
      >
    </el-col>
    <el-col :span="15">
      <div class="searchBox">
        <el-form :model="search" :label-width="searchLabelWidth" ref="searchForm">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="资源码" prop="code">
                <el-input
                  clearable
                  v-model="search.code"
                  autocomplete="off"
                  placeholder="请输入 资源码"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="物理码" prop="mac">
                <el-input
                  clearable
                  v-model="search.mac"
                  autocomplete="off"
                  placeholder="请输入 物理码"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="指令" prop="instructionId	">
                <el-select
                  v-model="search.instructionId	"
                  placeholder="请选择 指令"
                  clearable
                >
                  <el-option :label="item.name" :value="item.id" v-for="(item,index) in instructionData"
                             :key="index"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <!--              <el-form-item>-->
              <el-button @click="searchFormFn('searchForm')" class="searchBtn"
              >查询
              </el-button
              >
              <el-button @click="resetForm('searchForm')" class="resetBtn"
              >重置
              </el-button
              >

              <!--              </el-form-item>-->
            </el-col>
          </el-row>
        </el-form>
        <!--    <div class="divide">-->
        <!--      <img src="../../assets/divide.png" style="width: 100%" />-->
        <!--    </div>-->
        <!--    <div class="btnContainer">-->
        <!--      <div class="btnLeft">-->
        <!--        <el-button-->
        <!--          icon="Plus"-->
        <!--          @click="dialogFormVisible = true"-->
        <!--          class="searchBtn"-->
        <!--        >新增-->
        <!--        </el-button-->
        <!--        >-->
        <!--      </div>-->
        <!--    </div>-->
      </div>
      <basicTable
        :indexshow="false"
        :pageShow="false"
        :attrList="attrList"
        :tableData="tableData"
        ref="table"
        :page="page.current"
        :limit="page.limit"
        :total="page.total"
      >
      </basicTable>
    </el-col>
  </el-row>
</template>

<script>

import { onMounted, reactive, toRefs, watch, ref } from "vue";
import { getadapterParams } from "@/api/orchestrate/adapterQuery";
import {
  getList,
  instructionList,
  getterminalList,
  sendCode,
  sendVolume,
  sendParam,
  sendPassiveBack,
  sendClock,
  sendnetParams,
  sendSwitch,
  sendRebackCycle,
  sendReback
} from "@/api/orchestrate/generalPage";
import { getreturnQuery } from "@/api/orchestrate/manage";
import { ElMessage } from "element-plus";

export default {
  name: "generalPage",
  setup() {
    const instructForm = ref();
    const searchForm = ref();
    let option = reactive({
      instructionData: [],
      adapterList: [],
      terminalList: [],
      dialogFormVisible: false,
      title: "新增",
      terminalShow: false,
      instruction: {
        kind: 1,
        mold: 1,
        codesAndMacs: [],
        state: 1,
        param: []
      },
      multiple: true,
      checkAll: false,
      isIndeterminate: true,
      form: {},
      checkBoxVal: ["TERMINAL_VOLUME", "FM_KEEP_CMD_MODE", "FM_CURRENT_HZ_VALUE", "FM_HZ_VALUE_SCAN_LIST", "DVB_T_HZ", "DVB_C_HZ", "DVB_T_SIGNAL_STATUS",
        "DVB_C_SIGNAL_STATUS", "FM_SIGNAL_STATUS", "SOFTWARE_VERSION", "HARDWARE_VERSION", "DEVICE_TYPE", "FAULT_TYPE", "WORK_STATUS", "PHYSICAL_ADDRESS", "TERMINAL_RESOURCE_CODE", "REBACK_ADDRESS", "LOCAL_ADDRESS"],
      orderData: [],
      rebackAddressList: [],
      searchLabelWidth: "100px",
      formLabelWidth: "140px",
      search: {},
      page: {
        current: 1,
        limit: 10,
        total: 0
      },
      tableData: [],
      attrList: [
        {
          label: "资源编码",
          prop: "code",
          render: (row) => <div>{row.code}</div>
        }, {
          label: "设备物理码",
          prop: "mac",
          render: (row) => <div>{row.mac}</div>
        }, {
          label: "指令发送信息",
          prop: "sendDetail",
          render: (row) => <div>{row.sendDetail}</div>
        }, {
          label: "发送指令时间",
          prop: "startTime",
          render: (row) => <div>{row.startTime}</div>
        }, {
          label: "指令描述",
          prop: "msg",
          render: (row) => <div>{row.msg}</div>
        }
        // {
        //   prop: "",
        //   label: "操作",
        //   render: (row) => {
        //     let str = row.state == 1 ? "授权web" : "授权app";
        //     return (
        //       <div>
        //         <el-button
        //           type="text"
        //           icon="Edit"
        //           onClick={() => this.handleEdit(row)}
        //         ></el-button>
        //         <el-button
        //           type="text"
        //           icon="Delete"
        //           onClick={() => this.handleDelete(row)}
        //         ></el-button>
        //         <el-button
        //           type="text"
        //           icon="CircleCheck"
        //           onClick={() => this.handleAuthorization(row)}
        //         >
        //           {str}
        //         </el-button>
        //       </div>
        //     );
        //   }
        // }
      ]
    });
    onMounted(() => {
      getadapterParams().then(res => {
        if (res.data.success) {
          option.adapterList = res.data.data;
        }
      });
      getreturnQuery().then(res => {
        if (res.data.success) {
          option.rebackAddressList = res.data.data.rebackAddressType;
        }
      });
      instructionList(1).then(res => {
        if (res.data.success) {
          option.instructionData = res.data.data;
        }
      });


    });

    function handleCheckAllChange(val) {
      option.instruction.param = val ? option.checkBoxVal : [];
      option.isIndeterminate = false;
    };

    function checkboxChange(value) {
      const checkedCount = value.length;
      option.checkAll = checkedCount === option.checkBoxVal.length;
      option.isIndeterminate = checkedCount > 0 && checkedCount < option.checkBoxVal.length;
    };

    function searchFormFn() {
      option.page.current = 1;
      getData(option.page);
    }

    function resetForm() {
      searchForm.value.resetFields();
      option.page.current = 1;
      getData(option.page);
    }

    function handleCurrentChange(currentPage) {
      option.page.current = currentPage;
      getData(option.page);
    }

    function handleSizeChange(pageSize) {
      option.page.limit = pageSize;
      getData(option.page);
    }

    function getData(page) {
      getList(page.current, page.limit, option.search).then(res => {
        if (res.data.success) {
          option.tableData = res.data.data.list;
        }
      });
    }

    function getsameData(mold, type, codesAndMacs, code, mac) {
      //mold适配器或终端，type资源码物理码
      if (mold == 1) {
        if (type == 1) codesAndMacs = code;
        else codesAndMacs = mac;
      } else if (mold == 2) {
        //如果是终端 并是多选
        if (typeof (codesAndMacs) == "object") {
          let data = [];
          if (type == 1) {
            codesAndMacs.map(item => {
              let arr = item.split("，");
              data.push(arr[1]);
            });
          } else {
            codesAndMacs.map(item => {
              let arr = item.split("，");
              data.push(arr[2]);
            });
          }
          codesAndMacs = data.join(",");
        } else {
          //如果是终端 并是单选
          let arr = codesAndMacs.split("，");
          if (type == 1) codesAndMacs = arr[1];
          else codesAndMacs = codesAndMacs = arr[2];
        }

      }
      return codesAndMacs;
    }

    function sendInstruct() {
      instructForm.value.validate((valid) => {
        if (valid) {
          if (option.instruction.kind == 1) {
            let { codesAndMacs, mold, code, mac } = option.instruction;
            if (mold == 2) {
              if (option.multiple) {
                let data = [];
                codesAndMacs.map(item => {
                  let arr = item.split("，");
                  data.push(`${arr[1]}=${arr[2]}`);
                });
                codesAndMacs = data.join(",");
              } else {
                let arr = codesAndMacs.split("，");
                codesAndMacs = `${arr[1]}=${arr[2]}`;
              }
            } else codesAndMacs = code + "=" + mac;
            sendCode({ codesAndMacs, mold, code, mac }).then(res => {
              if (res.data.success) {
                ElMessage({
                  type: "success",
                  message: "操作成功！"
                });
              }
            });
          } else if (option.instruction.kind == 2) {
            let { codesAndMacs, mold, code, mac, type, volume, volumeNumber } = option.instruction;
            codesAndMacs = getsameData(mold, type, codesAndMacs, code, mac);
            if (volume == 3) volume = volumeNumber;
            sendVolume({ codesOrMacs: codesAndMacs, mold, code, mac, type, volume }).then(res => {
              if (res.data.success) {
                ElMessage({
                  type: "success",
                  message: "操作成功！"
                });
              }
            });
          } else if (option.instruction.kind == 3) {
            let { codesAndMacs, mold, code, mac, type, rebackAddress, state } = option.instruction;
            codesAndMacs = getsameData(mold, type, codesAndMacs, code, mac);
            if (state == 2) rebackAddress = `${option.instruction.ip}:${option.instruction.ip_port}`;
            else if (state == 3) rebackAddress = `${option.instruction.domain}:${option.instruction.domain_port}`;
            sendParam({ codesOrMacs: codesAndMacs, mold, code, mac, type, rebackAddress, state }).then(res => {
              if (res.data.success) {
                ElMessage({
                  type: "success",
                  message: "操作成功！"
                });
              }
            });
          } else if (option.instruction.kind == 4) {
            let { codesAndMacs, mold, code, mac, type, rebackAddress, state, param } = option.instruction;
            codesAndMacs = getsameData(mold, type, codesAndMacs, code, mac);
            if (state == 2) rebackAddress = `${option.instruction.ip}:${option.instruction.ip_port}`;
            else if (state == 3) rebackAddress = `${option.instruction.domain}:${option.instruction.domain_port}`;
            sendPassiveBack({
              codesOrMacs: codesAndMacs,
              mold,
              code,
              mac,
              type,
              rebackAddress,
              state,
              param: param.join(",")
            }).then(res => {
              if (res.data.success) {
                ElMessage({
                  type: "success",
                  message: res.data.code.message
                });
              }
            });
          } else if (option.instruction.kind == 5) {
            let { code, mac } = option.instruction;
            sendClock({ code, mac }).then(res => {
              if (res.data.success) {
                ElMessage({
                  type: "success",
                  message: res.data.code.message
                });
              }
            });
          } else if (option.instruction.kind == 6) {
            let { codesAndMacs, mold, code, mac, ip, subnetMask, gateway, type } = option.instruction;
            console.log({ codesAndMacs, mold, code, mac, ip, subnetMask, gateway, type });
            codesAndMacs = getsameData(mold, type, codesAndMacs, code, mac);
            console.log({ codesOrMac: codesAndMacs, mold, code, mac, ip, subnetMask, gateway, type });
            sendnetParams({ codesOrMac: codesAndMacs, mold, code, mac, ip, subnetMask, gateway, type }).then(res => {
              if (res.data.success) {
                ElMessage({
                  type: "success",
                  message: res.data.code.message
                });
              }
            });
          } else if (option.instruction.kind == 7) {
            let { codesAndMacs, mold, code, mac, switchOption, type } = option.instruction;
            codesAndMacs = getsameData(mold, type, codesAndMacs, code, mac);
            sendSwitch({ codesOrMacs: codesAndMacs, mold, code, mac, switchOption, type }).then(res => {
              if (res.data.success) {
                ElMessage({
                  type: "success",
                  message: res.data.code.message
                });
              }
            });
          } else if (option.instruction.kind == 8) {
            let { codesAndMacs, mold, code, mac, rebackCycle, type } = option.instruction;
            codesAndMacs = getsameData(mold, type, codesAndMacs, code, mac);
            sendRebackCycle({ codesOrMacs: codesAndMacs, mold, code, mac, rebackCycle, type }).then(res => {
              ElMessage({
                type: "success",
                message: res.data.code.message
              });
            });
          } else if (option.instruction.kind == 9) {
            let { code, mac, rebackCycle, state, rebackAddress } = option.instruction;
            if (state == 2) rebackAddress = `${option.instruction.ip}:${option.instruction.ip_port}`;
            else if (state == 3) rebackAddress = `${option.instruction.domain}:${option.instruction.domain_port}`;
            sendReback({ code, mac, rebackCycle, state, rebackAddress }).then(res => {
              ElMessage({
                type: "success",
                message: res.data.code.message
              });
            });
          }
        }
      });
    }

    //监测适配器选中
    watch(
      () => option.instruction.adapter, (value) => {
        option.terminalList = [];
        option.instruction.codesAndMacs = [];
        option.instruction.mac = "";
        option.instruction.code = "";
        if (value) {
          let obj = option.adapterList.find(item => item.code == value);
          option.instruction.mac = obj.macAddr;
          option.instruction.code = obj.code;
          option.instruction.adapterName = obj.name;
          if (option.instruction.mold == 2) {
            option.terminalShow = true;
            getterminalList({ code: obj.code, mac: obj.macAddr }).then(res => {
              if (res.data.success) {
                option.terminalList = res.data.data;
              }
            });
          }
        } else return option.terminalShow = false;
      });
    //监测选中的是适配器还是终端
    watch(
      () => option.instruction.mold, (value) => {
        option.terminalList = [];
        option.instruction.codesAndMacs = [];
        if (value == 2) {
          if (option.instruction.adapter) {
            option.terminalShow = true;
            getterminalList({ code: option.instruction.code, mac: option.instruction.mac }).then(res => {
              if (res.data.success) {
                option.terminalList = res.data.data;
              }
            });
          }
        } else return option.terminalShow = false;
      });
    //根据指令选择多选还是单选
    watch(
      () => option.instruction.kind, (value) => {
        option.instruction.adapter = "";
        if ([6, 8].includes(value)) {
          option.instruction.codesAndMacs = "";
          option.multiple = false;
        } else {
          option.instruction.codesAndMacs = [];
          option.multiple = true;
        }
        option.terminalShow = false;
        setTimeout(() => {
          if (option.instruction.mold == 2) return option.terminalShow = true;
        }, 16);
      });

    return {
      ...toRefs(option),
      sendInstruct,
      instructForm,
      searchForm,
      handleSizeChange,
      handleCurrentChange,
      searchFormFn,
      resetForm,
      handleCheckAllChange,
      checkboxChange
    };
  }
};
</script>

<style scoped lang="scss">
.allDescribe {
  padding: 10px;
  width: 100%;
  max-height: 240px;
  min-height: 100px;
  border: #00ffc6 1px dashed;
  margin-bottom: 20px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 0;
  }

}

@keyframes rotateIn {
  0% {
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
    -webkit-transform: rotate(-200deg);
    -ms-transform: rotate(-200deg);
    transform: rotate(-200deg);
    opacity: 0
  }

  100% {
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    opacity: 1
  }
}

.allDescribe-enter-active, {
  animation: rotateIn 0.5s;
}

//.allDescribe-leave-active {
//  animation: ease 0.5s reverse;
//}

//.allDescribe-enter-from,
//.allDescribe-leave-to {
//  opacity: 0;
//}

@keyframes bounce-in {
  0% {
    transform: scale(.3);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}

.describe {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.radioBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.radio {
  text-align: left;
}

.checkbox {
  text-align: left;
  display: grid;
  grid-template-columns: repeat(3, 1fr);

}
</style>