import axios from "@/api/axios"; // 引用axios
//获取待处理列表
export const getuntreateList = (page, limit, params) => {
  const param = {
    ...params,
    page,
    limit
  };
  return axios({
    url: "/api/audit/scheduling/untreatedListDate",
    method: "post",
    params: param
  });
};
//获取已处理列表
export const gethasList = (page, limit, params) => {
  const param = {
    ...params,
    page,
    limit
  };
  return axios({
    url: "/api/audit/scheduling/closedListDate",
    method: "post",
    params: param
  });
};
//获取调度中止列表
export const getCancelList = (page, limit, params) => {
  const param = {
    ...params,
    page,
    limit
  };
  return axios({
    url: "/api/audit/scheduling/getListSuspension",
    method: "post",
    params: param
  });
};
//获取调度详情
export const getDetail = (id) => {
  return axios({
    url: "/api/audit/scheduling/bcastDetails",
    method: "post",
    params: {
      id
    }
  });
};
//获取调度
export const executeSchedul = (id) => {
  return axios({
    url: "/api/audit/scheduling/schedulingParameters",
    method: "post",
    params: {
      id
    }
  });
};
//提交调度
export const submitSchedul = (params) => {
  return axios({
    url: "/api/audit/scheduling/addScheduling",
    method: "post",
    params: params
  });
};
//终止调度
export const stopSchedul = (id) => {
  return axios({
    url: "/api/audit/scheduling/stopSchedul",
    method: "post",
    params: {
      id
    }
  });
};
//审核详情
export const audioDetails = (id) => {
  return axios({
    url: "/api/audit/scheduling/broadCastDetails",
    method: "post",
    params: {
      id
    }
  });
};
//获取音频文件
export const getVideoList = (id) => {
  return axios({
    url: "/api/audit/scheduling/audioDetails",
    method: "get",
    params: {
      id
    }
  });
};
//发送报文
export const sendMessage = (id) => {
  return axios({
    url: "/api/audit/scheduling/messageDetails",
    method: "get",
    params: {
      id
    }
  });
};
//获取反馈
export const getfeedback = (id) => {
  return axios({
    url: "/api/audit/scheduling/messageEBMStateResponse",
    method: "get",
    params: {
      id
    }
  });
};
//广播广播
export const offBCast = (ebmId) => {
  return axios({
    url: "/api/audit/scheduling/stopBCast",
    method: "post",
    params: {
      ebmId
    }
  });
};
//地图的评估
export const mapEvaluate = (id) => {
  return axios({
    url: "/api/audit/scheduling/getMapAssessment",
    method: "post",
    params: {
      id
    }
  });
};
