import axios from "@/api/axios"; // 引用axios

export const editData = (params) => {
    return axios({
        url: "/api/safety/encryption/editData",
        method: "post",
        params: params,
    });
};

export const getDetail = (params) => {
    return axios({
        url: "/api/safety/encryption/detail",
        method: "get",
        params: params,
    });
};