<template>
  <div>
    <Searchbar @emit-search="onSearch" @reset-form="resetForm">
      <template #SEARCH>
        <el-col :span="6">
          <el-form-item label="版本号">
            <el-input clearable v-model="searchForm.versionNum" placeholder="请输入 版本号"></el-input>
          </el-form-item>
        </el-col>
      </template>

      <template #BTN>
        <el-button @click="dialogFormVisible = true" class="searchBtn">新增</el-button>
      </template>
    </Searchbar>

    <basicTable
        :tableData="tableData"
        :attrList="attrList"
        :page="page.current"
        :limit="page.limit"
        @currentChange="handleCurrentChange"
        @sizeChange="handleSizeChange"
        :total="page.total">
    </basicTable>
  </div>

  <el-dialog
      v-model="dialogFormVisible"
      title="新增"
      :close-on-click-modal="false"
      :before-close="handleClose"
  >
    <el-form label-position="right" :model="form" size="small" label-width="150px" ref="dialogForm">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="版本类型" prop="versionType" :rules="[{ required: true, message: '版本类型 不能为空' }]">
              <el-radio-group v-model="form.versionType">
                <el-radio :label="1" size="large">Android</el-radio>
              </el-radio-group>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="版本号" prop="versionNum" :rules="[{ required: true, message: '版本号 不能为空' }]">
            <el-input type="number" v-model="form.versionNum" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="版本名称" prop="versionName" :rules="[{ required: true, message: '版本名称 不能为空' }]">
            <el-input v-model="form.versionName" autocomplete="off" maxlength="32"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="包名" prop="packageName" :rules="[{ required: true, message: '包名 不能为空' }]">
            <el-input v-model="form.packageName" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="地址/包大小" prop="remark" :rules="[{ required: true, message: '地址 不能为空' }]">
            <el-upload
                accept="application/octet-stream"
                class="upload-demo"
                action="/api/version/uploadApk"
                :headers="headers"
                :on-success="onSuccess"
                :limit="1"
            >
              <el-button type="primary">上传安装包</el-button>
            </el-upload>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="签名地址" prop="remark">
            <el-input v-model="apkInfo.signAddress" autocomplete="off" readonly></el-input>
          </el-form-item>
        </el-col>


        <el-col :span="12">
          <el-form-item label="文件大小" prop="remark">
            <el-input v-model="apkInfo.apkSize" autocomplete="off" readonly></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="强制更新" prop="vstatus">
            <el-switch v-model="form.vstatus" :active-value="1" inactive-value="2" size="large" active-text="需要" inactive-text="不需要"/>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="md5" prop="apkMd5" :rules="[{ required: true, message: 'md5 不能为空' }]">
            <el-input v-model="form.apkMd5" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="版本信息描述" prop="remark" :rules="[{ required: true, message: '版本信息描述 不能为空' }]">
            <el-input v-model="form.remark" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <template #footer>
      <div class="dialog-footer">
        <el-button type="primary" size="small" @click="small">提交</el-button>
      </div>
    </template>
  </el-dialog>


  <el-dialog v-model="isqr" title="二维码" width="30%" >
    <img style="width: 300px;height: 300px" :src="`data:image/jpg;base64,${qrUrl}`">
  </el-dialog>
</template>
<script>
import Searchbar from '@/components/Searchbar';
import { getToken, getverifyToken } from "@/util/auth";
import { getData, dataSave, dataDel } from "@/api/editionupgrade/app";
import {ElMessage, ElMessageBox} from "element-plus";
export default {
  components:{
    Searchbar
  },
  data(){
    return{
      qrUrl:"",
      isqr:false,
      apkInfo:{},
      searchForm:{},
      form:{
        vstatus:1,
        versionType:1
      },
      tableData:[],
      attrList:[
        {
          prop: "versionNum",
          label: "版本号",
          render: (row) => <div>{row.versionNum}</div>,
        },
        {
          prop: "versionName",
          label: "版本名",
          render: (row) => <div>{row.versionName}</div>,
        },
        {
          prop: "packageName",
          label: "包名",
          render: (row) => <div>{row.packageName}</div>,
        },
        {
          prop: "versionType",
          label: "版本类别",
          render: (row) => <div>{row.versionType == 1 ? 'android' : 'ios'}</div>,
        },
        {
          prop: "signAddress",
          label: "签名地址",
          render: (row) => <div>{row.signAddress}</div>,
        },
        {
          prop: "vstatus",
          label: "强制更新",
          render: (row) => <div>{row.vstatus == 1 ? '需要' : '不需要'}</div>,
        },
        {
          prop: "remark",
          label: "版本信息描述",
          render: (row) => <div>{row.remark}</div>,
        },
        {
          prop: "creatTime",
          label: "上传时间",
          render: (row) => <div>{row.creatTime}</div>,
        },
        {
          prop: "",
          label: "操作",
          render: (row) => <div>
            <el-button type="text" icon="Delete" onClick={()=>this.delAjax(row)}>删除</el-button>
            <el-button type="text" icon="Grid" onClick={()=>this.qrshow(row)}>二维码</el-button>
          </div>
        },
      ],
      page:{
        current: 1,
        limit: 10,
        total: 0,
      },
      dialogFormVisible:false,
    }
  },
  methods:{
    handleCurrentChange(currentPage) {
      this.page.current = currentPage;
      this.onLoad(this.searchForm);
    },

    handleSizeChange(pageSize) {
      this.page.limit = pageSize;
      this.onLoad(this.searchForm);
    },

    onSearch(){
      this.page.current = 1;
      this.onLoad(this.searchForm);
    },

    resetForm() {
      this.searchForm = {};
      this.page.current = 1;
      this.onLoad(this.searchForm);
    },

    qrshow(row){
      this.isqr = true;
      this.qrUrl = row.qrCode
    },

    delAjax(row){
        ElMessageBox.confirm('确认删除?', '提示', { confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'Warning',
        }).then(async () => {
          let res = await dataDel({id:row.id});
          if(res.data.success){
            this.onLoad(this.searchForm);
          }
        }).catch(() => {})
      },

    handleClose(done){
      this.$nextTick(()=>{
        this.$refs.dialogForm.resetFields();
      })
      done();
    },
    small(){
        this.$refs.dialogForm.validate((valid) => {
          if (valid) {
            let { versionName, versionNum, packageName, vstatus, apkMd5, remark } = this.form;
            let { signAddress, apkSize } = this.apkInfo;
            dataSave({
              versionName, versionNum, packageName, signAddress, apkSize, vstatus, apkMd5, remark, versionType:1
            }).then(res=>{
              if(res.data.success){
                ElMessage({message: res.data.code.message, type: 'success'});
                this.page.current = 1;
                this.dialogFormVisible = false;
                this.$nextTick(()=>{
                  this.$refs.dialogForm.resetFields();
                })
                this.onLoad(this.searchForm);
              }
            })
          }else {
            return false;
          }
        })
      },
    onSuccess(response, file, fileList){
      this.apkInfo.signAddress = response.data.url;
      this.apkInfo.apkSize = response.data.fileSize;
    },
    onLoad(form = {}){
      getData({ page:this.page.current, limit:this.page.limit, ...form }).then(res=>{
        this.tableData = res.data.data.list;
        this.page.total = res.data.data.count;
      });
    }
  },
  mounted() {
    this.headers = { sign:'0000000000000', timestamp: new Date().getTime(), verifyToken: getverifyToken() || "", accessToken: getToken() || "" }
    this.onLoad()
  }
}

</script>
<style lang="scss">

</style>