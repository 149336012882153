import axios from "@/api/axios"; // 引用axios
//获取广播基础参数
export const getList = (page, limit, params) => {
  const param = {
    ...params,
    page,
    limit
  };
  return axios({
    url: "/api/broadcast/bcastListData",
    method: "post",
    params: param
  });
};
//获取状态列表
export const getDetail = (id) => {
  return axios({
    url: "/api/broadcast/bcastDetails",
    method: "post",
    params: {
      id
    }
  });
};//获取状态列表
export const getStatus = (params={}) => {
  return axios({
    url: "/api/broadcast/getStatList",
    method: "post",
    params: params
  });
};
//获取指令列表
export const getinstruct = (params={}) => {
  return axios({
    url: "/api/broadcast/getInputList",
    method: "post",
    params: params
  });
};//关闭广播
export const offRadio = (ebmId) => {
  return axios({
    url: "/api/broadcast/stopBCast",
    method: "post",
    params: {
      ebmId:ebmId
    }
  });
};