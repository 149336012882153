<template>
  <div class="searchBox">
    <el-form :model="search" :label-width="searchLabelWidth" ref="searchForm">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="角色名称" prop="roleName">
            <el-input
              v-model="search.roleName"
              autocomplete="off"
              placeholder="请输入 角色名称"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="状态" prop="state">
            <el-select
              :fit-input-width="true"
              v-model="search.state"
              placeholder="请选择 状态"
            >
              <el-option label="平台" :value="1"></el-option>
              <el-option label="app" :value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <!--              <el-form-item>-->
          <el-button @click="searchForm('searchForm')" class="searchBtn"
          >查询
          </el-button
          >
          <el-button @click="resetForm('searchForm')" class="resetBtn"
          >重置
          </el-button
          >

          <!--              </el-form-item>-->
        </el-col>
      </el-row>
    </el-form>
    <div class="divide">
      <img src="../../assets/divide.png" style="width: 100%" />
    </div>
    <div class="btnContainer">
      <div class="btnLeft">
        <el-button
          icon="Plus"
          @click="dialogFormVisible = true"
          class="searchBtn"
        >新增
        </el-button
        >
      </div>
    </div>
  </div>
  <basicTable
    :attrList="attrList"
    :tableData="tableData"
    ref="table"
    :page="page.current"
    :limit="page.limit"
    :total="page.total"
    @currentChange="handleCurrentChange"
    @sizeChange="handleSizeChange"
  >
  </basicTable>
  <el-dialog
    v-model="dialogFormVisible"
    :title="title"
    :close-on-click-modal="false"
    :before-close="handleClose"
  >
    <el-form
      label-position="right"
      :model="form"
      size="small"
      :label-width="formLabelWidth"
      ref="dialogForm"
    >
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            label="角色名称"
            :rules="[{ required: true, message: '角色名称 不能为空' }]"
            prop="roleName"
          >
            <el-input v-model="form.roleName" placeholder="请输入 角色名称" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="状态"
            :rules="[{ required: true, message: '状态 不能为空' }]"
            prop="state"
          >
            <el-radio-group v-model="form.state">
              <el-radio :label="1">平台</el-radio>
              <el-radio :label="2">app</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="角色描述" prop="msg">
            <el-input
              v-model="form.msg"
              :autosize="{ minRows: 2, maxRows: 4 }"
              type="textarea"
              placeholder="请输入 角色描述"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button type="primary" size="small" @click="submitForm('dialogForm')"
        >提交
        </el-button>
      </div>
    </template>
  </el-dialog>
  <el-dialog
    width="30%"
    v-model="permVisible"
    title="角色授权"
    :close-on-click-modal="false"
    :before-close="handleClose2"
  >
    <el-form :model="permForm" ref="permForm">
      <el-form-item label="角色名称" prop="roleName">
        <el-input
          v-model="permForm.roleName"
          autocomplete="off"
          readonly
        ></el-input>
      </el-form-item>
      <el-form-item label="角色描述" prop="msg">
        <el-input v-model="permForm.msg" autocomplete="off" readonly></el-input>
      </el-form-item>
      <el-form-item label="角色权限" prop="perms">
        <el-tree
          :data="permList"
          show-checkbox
          node-key="id"
          accordion
          :props="treeProps"
          :render-after-expand="true"
          ref="tree"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button type="primary" size="small" @click="submitPerm('permForm')"
        >提交
        </el-button>
        <el-button @click="permVisible = false" size="small">取消</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import {
  getroles,
  addroles,
  editroles,
  delroles,
  getRolePerm,
  updateRolePerm
} from "@/api/system/roles";
import { getMenuMana } from "@/api/system/menu";
import { ElMessage } from "element-plus";
import {nextTick, ref} from "vue";

export default {
  name: "roles",
  setup() {
    const permVisible = ref(false);
    const permList = ref([]);
    return {
      permVisible,
      permList,
    };
  },
  data() {
    return {
      treeProps: {
        label: "name",
        children: "children",
      },
      getParId:[],
      permForm: {},
      formLabelWidth: "22%",
      dialogFormVisible: false,
      title: "新增",
      form: {
        roleName: ""
      },
      searchLabelWidth: "100px",
      search: {},
      page: {
        current: 1,
        limit: 10,
        total: 0
      },
      tableData: [],
      attrList: [
        {
          label: "角色名称",
          prop: "roleName",
          render: (row) => <div>{row.roleName}</div>
        },
        {
          label: "状态",
          prop: "state",
          render: (row) => {
            if (row.state == 1) return <div>平台</div>;
            else if (row.state == 2) return <div>app</div>;
          }
        },
        {
          prop: "",
          label: "操作",
          render: (row) => {
            let str = row.state == 1 ? "授权web" : "授权app";
            if(row.state == 1){
              return (
                  <div>
                    <el-button
                        type="text"
                        icon="Edit"
                        onClick={() => this.handleEdit(row)}
                    >编辑</el-button>
                    <el-button
                        type="text"
                        icon="Delete"
                        onClick={() => this.handleDelete(row)}
                    >删除</el-button>
                    <el-button
                        type="text"
                        icon="CircleCheck"
                        disabled={row.state != 1}
                        onClick={() => this.handleAuthorization(row)}
                    >
                      {str}
                    </el-button>
                  </div>
              );
            }else {
              return (
                  <div>
                    <el-button
                        type="text"
                        icon="Edit"
                        onClick={() => this.handleEdit(row)}
                    >编辑</el-button>
                    <el-button
                        type="text"
                        icon="Delete"
                        onClick={() => this.handleDelete(row)}
                    >删除</el-button>
                  </div>
              );
            }
          }
        }
      ]
    };
  },
  created() {
    this.getData(this.page);
    getMenuMana().then((res) => {
      if (res.data.success) {
        this.permList = res.data.data;
        let treeData = res.data.data;
        function getIds(arr, result = []) {
          arr.forEach(item => {
            if (item.children.length !== 0) {
              result.push(item.id);
              if (item.children.length) {
                 return getIds(item.children, result);
              }
            }
          });
          return result;
        }
        this.getParId = getIds(treeData);
      }
    });
  },
  methods: {
    setDate(data){
      if (data.length) {
        let arrList = data.filter(item => {
          if (!this.getParId.includes(item)) {
            return item;
          }
        })  ;
        this.$refs.tree.setCheckedKeys(arrList);
      }
    },
    submitPerm() {
      let { id } = this.permForm;
      let perms = this.$refs.tree.getCheckedNodes(false, true).map(item=>item.id).join(',')
      updateRolePerm({ id, perms }).then((res) => {
        if (res.data.success) {
          this.permVisible = false;
          this.getData(this.page);
          ElMessage({
            type: "success",
            message: "操作成功！"
          });
        }
      });
    },


    handleAuthorization(row) {
      this.permVisible = true;
      getRolePerm(row.id).then((res) => {
        if (res.data.success) {
          this.permForm.roleName = row.roleName;
          this.permForm.msg = row.msg;
          this.permForm.id = row.id;
          this.setDate(res.data.data)
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.page.current = 1;
      this.getData(this.page);
    },
    searchForm() {
      this.page.current = 1;
      this.getData(this.page);
    },
    handleEdit(row) {
      let { roleName, state, msg, id } = row;
      this.form = { roleName, state, msg, id };
      this.title = "编辑";
      this.dialogFormVisible = true;
    },

    handleDelete(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          return delroles(row.id);
        })
        .then((res) => {
          console.log(res);
          if (res.data.success) {
            this.getData(this.page);
            ElMessage({
              message: "操作成功",
              type: "success"
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getData(page) {
      getroles(page.current, page.limit, this.search).then((res) => {
        this.tableData = res.data.data.list;
        this.page.total = res.data.data.count;
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.form);
          if (this.title == "新增") {
            addroles(this.form).then((res) => {
              if (res.data.success) {
                this.getData(this.page);
                this.$refs[formName].resetFields();
                ElMessage({
                  message: "操作成功",
                  type: "success"
                });
                this.dialogFormVisible = false;
              } else {
                ElMessage({
                  message: res.data.code.message,
                  type: "error"
                });
              }
            });
          } else {
            editroles(this.form).then((res) => {
              if (res.data.success) {
                this.getData(this.page);
                this.$refs[formName].resetFields();
                ElMessage({
                  message: "操作成功",
                  type: "success"
                });
                this.dialogFormVisible = false;
              } else {
                ElMessage({
                  message: res.data.code.message,
                  type: "error"
                });
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    handleClose(done){
      nextTick(()=>{
        this.$refs['dialogForm'].resetFields();
        this.form = {};
      })
      done();
    },

    handleClose2(done){
      nextTick(()=>{
        this.$refs.tree.setCheckedKeys([]);
      })
      done();
    },

    handleCurrentChange(currentPage) {
      this.page.current = currentPage;
      this.getData(this.page);
    },
    handleSizeChange(pageSize) {
      this.page.limit = pageSize;
      this.getData(this.page);
    }
  }
};
</script>

<style scoped></style>
