<template>
  <div class="box">
    <div class="main">
      <el-tag class="ml-2" type="success">通用时钟校准</el-tag>
      <p class="introduce">目的： 操作更改设备回传参数</p>
      <p class="introduce">设置： 直接对设备进行控制</p>
      <div class="btn">
        <el-button type="success" @click="handleCalibrate">开始校准</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { timeCalibrate } from "@/api/terminalRegulate/generalVolume";

export default {
  name: "timeCalibrate",
  methods: {
    handleCalibrate() {
      timeCalibrate().then(res => {
        if (res.data.success) {
          this.$message.success("操作成功");
        }
      });
    }
  }

};
</script>

<style scoped>
.box {
  width: 100%;
  display: flex;
  justify-content: center;
}

.main {
  padding: 10px;
  text-align: left;
  color: #1C9C77;
  width: 500px;
  border: 1px solid #1C9C77;
}

.ml-2 {
  margin-bottom: 30px;
}

.btn {
  margin-top: 50px;
  text-align: right;
}
</style>