import axios from "@/api/axios"; // 引用axios

//获取展示信息
export const getData = (params = {}) => {
  return axios({
    url: "/api/platform/system/localSystem",
    method: "get",
    params: params
  });
};//信息上报
export const infoUp = (params) => {
  return axios({
    url: "/api/platform/system/localSystemReportInfo",
    method: "post",
    params: params
  });
};
//状态上报
export const statusUp = (params) => {
  return axios({
    url: "/api/platform/system/localSystemReportState",
    method: "post",
    params: params
  });
};