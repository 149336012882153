<template>
  <div class="searchBox">
    <el-form :model="search" :label-width="searchLabelWidth" ref="searchForm">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="媒体资源名称" prop="display_name">
            <el-input
              v-model="search.display_name"
              autocomplete="off"
              placeholder="请输入 媒体资源名称"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <!--              <el-form-item>-->
          <el-button @click="searchForm('searchForm')" class="searchBtn"
          >查询
          </el-button
          >
          <el-button @click="resetForm('searchForm')" class="resetBtn"
          >重置
          </el-button
          >

          <!--              </el-form-item>-->
        </el-col>
      </el-row>
    </el-form>
    <div class="divide">
      <img src="../../assets/divide.png" style="width: 100%" />
    </div>
    <div class="btnContainer">
      <div class="btnLeft">
        <el-button
          icon="Plus"
          @click="dialogFormVisible = true"
          class="searchBtn"
        >新增
        </el-button
        >
      </div>
    </div>
  </div>
  <basicTable
    :indexshow="false"
    :attrList="attrList"
    :tableData="tableData"
    ref="table"
    :page="page.current"
    :limit="page.limit"
    :total="page.total"
    @currentChange="handleCurrentChange"
    @sizeChange="handleSizeChange"
  >
  </basicTable>
  <el-dialog
    @close="close"
    v-model="dialogFormVisible"
    title="新增"
    :close-on-click-modal="false"
    width="65%"
  >
    <el-tabs tab-position="left" v-model="formName" v-loading="loading">
      <el-tab-pane label="资源上传" name="dialogForm">
        <el-form
          label-position="right"
          :model="form"
          size="small"
          :label-width="formLabelWidth"
          ref="dialogForm"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item
                label="文件"
                prop="file_name"
                :rules="[{ required: true, message: '文件 不能为空' }]"
              >
                <el-upload
                  ref="uploadfile"
                  multiple
                  accept="audio/mpeg,audio/mp4,video/mp4"
                  :headers="headers"
                  :on-change="handleChange"
                  :on-remove="onRemove"
                  :before-remove="beforeRemove"
                  class="upload-demo"
                  action=""
                  :file-list="fileList"
                  :auto-upload="false"
                >
                  <template #trigger>
                    <el-button size="small" type="primary">选择文件</el-button>
                  </template>
                  <el-button size="small" type="warning" class="ml-3" @click="sendServer">确定上传</el-button>
                  <template #tip>
                    <div class="el-upload__tip">只能上传mp3,mp4文件</div>
                  </template>
                </el-upload>
              </el-form-item>
            </el-col>
            <!--            <el-col :span="12">-->
            <!--              <el-form-item prop="" label="">-->

            <!--              </el-form-item>-->
            <!--            </el-col>-->
          </el-row>
          <el-row :gutter="12" v-for="(item,index) in fileInfo" :key="index">
            <el-col :span="6">
              <el-form-item
                label="播放时长"
                :prop="'time_length'+index"
              >
                <el-input
                  v-model="item.timeStr"
                  autocomplete="off"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item
                label="媒体资源名称"
                :prop="'display_name'+index"
              >
                <el-input
                  v-model="item.fileName"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="资源类型" :prop="'sourceType'+index">
                <el-select disabled  v-model="item.sourceType" placeholder="请选择 资源类型" clearable>
                  <el-option v-for="(item2,index2) in sourceType" :key="index2" :label="item2.name" :value="item2.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                label="访问级别"
                :rules="[{ required: true, message: '访问级别 不能为空' }]"
                :prop="'access_level'+index"
              >
                <el-select
                  v-model="item.access_level"
                  placeholder="请选择 访问级别"
                  clearable
                >
                  <el-option label="私有" value="private"></el-option>
                  <el-option label="所有" value="public"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="文字转语音" name="tdialogForm">
        <el-form
          label-position="right"
          :model="tform"
          size="small"
          :label-width="formLabelWidth"
          ref="tdialogForm"
        >
          <el-row :gutter="20" v-if="title == '新增'">
            <el-col :span="12">
              <el-form-item
                label="内容"
                prop="text"
                :rules="[{ required: true, message: '内容 不能为空' }]"
              >
                <el-input
                  v-model="tform.text"
                  autocomplete="off"
                  type="textarea"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="媒体资源名称"
                :rules="[{ required: true, message: '媒体资源名称 不能为空' }]"
                prop="display_name"
              >
                <el-input
                  v-model="tform.display_name"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item
                label="访问级别"
                :rules="[{ required: true, message: '访问级别 不能为空' }]"
                prop="access_level"
              >
                <el-select
                  v-model="tform.access_level"
                  placeholder="请选择 访问级别"
                  clearable
                >
                  <el-option label="私有" value="private"></el-option>
                  <el-option label="本级" value="protect"></el-option>
                  <el-option label="所有" value="public"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="文件" prop="file_name">
                <el-upload
                  :headers="headers"
                  :before-upload="tbeforeUpload"
                  class="upload-demo"
                  action="/api/local/media/fileTransferString"
                  :on-success="thandleSuccess"
                >
                  <el-button size="small" type="primary">点击上传</el-button>
                  <template #tip>
                    <div class="el-upload__tip">
                      可上传本地.txt文件 / 可自定义播放文本
                    </div>
                  </template>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>

      <el-tab-pane label="图片上传" name="imageForm">
        <el-form
            label-position="right"
            :model="form2"
            size="small"
            :label-width="formLabelWidth"
            ref="imageForm"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="图片" prop="file_name" :rules="[{ required: true, message: '图片 不能为空' }]">
                <el-upload
                    ref="uploadfile"
                    multiple
                    accept="image/jpeg,image/gif,image/png"
                    :headers="headers"
                    :before-remove="beforeRemove"
                    :on-change="handleChange2"
                    class="upload-demo"
                    action=""
                    :file-list="fileList2"
                    :auto-upload="false"
                >
                  <template #trigger>
                    <el-button size="small" type="primary">选择文件</el-button>
                  </template>
                  <el-button size="small" type="warning" class="ml-3" @click="sendServerImage">确定上传</el-button>
                  <template #tip>
                    <div class="el-upload__tip">只能上传JPG,JPEG,PNG,GIF文件</div>
                  </template>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="12" v-for="(item,index) in fileInfo2" :key="index">
            <el-col :span="6">
              <el-form-item label="播放时长" :prop="'time_length'+index">
                <el-input v-model="item.timeStr" autocomplete="off" readonly></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="媒体资源名称" :prop="'display_name'+index">
                <el-input v-model="item.fileName" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="资源类型" :prop="'sourceType'+index">
                <el-select disabled  v-model="item.sourceType" placeholder="请选择 资源类型" clearable>
                  <el-option v-for="(item2,index2) in sourceType" :key="index2" :label="item2.name" :value="item2.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                  label="访问级别"
                  :rules="[{ required: true, message: '访问级别 不能为空' }]"
                  :prop="'access_level'+index"
              >
                <el-select
                    v-model="item.access_level"
                    placeholder="请选择 访问级别"
                    clearable
                >
                  <el-option label="私有" value="private"></el-option>
                  <el-option label="所有" value="public"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
    </el-tabs>

    <template #footer>
      <div class="dialog-footer">
        <el-button type="primary" size="small" @click="submitForm(formName)">提交</el-button>
        <el-button @click="dialogFormVisible = false; loading = false" size="small" type="info">取消</el-button>
      </div>
    </template>
  </el-dialog>


  <el-dialog
    v-model="editFormVisible"
    title="编辑"
    :close-on-click-modal="false"
  >
    <el-form
      label-position="right"
      :model="editForm"
      size="small"
      :label-width="formLabelWidth"
      ref="editForm"
    >
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            label="媒体资源名称"
            :rules="[{ required: true, message: '媒体资源名称 不能为空' }]"
            prop="display_name"
          >
            <el-input
              v-model="editForm.display_name"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="访问级别"
            :rules="[{ required: true, message: '访问级别 不能为空' }]"
            prop="access_level"
          >
            <el-select
              v-model="editForm.access_level"
              placeholder="请选择 访问级别"
              clearable
            >
              <el-option label="私有" value="private"></el-option>
              <el-option label="本级" value="protect"></el-option>
              <el-option label="所有" value="public"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button
          type="primary"
          size="small"
          @click="submitEditForm('editForm')"
        >提交
        </el-button>
        <el-button @click="editFormVisible = false" size="small" type="info"
        >取消
        </el-button>
      </div>
    </template>
  </el-dialog>

  <el-dialog append-to-body v-model="viodeVisible" :title="dtitle" width="30%">
    <video width="500" height="200" style="margin: 0 auto" controls :src="viodeUrl" v-if="dtitle == '查看视频'"></video>
    <el-image v-else style="width: 500px; height: 200px" :src="viodeUrl" :preview-src-list="[viodeUrl]"></el-image>
  </el-dialog>
</template>

<script>
import {
  getList,
  addData,
  editData,
  getDetail,
  delData,
  handleVoice,
  uploadfiles,
  getSourceType,
  uploadPicMore,
  addDataMorePic
} from "@/api/dailyBroad/mediaResources";
import { ref } from "vue";
import { getToken, getverifyToken } from "@/util/auth";
import { ElMessage } from "element-plus";

export default {
  name: "mediaResources",
  setup() {
    const headers = ref({
      accessToken: getToken(),
      verifyToken: getverifyToken(),
      timestamp: new Date().getTime(),
      sign: "0000000000000"
    });


    return { headers };
  },
  data() {
    return {
      fullscreenLoading:null,
      fileList2:[],
      viodeVisible: false,
      viodeUrl: "",
      sourceType:[],
      fileInfo2:[],
      fileInfo: [],
      fileList: [],
      dtitle:"",
      editForm: {},
      editFormVisible: false,
      formName: "dialogForm",
      formLabelWidth: "140px",
      dialogFormVisible: false,
      title: "新增",
      form2:{},
      form: {},
      tform: {},
      searchLabelWidth: "140px",
      search: {},
      page: {
        current: 1,
        limit: 10,
        total: 0
      },
      loading: false,
      tableData: [],
      attrList: [
        {
          label: "媒体资源名称",
          prop: "display_name",
          render: (row) => <div>{row.display_name}</div>
        },
        {
          label: "播放时长",
          prop: "time_length",
          render: (row) => <div>{row.time_length}</div>
        }, {
          label: "音频",
          prop: "file_name",
          render: (row) =>{
            if(row.sourceType == 1){
              return <audio controls style="width:100%;height:30px" src={row.file_name}></audio>
            }else if(row.sourceType == 2){
              return <el-button type="text" onClick={()=>this.showviode(row.file_name)}>查看视频</el-button>
            }else{
              return <el-button type="text" onClick={()=>this.showImage(row.file_name)}>查看图片</el-button>
            }
          }
        },
        {
          label: "访问级别",
          prop: "access_level",
          render: (row) => {
            if (row.access_level == "private") return <div>私有</div>;
            else if (row.access_level == "protect") return <div>本级</div>;
            else if (row.access_level == "public") return <div>所有</div>;
          }
        },
        {
          label: "审核状态",
          prop: "status",
          render: (row) => {
            if (row.status == 1) return <div>待审核</div>;
            else if (row.status == 2) return <div>审核通过</div>;
            else if (row.status == 3) return <div>审核不通过</div>;
            else if (row.status == 0) return <div>禁用</div>;
          },
        },
        {
          prop: "",
          label: "操作",
          render: (row) => {
            return (
              <div>
                <el-button
                  type="text"
                  icon="Edit"
                  onClick={() => this.handleEdit(row)}
                >编辑</el-button>
                <el-button
                  type="text"
                  icon="Delete"
                  onClick={() => this.handleDelete(row)}
                >删除</el-button>
              </div>
            );
          }
        }
      ]
    };
  },
  created() {
    getSourceType().then(res=>{
      this.sourceType =  res.data.data;
    })
    this.getData(this.page);
  },
  methods: {
    showImage(url){
      this.dtitle = '查看图片';
      this.viodeUrl = url;
      this.viodeVisible = true;
    },

    showviode(url){
      this.dtitle = '查看视频';
      this.viodeUrl = url;
      this.viodeVisible = true;
    },

    close() {
      this.fileInfo = [];
      this.fileList = [];
      this.fileInfo2 = [];
      this.fileList2 = [];
      for (let key in this.form) {
        this.form[key] = "";
      }
      for (let key in this.tform) {
        this.tform[key] = "";
      }
      for (let key in this.form2) {
        this.form2[key] = "";
      }
      this.loading = false;
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.page.current = 1;
      this.getData(this.page);
    },
    searchForm() {
      this.page.current = 1;
      this.getData(this.page);
    },

    extractHostname(url) {
      var hostname;
      if (url.indexOf("//") > -1) {
        hostname = url.split('/')[2];
      } else {
        hostname = url.split('/')[0];
      }
      hostname = hostname.split(':')[0];
      hostname = hostname.split('?')[0];
      return hostname;
    },

    getData(page) {
      getList(page.current, page.limit, this.search).then((res) => {
        this.tableData = res.data.data.list;
        this.page.total = res.data.data.count;
        let url = window.location.hostname;
        this.tableData.forEach((item, index)=>{
          this.tableData[index].file_name = item.file_name.replace(this.extractHostname(item.file_name), url);
        })
      });
    },

    beforeRemove(){
      return false
    },

    onRemove(file, fileList){
      this.loading = true;
      const formData = new FormData();
      if(fileList.length < 1){
        this.fileInfo = [];
        this.fileList = []
        this.loading = false;
      }else{
        fileList.map(item => {
          formData.append("file", item.raw);
          uploadfiles(formData).then(res => {
            if (res.data.success) {
              this.loading = false;
              res.data.data.forEach(item => {
                item.access_level = "public";
              });
              this.fileInfo = res.data.data;
              this.$message.success("操作成功！");
            }
          })
        });
      }
    },
    handleChange2(file, fileList){
      if (fileList.length < 1) {
        this.fileList2 = [];
        return
      };
      this.form2.file_name = fileList;
      this.fileList2 = fileList;
    },
    handleChange(file, fileList) {
      if (fileList.length < 1) {
        this.fileList = [];
        return
      };
      this.form.file_name = fileList;
      this.fileList = fileList;
      console.log('file',this.fileList)
    },

    sendServerImage(){
      if (this.fileList2.length < 1)  return;
      this.loading = true;
      const formData = new FormData();
      this.fileList2.map(item => {
        formData.append("file", item.raw);
      });
      uploadPicMore(formData).then(res=>{
        if (res.data.success) {
          this.loading = false;
          res.data.data.forEach(item => {
            item.access_level = "public";
            item.sourceType = 3;
          });
          this.fileInfo2 = res.data.data;
          this.$message.success("操作成功！");
        }
      }).catch((err) => {
        this.loading = false;
        this.$message.error('上传失败！');
      });
    },

    sendServer() {
      if (this.fileList.length < 1)  return;

      this.fileList.forEach((item, index)=>{
        if(item.size / 1024 / 1024 > 200){
          this.fileList.splice(index , 1);
          this.$message.error(`上传${item.name}文件不能超过200M`);
        }
      })

      this.loading = true;
      const formData = new FormData();
      this.fileList.map(item => {
        formData.append("file", item.raw);
      });

      uploadfiles(formData).then(res => {
        if (res.data.success) {
          this.loading = false;
          let op = new Map([
            ['.mp3',1],
            ['.mp4',2]
          ])
          res.data.data.forEach(item => {
            item.access_level = "public";
            item.sourceType = op.get(item.fileName.substring(item.fileName.lastIndexOf("."),item.fileName.length))
          });
          this.fileInfo = res.data.data;
          this.$message.success("操作成功！");
        }
      }).catch((err) => {
        this.loading = false;
        this.$message.error('上传失败！');
      });
    },
    tbeforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg == "txt";
      if (!extension) {
        return this.$message({
          type: "error",
          message: "上传文件只能是txt格式!"
        });
      }
    },
    // handleSuccess(res, file, fileList) {
    //   console.log(res);
    //   this.form.display_name = res.data.fileName;
    //   this.form.file_name = res.data.filePath;
    //   this.form.time_length = res.data.timeStr;
    // },
    thandleSuccess(res, file, fileList) {
      this.tform.display_name = res.data.fileName;
      this.tform.text = res.data.resultContent;
    },
    submitForm(formName) {
      this.fileInfo.forEach((item, index) => {
        if (item.fileName) this.form["display_name" + index] = item.fileName;
        if (item.timeStr) this.form["time_length" + index] = item.timeStr;
        if (item.access_level) this.form["access_level" + index] = item.access_level;
        if (item.filePath) this.form["file_name" + index] = item.filePath;
        if (item.sourceType) this.form["sourceType" + index] = item.sourceType;
      });

      this.fileInfo2.forEach((item, index) => {
        if (item.fileName) this.form2["display_name" + index] = item.fileName;
        if (item.timeStr) this.form2["time_length" + index] = item.timeStr;
        if (item.access_level) this.form2["access_level" + index] = item.access_level;
        if (item.filePath) this.form2["file_name" + index] = item.filePath;
        if (item.sourceType) this.form2["sourceType" + index] = item.sourceType;
      });

      this.$refs[formName].validate((valid) => {
        if (valid) {
          let fn, params;
          if (formName == "dialogForm") {
            let arr = [];
            fn = addData;
            this.fileInfo.map(item => {
              let { fileName, timeStr, access_level, filePath, sourceType } = item;
              arr.push({ display_name: fileName, time_length: timeStr, access_level, file_name: filePath, sourceType });
            });
            let formData = new FormData();
            formData.append("jsonStr", JSON.stringify(arr));
            params = formData;
          } else if(formName == "imageForm") {
            let arr = [];
            fn = addDataMorePic;
            this.fileInfo2.map(item => {
              let { fileName, timeStr, access_level, filePath, sourceType } = item;
              arr.push({ display_name: fileName, time_length: timeStr, access_level, file_name: filePath, sourceType });
            });
            let formData = new FormData();
            formData.append("jsonStr", JSON.stringify(arr));
            params = formData;
          }else{
            this.fullscreenLoading = this.$loading({lock: true, text: '文字转语音中，请稍后...', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)'});
            fn = handleVoice;
            params = this.tform;
          }
          fn(params).then((res) => {
            if (res.data.success) {
              this.getData(this.page);
              this.$refs[formName].resetFields();
              ElMessage({
                message: "操作成功",
                type: "success"
              });
              this.dialogFormVisible = false;
              this.fullscreenLoading.close();
            } else {
              ElMessage({
                message: res.data.code.message,
                type: "error"
              });
            }
          }).catch(err=>{
            if(this.fullscreenLoading) this.fullscreenLoading.close();
          });
        } else {
          return false;
        }
      });
    },
    submitEditForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          editData(this.editForm).then((res) => {
            if (res.data.success) {
              this.getData(this.page);
              this.$refs[formName].resetFields();
              ElMessage({
                message: "操作成功",
                type: "success"
              });
              this.editFormVisible = false;
            } else {
              ElMessage({
                message: res.data.code.message,
                type: "error"
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    handleEdit(row) {
      this.editFormVisible = true;
      getDetail(row.id).then((res) => {
        if (res.data.success) {
          this.editForm = res.data.data;
        }
      });
    },
    handleDelete(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          return delData(row.id);
        })
        .then((res) => {
          if (res.data.success) {
            this.getData(this.page);
            ElMessage({
              message: "操作成功",
              type: "success"
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleCurrentChange(currentPage) {
      this.page.current = currentPage;
      this.getData(this.page);
    },
    handleSizeChange(pageSize) {
      this.page.limit = pageSize;
      this.getData(this.page);
    }
  }
};
</script>

<style scoped>
.el-upload__tip {
  color: #24ad8e !important;
}

.ml-3 {
  margin-left: 20px;
}

/*::v-deep .el-tabs__nav-wrap::after{*/
/*  background-color:#10433A!important*/
/*}*/
</style>
