import axios from "@/api/axios"; // 引用axios
export const initAdd = (params) => {
    return axios({
        url: "/api/resource/preinstallation/initAddData",
        method: "post",
        params
    });
};


export const initData = () => {
    return axios({
        url: "/api/resource/preinstallation/initData",
        method: "get"
    });
};

export const terminalModel = () => {
    return axios({
        url: "/api/pub/terminalModel",
        method: "post"
    });
};


export const addData = (params) => {
    return axios({
        url: "/api/resource/preinstallation/addData",
        method: "post",
        params
    });
};

export const listData = (params) => {
    return axios({
        url: "/api/resource/preinstallation/listData",
        method: "post",
        params
    });
};

export const editData = (params) => {
    return axios({
        url: "/api/resource/preinstallation/editData",
        method: "post",
        params
    });
};

export const dataDel = (params) => {
    return axios({
        url: "/api/resource/preinstallation/del",
        method: "post",
        params
    });
};

export const addPreinstallationImportExcel = (params) => {
    return axios({
        url: "/api/resource/preinstallation/addPreinstallationImportExcel",
        method: "post",
        data:params
    });
};

