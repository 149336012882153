import axios from "@/api/axios"; // 引用axios
//获取媒体资源列表
export const getList = (page, limit, params) => {
  const param = {
    ...params,
    page,
    limit
  };
  return axios({
    url: "/api/local/media/listData",
    method: "post",
    params: param
  });
};
//资源详情
export const getDetail = (id) => {
  return axios({
    url: "/api/local/media/detail",
    method: "post",
    params: { id }
  });
}; //新增资源
export const addData = (params) => {
  return axios({
    headers: {
      "Content-Type": "multipart/form-data"
    },
    url: "/api/local/media/addDataMore",
    method: "post",
    data: params
  });
};
//编辑资源
export const editData = (params) => {
  return axios({
    url: "/api/local/media/editData",
    method: "post",
    params: params
  });
};
//删除资源
export const delData = (id) => {
  return axios({
    url: "/api/local/media/del",
    method: "post",
    params: { id }
  });
};
// //文字转语音
// export const wordToVoice = (file) => {
//   return axios({
//     url: "/api/local/media/fileTransferString",
//     method: "post",
//     params: { file }
//   });
// };
//提交文字转语音
export const handleVoice = (params) => {
  return axios({
    url: "/api/local/media/stringTransferVoice",
    method: "post",
    params: params,
    timeout: 90000,
  });
};
//多文件上传
export const uploadfiles = (params) => {
  return axios({
    headers: {
      "Content-Type": "multipart/form-data"
    },
    url: "/api/local/media/uploadMp3More",
    method: "post",
    data: params
  });
};

export const getSourceType = () => {
  return axios({
    url: "/api/pub/getSourceType",
    method: "post",
  });
};

export const uploadPicMore = (params) => {
  return axios({
    // headers: {
    //   "Content-Type": "multipart/form-data"
    // },
    url: "/api/local/media/uploadPicMore",
    method: "post",
    data: params
  });
};

export const addDataMorePic = (params) => {
  return axios({
    url: "/api/local/media/addDataMorePic",
    method: "post",
    data: params
  });
};



