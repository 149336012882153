import axios from "@/api/axios"; // 引用axios
//适配器下拉框
export const getAdapterList = (params={}) => {
  return axios({
    url: "/api/adapter/network/config",
    method: "post",
    params: params,
  });
};
//适配器网络设置
export const adapterSet = (params={}) => {
  return axios({
    url: "/api/adapter/network/configSet",
    method: "post",
    params: params,
  });
};
//回传设置参数
  export const getreturnQuery = (params={}) => {
  return axios({
    url: "/api/adapter/passBack/config",
    method: "post",
    params: params,
  });
};
//适配器网络设置
export const adapterreturn = (params={}) => {
  return axios({
    url: "/api/adapter/passBack/configSet",
    method: "post",
    params: params,
  });
};