<template>
  <basicTable
    :attrList="attrList"
    :tableData="tableData"
    ref="table"
    :page="page.current"
    :limit="page.limit"
    :total="page.total"
    @currentChange="handleCurrentChange"
    @sizeChange="handleSizeChange"
  >
  </basicTable>
  <el-dialog
    width="45%"
    v-model="dialogFormVisible"
    title="编辑"
    :close-on-click-modal="false"
  >
    <el-form
      label-position="right"
      :model="form"
      size="small"
      :label-width="formLabelWidth"
      ref="dialogForm"
    >

      <el-form-item
        label="类型"
        :rules="[{ required: true, message: '类型 不能为空' }]"
        prop="type"
      >
        <el-radio-group v-model="form.type" disabled>
          <el-radio label="1">每天</el-radio>
          <el-radio label="2">每周</el-radio>
          <el-radio label="3">每月</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="form.type==1"
        label="时间间隔"
        prop="interval"
        :rules="[
              { required: true, message: '类型编码 不能为空', trigger: 'blur' },
            ]"
      >
        <el-input-number
          :min="1"
          v-if="form.type==1"
          clearable
          autocomplete="off"
          v-model="form.interval"
        >
        </el-input-number>
      </el-form-item>
      <el-form-item
        v-if="form.type==2"
        label="时间间隔"
        prop="week"
        :rules="[
              { required: true, message: '类型编码 不能为空', trigger: 'blur' },
            ]"
      >
        <el-checkbox-group v-model="form.week">
          <el-checkbox v-for="(item,index) in week" :key="index" :label="item.label">{{
              item.name
            }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item
        v-if="form.type==3"
        label="时间间隔"
        prop="interval"
        :rules="[
              { required: true, message: '类型编码 不能为空', trigger: 'blur' },
            ]"
      >
        <el-row>
          <el-col :span="1">
            <span>每</span>
          </el-col>
          <el-col :span="9">
            <el-input-number
              clearable
              :min="1"
              autocomplete="off"
              v-model="form.month"
            >
            </el-input-number>
          </el-col>
          <el-col :span="2">
            <span>月，</span>
          </el-col>
          <el-col :span="1">
            <span>第</span>
          </el-col>
          <el-col :span="9">
            <el-input-number
              v-if="form.type==3"
              clearable
              :min="1"
              autocomplete="off"
              v-model="form.day"
            >
            </el-input-number>
          </el-col>
          <el-col :span="2">
            <span>天</span>
          </el-col>
        </el-row>

      </el-form-item>

      <el-form-item
        label="执行时间"
        :rules="[{ required: true, message: '执行时间 不能为空' }]"
        prop="execute_time"
      >
        <el-time-picker v-model="form.execute_time" value-format="HH:mm:ss" placeholder="请选择 执行时间"></el-time-picker>
      </el-form-item>

    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button
          type="primary"
          size="small"
          @click="handlesubmit('dialogForm')"
        >提交
        </el-button>
        <el-button @click="dialogFormVisible = false" size="small" type="info" plain
        >取消
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { onMounted, reactive, ref, toRefs } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { getList, editData, canUse } from "@/api/system/databaseSettings";

export default {
  name: "databaseSettings",
  setup() {
    const option = reactive({
      week: [
        {
          label: "2",
          name: "星期一"
        }, {
          label: "3",
          name: "星期二"
        }, {
          label: "4",
          name: "星期三"
        }, {
          label: "5",
          name: "星期四"
        }, {
          label: "6",
          name: "星期五"
        }, {
          label: "7",
          name: "星期六"
        }, {
          label: "1",
          name: "星期日"
        }
      ],
      formLabelWidth: "120px",
      dialogFormVisible: false,
      title: "新增",
      form: {
        type: 1,
        interval: 0,
        week: [],
        month: 1,
        day: 1
      },
      searchLabelWidth: "100px",
      search: {},
      page: {
        current: 1,
        limit: 10,
        total: 0
      },
      tableData: [],
      attrList: [
        {
          label: "类型",
          prop: "type",
          render: (row) => <div>{row.type == 1 ? "每天" : (row.type == 2 ? "每周" : "每月")}</div>
        }, {
          label: "执行间隔",
          prop: "interval",
          render: (row) => {
            let interval = row.interval.split(",");
            if (row.type == 1) return <div>{interval[0]}天</div>;
            else if (row.type == 2) {
              let arr = [];
              interval.map(item => {
                if (item - 1 == 0) return arr.push("周日");
                else return arr.push("周" + (item - 1));
              });
              return <div>{arr.join(",")}</div>;
            } else if (row.type == 3) return <div>每间隔{interval[0]}个月，当月的{interval[1] == "L" ? "最后一" : "第" + interval[1]}天执行</div>;
          }
        }, {
          label: "状态",
          prop: "status",
          render: (row) => <div>{row.status == 1 ? "禁用" : "启用"}</div>
        },
        {
          label: "执行时间",
          prop: "execute_time",
          render: (row) => <div>{row.execute_time}</div>
        },
        {
          prop: "",
          label: "操作",
          render: (row) => {
            return (
              <div>
                <el-button
                  type="text"
                  icon="Edit"
                  onClick={() => handleEdit(row)}
                >
                  编辑
                </el-button>
                <el-button
                  type="text"
                  icon="TurnOff"
                  onClick={() => handleStatus(row.id, row.status)}
                >{row.status == 1 ? "启用" : "禁用"}</el-button>
              </div>
            );
          }
        }
      ]
    });
    const searchForm = ref(null);
    const dialogForm = ref(null);

    function getData(page) {
      getList(page.current, page.limit, option.search).then(res => {
        if (res.data.success) {
          option.page.total = res.data.data.count;
          option.tableData = res.data.data.list;
        }
      });
    }

    function searchFormFn() {
      option.page.current = 1;
      getData(option.page);
    }

    function resetForm() {
      searchForm.value.resetFields();
      option.page.current = 1;
      getData(option.page);
    }

    function handleCurrentChange(currentPage) {
      option.page.current = currentPage;
      getData(option.page);
    }

    function handleSizeChange(pageSize) {
      option.page.limit = pageSize;
      getData(option.page);
    }

    function handleStatus(id, status) {
      status = status == 1 ? 2 : 1;
      canUse({ id, status }).then(res => {
        if (res.data.success) {
          getData(option.page);
          ElMessage({
            type: "success",
            message: "操作成功！"
          });
        }
      });
    }

    function handleEdit(row) {
      console.log(row);
      let { id, type, execute_time, interval } = row;
      option.form.id = id;
      option.form.type = type;
      option.form.execute_time = execute_time;
      if (type == 1) option.form.interval = interval;
      else if (type == 2) option.form.week = interval.split(",");
      else if (type == 3) {
        let arr = interval.split(",");
        option.form.month = arr[0];
        option.form.day = arr[1];
      }
      option.dialogFormVisible = true;
    }

    function handlesubmit() {
      dialogForm.value.validate((valid) => {
        if (valid) {
          console.log(option.form);
          let params = {};
          if (option.form.type == 1) {
            let { interval, execute_time, type, id } = option.form;
            params = { interval, execute_time, type, id };
          } else if (option.form.type == 2) {
            let { week, execute_time, type, id } = option.form;
            params = { interval: week.join(","), execute_time, type, id };
          } else if (option.form.type == 3) {
            let { month, day, execute_time, type, id } = option.form;
            params = { interval: month + "," + day, execute_time, type, id };
          }
          editData(params).then(res => {
            if (res.data.success) {
              getData(option.page);
              option.dialogFormVisible = false;
              ElMessage({
                type: "success",
                message: "操作成功！"
              });
            }
          });
        } else return;
      });
    }

    onMounted(() => {
      getData(option.page);
    });
    return {
      searchForm,
      ...toRefs(option),
      getData,
      searchFormFn,
      resetForm,
      handleCurrentChange,
      handleSizeChange,
      handlesubmit,
      handleEdit,
      dialogForm
    };
  }
};
</script>

<style scoped>

</style>