import axios from "@/api/axios"; // 引用axios
export const sendData = (params) => {
    return axios({
        url: "/api/local/rxtx/sendData",
        method: "post",
        params
    });
};

export const closeData = (params) => {
    return axios({
        url: "/api/local/rxtx/close",
        method: "post",
        params
    });
};