import axios from "@/api/axios"; // 引用axios
//获取节目审核列表
export const getList = (page, limit, params) => {
  const param = {
    ...params,
    page,
    limit,
  };
  return axios({
    url: "/api/local/programCheck/listData",
    method: "post",
    params: param,
  });
};
//节目审核通过
export const handlePass = (id) => {
  return axios({
    url: "/api/local/programCheck/pass",
    method: "post",
    params: { id },
  });
}; //节目审核不通过
export const handleReject = (params) => {
  return axios({
    url: "/api/local/programCheck/noPassEdit",
    method: "post",
    params: params,
  });
};
