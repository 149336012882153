<template>
  <div class="formContainer">
    <div class="formBox">
      <div class="aimTitle">
        <el-tag class="ml-2" type="success">参数/状态查询</el-tag>
        <span class="introduce">目的： 操作查询参数/状态查询</span>
        <span class="introduce">设置： 直接设备进行控制</span>
      </div>
      <el-form
        :model="form"
        :label-width="labelWidth"
        ref="addForm"
      >
        <!--        <el-row :gutter="24">-->

        <!--          <el-col :span="12" >-->
        <el-form-item
          label="回传地址类型"
          prop="rebackAddressType"
          :rules="[{ required: true, message: '回传地址类型 不能为空' }]"
        >
          <el-select
            :fit-input-width="true"
            v-model="form.rebackAddressType"
            placeholder="请选择 回传地址类型"
          >
            <el-option :label="item.name" :value="item.code" v-for="(item,index) in rebackAddressList"
                       :key="index"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="终端选择"
          prop="resourceCode"
          :rules="[{ required: true, message: '终端选择 不能为空' }]"
        >
          <el-select
            :fit-input-width="true"
            v-model="form.resourceCode"
            placeholder="请选择 终端选择"
          >
            <el-option :label="item.name" :value="item.code" v-for="(item,index) in terminalType"
                       :key="index"></el-option>
          </el-select>
        </el-form-item>
        <!--          </el-col>-->

        <!--        </el-row>-->
        <!--          </el-col>-->
        <!--          <el-col :span="6">-->
        <el-form-item
          v-if="phoneDis"
          label="电话"
          prop="phone"
          :rules="[{ required: true, message: '电话 不能为空' }]"
        >
          <el-input
            clearable
            autocomplete="off"
            v-model="form.phone"
            placeholder="请输入 电话"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          v-if="ipDis"
          label="ip"
          prop="ip"
          :rules="[{ required: true, message: 'ip 不能为空' }]"
        >
          <el-input
            clearable
            autocomplete="off"
            v-model="form.ip"
            placeholder="请输入 ip"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          v-if="ipDis"
          label="ip端口"
          prop="ip_port"
          :rules="[{ required: true, message: 'ip端口 不能为空' }]"
        >
          <el-input
            clearable
            autocomplete="off"
            v-model="form.ip_port"
            placeholder="请输入 ip端口"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          v-if="domainDis"
          label="域名"
          prop="domain"
          :rules="[{ required: true, message: '域名 不能为空' }]"
        >
          <el-input
            clearable
            autocomplete="off"
            v-model="form.domain"
            placeholder="请输入 域名"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          v-if="domainDis"
          label="域名端口"
          prop="domain_port"
          :rules="[{ required: true, message: '域名端口 不能为空' }]"
        >
          <el-input
            clearable
            autocomplete="off"
            v-model="form.domain_port"
            placeholder="请输入 域名端口"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="查询信息类型"
          prop="param"
          :rules="[{ required: true, message: '查询信息类型 不能为空' }]"
        >
          <el-checkbox
            v-model="checkAll"
            :indeterminate="isIndeterminate"
            @change="handleCheckAllChange"
          >全部
          </el-checkbox
          >
          <el-checkbox-group v-model="form.param" @change="checkboxChange">

            <el-checkbox label="TERMINAL_VOLUME">终端音量</el-checkbox>
            <el-checkbox label="LOCAL_ADDRESS">本地地址</el-checkbox>
            <el-checkbox label="REBACK_ADDRESS">回传地址</el-checkbox>
            <el-checkbox label="TERMINAL_RESOURCE_CODE">终端资源编码</el-checkbox>
            <el-checkbox label="PHYSICAL_ADDRESS">物理地址编码</el-checkbox>
            <el-checkbox label="WORK_STATUS">工作状态</el-checkbox>
            <el-checkbox label="FAULT_TYPE">故障代码</el-checkbox>
            <el-checkbox label="DEVICE_TYPE">设备类型</el-checkbox>
            <el-checkbox label="HARDWARE_VERSION">硬件版本号</el-checkbox>
            <el-checkbox label="SOFTWARE_VERSION">软件版本号</el-checkbox>
            <el-checkbox label="FM_SIGNAL_STATUS">调频信号状态</el-checkbox>
            <el-checkbox label="DVB_C_SIGNAL_STATUS">有线信号状态</el-checkbox>
            <el-checkbox label="DVB_T_SIGNAL_STATUS">地面无线信号状态</el-checkbox>
            <el-checkbox label="DVB_C_HZ">有线频率</el-checkbox>
            <el-checkbox label="DVB_T_HZ">地面无线频率</el-checkbox>
            <el-checkbox label="FM_HZ_VALUE_SCAN_LIST">FM频点扫描列表</el-checkbox>
            <el-checkbox label="FM_CURRENT_HZ_VALUE">FM当前频点</el-checkbox>
            <el-checkbox label="FM_KEEP_CMD_MODE">FM维持指令模式</el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <!--          </el-col>-->
        <!--          <el-col :span="6">-->
        <!--              <el-form-item>-->
        <el-button plain @click="addForm('addForm')" class="searchBtn">提交</el-button>
        <!--              </el-form-item>-->
        <!--          </el-col>-->
        <!--        </el-row>-->
      </el-form>
    </div>
  </div>
</template>

<script>
import { getRegionEquip } from "@/api/system/region";
import { paramsStatus, getparamsStatus } from "@/api/terminalRegulate/generalVolume";
import { ref, reactive, watch } from "vue";

export default {
  name: "parameterStatus",
  setup() {
    const checkAll = ref(false);
    const isIndeterminate = ref(true);
    const form = reactive({
      param: []
    });
    const domainDis = ref(false);
    const ipDis = ref(false);
    const phoneDis = ref(true);
    const checkBoxVal = ["TERMINAL_VOLUME", "FM_KEEP_CMD_MODE", "FM_CURRENT_HZ_VALUE", "FM_HZ_VALUE_SCAN_LIST", "DVB_T_HZ", "DVB_C_HZ", "DVB_T_SIGNAL_STATUS",
      "DVB_C_SIGNAL_STATUS", "FM_SIGNAL_STATUS", "SOFTWARE_VERSION", "HARDWARE_VERSION", "DEVICE_TYPE", "FAULT_TYPE", "WORK_STATUS", "PHYSICAL_ADDRESS", "TERMINAL_RESOURCE_CODE", "REBACK_ADDRESS", "LOCAL_ADDRESS"];
    const handleCheckAllChange = (val) => {
      form.param = val ? checkBoxVal : [];
      isIndeterminate.value = false;
    };
    const checkboxChange = (value) => {
      const checkedCount = value.length;
      checkAll.value = checkedCount === checkBoxVal.length;
      isIndeterminate.value = checkedCount > 0 && checkedCount < checkBoxVal.length;
    };
    watch(() => form.rebackAddressType,
      (val, oldVal) => {
        if (val == "phone") {
          console.log(phoneDis);
          phoneDis.value = true;
          domainDis.value = false;
          ipDis.value = false;
        } else if (val == "ipAndPort") {
          phoneDis.value = false;
          domainDis.value = false;
          ipDis.value = true;
        } else if (val == "domainAndPort") {
          phoneDis.value = false;
          domainDis.value = true;
          ipDis.value = false;
        }
      });
    return { form, domainDis, phoneDis, ipDis, checkAll, handleCheckAllChange, checkboxChange };
  },
  data() {
    return {
      code: [],
      rebackAddressList: [],
      terminalType: [],
      codeList: [],
      paramsList: [],
      labelWidth: "200px",
      regionList: [],
      treeSelectProps: {
        label: "name",
        value: "id",
        key: "code",
        children: "children"
      }
    };
  },
  created() {
    getRegionEquip().then(res => {
      if (res.data.success) {
        this.regionList = res.data.data.list;
      }
    });
    getparamsStatus().then(res => {
      if (res.data.success) {
        this.rebackAddressList = res.data.data.rebackAddressType;
        this.terminalType = res.data.data.terminal;
      }
    });
  },
  methods: {
    addForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let { resourceCode, rebackAddressType, phone, param, domain_port, domain, ip_port, ip } = this.form;
          param = param.join(",");
          paramsStatus({ resourceCode, rebackAddressType, phone, param,domain_port, domain, ip_port, ip }).then(res => {
            if (res.data.success) {
              this.$message.success("操作成功！");
              this.$refs[formName].resetFields();
              this.$refs.tree.setCheckedKeys([]);
            }
          });
        }
      });
    }
    // checkboxChange(value) {
    //   console.log(value);
    //   if (value.includes("all")) {
    //     this.form.param = ["all", "TERMINAL_VOLUME", "FM_KEEP_CMD_MODE", "FM_CURRENT_HZ_VALUE", "FM_HZ_VALUE_SCAN_LIST", "DVB_T_HZ", "DVB_C_HZ", "DVB_T_SIGNAL_STATUS",
    //       "DVB_C_SIGNAL_STATUS", "FM_SIGNAL_STATUS", "SOFTWARE_VERSION", "HARDWARE_VERSION", "DEVICE_TYPE", "FAULT_TYPE", "WORK_STATUS", "PHYSICAL_ADDRESS", "TERMINAL_RESOURCE_CODE", "REBACK_ADDRESS", "LOCAL_ADDRESS"];
    //   }
    // }
    // getSimpleCheckedNodes(store) {
    //   const checkedNodes = [];
    //   const traverse = function(node) {
    //     const childNodes = node.root ? node.root.childNodes : node.childNodes;
    //     childNodes.forEach(child => {
    //       if (child.checked) {
    //         checkedNodes.push(child.data);
    //       }
    //       if (child.indeterminate) {
    //         traverse(child);
    //       }
    //     });
    //   };
    //   traverse(store);
    //   return checkedNodes;
    // },
    // checkChange(node, status) {
    //   this.codeList = this.getSimpleCheckedNodes(this.$refs.tree.store);
    // }
  }
};
</script>

<style scoped lang="scss">
.formContainer {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.formBox {
  width: 800px;
}

.ml-2 {
  margin-bottom: 40px;
}

.introduce {
  color: #1C9C77;
}

.main {
  width: 300px;

  ::-webkit-scrollbar {
    width: 0;
  }
}

.el-tree {
  height: 700px !important;
  overflow-y: scroll;
}

.el-tree-node__content {
  margin-top: 20px !important;
}


.aimTitle span {

  margin-right: 30px;
}

.aimTitle {
  text-align: left;
}


</style>