<template>
  <div>
    <Searchbar @emit-search="onSearch" @reset-form="resetForm">
      <template #SEARCH>
        <el-col :span="6">
          <el-form-item label="系统名称">
            <el-input clearable v-model="searchForm.name" placeholder="请输入 系统名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="资源编码">
            <el-input clearable v-model="searchForm.code" placeholder="请输入 资源编码"></el-input>
          </el-form-item>
        </el-col>
      </template>

      <template #BTN>
        <el-button class="searchBtn" @click="dialogFormVisible = true;title = '新增'">新增</el-button>
      </template>
    </Searchbar>
    <basicTable
        :tableData="tableData"
        :attrList="attrList"
        :page="page.current"
        :limit="page.limit"
        @currentChange="handleCurrentChange"
        @sizeChange="handleSizeChange"
        :total="page.total">
    </basicTable>

    <!--  新增编辑  -->
    <el-dialog
        v-model="dialogFormVisible"
        :title="title"
        :close-on-click-modal="false"
        :before-close="handleClose"
    >
      <el-form
          label-position="right"
          :model="form"
          size="small"
          label-width="140px"
          ref="dialogForm"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="系统名称" prop="name" :rules="[{ required: true, message: '系统名称 不能为空' }]">
              <el-input placeholder="请输入 系统名称" clearable v-model="form.name" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="资源编码" prop="code" :rules="[{ required: true, message: '资源编码 不能为空' }]">
              <el-input placeholder="请输入 资源编码" clearable v-model="form.code" autocomplete="off"></el-input>
            </el-form-item>
          </el-col> <el-col :span="12">
            <el-form-item label="行政区域码" prop="region_code" :rules="[{ required: true, message: '行政区域码 不能为空' }]">
              <el-input placeholder="请输入 行政区域码" clearable v-model="form.region_code" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>


          <el-col :span="12">
            <el-form-item label="接入访问地址" prop="url" :rules="[{ required: true, message: '接入访问地址 不能为空' }]">
              <el-input placeholder="请输入 接入访问地址" clearable v-model="form.url" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="平台访问地址" prop="address" :rules="[{ required: true, message: '平台访问地址 不能为空' }]">
              <el-input placeholder="请输入 平台访问地址" clearable v-model="form.address" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="经度" prop="longitude" :rules="[{ required: true, message: '经度 不能为空' }]">
              <el-input placeholder="请输入 经度" clearable v-model="form.longitude" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="纬度" prop="latitude" :rules="[{ required: true, message: '纬度 不能为空' }]">
              <el-input placeholder="请输入 纬度" clearable v-model="form.latitude" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>


          <el-col :span="12">
            <el-form-item label="联系人" prop="linkman">
              <el-input placeholder="请输入 联系人" clearable v-model="form.linkman" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="联系电话" prop="phone">
              <el-input placeholder="请输入 联系电话" clearable v-model="form.phone" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>


          <el-col :span="12">
            <el-form-item label="覆盖面积(平方公里)" prop="square">
              <el-input placeholder="请输入 覆盖面积" clearable v-model="form.square" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="覆盖人口(万)" prop="population">
              <el-input placeholder="请输入 覆盖人口" clearable v-model="form.population" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="状态" prop="status" :rules="[{ required: true, message: '状态 不能为空' }]">
                <el-select  clearable v-model="form.status" placeholder="请选择" size="large">
                  <el-option v-for="item in statuslList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="支持协议" prop="agreement" :rules="[{ required: true, message: '支持协议 不能为空' }]">
              <el-select  clearable v-model="form.agreement" placeholder="请选择" size="large">
                <el-option v-for="item in agreementList" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="接入方式" prop="way" :rules="[{ required: true, message: '接入方式 不能为空' }]">
              <el-select  clearable v-model="form.way" placeholder="请选择" size="large">
                <el-option v-for="item in wayList" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" size="small" @click="onSend">提交</el-button>
          <el-button size="small" type="info" @click="quxiao">取消</el-button>
        </div>
      </template>
    </el-dialog>


    <el-dialog
        v-model="isresource"
        title="绑定设备"
        :close-on-click-modal="false"
        :before-close="resourceClose"
    >
      <div class="search-bar">
        <el-form class="search-form" :model="ffff" label-width="120px" ref="searchResource">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="名称">
                <el-input v-model="ffff.resource_name" placeholder="请输入 名称"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="资源码">
                <el-input v-model="ffff.resource_code" placeholder="请输入 资源码"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="类型">
                <el-select clearable v-model="ffff.type" placeholder="请选择" size="large">
                  <el-option v-for="item in resourceList" :key="item.code" :label="item.name" :value="item.code">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="安装地址">
                <el-input v-model="ffff.install_addr" placeholder="请输入 安装地址"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12" style="display: flex;margin-bottom: 10px">
              <el-button @click="resourceForm" class="searchBtn">查询</el-button>
              <el-button @click="resetBtn" class="resetBtn">重置</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <basicTable
          :indexshow="false"
          ref="voicetable"
          :tableData="resourceData"
          :attrList="bindList"
          @currentChange="handleCurrentChange2"
          @sizeChange="handleSizeChange2"
          :page="resourcePage.current"
          :limit="resourcePage.limit"
          :total="resourcePage.total"
          @selectChange='selectChange'>
      </basicTable>

      <el-button class="searchBtn btn" @click="choose">添加</el-button>

      <basicTable
          :isPage="false"
          :indexshow="false"
          :tableData="chooseData"
          :attrList="chooseList">
      </basicTable>

      <div class="btnflex"><el-button style="background: #009688" @click="onBind">确定</el-button></div>

    </el-dialog>

    <!--  设备详情  -->
    <el-dialog
        v-model="isxq"
        title="设备详情"
        :close-on-click-modal="false"
    >
      <basicTable
          :indexshow="false"
          ref="voicetable"
          :tableData="xqData"
          :attrList="xqList"
          :page="xqPage.current"
          :limit="xqPage.limit"
          :total="xqPage.total">
      </basicTable>
    </el-dialog>

    <el-dialog
        append-to-body
        v-model="dialogVisible"
        title="绑定"
        width="30%"
        :before-close="handleClose"
    >
      <el-form
          ref="macFormLabel"
          label-position="right"
          label-width="100px"
          :model="formLabelAlign"
      >
        <el-form-item label="设备MAC:">
          <el-input placeholder="请输入设备MAC" v-model="formLabelAlign.mac_addr" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="changeMac">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {reactive, onMounted, toRefs, ref, nextTick} from 'vue';
import { getData, dataSave, getedit, getDel, dataResource, childBind, dataBind, unBind, editMac } from "@/api/equipment/manage";
import Searchbar from '@/components/Searchbar';
import { ElMessageBox, ElMessage } from "element-plus";
import { getEquipType } from "@/api/basic/equipType";
export default {
  components:{
    Searchbar
  },
  setup(){
    let macFormLabel = ref(null)
    let isxq = ref(false);
    let voicetable = ref('');
    let codeIdx = ref('');
    let idx = ref('');
    let isresource = ref(false);
    let dialogForm = ref(null);
    let title = ref('');
    let dialogFormVisible = ref(false);
    let dialogVisible = ref(false);
    let state = reactive({
      xqList:[
        {
          prop: "id",
          label: "ID",
          render: (row) => <div>{row.id}</div>,
        },
        {
          prop: "resource_code",
          label: "资源码",
          render: (row) => <div>{row.resource_code }</div>,
        },
        {
          prop: "resource_name",
          label: "名称",
          render: (row) => <div>{row.resource_name}</div>,
        },
        {
          prop: "ip_addr",
          label: "安装地址",
          render: (row) => <div>{row.install_addr}</div>,
        },
        {
          prop: "ip_addr",
          label: "IP地址",
          render: (row) => <div>{row.ip_addr}</div>,
        },
        {
          prop: "",
          label: "操作",
          render: (row) => <el-button type="text" icon="Reading" onClick={()=>{ unBinds(row) }}>设备解绑</el-button>
        },
      ],
      formLabelAlign:{},
      xqPage:{
        current: 1,
        limit: 10,
        total: 0,
      },
      xqData:[],
      zcChoose:[],
      chooseData:[],
      ffff:{},
      resourceList:[],
      chooseList:[
        {
          prop: "id",
          label: "ID",
          render: (row) => <div>{row.id}</div>,
        },
        {
          prop: "resource_code",
          label: "资源码",
          render: (row) => <div>{row.resource_code }</div>,
        },
        {
          prop: "resource_name",
          label: "名称",
          render: (row) => <div>{row.resource_name}</div>,
        },
        {
          prop: "ip_addr",
          label: "安装地址",
          render: (row) => <div>{row.install_addr}</div>,
        },
        {
          prop: "ip_addr",
          label: "IP地址",
          render: (row) => <div>{row.ip_addr}</div>,
        },
        {
          prop: "",
          label: "操作",
          render: (row) => <el-button type="text" icon="Delete" onClick={()=>chooseRemove(row)}>移出</el-button>,
        },
      ],
      bindList: [
        {
          prop: "id",
          label: "ID",
          render: (row) => <div>{row.id}</div>,
        },
        {
          prop: "resource_code",
          label: "资源码",
          render: (row) => <div>{row.resource_code }</div>,
        },
        {
          prop: "resource_name",
          label: "名称",
          render: (row) => <div>{row.resource_name}</div>,
        },
        {
          prop: "ip_addr",
          label: "安装地址",
          render: (row) => <div>{row.install_addr}</div>,
        },
        {
          prop: "ip_addr",
          label: "IP地址",
          render: (row) => <div>{row.ip_addr}</div>,
        },
      ],
      resourcePage: {
        current: 1,
        limit: 10,
        total: 0,
      },
      resourceData:[],
      searchForm:{},
      form:{},
      tableData:[],
      statuslList:[
        {
          value:1,
          label:"启用"
        },
        {
          value:2,
          label:"禁用"
        }
      ],
      agreementList:[
        {
          value:1,
          label:"GDJ083"
        },
        {
          value:2,
          label:"GDJ089A"
        }
      ],
      wayList:[
        {
          value: 1,
          label:"向上接入"
        },
        {
          value: 2,
          label:"向下推送"
        },
        {
          value: 3,
          label:"双向接入"
        },
        {
          value: 4,
          label:"IP话筒"
        },
        {
          value: 5,
          label:"便携式系统"
        }
      ],
      attrList:[
        {
          prop: "name",
          label: "名称",
          render: (row) => <div>{row.name}</div>,
        },
        {
          prop: "code",
          label: "资源编码",
          render: (row) => <div>{row.code}</div>,
        },
        {
          prop: "way",
          label: "接入方式",
          render: (row) => <div>{row.way == 1 ? '向上接入' : row.way == 2 ? "向下推送" : row.way == 3 ? '双向接入' : row.way == 4 ? 'IP话筒' : '便携式系统' }</div>,
        },
        {
          prop: "link_state",
          label: "连接状态",
          render: (row) => <div>{row.link_state == 1 ? "未连接" : "连接在线"}</div>,
        },
        {
          prop: "work_state",
          label: "工作状态",
          render: (row) => <div>{row.work_state == 1 ? "开机/运行正常" : "关机/停止运行"}</div>,
        },
        {
          prop: "link_time",
          label: "心跳时间",
          render: (row) => <div>{row.link_time}</div>,
        },
        {
          prop: "work_time",
          label: "上报时间",
          render: (row) => <div>{row.work_time}</div>,
        },
        {
          prop: "",
          width:360,
          label: "操作",
          render: (row) =>
              <div>
                <el-button style={ row.way != 5 && 'display:none'} type="text" icon="Connection" onClick={()=>macBind(row)}>
                  绑定
                </el-button>
                <el-button type="text" icon="Paperclip" onClick={()=>resourceAjax(row)}>
                  设备绑定
                </el-button>
                <el-button type="text" icon="Reading" onClick={()=>bindAjax(row)}>
                  设备详情
                </el-button>
                <el-button type="text" icon="Edit" onClick={()=>edit(row)}>
                  编辑
                </el-button>
                <el-button type="text" icon="Delete" onClick={()=>delAjax(row)}>
                  删除
                </el-button>
              </div>,
        },
      ],
      macIdx:"",
      page: {
        current: 1,
        limit: 10,
        total: 0,
      },
    })
    onMounted(()=>{
      getEquipType().then(res=> state.resourceList = res.data.data);
      onLoad();
    })
    function onLoad(form = {}){
      getData({ limit:state.page.limit, page:state.page.current, ...form }).then(res=>{
        state.tableData = res.data.data.list;
        state.page.total = res.data.data.count;
      })
    }

    function resourceForm(){
      resourceData(codeIdx.value, state.ffff)
    }

    function resourceAjax(row){
      resourceData(row.code);
      codeIdx.value = row.code;
      isresource.value = true;
    }

    async function resourceData(code, form = {}){
      let res =  await dataResource({
        platform_code: code,
        ...form,
        page: state.resourcePage.current,
        limit: state.resourcePage.limit
      })
      state.resourceData = res.data.data.list;
      state.resourcePage.total = res.data.data.count;
    }

    function onSend(){
      dialogForm.value.validate((valid) => {
        if (valid) {
          if(title.value == '新增'){
            dataSave(state.form).then(res=>{
              if(res.data.success){
                ElMessage({message: res.data.code.message, type: 'success'});
                state.page.current = 1;
                onLoad();
                dialogForm.value.resetFields();
                state.form = {};
                dialogFormVisible.value = false;
              }
            })
          }else{
            getedit({...state.form, id:idx.value}).then(res=>{
              if(res.data.success){
                ElMessage({message: res.data.code.message, type: 'success'});
                state.page.current = 1;
                onLoad();
                dialogForm.value.resetFields();
                state.form = {};
                dialogFormVisible.value = false;
              }
            })
          }
        } else {
          return false
        }
      })
    }

    function edit(row){
      state.form = JSON.parse(JSON.stringify(row));
      idx.value = row.id;
      dialogFormVisible.value = true;
      title.value = '编辑';
    }

    function delAjax(row){
      ElMessageBox.confirm('此操作将做删除操作, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        getDel({id:row.id}).then(res=>{
          if(res.data.success){
            ElMessage({message: res.data.code.message, type: 'success'});
            state.page.current = 1;
            onLoad();
          }
        })
      }).catch(() => {
      });
    }

    function handleClose(done){
      done();

      if(dialogVisible.value){
        state.formLabelAlign = {};
        macFormLabel.value.resetFields();
      }


      if(dialogFormVisible.value){
        state.form = {};
        dialogForm.value.resetFields();
      }

    }

    function quxiao(){
      dialogFormVisible.value = false;
      nextTick(()=>{
        state.form = {};
        dialogForm.value.resetFields();
      })
    }

    function onSearch(){
      state.page.current = 1;
      onLoad(state.searchForm);
    }

    function handleCurrentChange(currentPage) {
      state.page.current = currentPage;
      onLoad(state.searchForm);
    }

    function handleSizeChange(pageSize) {
      state.page.limit = pageSize;
      onLoad(state.searchForm);
    }

    function resetForm() {
      state.searchForm = {};
      state.time = [];
      onLoad(state.searchForm);
    }

    function resetBtn(){
      state.resourcePage.page = 1;
      state.resourcePage.limit = 10;
      state.ffff = {};
      resourceData(codeIdx.value, state.ffff)
    }

    function choose(){
      state.zcChoose.forEach(item=>{
        if(state.chooseData.indexOf(item) === -1) state.chooseData.push(item);
      })
      voicetable.value.clearSelect();
    }

    function selectChange(value){
      state.zcChoose = value;
    }


    function chooseRemove(row){
      state.chooseData.splice(state.chooseData.findIndex(value=>value.id == row.id),1)
    }

    function onBind(){
      let codes = state.chooseData.map(item=>item.resource_code);
      childBind({
        platform_code:codeIdx.value,
        codes:codes.join(',')
      }).then(res=>{
        if(res.data.success){
          ElMessage({message: res.data.code.message, type: 'success'})
          isresource.value = false;
          state.chooseData = [];
          onLoad();
        }
      })
    }

    function macBind(row){
      state.macIdx = row.id;
      dialogVisible.value = true;
    }

    function changeMac(){
      editMac({
        id: state.macIdx,
        mac_addr: state.formLabelAlign.mac_addr
      }).then(res=>{
        if (res.data.success) {
          ElMessage({message: "操作成功", type: "success"});
          nextTick(()=>{
            state.formLabelAlign = {};
            macFormLabel.value.resetFields();
          })
          dialogVisible.value = false;
        }
      })
    }

    async function bindAjax(row){
      let res =  await dataBind({
        platform_code:row.code,
        page:state.xqPage.current,
        limit:state.xqPage.limit
      });
      codeIdx.value = row.code;
      isxq.value = true;
      state.xqData = res.data.data.list;
      state.xqPage.total = res.data.data.count;
    }

    function unBinds(row){
      console.log(row);
      unBind({ id: row.id, code: codeIdx.value }).then(res=>{
        if(res.data.success){
          ElMessage({message: res.data.code.message, type: 'success'});
          bindAjax({code: codeIdx.value});
        }
      })
    }

    function handleCurrentChange2(currentPage) {
      state.resourcePage.current = currentPage;
      resourceData(codeIdx.value, state.ffff)
    }

    function handleSizeChange2(pageSize) {
      state.resourcePage.limit = pageSize;
      resourceData(codeIdx.value, state.ffff)
    }

    return{
      onBind,
      selectChange,
      choose,
      resetBtn,
      isresource,
      isxq,
      codeIdx,
      voicetable,
      handleSizeChange2,
      handleCurrentChange2,
      resourceForm,
      handleCurrentChange,
      handleSizeChange,
      quxiao,
      resetForm,
      changeMac,
      idx,
      title,
      dialogFormVisible,
      dialogVisible,
      dialogForm,
      macFormLabel,
      onSearch,
      handleClose,
      onSend,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
.btn{
  margin: 10px 0;
  display: block !important;
}
.btnflex{
  display:flex;
  width: 100%;
  flex-direction: row-reverse;
  margin-top: 20px;
}
</style>