<template>
  <div class="searchBox">
    <el-form
      :model="search"
      :label-width="searchLabelWidth"
      ref="searchForm"
    >
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item
            label="用户账号"
            prop="userNo"
          >
            <el-input v-model="search.userNo" autocomplete="off" placeholder="请输入 用户账号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            label="用户姓名"
            prop="realName"
          >
            <el-input v-model="search.realName" autocomplete="realName" placeholder="请输入 用户姓名"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            label="状态"
            prop="state"
          >
            <el-select
              :fit-input-width="true"
              v-model="search.state"
              placeholder="请选择 启用"
            >
              <el-option label="启用" value="1"></el-option>
              <el-option label="禁用" value="2"></el-option>
            </el-select>
          </el-form-item>

        </el-col>
        <el-col :span="6">
          <el-form-item
            label="所属区域"
            prop="regionId"
          >
            <selectTree :treeData="regionList" @selectTreeChange="searchselectTree" ref="selectTree"></selectTree>
          </el-form-item>

        </el-col>
        <el-col :span="6">
          <el-form-item
            label="角色"
            prop="roleId"
          >
            <el-select
              :fit-input-width="true"
              v-model="search.roleId"
              placeholder="请选择 角色"
            >
              <el-option :label="item.roleName" :value="item.id" v-for="(item,index) in rolesList"
                         :key="index"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <!--              <el-form-item>-->
          <el-button plain @click="searchForm('searchForm')" class="searchBtn">查询</el-button>
          <el-button plain @click="resetForm('searchForm')" class="resetBtn">重置</el-button>


          <!--              </el-form-item>-->
        </el-col>
      </el-row>
    </el-form>
    <div class="divide">
      <img src="../../assets/divide.png" style="width: 100%">
    </div>
    <div class="btnContainer">
      <div class="btnLeft">
        <el-button icon="Plus" @click="handleAdd" class="searchBtn">新增</el-button>
      </div>

    </div>
  </div>
  <basicTable
    :attrList="attrList"
    :tableData="tableData"
    ref="table"
    :page="page.current"
    :limit="page.limit"
    :total="page.total"
    @currentChange="handleCurrentChange"
    @sizeChange="handleSizeChange">
  </basicTable>
  <el-dialog
    width="60%"
    v-model="dialogFormVisible"
    :title="title"
    :close-on-click-modal="false"
    :before-close="handleClose2"
  >
    <el-form
      label-position="right"
      :model="form"
      size="small"
      :label-width="formLabelWidth"
      ref="dialogForm"
    >
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            label="用户账号"
            :rules="[{ required: true, message: '用户账号 不能为空' }]"
            prop="userNo"
          >
            <el-input v-model="form.userNo" autocomplete="off" clearable placeholder="请输入 用户账号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="用户姓名"
            prop="realName"
            :rules="realNameRules"
          >
            <el-input
              clearable
              autocomplete="off"
              v-model="form.realName"
              placeholder="请输入 用户姓名"
            >
            </el-input>
          </el-form-item>

        </el-col>

      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            label="角色"
            prop="roleId"
            :rules="[{ required: true, message: '角色 不能为空' }]"
          >
            <el-select
              :fit-input-width="true"
              v-model="form.roleId"
              placeholder="请选择 角色"
            >
              <el-option :label="item.roleName" :value="item.id" v-for="(item,index) in rolesList"
                         :key="index"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="性别"
            prop="sex"
            :rules="[{ required: true, message: '性别 不能为空' }]"
          >
            <el-radio-group v-model="form.sex">
              <el-radio :label="1">男</el-radio>
              <el-radio :label="2">女</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            label="移动电话"
            prop="telPhone"
            :rules="[{ required: true, message: '移动电话 不能为空' }]"
          >
            <el-input
              clearable
              type="tel"
              autocomplete="off"
              v-model="form.telPhone"
              placeholder="请输入 移动电话"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="到期时间"
            prop="expiryDate"

            :rules="[{ required: true, message: '到期时间 不能为空' }]"
          >
            <el-date-picker
              type="datetime"
              value-format="YYYY-MM-DD HH:mm:ss"
              v-model="form.expiryDate"
              placeholder="选择 到期时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">

          <el-form-item
            label="审核权限"
            prop="power"
            :rules="[{ required: true, message: '审核权限 不能为空' }]"
          >
            <el-select
              :fit-input-width="true"
              v-model="form.power"
              placeholder="请选择 审核权限"
            >
              <el-option label="上传权限" :value="1"></el-option>
              <el-option label="一审权限" :value="2"></el-option>
              <el-option label="二审权限" :value="3"></el-option>
              <el-option label="所有权限" :value="4"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="状态"
            prop="state"
            :rules="[{ required: true, message: 'state 不能为空' }]"
          >
            <el-switch v-model="form.state" active-text="启用" inactive-text="禁用" :active-value="1"
                       :inactive-value="2" active-color="#00ffc6">
            </el-switch>
          </el-form-item>

        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12" v-if="pwdShow">
          <el-form-item

            :label="pwdLabel"
            prop="password"
            :rules="[{ required: true, message: '用户密码 不能为空' }]"
          >
            <el-input
              clearable
              show-password
              autocomplete="off"
              v-model="form.password"
              placeholder="请输入 用户密码"
            >
            </el-input>
          </el-form-item>

        </el-col>
        <el-col :span="12">
          <el-form-item
            label="所属区域"
            prop="regionId"
            :rules="[{ required: true, message: '区域Id 不能为空' }]"
          >
            <selectTree :treeData="regionList" @selectTreeChange="selectTreeChange" :propsId="propsId"
            ></selectTree>
          </el-form-item>

        </el-col>
        <el-col :span="12" v-if="regionIdShow">
          <el-form-item
            label="区域集"
            prop="regionIdStrs"
          >
            <div class="main">
              <el-tree :data="childRegion" :props="TreeSelectProps" show-checkbox node-key="id" accordion
                       :default-checked-keys="regionIdStrs" ref="tree" />
            </div>

            <!--            <TreeSelect placeholder="请选择 区域集" :tree-data="childRegion" multiple allowClear showCheckedStrategy="TreeSelect.SHOW_PARENT"-->
            <!--                        :value="regionIdStrs"-->
            <!--                        style="width: 100%" labelInValue :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"-->
            <!--                        :fieldNames="TreeSelectProps" treeNodeLabelProp="name" treeCheckable @change="TreeSelectChange"-->
            <!--                        ref="TreeSelect" :maxTagCount="3" >-->
            <!--            </TreeSelect>-->
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="模块选择"
            prop="modularVal"
            :rules="modularValRules"
          >
            <el-select
              :fit-input-width="true"
              v-model="form.modularVal"
              placeholder="请选择 模块"
            >
              <el-option label="日常模块" :value="1"></el-option>
              <el-option label="运维模块" :value="2"></el-option>
              <el-option label="管理模块" :value="3"></el-option>
            </el-select>
          </el-form-item>

        </el-col>

        <el-col :span="12">
          <el-form-item
            label="备注"
            prop="remark"
          >
            <el-input
              v-model="form.remark"
              autosize
              type="textarea"
              placeholder="请输入 备注"
            />
          </el-form-item>
        </el-col>
      </el-row>

    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button type="primary" size="small" @click="handlesubmit('dialogForm')"
        >提交
        </el-button>
      </div>
    </template>
  </el-dialog>
  <el-dialog
    width="30%"
    v-model="avatorVisible"
    title="上传头像"
    :close-on-click-modal="false"
  >
    <el-upload
      :headers="headers"
      :data="ayatorData"
      class="avatar-uploader"
      action="/api/user/upimg"
      :show-file-list="false"
      :on-success="handleAvatarSuccess"
      :before-upload="beforeAvatarUpload"
    >
      <el-icon class="avatar-uploader-icon">
        <plus />
      </el-icon>
    </el-upload>
  </el-dialog>

  <el-dialog
      width="30%"
      v-model="showAvator"
      title="查看头像"
      :close-on-click-modal="false"
  >
    <div style="display: flex;justify-content: center">
      <img :src="imageUrl" class="avatar" />
    </div>
  </el-dialog>

  <el-dialog
    width="60%"
    v-model="cameraVisible"
    title="绑定摄像头"
    :close-on-click-modal="false"
    :before-close="handleClose"
  >
    <el-form
      label-position="right"
      :model="cameraForm"
      size="small"
      :label-width="formLabelWidth"
      ref="carmeradialogForm"
    >
      <el-form-item
        label="终端选择"
        prop="macResource"
      >
        <selectTree ref="tree" @sclear="sclear" :treeData="terminalList" nodeKey="code" @selectTreeChange="terminalChange" :treeProps="treeProps" :propsId="propsIdx"></selectTree>

      </el-form-item>
      <el-form-item
        label="选择摄像头"
        prop="jsonCamera"
      >
        <el-transfer
          v-model="cameraForm.jsonCamera"
          style="text-align: left; display: inline-block"
          filterable
          :props="option"
          :titles="['未绑定', '已绑定']"
          :button-texts="['取消', '选中']"
          :format="{
          noChecked: '${total}',
          hasChecked: '${checked}/${total}',
        }"
          :data="cameraList"
        >
          <template #left-footer>
            <p class="transfer-footer" size="small">操作</p>
          </template>
          <template #right-footer>
            <p class="transfer-footer" size="small">操作</p>
          </template>
        </el-transfer>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button
          type="primary"
          size="small"
          @click="submitCamera('carmeradialogForm')"
        >提交
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import {nextTick, ref, watch} from "vue";
import selectTree from "@/components/selectTree";
import {
  getUser,
  addUser,
  editUser,
  getDetail,
  delUser,
  initPwd,
  bindCamera,
  getCamera,
  unBind,
  orEnabled,
  getTerminalList
} from "@/api/system/user";
import { getrolesList } from "@/api/system/roles";
import { getRegionById } from "@/api/system/region";
import { ElMessage } from "element-plus";
import { encrypton } from "@/util/rsa";
import { getToken, getverifyToken } from "@/util/auth";

export default {
  name: "user",
  setup() {
    let dialogForm = ref(null);
    let propsIdx = ref(0);
    let tree = ref(null);
    const terminalList = ref([]);
    const headers = ref({
      accessToken: getToken() || "",
      verifyToken: getverifyToken() || "",
      timestamp: new Date().getTime(),
      sign: "0000000000000"
    });
    const regionIdStrs = ref([]);
    const selectTree = ref();
    const carmeradialogForm = ref(null);
    // const terminalId = ref("");
    const ayatorData = ref({});
    const option = ref({
      key: "value",
      label: "title"
    });
    const cameraForm = ref({
      jsonCamera: []
    });
    const pwdShow = ref(true);
    const regionIdShow = ref(false);
    const avatorVisible = ref(false);
    const cameraVisible = ref(false);
    const cameraList = ref([]);
    let showAvator = ref(false);

    function clearRegion() {
      selectTree.value.selectClear();
    }

    function terminalChange(id) {
      cameraForm.value.macResource = id;
    }

    function sclear(){
      cameraForm.value.macResource = "";
    }

    /** @method 绑定摄像头* */
    function handleBind(id) {
      cameraForm.value.userId = id;
      cameraVisible.value = true;
      //获取终端
      getTerminalList(id).then(res => {
        if (res.data.success) {
          terminalList.value = res.data.data.data;
          nextTick(()=>{
            cameraForm.value.macResource = res.data.data.initmacResource;
            propsIdx.value = res.data.data.initmacResource;
          })
        }
      });
      //获取摄像头
      getCamera(id).then(res => {
        if (res.data.success) {
          let allData = res.data.data.data;
          let mydata = res.data.data.mydata;
          cameraList.value = allData;
          if (mydata.length) {
            mydata.forEach((val, index) => {
              cameraForm.value.jsonCamera.push(val);
            });
          }
        }
      });
    }

    /** @method 绑定摄像头提交* */
    function submitCamera() {
      // console.log(this.state.cameraForm.jsonCamera)
      carmeradialogForm.value.validate((valid) => {
        if (valid) {
          let { jsonCamera, macResource, userId } = cameraForm.value;
          console.log(macResource)
          if(jsonCamera == '') jsonCamera = [];
          if(jsonCamera.length > 0){
            jsonCamera = jsonCamera.join(",");
          }else {
            jsonCamera = ","
          }
          bindCamera({ jsonCamera, macResource, userId }).then(res => {
            if (res.data.success) {
              cameraVisible.value = false;
              ElMessage({
                type: "success",
                message: "操作成功！"
              });
              tree.value.selectClear();
              cameraForm.value.jsonCamera = [];
            }
          });
        }
      });
    }

    function handleClose(done){
      tree.value.selectClear();
      cameraForm.value.jsonCamera = [];
      done();
    }

    function handleClose2(done){
      nextTick(()=>{
        dialogForm.value.resetFields();
        state.form = {}
      })
      done()
    }

    return {
      handleClose2,
      tree,
      handleClose,
      dialogForm,
      showAvator,
      headers,
      carmeradialogForm,
      submitCamera,
      // terminalId,
      terminalChange,
      sclear,
      terminalList,
      option,
      propsIdx,
      cameraForm,
      regionIdStrs,
      regionIdShow,
      pwdShow,
      avatorVisible,
      ayatorData,
      selectTree,
      cameraVisible,
      clearRegion,
      cameraList,
      handleBind
    };
  },
  components: { selectTree },
  data() {
    const validaterealName = (rule, value, callback) => {
      console.log(value);
      if (!/^[\u4e00-\u9fa5]{0,}$/.test(value)) {
        callback(new Error("用户姓名必须为中文"));
      } else {
        callback();
      }
    };
    return {
      treeProps: {
        children: "children",
        label: "name",
        key: "code"
      },
      idx: "",
      imageUrl: "",
      realNameRules: [{ required: true, message: "用户姓名 不能为空", trigger: "blur" }, {
        validator: validaterealName,
        trigger: "blur"
      }],
      modularValRules: [],
      TreeSelectProps: {
        label: "name",
        value: "id",
        key: "id",
        children: "children"
      },
      childRegion: [],
      propsId: 0,
      searchpropsId: 0,
      pwdLabel: "用户密码",
      rolesList: [],
      regionList: [],
      formLabelWidth: "140px",
      dialogFormVisible: false,
      title: "新增",
      form: {
        realName: ""
      },
      searchLabelWidth: "100px",
      search: {},
      page: {
        current: 1,
        limit: 10,
        total: 0
      },
      tableData: [],
      attrList: [
        {
          label: "用户账号",
          prop: "userNo",
          render: (row) => (<div>{row.userNo}</div>)
        }, {
          label: "用户姓名",
          prop: "realName",
          render: (row) => (<div>{row.realName}</div>)
        }, {
          label: "系统角色",
          prop: "state",
          render: (row) => (<div>{row.sysRoleVO.state == 1 ? "平台" : "app"}</div>)
        }, {
          label: "角色名称",
          prop: "roleName",
          render: (row) => (<div>{row.sysRoleVO.roleName}</div>)
        }, {
          label: "用户状态",
          prop: "state",
          render: (row) => {
            return (<el-switch
              v-model={row.state}
              size="small"
              active-value={1}
              inactive-value={2}
              active-text="启用"
              inactive-text="禁用"
              onClick={() => this.updateEnabled(row.id, row.state)}
            />);
          }
        }, {
          width: 550,
          prop: "",
          label: "操作",
          render: (row) => {
            if (row.sysRoleVO.state == 2) return <div>
              <el-button type="text" icon="Edit" onClick={() => this.handleEdit(row)}>编辑</el-button>
              <el-button type="text" icon="Delete" onClick={() => this.handleDelete(row)}>删除</el-button>
              <el-button type="text" icon="Refresh" onClick={() => this.handleInitPwd(row)}>初始化密码</el-button>
              <el-button type="text" icon="User" onClick={() => this.handleAvator(row)}>更换头像</el-button>
              <el-button type="text" icon="Picture" onClick={() => this.onAvator(row)}>查看头像</el-button>
              <el-button type="text" icon="VideoCamera" style="color:#E8BD3B" onClick={() => this.handleBind(row.id)}>绑定摄像头</el-button>
              <el-button type="text" icon="Minus" style="color:#E8BD3B" onClick={() => this.handleUnBind(row.id)} active-color="#00ffc6">解绑</el-button>
            </div>;
            else return <div>
              <el-button type="text" icon="Edit" onClick={() => this.handleEdit(row)}>编辑</el-button>
              <el-button type="text" icon="Delete" onClick={() => this.handleDelete(row)}>删除</el-button>
              <el-button type="text" icon="Refresh" onClick={() => this.handleInitPwd(row)}>初始化密码</el-button>
              <el-button type="text" icon="User" onClick={() => this.handleAvator(row)}>更换头像</el-button>
              <el-button type="text" icon="Picture" onClick={() => this.onAvator(row)}>查看头像</el-button>
              <el-button type="text" icon="VideoCamera" style="color:#E8BD3B" onClick={() => this.handleBind(row.id)}>绑定摄像头</el-button>
            </div>;


          }
        }
      ]
    };
  },
  created() {
    this.getData(this.page);
    getrolesList().then(res => {
      if (res.data.success) {
        this.rolesList = res.data.data;
      }
    });
    getRegionById().then(res => {
      if (res.data.success) {
        this.regionList = res.data.data;
      }
    });
  },
  watch: {
    "form.roleId"(value) {
      if (value) {
        let obj = this.rolesList.find(item => item.id == value);
        console.log(obj);
        if (obj.state == 2) {
          this.pwdShow = true;
          this.pwdLabel = "app管理员密码";
          this.modularValRules = [{ required: true, message: "模块选择 不能为空" }];
        } else if (obj.state == 1) {
          this.pwdLabel = "用户密码";
          this.modularValRules = [];
        }
      }

    },
    "form.regionId"(val, oldval) {
      if (oldval) {
        this.regionIdStrs = [];
      }
      this.childRegion = [];
      if (val) {
        getRegionById({ id: val }).then(res => {
          if (res.data.success) {
            console.log(res);
            this.childRegion = res.data.data[0].children || [];
            this.regionIdShow = true;
          }
        });
      }

    }
  },
  methods: {


    onAvator(row) {
      this.imageUrl = row.headPicPath;
      this.showAvator = true;
    },
    /** @method 更换头像* */
    handleAvator(row) {
      this.ayatorData = { id: row.id };
      this.avatorVisible = true;
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.avatorVisible = false;
      this.getData(this.page);
    },
    handleInitPwd(row) {
      initPwd({ id: row.id, userNo: row.userNo }).then(res => {
        if (res.data.success) {
          ElMessage({
            type: "success",
            message: "操作成功"
          });
        }
      });
    },
    updateEnabled(id, state) {
      state = state == 1 ? 2 : 1;
      orEnabled(id, state).then(res => {
        if (res.data.success) {
          this.$message.success("操作成功！");
          this.getData(this.page);
        }
      });
    },
    searchForm() {
      this.page.current = 1;
      this.getData(this.page);
    },
    resetForm(formName) {
      this.clearRegion();
      this.$refs[formName].resetFields();
      this.page.current = 1;
      this.getData(this.page);
    },
    selectTreeChange(value) {
      this.form.regionId = value;
    },
    searchselectTree(value) {
      this.search.regionId = value;
    },
    getData(page) {
      getUser(page.current, page.limit, this.search).then(res => {
        if (res.data.success) {
          this.tableData = res.data.data.list;
          this.page.total = res.data.data.count;
        }
      });
    },
    handlesubmit(formName) {
      console.log(this.$refs.tree);
      this.form.regionIdStrs = this.$refs.tree.getCheckedKeys().join(",");
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // console.log(this.form);
          let {
            id,
            expiryDate,
            password,
            power,
            realName,
            regionId,
            roleId,
            sex,
            state,
            telPhone,
            userNo,
            modularVal,
            regionIdStrs,
            remark
          } = this.form;

          if (this.title == "新增") {
            password = encrypton(password);
            addUser({
              expiryDate,
              password,
              power,
              realName,
              regionId,
              roleId,
              sex,
              state,
              telPhone,
              userNo,
              modularVal,
              regionIdStrs,
              remark
            }).then(res => {
              if (res.data.success) {
                this.getData(this.page);
                this.$refs[formName].resetFields();
                ElMessage({
                  message: "操作成功",
                  type: "success"
                });
                this.dialogFormVisible = false;
              } else {
                ElMessage({
                  message: res.data.code.message,
                  type: "error"
                });
              }
            });
          } else {
            let obj = this.rolesList.find(item => item.id == roleId);
            if (obj.state == 2) {
              password = encrypton(password);
            }
            editUser({
              id,
              expiryDate,
              password,
              power,
              realName,
              regionId,
              roleId,
              sex,
              state,
              telPhone,
              userNo,
              modularVal,
              regionIdStrs,
              remark
            }).then(res => {
              if (res.data.success) {
                this.getData(this.page);
                this.$refs[formName].resetFields();
                ElMessage({
                  message: "操作成功",
                  type: "success"
                });
                this.dialogFormVisible = false;
              } else {
                ElMessage({
                  message: res.data.code.message,
                  type: "error"
                });
              }
            });
          }

        } else {
          return false;
        }
      });
    },
    handleAdd() {
      this.dialogFormVisible = true;
      this.title = "新增";
      this.pwdShow = true;
    },
    handleEdit(row) {
      this.pwdShow = false;
      this.dialogFormVisible = true;
      this.title = "编辑";
      getDetail(row.id).then(res => {
        if (res.data.success) {
          this.form = res.data.data;
          this.propsId = this.form.regionId;
          let regionIdStrs = this.form.regionIdStrs.split(",");
          this.regionIdStrs = regionIdStrs;

          // this.$refs.tree.setCheckedKeys(regionIdStrs);
        }
      });

    },

    handleDelete(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          return delUser(row.id);
        }).then((res) => {
        this.getData(this.page);
        this.$message({
          message: "操作成功",
          type: "success"
        });
      });
    }, handleUnBind(id) {
      this.$confirm("确定解绑?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          return unBind(id);
        }).then((res) => {
        if (res.data.success) {
          this.getData(this.page);
          this.$message({
            message: "操作成功",
            type: "success"
          });
        }
      });
    },
    handleCurrentChange(currentPage) {
      this.page.current = currentPage;
      this.getData(this.page);

    },
    handleSizeChange(pageSize) {
      this.page.limit = pageSize;
      this.getData(this.page);
    }

  }
};
</script>

<style scoped lang="scss">
.main {
  ::-webkit-scrollbar {
    width: 0;
  }
}

.el-tree {
  height: 312px !important;
  overflow-y: scroll;
}

.avatar-uploader .el-upload {
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.el-icon.avatar-uploader-icon {
  border: 1px dashed #126B51 !important;
}

.avatar-uploader .el-upload:hover {
  border-color: #1C9C77 !important;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}

//.avatar-uploader-icon svg {
//  margin-top: 74px; /* (178px - 28px) / 2 - 1px */
//}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

::v-deep .el-transfer-panel {
  border-color: #148F75 !important;

  .el-transfer-panel__header {
    background: none !important;
    border-color: #148F75 !important;
  }

  .transfer-footer {
    padding: 0px 11px
  }
}

</style>