export default [
  "Aim",
  "AddLocation",
  "Apple",
  "AlarmClock",
  "ArrowDown",
  "ArrowDownBold",
  "ArrowLeftBold",
  "ArrowRightBold",
  "ArrowUp",
  "Back",
  "Bell",
  "Baseball",
  "BellFilled",
  "Basketball",
  "Bottom",
  "Box",
  "Briefcase",
  "BrushFilled",
  "Avatar",
  "Brush",
  "Burger",
  "Camera",
  "BottomLeft",
  "Calendar",
  "CaretLeft",
  "CaretRight",
  "CaretTop",
  "ChatDotSquare",
  "Cellphone",
  "ChatDotRound",
  "ChatLineRound",
  "ChatRound",
  "Check",
  "ChatSquare",
  "Cherry",
  "Chicken",
  "CircleCheck",
  "Checked",
  "CircleCloseFilled",
  "ArrowRight",
  "CirclePlus",
  "CloseBold",
  "Close",
  "Cloudy",
  "CirclePlusFilled",
  "CoffeeCup",
  "ColdDrink",
  "ArrowUpBold",
  "CollectionTag",
  "BottomRight",
  "Coffee",
  "CameraFilled",
  "Crop",
  "Coordinate",
  "DArrowLeft",
  "Compass",
  "Connection",
  "CreditCard",
  "DArrowRight",
  "Dessert",
  "DeleteLocation",
  "DCaret",
  "Delete",
  "Dish",
  "DocumentCopy",
  "Discount",
  "DocumentChecked",
  "DocumentAdd",
  "DocumentRemove",
  "DataAnalysis",
  "Download",
  "Drizzling",
  "Eleme",
  "ElemeFilled",
  "Edit",
  "Document",
  "Film",
  "Finished",
  "DataLine",
  "Filter",
  "FolderChecked",
  "FirstAidKit",
  "FolderAdd",
  "FolderDelete",
  "DocumentDelete",
  "FolderOpened",
  "FolderRemove",
  "Comment",
  "HelpFilled",
  "Histogram",
  "HomeFilled",
  "InfoFilled",
];
