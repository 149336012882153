import axios from "@/api/axios"; // 引用axios
//获取媒体资源审核列表
export const getList = (page, limit, params) => {
  const param = {
    ...params,
    page,
    limit,
  };
  return axios({
    url: "/api/local/mediaCheck/listData",
    method: "post",
    params: param,
  });
};
//媒体资源审核通过
export const handlePass = (id) => {
  return axios({
    url: "/api/local/mediaCheck/pass",
    method: "post",
    params: { id },
  });
}; //媒体资源审核不通过
export const handleReject = (params) => {
  return axios({
    url: "/api/local/mediaCheck/noPassEdit",
    method: "post",
    params: params,
  });
};
