<template>
  <div class="searchBox">
    <el-form
      :model="search"
      :label-width="searchLabelWidth"
      ref="searchForm"
    >
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item
            label="型号名称"
            prop="name"
          >
            <el-input v-model="search.name" autocomplete="off" placeholder="请输入 型号名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            label="资源编码"
            prop="code"
          >
            <el-input v-model="search.code" autocomplete="off" placeholder="请输入 资源编码"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            label="MAC"
            prop="macAddr"
          >
            <el-input v-model="search.macAddr" autocomplete="off" placeholder="请输入 MAC"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            label="工作状态"
            prop="workStatus"
          >
            <el-select v-model="search.workStatus" placeholder="请选择 工作状态" clearable>
              <el-option label="空闲" value=1></el-option>
              <el-option label="离线" value=0></el-option>
              <el-option label="工作" value=2></el-option>
              <el-option label="未知" value=9></el-option>
              <el-option label="故障" value=3></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            label="区域"
            prop="regionId"
          >
            <selectTree :treeData="regiontree" @selectTreeChange="searchselectTree" ref="selectTree"
            ></selectTree>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            label="设备类型"
            prop="type"
          >
            <el-select v-model="search.type" placeholder="请选择 设备类型" clearable>
              <el-option
                v-for="item in equipType"
                :key="item.id"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <!--              <el-form-item>-->
          <el-button plain @click="searchForm('searchForm')" class="searchBtn">查询</el-button>
          <el-button plain @click="resetForm('searchForm')" class="resetBtn">清空</el-button>


          <!--              </el-form-item>-->
        </el-col>
      </el-row>
    </el-form>
    <div class="divide">
      <img src="../../assets/divide.png" style="width: 100%">
    </div>
    <div class="btnContainer">
      <div class="btnLeft">
        <el-button icon="Plus" @click="dialogFormVisible = true;title ='新增'" class="searchBtn">新增</el-button>
        <!--        <el-button class="searchBtn" @click="handleUpdate">证书更新</el-button>-->
        <el-button class="searchBtn" @click="handledownload">下载模板</el-button>
        <el-button class="searchBtn" @click="importVisible=true;type=1">设备导入</el-button>
        <el-button class="searchBtn" @click="importVisible=true;type=2">离线证书导入</el-button>
        <download-excel :data="tableData" :fields="json_fields" style="width: 130px" :name="getName">
          <el-button style="display: block;margin-left: 10px" class="searchBtn">导出excel</el-button>
        </download-excel>
      </div>

    </div>
  </div>



  <basicTable
    :indexshow="false"
    :attrList="attrList"
    :tableData="tableData"
    ref="table"
    :page="page.current"
    :limit="page.limit"
    :total="page.total"
    @currentChange="handleCurrentChange"
    @sizeChange="handleSizeChange"
    @selectChange="handleSelectChange">
  </basicTable>
  <el-dialog
    width="60%"
    v-model="dialogFormVisible"
    :title="title"
    :close-on-click-modal="false"
    :before-close="handleClose2"
  >
    <el-form
      label-position="right"
      :model="form"
      size="small"
      :label-width="formLabelWidth"
      ref="dialogForm"
    >
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            label="设备名称"
            :rules="[{ required: true, message: '设备名称 不能为空' }]"
            prop="name"
          >
            <el-input v-model="form.name" autocomplete="off" clearable placeholder="请输入 设备名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="所属区域"
            :rules="[{ required: true, message: '所属区域 不能为空' }]"
            prop="regionId"
          >
            <selectTree v-if="dialogFormVisible" :treeData="regiontree" @selectTreeChange="selectTreeChange" @sclear="sclear" :propsId="propsId"></selectTree>
            <!--                        <TreeSelect placeholder="请选择 所属区域"  :tree-data="treeData" multiple  allowClear v-model="form.regionId"  style="width: 100%"  :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" >-->
            <!--                        </TreeSelect>-->
          </el-form-item>

        </el-col>

      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            label="设备型号"
            :rules="[{ required: true, message: '设备型号 不能为空' }]"
            prop="model"
          >
            <el-select v-model="form.model" @change="changeModel" placeholder="请选择 设备型号" clearable>
              <el-option
                v-for="item in equipModel"
                :key="item.id"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="设备MAC编号"
            :rules="[{ required: true, message: '设备MAC编号 不能为空' }]"
            prop="macAddr"
          >
            <el-input
              clearable
              type="tel"
              autocomplete="off"
              v-model="form.macAddr"
              placeholder="请输入 设备MAC编号"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            label="安全模块编号"
            :rules="[{ required: true, message: '安全模块编号为12位字符串，如没有填写12个0' }]"
            prop="securityModuleNo"
          >
            <el-input
              maxlength="12"
              clearable
              autocomplete="off"
              v-model="form.securityModuleNo"
              placeholder="请输入 安全模块编号"
            >
            </el-input>
          </el-form-item>
        </el-col>
<!--        <el-col :span="12">-->
<!--          <el-form-item-->
<!--            label="经度"-->
<!--            :rules="[{ required: true, message: '经度 不能为空' }]"-->
<!--            prop="longitude"-->
<!--          >-->
<!--            <el-input-->
<!--              clearable-->
<!--              autocomplete="off"-->
<!--              v-model="form.longitude"-->
<!--              placeholder="请输入 经度"-->
<!--            >-->
<!--            </el-input>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="经纬度" :rules="[{ required: true, message: '经纬度格式不正确' }]" prop="latitude">
<!--            <el-input clearable autocomplete="off" v-model="form.latitude" placeholder="请输入 纬度"></el-input>-->
            <Getnpoint ref="point" @SET_POINT="setpoint" @INPUT_POINT="inputpoint" @CHANGE_POINT="changepoint"/>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="启用状态"
            :rules="[{ required: true, message: '启用状态 不能为空' }]"
            prop="isForbidden"
          >
            <el-radio-group v-model="form.isForbidden">
              <el-radio :label="1">禁用</el-radio>
              <el-radio :label="0">启用</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            label="是否具有回传功能"
            :rules="[{ required: true, message: '是否具有回传功能 不能为空' }]"
            prop="rebackFlag"
          >
            <el-radio-group v-model="form.rebackFlag">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="通信方式"
            :rules="[{ required: true, message: '通信方式 不能为空' }]"
            prop="communicationMode"
          >
            <el-select v-model="form.communicationMode" placeholder="请选择 通信方式" clearable>
              <el-option
                v-for="item in communicateList"
                :key="item.id"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            class="sourceCode"
            label="资源编码"
            prop="code"
            :rules="[{ required: true, message: '资源编码为24位',min:24,max:24, trigger: 'blur' }]"
          >
            <el-row :gutter="20">
              <el-col :span="16">
                <el-input
                  clearable
                  type="tel"
                  autocomplete="off"
                  v-model="form.code"
                  placeholder="请输入 资源编码"
                >
                </el-input>
              </el-col>
              <el-col :span="8">
                <el-button size="mini" class="codeBtn" @click="getCode">点击获取</el-button>
              </el-col>
            </el-row>


          </el-form-item>

        </el-col>
<!--        <el-col :span="12">-->
<!--          <el-form-item-->
<!--            label="分管机构"-->
<!--            prop="orgId"-->
<!--          >-->
<!--            <el-select v-model="form.orgId" placeholder="请选择 分管机构" clearable>-->
<!--              <el-option-->
<!--                v-for="item in communicateList"-->
<!--                :key="item.id"-->
<!--                :label="item.name"-->
<!--                :value="item.code"-->
<!--              >-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            label="设备编号"
            prop="resourceNo"
          >
            <el-input
              clearable
              autocomplete="off"
              v-model="form.resourceNo"
              placeholder="请输入 设备编号"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="负责人"
            prop="charger"
          >
            <el-input
              clearable
              autocomplete="off"
              v-model="form.charger"
              placeholder="请输入 负责人"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            label="负责人电话"
            prop="chargerPhone"
          >
            <el-input
              clearable
              autocomplete="off"
              v-model="form.chargerPhone"
              placeholder="请输入 负责人电话"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="安装地址"
            prop="installAddr"
          >
            <el-input
              clearable
              autocomplete="off"
              v-model="form.installAddr"
              placeholder="请输入 安装地址"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            label="安装时间"
            prop="installTime"
          >
            <el-date-picker
              value-format="YYYY-MM-DD HH:mm:ss"
              v-model="form.installTime"
              type="datetime"
              placeholder="请选择 安装时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="高度"
            prop="altitude"
          >
            <el-input
              clearable
              autocomplete="off"
              v-model="form.altitude"
              placeholder="请输入 高度"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button type="primary" size="small" @click="handlesubmit('dialogForm')"
        >提交
        </el-button>
      </div>
    </template>
  </el-dialog>
  <el-dialog
    width="60%"
    v-model="importVisible"
    title="导入数据"
    :close-on-click-modal="false"
    :before-close="handleClose"
  >
    <el-upload
      drag
      class="upload-demo"
      action="https://jsonplaceholder.typicode.com/posts/"
      :limit="1"
      :on-change="fileChange"
      :file-list="fileList"
      :auto-upload="false"
    >
      <el-icon class="el-icon--upload">
        <upload-filled />
      </el-icon>
      <div class="el-upload__text">
        拖拽文件 or <em>点击上传</em>
      </div>

      <template #tip>
        <div class="el-upload__tip">
          只能使用下载模板进行导入
        </div>
      </template>
    </el-upload>
    <template #footer>
      <div class="dialog-footer">
        <el-button type="primary" size="small" @click="importData">确定上传</el-button>
        <el-button @click="importVisible = false" size="small"
        >取消
        </el-button
        >
      </div>
    </template>
  </el-dialog>
</template>

<script>
import {
  getList,
  addData,
  editData,
  delData,
  getDetail,
  getresourceCode,
  getTemplete,
  ertificateUpdate,
  importFile,
  importFile2
} from "@/api/basic/equipmanage";
import { getEquipType } from "@/api/basic/equipType";
import { getcommunicateList } from "@/api/dataDictionary";
import { getEquipModel } from "@/api/basic/deviceModel";
import { getRegionById } from "@/api/system/region";
import { ElMessage } from "element-plus";
import selectTree from "@/components/selectTree";
import { ref } from "vue";
import Getnpoint from '@/components/Getnpoint'
export default {
  name: "equipmanage",
  components: {
    selectTree,
    Getnpoint,
  },
  setup() {
    let selectTree = ref();

    function clearRegion() {
      selectTree.value.selectClear();
    }

    return { selectTree, clearRegion };
  },

  data() {
    return {
      json_fields:{
        "设备名称":"name",
        "资源编码": "code",
        "设备类型": "typeName",
        "厂商id":"manufacturerId",
        "设备型号": "model",
        "设备编号":"resourceNo",
        "物理地址": {
          field: "macAddr",
          callback: (value) => {
            function toThousands(num) {
              var result = '', counter = 0;
              num = (num || 0).toString();
              for (var i = num.length - 1; i >= 0; i--) {
                counter++;
                result = num.charAt(i) + result;
                if (!(counter % 2) && i != 0) {
                  result = ':' + result;
                }
              }
              return result;
            }
            return toThousands(value)
          }
        },
        "版本信息": "softVersion",
        "安全模块编号":"securityModuleNo",
        "证书编号":"certSn",
        "通信方式":"communicationModeName",
        "是否具有回传功能": {
          field: "rebackFlag",
          callback:(value)=>{
            return value==1 ? "是" : "否"
          }
        },
        "责任人":"charger",
        "负责人电话":"chargerPhone",
        "软件版本":"softVersion",
        "硬件版本":"hardVersion",
        "ip地址":"ipAddr",
        // "所属区域id":"regionId",
        "所属区域名称":"regionName",
        "安装地址":"installAddr",
        "安装时间":"installTime",
        "经度":"longitude",
        "纬度":"latitude",
        "高度":"altitude",
        "工作状态":{
          field: "workStatus",
          callback:(value)=>{
            var op = new Map([
              ["1", '空闲'],
              ["2", '工作'],
              ["0", '离线'],
              ["9", '未知'],
              ["3", '故障'],
              ["4", '故障恢复'],
            ])
            return op.get(value)
          }
        },
        "上次在线时间":"lastOnlineTime",
        "启用状态":{
          field: "isForbidden",
          callback:(value)=>{
            return value==1 ? "禁用" : "启用"
          }
        }
      },
      type:0,
      fileList:[],
      file: null,
      importVisible: false,
      selectionList: [],
      propsId: "",
      regiontree: [],
      equipType: [],
      equipModel: [],
      communicateList: [],
      formLabelWidth: "170px",
      dialogFormVisible: false,
      title: "新增",
      form: {},
      searchLabelWidth: "100px",
      search: {},
      page: {
        current: 1,
        limit: 10,
        total: 0
      },
      tableData: [],
      attrList: [
        {
          label: "设备名称",
          prop: "name",
          render: (row) => (<div>{row.name}</div>)
        }, {
          label: "资源编码",
          prop: "code",
          render: (row) => (<div>{row.code}</div>)
        }, {
          label: "设备型号",
          prop: "model",
          render: (row) => (<div>{row.model}</div>)
        }, {
          label: "设备MAC编号",
          prop: "macAddr",
          render: (row) => (<div>{row.macAddr}</div>)
        }, {
          label: "版本信息",
          prop: "softVersion",
          render: (row) => (<div>{row.softVersion}{row.hardVersion}</div>)
        }, {
          width: "180",
          label: "是否具有回传功能",
          prop: "rebackFlag",
          render: (row) => {
            if (row.rebackFlag) return <div>是</div>;
            else return <div>否</div>;
          }
        }, {
          label: "所属",
          prop: "regionName",
          render: (row) => <div>{row.regionName}</div>
        }, {
          label: "工作状态",
          prop: "workStatus",
          render: (row) => {
            if (row.workStatus == 0) return <div className="flex"><i className="icon" style={{background:'#767676'}}></i>离线</div>;
            if (row.workStatus == 1) return <div className="flex"><i className="icon" style={{background:'yellow'}}></i>空闲</div>;
            if (row.workStatus == 2) return <div className="flex"><i className="icon" style={{background:'green'}}></i>工作</div>;
            if (row.workStatus == 3) return <div className="flex"><i className="icon" style={{background:'red'}}></i>故障</div>;
            if (row.workStatus == 9) return <div className="flex"><i className="icon" style={{background:'#10433a'}}></i>未知</div>;

          }
        }, {
          label: "启用状态",
          prop: "isForbidden",
          render: (row) => {
            if (row.isForbidden) return <div className="notforbidden">禁用</div>;
            else return <div className="forbidden">启用</div>;
          }
        }, {
          label: "在线时间",
          prop: "lastOnlineTime",
          render: (row) => {
            return <div>{row.lastOnlineTime}</div>;
          }
        }, {
          label: "IP",
          prop: "ipAddr",
          render: (row) => {
            return <div>{row.ipAddr}</div>;
          }
        },
        {
          prop: "",
          label: "操作",
          width: 220,
          render: (row) => {
            return <div>
              <el-button type="text" icon="Edit" onClick={() => this.handleEdit(row)}>编辑</el-button>
              <el-button type="text" icon="Delete" onClick={() => this.handleDelete(row)}>删除</el-button>
              <el-button type="text" icon="Finished" onClick={() => this.handleUpdate(row)}>证书更新</el-button>
            </div>;

          }
        }
      ]
    };
  },
  created() {
    this.getData(this.page);
    getEquipType().then(res => {
      if (res.data.success) {
        this.equipType = res.data.data;
      }
    });
    getcommunicateList().then(res => {
      if (res.data.success) {
        this.communicateList = res.data.data;
      }
    });
    getEquipModel().then(res => {
      if (res.data.success) {
        this.equipModel = res.data.data;
      }
    });
    getRegionById().then(res => {
      if (res.data.success) {
        this.regiontree = res.data.data;
      }
    });
  },
  watch:{

  },
  computed: {
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },

    getName(){
      function addZero(s) {
        return s < 10 ? ('0' + s) : s;
      }
      var myDate = new Date();
      var year = myDate.getFullYear();    //获取完整的年份(4位,1970-????)
      var month = myDate.getMonth() + 1;       //获取当前月份(0-11,0代表1月)
      var date = myDate.getDate();        //获取当前日(1-31)

      return `设备详情信息_${year}${addZero(month)}${addZero(date)}.xls`
    },
  },
  methods: {
    changepoint(){
      this.form.longitude = "";
      this.form.latitude = "";
    },

    inputpoint(val){
      let point = val.split(',');
      this.form.longitude = point[0];
      this.form.latitude = point[1];
    },

    setpoint(val){
      let point = val.split(',');
      this.form.longitude = point[0];
      this.form.latitude = point[1];
    },
    changeModel(){
      this.form.code = "";
    },
    handleClose2(done){
      this.$nextTick(()=>{
        this.$refs['dialogForm'].resetFields();
      })
      this.$refs['point'].point = "";
      this.$refs['point'].points = "";
      this.form = {};
      this.propsId = "";
      done()
    },

    handleClose(done){
      this.fileList = [];
      done()
    },
    fileChange(file, fileList) {
      this.file = file;
    },
    importData() {
      let formData = new FormData();
      formData.append("file", this.file.raw);

      if(this.type == 1){
        importFile(formData).then(res => {
          if (res.data.success) {
            this.$message.success("操作成功！");
            this.importVisible = false;
          }
        });
      }else{
        importFile2(formData).then(res => {
          if (res.data.success) {
            this.$message.success("操作成功！");
            this.importVisible = false;
          }
        });
      }
    },
    //证书更新
    handleUpdate(row) {
      // if (!this.selectionList.length) return this.$message.info("请至少选择一条数据！");
      ertificateUpdate(row.id).then(res => {
        if (res.data.success) this.$message.success("操作成功");
        else this.$message.error(res.data.message);
      });
    },
    handledownload() {
      window.open('/api/resource/equipment/equipmentDownloadTem');
      // getTemplete().then(res => {
      //   if (res.data) {
      //     // 文件下载
      //     const blob = new Blob(['\uFEFF' + res.data], {
      //       type: "application/vnd.ms-excel;charset=utf-8"
      //     });
      //     let link = document.createElement("a");
      //     link.href = URL.createObjectURL(blob);
      //     link.setAttribute("download", "设备导入模板.xls");
      //     link.click();
      //     link = null;
      //   }
      // });
    },
    getCode() {
      getresourceCode({ regionId: this.form.regionId, model: this.form.model }).then(res => {
        if (res.data.success) {
          this.form.code = res.data.data;
        }
      });
    },
    sclear(){
      this.form.code = "";
      this.form.regionId = ""
    },
    selectTreeChange(value) {
      this.form.regionId = value;
      this.form.code = "";
    },
    searchselectTree(value) {
      this.search.regionId = value;
    },
    searchForm() {
      this.page.current = 1;
      this.getData(this.page);
    },
    resetForm(formName) {
      this.clearRegion();
      this.search.regionId = "";
      this.$refs[formName].resetFields();
      this.page.current = 1;
      this.getData(this.page);
    },
    getData(page) {
      getList(page.current, page.limit, this.search).then(res => {
        if (res.data.success) {
          this.page.total = res.data.data.count;
          this.tableData = res.data.data.list;
        }
      });
    },
    handlesubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.title == "新增") {
            addData(this.form).then(res => {
              if (res.data.success) {
                this.getData(this.page);
                this.$refs[formName].resetFields();
                ElMessage({
                  message: "操作成功",
                  type: "success"
                });
                this.dialogFormVisible = false;
              } else {
                ElMessage({
                  message: res.data.code.message,
                  type: "error"
                });
              }
            });
          } else {
            editData(this.form).then(res => {
              if (res.data.success) {
                this.getData(this.page);
                this.$refs[formName].resetFields();
                ElMessage({
                  message: "操作成功",
                  type: "success"
                });
                this.dialogFormVisible = false;
              } else {
                ElMessage({
                  message: res.data.code.message,
                  type: "error"
                });
              }
            });
          }

        } else {
          return false;
        }
      });
    },
    handleEdit(row) {
      getDetail(row.id).then(res => {
        if (res.data.success) {
          this.form = res.data.data;
          this.$refs['point'].points = `${this.form.longitude},${this.form.latitude}`;
          this.propsId = this.form.regionId;
        }
      });
      this.dialogFormVisible = true;
      this.title = "编辑";
    },

    handleDelete(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          return delData(row.id);
        }).then((res) => {
        this.getData(this.page);
        ElMessage({
          message: "操作成功",
          type: "success"
        });
      });
    },
    handleCurrentChange(currentPage) {
      this.page.current = currentPage;
      this.getData(this.page);
    },
    handleSizeChange(pageSize) {
      this.page.limit = pageSize;
      this.getData(this.page);
    },
    //选择数据
    handleSelectChange(value) {
      this.selectionList = value;
    }
  }
};
</script>

<style scoped>
::v-deep .sourceCode .el-input__inner {
  width: 80% !important;
}

.codeBtn {
  width: 100%;
  height: 100%;
  background: #448D7C;
  font-size: 18px;
  font-family: Source Han Sans SC;
  font-weight: 300;
  border: none;
}

/*.codeBtn :hover{*/
/*  color:#00FFC6 !important;*/
/*}*/
.el-button--default {
  --el-button-hover-text-color: #00FFC6 !important;
}

.importBtn {
  margin-left: 10px;
}
>>>.flex{
  display: flex;
  align-items: center;
  justify-content: center;
}

>>> .icon{
  display: block;
  border-radius: 100%;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}

>>> .forbidden{
  padding: 5px 7px;
  border: 1px solid #7aa9a1;
  background: rgba(13, 65, 61, .5);
}

>>> .notforbidden{
  padding: 5px 7px;
  border: 1px solid #6f6f6f;
  background: rgba(111, 111, 111, .5);
}
</style>