<template>
  <div class="search-bar">
    <el-form class="search-form" label-width="120px" ref="searchForm">
      <el-row :gutter="40">
        <slot name="SEARCH"></slot>

        <el-col :span="6" style="display: flex;">
          <el-button @click="searchForm" class="searchBtn">查询</el-button>
          <el-button @click="resetForm" class="resetBtn">重置</el-button>
        </el-col>
      </el-row>
    </el-form>

    <div class="operate">
      <div class="operate-btn">
        <slot name="BTN"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, toRefs  } from "vue";
export default {
  setup(props,{ emit }) {
    let dialogFormVisible = ref(true);
    let state = reactive({
      form:{}
    })

    function resetForm(){
      emit('reset-form')
    }

    function searchForm(){
      emit('emit-search')
    }

    return {
      resetForm,
      searchForm,
      dialogFormVisible,
      ...toRefs(state)
    };
  },
};
</script>

<style lang="less" scoped>
.search-bar{
  width: 1690px;
  background: #0A2B28;
  border: 1px solid #178164;
  margin-bottom: 10px;
  .search-form{
    padding:20px 10px;
  }
  .operate{
    height: 100px;
    margin:0 10px;
    border-top:2px dashed #747474 ;
    .operate-btn{
      display: flex;
      align-items: center;
      height: 100%;
    }
  }
}
</style>
