<template>
  <div>
    <Searchbar @emit-search="onSearch" @reset-form="resetForm">
      <template #SEARCH>
        <el-col :span="6">
          <el-form-item label="版本号">
            <el-input clearable v-model="searchForm.versionNum" placeholder="请输入 版本号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="设备类型">
            <el-select clearable v-model="searchForm.versionType" placeholder="请选择" size="large">
              <el-option v-for="item in typesList" :key="item.code" :label="item.name" :value="item.code">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="设备型号">
            <el-select clearable v-model="searchForm.versionModel" placeholder="请选择" size="large">
              <el-option v-for="item in modelsList" :key="item.code" :label="item.name" :value="item.code">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </template>

      <template #BTN>
        <el-button @click="dialogFormVisible = true" class="searchBtn">新增</el-button>
      </template>
    </Searchbar>

    <basicTable
        :tableData="tableData"
        :attrList="attrList"
        :page="page.current"
        :limit="page.limit"
        @currentChange="handleCurrentChange"
        @sizeChange="handleSizeChange"
        :total="page.total">
    </basicTable>

    <el-dialog
        v-model="dialogFormVisible"
        title="新增"
        :close-on-click-modal="false"
    >
      <el-form label-position="right" :model="form" size="small" label-width="150px" ref="dialogForm">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="版本号(V)" prop="versionNum" :rules="[{ required: true, message: '版本号(V) 不能为空' }]">
              <el-input v-model="form.versionNum" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="可升级设备型号" prop="versionName" :rules="[{ required: true, message: '可升级设备型号 不能为空' }]">
              <el-select v-model="form.versionName" placeholder="请选择" size="large">
                <el-option
                    v-for="item in models"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="MD5值" prop="fileMd5" :rules="[{ required: true, message: 'MD5值 不能为空' }]">
              <el-input v-model="form.fileMd5" autocomplete="off" maxlength="32"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="版本信息描述" prop="remark" :rules="[{ required: true, message: '版本信息描述 不能为空' }]">
              <el-input v-model="form.remark" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="地址" prop="remark" :rules="[{ required: true, message: '地址 不能为空' }]">
              <el-upload
                  accept="application/octet-stream"
                  class="upload-demo"
                  action="/api/deviceVersion/uploadApk"
                  :headers="headers"
                  :on-success="onSuccess"
                  :limit="1"
              >
                <el-button type="primary">上传安装包</el-button>
              </el-upload>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="文件名称" prop="remark">
              <el-input v-model="apkInfo.fileName" autocomplete="off" readonly></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="文件大小" prop="remark">
              <el-input v-model="apkInfo.fileSize" autocomplete="off" readonly></el-input>
            </el-form-item>
          </el-col>

        </el-row>
      </el-form>

      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" size="small" @click="small">提交</el-button>
          <el-button size="small" type="info" @click="dialogFormVisible = false">取消</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { ref, toRefs, reactive, onMounted } from 'vue';
import { ElMessageBox, ElMessage } from 'element-plus';
import { getToken, getverifyToken } from "@/util/auth";
import Searchbar from '@/components/Searchbar';
import { getData, addParam, dataDel, typeAndModels, dataSave } from "@/api/editionupgrade/info";
export default {
  components:{
    Searchbar
  },
  setup(){
    let dialogFormVisible = ref(false);
    let dialogForm = ref(null);
    let state = reactive({
      apkInfo:{},
      headers:{},
      searchForm:{},
      typesList:[],
      modelsList:[],
      form:{},
      page:{
        current: 1,
        limit: 10,
        total: 0,
      },
      models:[],
      attrList:[
        {
          prop: "versionNum",
          label: "版本号",
          render: (row) => <div>{row.versionNum}</div>,
        },
        {
          prop: "versionName",
          label: "版本名",
          render: (row) => <div>{row.versionName}</div>,
        },
        {
          prop: "fileSize",
          label: "包大小",
          render: (row) => <div>{row.fileSize}</div>,
        },
        {
          prop: "fileName",
          label: "包名称",
          render: (row) => <div>{row.fileName}</div>,
        },
        {
          prop: "addressUrl",
          label: "地址",
          render: (row) => <div>{row.addressUrl}</div>,
        },
        {
          prop: "versionType",
          label: "类型",
          render: (row) => <div>{row.versionType}</div>,
        },
        {
          prop: "versionModel",
          label: "型号",
          render: (row) => <div>{row.versionModel}</div>,
        },
        {
          prop: "remark",
          label: "版本信息描述",
          render: (row) => <div>{row.remark}</div>,
        },
        {
          prop: "creatTime",
          label: "上传时间",
          render: (row) => <div>{row.creatTime}</div>,
        },
        {
          prop: "",
          label: "操作",
          render: (row) => <el-button type="text" icon="Delete" onClick={()=>delAjax(row)}>
            删除
          </el-button>,
        },
      ],
      tableData:[]
    })

    function onSearch(){
      onLoad(state.searchForm);
    }

    function delAjax(row){
      ElMessageBox.confirm(
          '确认删除?',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'Warning',
          }
      )
          .then(async () => {
            let res = await dataDel({id:row.id});
            if(res.data.success){
              onLoad(state.searchForm);
            }
          })
          .catch(() => {})
    }
    function onLoad(form = {}){
      getData({ page:state.page.current, limit:state.page.limit, ...form }).then(res=>{
        state.tableData = res.data.data.list;
        state.page.total = res.data.data.count;
      });
    }

    function onSuccess(response, file, fileList){
      state.apkInfo = response.data;
    }
    function resetForm() {
      state.searchForm = {};
      onLoad(state.searchForm);
    }

    function handleCurrentChange(currentPage) {
      state.page.current = currentPage;
      onLoad(state.searchForm);
    }

    function handleSizeChange(pageSize) {
      state.page.limit = pageSize;
      onLoad(state.searchForm);
    }

    function small(){
      dialogForm.value.validate((valid) => {
        if (valid) {
          let { name, code, manufacturerId, resourceType } = state.models.filter(item=>item.code == state.form.versionName)[0];
          let { versionNum, fileMd5, remark } = state.form;
          let { addressUrl, fileName, fileSize } = state.apkInfo;
          dataSave({
            versionName:name,
            manufacturer_id: manufacturerId,
            versionType: resourceType,
            versionModel:code,
            versionNum,
            fileMd5,
            fileName,
            fileSize,
            addressUrl,
            remark
          }).then(res=>{

            if(res.data.success){
              ElMessage({message: res.data.code.message, type: 'success'});
              state.page.current = 1;
              dialogFormVisible.value = false;
              onLoad(state.searchForm)
            }
          })
        }else {
          return false;
        }
      })
    }

    onMounted(()=>{
      state.headers = { sign:'0000000000000', timestamp: new Date().getTime(), verifyToken: getverifyToken() || "", accessToken: getToken() || "" }
      onLoad()
      addParam().then(res=>{state.models = res.data.data.models});
      typeAndModels().then(res=>{
        state.modelsList = res.data.data.models;
        state.typesList = res.data.data.types;
      })
    })
    return{
      handleCurrentChange,
      handleSizeChange,
      dialogForm,
      small,
      resetForm,
      onSuccess,
      onSearch,
      dialogFormVisible,
        ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>

</style>