<template>
  <div class="searchBox">
    <el-form :model="search" :label-width="searchLabelWidth" ref="searchForm">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="型号名称" prop="name">
            <el-input
              v-model="search.name"
              autocomplete="off"
              placeholder="请输入 型号名称"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="厂家" prop="manufacturerId">
            <el-select
              v-model="search.manufacturerId"
              placeholder="请选择 厂家"
              clearable
            >
              <el-option
                v-for="item in vendorList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="设备类型" prop="resourceType">
            <el-select
              v-model="search.resourceType"
              placeholder="请选择 设备类型"
              clearable
            >
              <el-option
                v-for="item in deviceType"
                :key="item.id"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <!--              <el-form-item>-->
          <el-button plain @click="searchForm('searchForm')" class="searchBtn"
            >查询</el-button
          >
          <el-button plain @click="resetForm('searchForm')" class="resetBtn"
            >清空</el-button
          >

          <!--              </el-form-item>-->
        </el-col>
      </el-row>
    </el-form>
    <div class="divide">
      <img src="../../assets/divide.png" style="width: 100%" />
    </div>
    <div class="btnContainer">
      <div class="btnLeft">
        <el-button
          icon="Plus"
          @click="dialogFormVisible = true"
          class="searchBtn"
          >新增</el-button
        >
      </div>
    </div>
  </div>
  <basicTable
    :attrList="attrList"
    :tableData="tableData"
    ref="table"
    :page="page.current"
    :limit="page.limit"
    :total="page.total"
    @currentChange="handleCurrentChange"
    @sizeChange="handleSizeChange"
  >
  </basicTable>
  <el-dialog
    width="60%"
    v-model="dialogFormVisible"
    :title="title"
    :close-on-click-modal="false"
    :before-close="handleClose"
  >
    <el-form
      label-position="right"
      :model="form"
      size="small"
      :label-width="formLabelWidth"
      ref="dialogForm"
    >
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            label="型号名称"
            :rules="[{ required: true, message: '型号名称 不能为空' }]"
            prop="name"
          >
            <el-input
              v-model="form.name"
              autocomplete="off"
              clearable
              placeholder="请输入 型号名称"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="型号编码"
            prop="code"
            :rules="[
              { required: true, message: '型号编码 不能为空', trigger: 'blur' },
            ]"
          >
            <el-input
              clearable
              type="tel"
              autocomplete="off"
              v-model="form.code"
              placeholder="请输入 型号编码"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            label="厂家"
            :rules="[{ required: true, message: '厂家 不能为空' }]"
            prop="manufacturerId"
          >
            <el-select
              v-model="form.manufacturerId"
              placeholder="请选择 厂家"
              clearable
            >
              <el-option
                v-for="item in vendorList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="设备类型"
            :rules="[{ required: true, message: '设备类型 不能为空' }]"
            prop="resourceType"
          >
            <el-select
              v-model="form.resourceType"
              placeholder="请选择 设备类型"
              clearable
            >
              <el-option
                v-for="item in deviceType"
                :key="item.id"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button
          type="primary"
          size="small"
          @click="handlesubmit('dialogForm')"
          >提交
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import {
  getList,
  addData,
  editData,
  delData,
  getDetail,
} from "@/api/basic/deviceModel";
import { getVendor } from "@/api/basic/vendor";
import { getEquipType } from "@/api/basic/equipType";
import { ElMessage } from "element-plus";
import {nextTick} from "vue";

export default {
  name: "deviceModel",
  data() {
    return {
      deviceType: [],
      vendorList: [],
      formLabelWidth: "22%",
      dialogFormVisible: false,
      title: "新增",
      form: {},
      searchLabelWidth: "100px",
      search: {},
      page: {
        current: 1,
        limit: 10,
        total: 0,
      },
      tableData: [],
      attrList: [
        {
          label: "型号名称",
          prop: "name",
          render: (row) => <div>{row.name}</div>,
        },
        {
          label: "型号编码",
          prop: "code",
          render: (row) => <div>{row.code}</div>,
        },
        {
          label: "厂家",
          prop: "manufacturerId",
          render: (row) => <div>{row.manufacturerName}</div>,
        },
        {
          label: "资源类型",
          prop: "resourceType",
          render: (row) => <div>{row.typeName}</div>,
        },
        {
          prop: "",
          label: "操作",
          render: (row) => {
            return (
              <div>
                <el-button
                  type="text"
                  icon="Edit"
                  onClick={() => this.handleEdit(row)}
                >
                  编辑
                </el-button>
                <el-button
                  type="text"
                  icon="Delete"
                  onClick={() => this.handleDelete(row)}
                >
                  删除
                </el-button>
              </div>
            );
          },
        },
      ],
    };
  },
  created() {
    this.getData(this.page);
    getEquipType().then((res) => {
      if (res.data.success) {
        this.deviceType = res.data.data;
      }
    });
    getVendor().then((res) => {
      if (res.data.success) {
        this.vendorList = res.data.data;
      }
    });
  },
  methods: {
    handleClose(done){
      nextTick(()=>{
        this.$refs['dialogForm'].resetFields();
        this.form = {}
      })
      done();
    },
    searchForm() {
      this.page.current = 1;
      this.getData(this.page);
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.page.current = 1;
      this.getData(this.page);
    },
    getData(page) {
      getList(page.current, page.limit, this.search).then((res) => {
        if (res.data.success) {
          console.log(res);
          this.page.total = res.data.data.count;
          this.tableData = res.data.data.list;
        }
      });
    },
    handlesubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.title == "新增") {
            addData(this.form).then((res) => {
              if (res.data.success) {
                this.getData(this.page);
                this.$refs[formName].resetFields();
                ElMessage({
                  message: "操作成功",
                  type: "success",
                });
                this.dialogFormVisible = false;
              } else {
                ElMessage({
                  message: res.data.code.message,
                  type: "error",
                });
              }
            });
          } else {
            editData(this.form).then((res) => {
              if (res.data.success) {
                this.getData(this.page);
                this.$refs[formName].resetFields();
                ElMessage({
                  message: "操作成功",
                  type: "success",
                });
                this.dialogFormVisible = false;
              } else {
                ElMessage({
                  message: res.data.code.message,
                  type: "error",
                });
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    handleEdit(row) {
      getDetail(row.id).then((res) => {
        if (res.data.success) {
          this.form = res.data.data;
          this.dialogFormVisible = true;
          this.title = "编辑";
        }
      });
    },
    handleDelete(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return delData(row.id);
        })
        .then((res) => {
          this.getData(this.page);
          ElMessage({
            message: "操作成功",
            type: "success",
          });
        });
    },
    handleCurrentChange(currentPage) {
      this.page.current = currentPage;
      this.getData(this.page);
    },
    handleSizeChange(pageSize) {
      this.page.limit = pageSize;
      this.getData(this.page);
    },
  },
};
</script>

<style scoped></style>
