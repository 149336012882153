<template>
  <div>
    <Searchbar @emit-search="onSearch" @reset-form="resetForm">
      <template #SEARCH>
        <el-col :span="6">
          <el-form-item label="名称内容">
            <el-input v-model="searchForm.name" placeholder="请输入 名称内容"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="状态">
            <el-select clearable v-model="searchForm.status" placeholder="请选择" size="large">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </template>
      <template #BTN>
        <el-button @click="dialogFormVisible = true; title = '新增'" class="searchBtn">新增</el-button>
      </template>
    </Searchbar>

    <basicTable
        :tableData="tableData"
        :attrList="attrList"
        :page="page.current"
        :limit="page.limit"
        :total="page.total">
    </basicTable>

    <el-dialog
        v-model="dialogFormVisible"
        :title="title"
        :close-on-click-modal="false"
        :before-close="handleClose"
    >
      <el-form
          label-position="right"
          :model="form"
          size="small"
          label-width="120px"
          ref="dataform"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="名称内容" prop="name" :rules="[{ required: true, message: '名称内容 不能为空' }]">
              <el-input clearable v-model="form.name" placeholder="请输入名称内容" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="状态" prop="status" :rules="[{ required: true, message: '请选择 状态' }]">
              <el-select clearable v-model="form.status" placeholder="请选择" size="large">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" size="small" @click="onSave">提交</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {reactive, onMounted, toRefs, ref, nextTick} from 'vue';
import { getData, dataSave, getEdit, getdel } from "@/api/safe/sensitive";
import Searchbar from '@/components/Searchbar';
import { ElMessage } from 'element-plus';
export default {
  components:{
    Searchbar
  },
  setup(){
    let dialogFormVisible = ref(false);
    let idx = ref('');
    let title = ref('');
    let dataform = ref(null);
    let state = reactive({
      options:[
        {
          value: 1,
          label: '启用',
        },
        {
          value: 2,
          label: '禁用',
        }
      ],
      form:{},
      searchForm:{},
      tableData:[],
      attrList:[
        {
          prop: "name",
          label: "名称内容",
          render: (row) => <div>{row.name}</div>,
        },
        {
          prop: "status",
          label: "状态",
          render: (row) => <div>{row.status == 1 ? '启用' : '禁用'}</div>,
        },
        {
          prop: "",
          label: "操作",
          render: (row) => (
              <div>
                <el-button type="text" icon="Edit" onClick={()=>dataEdit(row)}>编辑</el-button>
                <el-button type="text" icon="Delete" onClick={()=>dataDel(row)}>删除</el-button>
              </div>
          ),
        },
      ],
      page: {
        current: 1,
        limit: 10,
        total: 0,
      },
    })
    onMounted(()=>{
      onLoad();
    })
    function onSearch(){
      onLoad(state.searchForm);
    }

    function onLoad(form = {}){
      getData({ limit:state.page.limit, page:state.page.current, ...form }).then(res=>{
        state.tableData = res.data.data.list;
        state.page.total = res.data.data.count;
      })
    }

    function resetForm() {
      state.searchForm = {};
      onLoad(state.searchForm);
    }

    function dataDel(row){
      getdel({
        id:row.id
      }).then(res=>{
        if(res.data.success){
          ElMessage({message: res.data.code.message, type: 'success'});
          state.page.current = 1;
          onLoad();
        }
      })
    }

    function dataEdit(row){
      idx.value = row.id;
      state.form = row;
      dialogFormVisible.value = true;
      title.value = '编辑'
    }

    function onSave(){
      if(title.value == '新增'){
        dataSave({
          name:state.form.name,
          status:state.form.status
        }).then(res=>{
          if(res.data.success){
            ElMessage({message: res.data.code.message, type: 'success'});
            dialogFormVisible.value = false;
            dataform.value.resetFields();
            state.page.current = 1;
            onLoad();
          }
        })
      }else{
        getEdit({
          id:idx.value,
          name:state.form.name,
          status:state.form.status
        }).then(res=>{
          if(res.data.success){
            ElMessage({message: res.data.code.message, type: 'success'});
            dialogFormVisible.value = false;
            dataform.value.resetFields();
            state.page.current = 1;
            onLoad();
          }
        })
      }
    }

    function handleClose(done){
      nextTick(()=>{
        dataform.value.resetFields();
        state.form = {}
      })
      done();
    }
    return{
      resetForm,
      title,
      idx,
      dialogFormVisible,
      dataform,
      onSearch,
      handleClose,
      onSave,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>

</style>