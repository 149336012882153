<template>
  <div>
    <Searchbar @emit-search="onSearch" @reset-form="resetForm">
      <template #SEARCH>
        <el-col :span="6">
          <el-form-item label="摄像头名称">
            <el-input  v-model="searchForm.cName" placeholder="请输入 摄像头名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="摄像头型号">
            <el-input  v-model="searchForm.cModel" placeholder="请输入 摄像头型号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="摄像头类型">
            <el-select clearable v-model="searchForm.cType" placeholder="请选择" size="large">
              <el-option v-for="item in typeList" :key="item.code" :label="item.name" :value="item.code">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="IP地址">
            <el-input v-model="searchForm.ip" placeholder="请输入 IP地址"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="MAC地址">
            <el-input v-model="searchForm.mac" placeholder="请输入 MAC地址"></el-input>
          </el-form-item>
        </el-col>
      </template>
      <template #BTN>
        <el-button class="searchBtn" @click="dialogFormVisible = true;title = '新增'">新增</el-button>
      </template>
    </Searchbar>
    <basicTable
        :tableData="tableData"
        :attrList="attrList"
        :page="page.current"
        :limit="page.limit"
        @currentChange="handleCurrentChange"
        @sizeChange="handleSizeChange"
        :total="page.total">
    </basicTable>

    <!--  新增编辑  -->
    <el-dialog
        v-model="dialogFormVisible"
        :title="title"
        :close-on-click-modal="false"
        :before-close="handleClose"
    >
      <el-form
          label-position="right"
          :model="form"
          size="small"
          label-width="140px"
          ref="dialogForm"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="摄像头名称" prop="cName" :rules="[{ required: true, message: '摄像头名称 不能为空' }]">
              <el-input v-model="form.cName" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="摄像头型号" prop="cModel" :rules="[{ required: true, message: '摄像头型号 不能为空' }]">
              <el-input v-model="form.cModel" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="设备账号" prop="dAccount">
              <el-input v-model="form.dAccount" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="设备密码" prop="dPassword">
              <el-input v-model="form.dPassword" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="安装位置" prop="installAddress">
              <el-input v-model="form.installAddress" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="坐标" prop="location">
<!--              <el-input v-model="form.location" autocomplete="off"></el-input>-->
              <Getnpoint ref="point" @SET_POINT="setpoint" @INPUT_POINT="inputpoint"/>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="NVR" prop="nvr">
              <el-input v-model="form.nvr" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="摄像头类型" prop="cType" :rules="[{ required: true, message: '摄像头类型 不能为空' }]">
              <el-select v-model="form.cType" placeholder="请选择" size="large">
                <el-option
                    v-for="item in typeList"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12" v-if="isremark">
            <el-form-item label="物理码" prop="remark" :rules="[{ required: true, message: '物理码 不能为空' }]">
              <el-input v-model="form.remark" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="启用状态" prop="dStatus" :rules="[{ required: true, message: '启用状态 不能为空' }]">
              <el-switch
                  :active-value="1"
                  :inactive-value="2"
                  v-model="form.dStatus"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  active-text="启用"
                  inactive-text="禁用"
              />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="IP" prop="ip" :rules="[{ required: true, message: 'IP 不能为空' }]">
              <el-input v-model="form.ip" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="MAC" prop="mac">
              <el-input v-model="form.mac" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="监控查看地址" prop="serial" :rules="[{ required: true, message: '监控查看地址 不能为空' }]">
              <el-input v-model="form.serial" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="外部监控地址" prop="serial">
              <el-input v-model="form.serialtwo" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="所属区域" prop="area" :rules="[{ required: true, message: '所属区域 不能为空' }]">
              <selectTree
                  :treeData="treeData"
                  @selectTreeChange="form.area = $event"
                  :propsId="propsId"
              ></selectTree>
            </el-form-item>
          </el-col>

          <el-col :span="12" v-if="title == '新增'">
            <el-form-item label="厂商" prop="company" :rules="[{ required: true, message: '厂商 不能为空' }]">
              <el-select v-model="form.company" placeholder="请选择 厂商" size="large">
                <el-option
                    v-for="item in companyData"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12" v-if="title == '新增'">
            <el-form-item label="是否布防" prop="time_length">
              <el-select v-model="form.isProtect" placeholder="请选择" size="large">
                <el-option
                    v-for="item in protect"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" size="small" @click="ontj">提交</el-button>
          <el-button size="small" type="info" @click="quxiao">取消</el-button>
        </div>
      </template>
    </el-dialog>
    <!--  音源  -->
    <el-dialog
        v-model="isMedia"
        title="报警音源"
        :close-on-click-modal="false"
        :before-close="ypClose"
    >
      <el-form
          label-position="right"
          :model="form"
          size="small"
          label-width="120px"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="摄像头名称" prop="cname" :rules="[{ required: true, message: '摄像头名称 不能为空' }]">
              <el-input readonly v-model="form.cname" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="摄像头型号" prop="cmodel" :rules="[{ required: true, message: '摄像头型号 不能为空' }]">
              <el-input readonly v-model="form.cmodel" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="选择音源" prop="protectMediaId" :rules="[{ required: true, message: '请选择 选择音源' }]">
              <el-select v-model="form.protectMediaId" placeholder="请选择" size="large">
                <el-option
                    v-for="item in mediaList"
                    :key="item.id"
                    :label="item.display_name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" size="small" @click="sendMedia">提交</el-button>
        </div>
      </template>
    </el-dialog>
    <!--  绑定设备  -->
    <el-dialog
        v-model="isresource"
        title="绑定设备"
        :close-on-click-modal="false"
        :before-close="resourceClose"
    >
      <div class="search-bar">
        <el-form class="search-form" :model="ffff" label-width="120px" ref="searchResource">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="设备名称">
                <el-input v-model="ffff.name" placeholder="请输入 设备名称"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="物理码">
                <el-input v-model="ffff.macAddr" placeholder="请输入 物理码"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="选择区域">
                <el-input v-model="ffff.region_id" placeholder="请输入 选择区域"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="类型">
                <el-select clearable v-model="ffff.type" placeholder="请选择" size="large">
                  <el-option v-for="item in resourceList" :key="item.code" :label="item.name" :value="item.code">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="资源编码">
                <el-input v-model="ffff.code" placeholder="请输入 资源编码"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12" style="display: flex;">
              <el-button @click="resourceForm" class="searchBtn">查询</el-button>
              <el-button @click="resetBtn" class="resetBtn">重置</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <basicTable
          :indexshow="false"
          ref="voicetable"
          :tableData="resourceData"
          :attrList="bindList"
          @currentChange="handleCurrentChange2"
          @sizeChange="handleSizeChange2"
          :page="resourcePage.current"
          :limit="resourcePage.limit"
          :total="resourcePage.total"
          @selectChange='selectChange'>
      </basicTable>

      <el-button class="searchBtn btn" @click="choose">添加</el-button>

      <basicTable
          :isPage="false"
          :indexshow="false"
          :tableData="chooseData"
          :attrList="chooseList">
      </basicTable>

      <div class="btnflex"><el-button style="background: #009688" @click="onBind">确定</el-button></div>

    </el-dialog>

    <!--  设备详情  -->
    <el-dialog
        v-model="isxq"
        title="设备详情"
        :close-on-click-modal="false"
    >
      <basicTable
          :indexshow="false"
          ref="voicetable"
          :tableData="xqData"
          :attrList="xqList"
          :page="xqPage.current"
          :limit="xqPage.limit"
          :total="xqPage.total">
      </basicTable>
    </el-dialog>
  </div>
</template>

<script>
import {ref, onMounted, reactive, toRefs, watch, nextTick} from "vue";
import Searchbar from '@/components/Searchbar';
import { ElMessage } from 'element-plus'
import { getData, disOrEnb, dataMedia, mediaData, dataResource, dataBind, dataDel, dataType, dataSave, dataTree, childBind, unBind, dataEdit, companyList } from "@/api/viode/test";
import { getEquipType } from "@/api/basic/equipType";
import selectTree from "@/components/selectTree";
import { getRegionById } from "@/api/system/region";
import Getnpoint from '@/components/Getnpoint';
export default {
  components: {
    Searchbar,
    selectTree,
    Getnpoint
  },
  setup() {
    let point = ref(null);
    let isremark = ref(false);
    let idx = ref('');
    let cameraId = ref('');
    let propsId = ref('');
    let voicetable = ref(null);
    let dialogFormVisible = ref(false);
    let isresource = ref(false);
    let isMedia = ref(false);
    let isxq = ref(false);
    let title = ref('');
    let dialogForm = ref(null);
    let state = reactive({
      ffff:{},
      resourceList:[],
      companyData:[],
      treeData:[],
      xqList:[
        {
          prop: "code",
          label: "设备资源码",
          render: (row) => <div>{row.code}</div>,
        },
        {
          prop: "name",
          label: "设备名称",
          render: (row) => <div>{row.name}</div>,
        },
        {
          prop: "macAddr",
          label: "物理码",
          render: (row) => <div>{row.macAddr}</div>,
        },
        {
          prop: "regionName",
          label: "安装地址",
          render: (row) => <div>{row.regionName}</div>,
        },
        {
          prop: "",
          label: "操作",
          render: (row) => <el-button type="text" icon="Reading" onClick={()=>{ unBinds(row) }}>设备解绑</el-button>
        },
      ],
      xqData:[],
      searchForm:{},
      protect:[
        {
          value: 1,
          label: '是',
        },
        {
          value: 2,
          label: '否',
        },
      ],
      zcChoose:[],
      typeList:[],
      tableData: null,
      resourceData:[],
      chooseData:[],
      mediaList:[],
      form:{
        isProtect:2
      },
      xqPage:{
        current: 1,
        limit: 10,
        total: 0,
      },
      page: {
        current: 1,
        limit: 10,
        total: 0,
      },
      resourcePage: {
        current: 1,
        limit: 10,
        total: 0,
      },
      attrList: [
        {
          prop: "cname",
          label: "摄像头名称",
          render: (row) => <div>{row.cname}</div>,
        },
        {
          prop: "installAddress",
          label: "安装位置",
          render: (row) => <div>{row.installAddress}</div>,
        },
        {
          prop: "cmodel",
          label: "摄像头型号",
          render: (row) => <div>{row.cmodel}</div>,
        },
        {
          prop: "ctype",
          label: "摄像头类型",
          render: (row) => <div>{row.ctype}</div>,
        },
        {
          prop: "title",
          label: "类型名称",
          render: (row) => <div>{row.title}</div>,
        },
        {
          prop: "ip",
          label: "IP",
          render: (row) => <div>{row.ip}</div>,
        },
        {
          prop: "mac",
          label: "MAC",
          render: (row) => <div>{row.mac}</div>,
        },
        {
          prop: "protectStatus",
          label: "设备状态",
          width: 180,
          render: (row) => (
            <el-switch
              onChange={() => handleDelete(row)}
              active-value={row.dstatus == 2}
              inactive-value={row.dstatus == 1}
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-text="已启用"
              inactive-text="已禁用"
            />
          ),
        },

        {
          prop: "company",
          label: "厂商",
          render: (row) => <div>{row.company == 1 ? "海康" : row.company == 2 ? "大华" : "璧虹"}</div>,
        },
        {
          prop: "protectStatus",
          label: "布放状态",
          render: (row) => <div>{row.protectStatus == 1 ? "已布放" : row.protectStatus == 2 ? "布放失败" : "未布放"}</div>,
        },
        {
          prop: "isProtect",
          label: "是否布防",
          render: (row) => <div>{row.isProtect == 1 ? "是" : "否"}</div>,
        },
        {
          prop: "",
          label: "操作",
          width: 400,
          render: (row) => {
            if(row.ctype == 'BHDS2CD2125FDV2IS'){
              return (
                  <div>
                    <el-button type="text" icon="Mic" onClick={()=>yp(row)}>
                      音频
                    </el-button>
                    <el-button type="text" icon="Edit" onClick={()=>edit(row)}>
                      编辑
                    </el-button>
                    <el-button type="text" icon="Delete" onClick={()=>delAjax(row)}>
                      删除
                    </el-button>
                  </div>
              );
            }else{
              return (
                  <div>
                    <el-button type="text" icon="Mic" onClick={()=>yp(row)}>
                      音频
                    </el-button>
                    <el-button type="text" icon="Paperclip" onClick={()=>resourceAjax(row)}>
                      设备绑定
                    </el-button>
                    <el-button type="text" icon="Reading" onClick={()=>bindAjax(row)}>
                      设备详情
                    </el-button>
                    <el-button type="text" icon="Edit" onClick={()=>edit(row)}>
                      编辑
                    </el-button>
                    <el-button type="text" icon="Delete" onClick={()=>delAjax(row)}>
                      删除
                    </el-button>
                  </div>
              );
            }

          },
        },
      ],
      bindList: [
        {
          prop: "id",
          label: "ID",
          render: (row) => <div>{row.id}</div>,
        },
        {
          prop: "macAddr",
          label: "物理码",
          render: (row) => <div>{row.macAddr }</div>,
        },
        {
          prop: "code",
          label: "设备资源码",
          render: (row) => <div>{row.code}</div>,
        },
        {
          prop: "name",
          label: "设备名称",
          render: (row) => <div>{row.name}</div>,
        },
        {
          prop: "installAddr",
          label: "安装位置",
          render: (row) => <div>{row.installAddr}</div>,
        },
        {
          prop: "ipAddr",
          label: "IP",
          render: (row) => <div>{row.ipAddr}</div>,
        },
      ],

      chooseList:[
        {
          prop: "macAddr",
          label: "物理码",
          render: (row) => <div>{row.macAddr}</div>,
        },
        {
          prop: "code",
          label: "设备资源码",
          render: (row) => <div>{row.code}</div>,
        },
        {
          prop: "name",
          label: "设备名称",
          render: (row) => <div>{row.name}</div>,
        },
        {
          prop: "installAddr",
          label: "安装地址",
          render: (row) => <div>{row.installAddr}</div>,
        },
        {
          prop: "ipAddr",
          label: "IP",
          render: (row) => <div>{row.ipAddr}</div>,
        },
        {
          prop: "",
          label: "操作",
          render: (row) => <el-button type="text" icon="Delete" onClick={()=>chooseRemove(row)}>移出</el-button>,
        },
      ]
    });

    watch(()=> state.form.cType, (val)=>{
      if(val == 'BHDS2CD2125FDV2IS'){
        isremark.value = true;
      }else{
        isremark.value = false;
      }
    })

    function selectChange(value){
      state.zcChoose = value;
    }

    async function ontj(){
      if(title.value == '新增'){
        let res = await dataSave(state.form);
        if(res.data.success){
          ElMessage({message: res.data.code.message, type: 'success'});
          state.page.current = 1;
          ajaxData();
          dialogFormVisible.value = false;
        }
      }else{
        let res = await dataEdit({...state.form,id: idx.value});
        if(res.data.success){
          ElMessage({message: res.data.code.message, type: 'success'});
          state.page.current = 1;
          ajaxData();
          dialogFormVisible.value = false;
        }
      }
    }

    function edit(row){
      state.form = row;
      state.form.cName = row.cname
      state.form.dAccount = row.daccount
      state.form.dPassword = row.dpassword
      state.form.cType = row.ctype
      state.form.dStatus = row.dstatus
      state.form.cModel = row.cmodel
      nextTick(()=>{
        point.value.points = state.form.location;
      })
      propsId.value = row.area;
      idx.value = row.id;
      title.value = '编辑';
      dialogFormVisible.value = true;
    }

    function choose(){
      state.zcChoose.forEach(item=>{
        if(state.chooseData.indexOf(item) === -1) state.chooseData.push(item);
      })
      voicetable.value.clearSelect();
    }

    function chooseRemove(row){
      state.chooseData.splice(state.chooseData.findIndex(value=>value.id == row.id),1)
    }

    async function resourceData(id, form = {}){
      let res =  await dataResource({
        cameraId:id,
        ...form,
        page:state.resourcePage.current,
        limit:state.resourcePage.limit
      })
      state.resourceData = res.data.data.list;
      state.resourcePage.total = res.data.data.count;
    }

    async function resourceAjax(row){
      resourceData(row.id)
      cameraId.value = row.id;
      isresource.value = true;
    }

    async function handleDelete(row){
      let res = await disOrEnb({id:row.id, dStatus:row.dstatus})
      ajaxData();
    }

    function ajaxData(form = {}){
      getData({ page:state.page.current, limit: state.page.limit, ...form }).then((res) => {
        state.tableData = res.data.data.list;
        state.page.total = res.data.data.count;
      });
    }

    async function bindAjax(row){
      let res =  await dataBind({
        cameraId:row.id,
        page:state.xqPage.current,
        limit:state.xqPage.limit
      });
      cameraId.value = row.id;
      isxq.value = true;
      state.xqData = res.data.data.list;
      state.xqPage.total = res.data.data.count;
    }

    async function delAjax(row){
      let res = await dataDel({id:row.id});
      if(res.data.success)
      ElMessage({message: res.data.code.message, type: 'success'});
      state.page.current = 1;
      ajaxData();
    }

    function handleCurrentChange(currentPage) {
      state.page.current = currentPage;
      ajaxData(state.searchForm);
    }

    function handleSizeChange(pageSize) {
      state.page.limit = pageSize;
      ajaxData(state.searchForm);
    }


    function handleCurrentChange2(currentPage) {
      state.resourcePage.current = currentPage;
      resourceData(cameraId.value, state.ffff)
    }

    function handleSizeChange2(pageSize) {
      state.resourcePage.limit = pageSize;
      resourceData(cameraId.value, state.ffff)
    }

    function onBind(){
      let codes = state.chooseData.map(item=>item.code);
      childBind({
        cameraId:cameraId.value,
        codes:codes.join(',')
      }).then(res=>{
        if(res.data.success){
          ElMessage({message: res.data.code.message, type: 'success'})
          isresource.value = false;
          state.chooseData = [];
          ajaxData();
        }
      })
    }

    function unBinds(row){
      unBind({id:row.cameraResourceId}).then(res=>{
        if(res.data.success){
          ElMessage({message: res.data.code.message, type: 'success'});
          bindAjax({id:cameraId.value});
        }
      })
    }

    function resourceClose(done){
      state.chooseData = [];
      done();
    }

    function onSearch(){
      ajaxData(state.searchForm);
    }

    function resetBtn(){
      state.resourcePage.page = 1;
      state.resourcePage.limit = 10;
      state.ffff = {};
      resourceData(cameraId.value, state.ffff)
    }

    function resourceForm(){
      resourceData(cameraId.value, state.ffff)
    }

    function selectTreeChange(row){
      console.log(row)
    }

    function handleClose(done){
      nextTick(()=>{
        state.form = {};
        dialogForm.value.resetFields();
      })
      done();
    }

    function quxiao(){
      nextTick(()=>{
        dialogForm.value.resetFields();
        state.form = {};
      })
      dialogFormVisible.value = false;
    }

    function resetForm() {
      state.searchForm = {};
      ajaxData(state.searchForm);
    }

    function ypClose(done){
      state.form = {};
      done()
    }

    function sendMedia(){
      mediaData({
        id:state.form.id,
        protectMediaId:state.form.protectMediaId
      }).then(res=>{
        if(res.data.success){
          ElMessage({message: res.data.code.message, type: 'success'})
          isMedia.value = false;
          state.form = {};
          ajaxData();
        }
      })
    }

    function inputpoint(val){
      state.form.location = val
    }

    function setpoint(val){
      state.form.location = val;
    }

    function yp(row){
      state.form = row;
      isMedia.value = true;
    }

    onMounted(() => {
      ajaxData()
      companyList().then(res=>state.companyData = res.data.data);
      dataMedia().then(res=>state.mediaList = res.data.data);
      dataType().then(res=>state.typeList = res.data.data);
      getEquipType().then(res=> state.resourceList = res.data.data);
      getRegionById().then(res=> state.treeData = res.data.data)
    });

    return {
      isremark,
      dialogFormVisible,
      isresource,
      voicetable,
      isMedia,
      isxq,
      propsId,
      title,
      idx,
      point,
      dialogForm,
      setpoint,
      inputpoint,
      handleCurrentChange2,
      handleSizeChange2,
      handleCurrentChange,
      handleSizeChange,
      resetBtn,
      ypClose,
      quxiao,
      sendMedia,
      resetForm,
      handleClose,
      selectTreeChange,
      resourceForm,
      resourceClose,
      onBind,
      onSearch,
      selectChange,
      ontj,
      choose,
      ...toRefs(state),
    };
  },
};
</script>

<style scoped lang="less">
/deep/ .el-switch {
  padding: 0 !important;
  border: none !important;
}

.search-bar{
  width:100%;
  background: #0A2B28;
  border: 1px #178164 dashed;
  margin-bottom: 10px;
  .search-form{
    padding:20px 10px;
  }
}

.btn{
  margin: 10px 0;
  display: block !important;
}

.btnflex{
  display:flex;
  width: 100%;
  flex-direction: row-reverse;
  margin-top: 20px;
}

</style>
