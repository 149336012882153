import axios from "@/api/axios"; // 引用axios
//获取指令历史数据
export const getList = (page, limit, params) => {
  const param = {
    ...params,
    page,
    limit
  };
  return axios({
    url: "/api/adapter/query/getAdapterCurrencyList",
    method: "post",
    params: param
  });
};
//获取指令列表
export const instructionList = (type) => {
  return axios({
    url: "/api/adapter/query/getInstructionTypeList",
    method: "post",
    params: {
      type
    }
  });
};
// 获取连接适配器终端设备列表
export const getterminalList = (params) => {
  return axios({
    url: "/api/adapter/query/getTerminalListByCodeAndMac",
    method: "post",
    params: params
  });
};
// 通用编码设置
export const sendCode = (params) => {
  return axios({
    url: "/api/adapter/query/setResourceCode",
    method: "post",
    params: params
  });
};
// 通用音量设置
export const sendVolume = (params) => {
  return axios({
    url: "/api/adapter/query/setCmdVolume",
    method: "post",
    params: params
  });
};
// 通用回传参数设置
export const sendParam = (params) => {
  return axios({
    url: "/api/adapter/query/setRebackParam",
    method: "post",
    params: params
  });
};
// 适配器通用被动回传查询指令
export const sendPassiveBack = (params) => {
  return axios({
    url: "/api/adapter/query/setParamAndStatus",
    method: "post",
    params: params
  });
};
// 适配器通用时钟校准
export const sendClock = (params) => {
  return axios({
    url: "/api/adapter/query/setSetTime",
    method: "post",
    params: params
  });
};
// 适配器网络参数配置
export const sendnetParams = (params) => {
  return axios({
    url: "/api/adapter/query/setNetworkParam",
    method: "post",
    params: params
  });
};
// 适配器功放开关设置
export const sendSwitch = (params) => {
  return axios({
    url: "/api/adapter/query/setAmplifierSwitch",
    method: "post",
    params: params
  });
};
// 适配器回传周期设置
export const sendRebackCycle = (params) => {
  return axios({
    url: "/api/adapter/query/setRebackCycle",
    method: "post",
    params: params
  });
};
// 指令回传设置
export const sendReback= (params) => {
  return axios({
    url: "/api/adapter/query/setNetParam",
    method: "post",
    params: params
  });
};
