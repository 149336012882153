<template>
  <div class="outer_box">
    <div class="left_region">
      <el-tree :data="regionList" :props="treeSelectProps" show-checkbox node-key="code" accordion ref="tree"
               @check-change="checkChange" check-on-click-node />
    </div>
    <div class="right_form">
      <el-form
        :model="form"
        ref="addForm"
      >
        <el-row :gutter="24">
          <el-col :span="10">
            <el-form-item
              label="消息标题"
              prop="msgTitle"
              :rules="[{ required: true, message: '消息标题 不能为空' }]"
            >
              <el-input
                clearable
                autocomplete="off"
                v-model="form.msgTitle"
                placeholder="请输入 消息标题"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="14">
            <el-form-item
              label="消息类型"
              prop="bcastType"
              :rules="[{ required: true, message: '消息类型 不能为空' }]"
            >
              <el-radio-group v-model="form.bcastType">
                <el-radio :label="1">实际播发</el-radio>
                <el-radio :label="3">平台演练播发</el-radio>
                <el-radio :label="4">前端演练播发</el-radio>
                <el-radio :label="5">终端演练播发</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="10">
            <el-form-item
              label="事件级别"
              prop="eventLevel"
              :rules="[{ required: true, message: '事件级别 不能为空' }]"
            >
              <el-select
                v-model="form.eventLevel"
                placeholder="请选择 事件级别"
                clearable
              >
                <el-option :label="item.name" :value="item.code" v-for="(item,index) in eventLevel"
                           :key="index"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item
              :rules="[{ required: true, message: '开始时间 不能为空' }]"
              label="开始时间"
              prop="startTime"
            >
              <el-date-picker
                value-format="YYYY-MM-DD HH:mm:ss"
                v-model="form.startTime"
                type="datetime"
                placeholder="请选择 开始时间"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item
              :rules="[{ required: true, message: '结束时间 不能为空' }]"
              label="结束时间"
              prop="endTime"
            >
              <el-date-picker
                value-format="YYYY-MM-DD HH:mm:ss"
                v-model="form.endTime"
                type="datetime"
                placeholder="请选择 结束时间"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="10">
            <el-form-item
              label="审核方式"
              prop="verify"
              :rules="[{ required: true, message: '审核方式 不能为空' }]"
            >
              <el-select
                v-model="form.verify"
                placeholder="请选择 审核方式"
                clearable
              >
                <el-option label="自动审核" :value="1"></el-option>
                <el-option label="策略配置" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item
              :rules="[{ required: true, message: '调度目标 不能为空' }]"
              label="事件类型"
              prop="eventType"
            >
              <el-select
                v-model="form.eventType"
                placeholder="请选择 事件类型"
                clearable
              >
                <el-option :label="item.name" :value="item.code" v-for="(item,index) in eventType"
                           :key="index"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7" v-if="form.verify==2">
            <el-form-item
              :rules="[{ required: true, message: '调度目标 不能为空' }]"
              label="调度目标"
              prop="scheduls"
            >
              <el-select
                v-model="form.scheduls"
                placeholder="请选择 调度目标"
                clearable
              >
                <el-option :label="item.name" :value="item.code" v-for="(item,index) in dispatchList"
                           :key="index"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item
          label="已选区域"
          prop="targetCodes"
          :rules="[{ required: true, message: '已选区域 不能为空' }]"
        >
          <el-input
            type="textarea"
            clearable
            autocomplete="off"
            v-model="nameStr"
            placeholder="请输入 已选区域"
          >
          </el-input>
        </el-form-item>
        <el-row :gutter="24">
          <el-col :span="20">
            <el-form-item
              :rules="[{ required: true, message: '音频文件 不能为空' }]"
              v-if="selectType"
              label="音频文件"
              prop="file"
            >
              <el-upload
                :file-list="fileList"
                :limit="1"
                :auto-upload="false"
                :on-change="handleChange"
                :before-upload="beforeUpload"
                class="upload-demo"
                action="/api/local/media/uploadMp3"
                accept="audio/mpeg"
              >
                <el-button size="small" type="primary">点击上传</el-button>
                <template #tip>
                  <div class="el-upload__tip">只能上传mp3文件</div>
                </template>
              </el-upload>
            </el-form-item>
            <el-form-item
              v-else
              :rules="[{ required: true, message: '消息内容 不能为空' }]"
              label="消息内容"
              prop="msgDesc"
            >
              <el-input
                  maxlength="200"
                type="textarea"
                clearable
                autocomplete="off"
                v-model="form.msgDesc"
                placeholder="请输入 消息内容"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4"><span class="toggle" @click="selectType=!selectType">切换</span></el-col>
        </el-row>


        <!--          </el-col>-->

        <!--          <el-col :span="6">-->
        <!--              <el-form-item>-->
        <el-button plain @click="addForm('addForm')" class="searchBtn">提交</el-button>


        <!--              </el-form-item>-->
        <!--          </el-col>-->
        <!--        </el-row>-->
      </el-form>
    </div>
  </div>

</template>
<script>
import {
  getbasicData
} from "@/api/basic/startBroadcast";
import { getTreeData } from "@/api/system/region";
import { release } from "@/api/schedule/emergencyRelease";

export default {
  name: "emergencyRelease",
  data() {
    return {
      selectType: true,
      form: {},
      fileList: [],
      dispatchList: [],
      eventType: [],
      eventLevel: [],
      regionList: [],
      codeList: [],
      treeSelectProps: {
        label: "name",
        value: "id",
        key: "code",
        children: "children"
      }
    };
  },
  computed: {
    nameStr() {
      let arr = [];
      this.codeList.map(item => {
        arr.push(item.name);
      });
      return arr.join(",");
    }
  },
  created() {
    getTreeData().then(res => {
      if (res.data.success) {
        this.regionList = res.data.data;
      }
    });
    getbasicData(1).then(res => {
      if (res.data.success) {
        this.dispatchList = res.data.data.dispatchList;
        this.eventLevel = res.data.data.eventLevel;
        this.eventType = res.data.data.eventType;
      }
    });
  },
  methods: {
    getSimpleCheckedNodes(store) {
      const checkedNodes = [];
      const traverse = function(node) {
        const childNodes = node.root ? node.root.childNodes : node.childNodes;
        childNodes.forEach(child => {
          if (child.checked) {
            checkedNodes.push(child.data);
          }
          if (child.indeterminate) {
            traverse(child);
          }
        });
      };
      traverse(store);
      return checkedNodes;
    },
    checkChange(node, status) {
      this.codeList = this.getSimpleCheckedNodes(this.$refs.tree.store);
    },
    beforeUpload(file) {
      console.log(file);
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg == "mp3";
      if (!extension) {
        return this.$message({
          type: "error",
          message: "上传文件只能是mp3格式!"
        });
      }
    },
    handleChange(file, fileList) {
      this.form.file = file.raw;
    },
    addForm(formName) {
      let arr = [];
      this.codeList.map(item => {
        arr.push(item.code);
      });
      this.form.targetCodes = arr.join(",");
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.form.uuidStr = Date.now();
          let obj=new FormData();
          for(let key in this.form){
            obj.append(key,this.form[key])
          }
          release(obj).then(res => {
            if (res.data.success) {
              this.fileList = [];
              this.$message.success("操作成功！");
              this.$refs[formName].resetFields();
              this.$refs.tree.setCheckedKeys([]);
            }
          });
        } else {
          return false;
        }
      });
    }

  }
};
</script>
<style lang="scss" scoped>
.outer_box {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 750px;
}

.el-tree {
  height: 100% !important;
  overflow-y: scroll;
}

.left_region {
  width: 320px;
  height: 100%;
  background: #0C3834;
  border: 1px solid #178164;

  ::-webkit-scrollbar {
    width: 0;
  }
}

.right_form {
  padding: 15px;
  width: 1360px;
  height: 100%;
  background: #0C3834;
  border: 1px solid #178164;
}

.el-upload__tip {
  color: #00ffc0;
}

.toggle {
  display: inline-block;
  width: 150px;
  height: 70px;
  background-color: #00AD85;
  //color: #32B89C;
  line-height: 70px;
  font-size: 16px;
  border-radius: 20px;
}

.toggle:hover {
  background-color: #1C9C77;
  color: #00FFC4;
}

.upload-demo {
  text-align: left;
}

::v-deep .el-upload-list__item-name {
  color: #00FFC4 !important;
}
</style>