<template>
  <div class="main">
    <div class="inbox">
      <div class="line"></div>
      <el-button @click="addForm" class="searchBtn btn">新增</el-button>
      <basicTable
          :tableData="tableData"
          :attrList="attrList"
          :page="page.current"
          :limit="page.limit"
          @currentChange="handleCurrentChange"
          @sizeChange="handleSizeChange"
          :total="page.total">
      </basicTable>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import { useRouter } from "vue-router";
import { ElMessage } from 'element-plus';
import {getData, onAudit, listDel} from "../../api/screen/deploy";
export default {
  setup(props, { root }){
    const router = useRouter();
    let state = reactive({
      attrList:[
        {
          prop: "name",
          label: "方案名称",
          render: (row) => <div>{row.name}</div>,
        },
        {
          prop: "resolution",
          label: "分辨率",
          render: (row) => <div>{row.resolutionOne}*{row.resolutionTwo}</div>,
        },
        {
          prop: "screen",
          label: "屏幕拼接方式",
          render: (row) => <div>{row.screenOne}*{row.screenTwo}</div>,
        },
        {
          prop: "status",
          label: "应用状态",
          render: (row) => <div>
            <el-switch
                onClick={()=>onChange(row)}
                value={row.status == 0 ? true : false}
                active-text="开启"
                inactive-text="禁用">
            </el-switch>
          </div>,
        },
        {
          prop: "",
          label: "操作",
          render: (row) =><div>
            <el-button type="text" onClick={()=>del(row)}>删除</el-button>
          </div>,
        },
      ],
      tableData:[],
      page: {
        current: 1,
        limit: 10,
        total: 0,
      },
    });
    function addForm(){
      router.push("/content/screen_dispose")
    }

    function onChange(row){
      onAudit({id:row.id,status:row.status ? 0 : 1}).then(res=>{
        ElMessage({
          message: res.data.code.message,
          type: 'success',
        })
        getData({limit:state.page.limit, page:state.page.current}).then(res=>{
          state.tableData = res.data.data.list;
          state.page.total = res.data.data.count;
        })

      })
    }

    function del(row){
      listDel({id:row.id}).then(res=>{
        ElMessage({
          message: res.data.code.message,
          type: 'success',
        })
        getData({limit:state.page.limit, page:state.page.current}).then(res=>{
          state.tableData = res.data.data.list;
          state.page.total = res.data.data.count;
        })
      })
    }

    function onLoad(){
      getData({limit:state.page.limit, page:state.page.current}).then(res=>{
        state.tableData = res.data.data.list;
        state.page.total = res.data.data.count;
      })
    }

    function handleCurrentChange(currentPage) {
      state.page.current = currentPage;
      onLoad(state.searchForm);
    }

    function handleSizeChange(pageSize) {
      state.page.limit = pageSize;
      onLoad(state.searchForm);
    }

    onMounted(()=>{
      onLoad();
    })


    return{
      handleCurrentChange,
      handleSizeChange,
      addForm,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="scss" scoped>
.tableBox{
  border:none;
  padding: 0;
}
.main{
  height: 869px;
  background: #0A2B28;
  border: 1px solid #2D6156;
  .inbox{
    margin: 0 auto;
    width: 1670px;
    height: 776px;
    .btn{
      display: inherit;
      margin: 20px;
    }
    .line{
      height: 10px;
      background: #0E3D34;
    }
  }
}
</style>