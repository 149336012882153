import axios from "@/api/axios"; // 引用axios
//获取广播基础参数
export const getbasicData = (type) => {
  return axios({
    url: "/api/broadcast/getMesToStartBCast",
    method: "post",
    params: {
      type: type,
    },
  });
};
//开播
export const startBCast = (params) => {
  return axios({
    url: "/api/broadcast/startBCast",
    method: "post",
    data: params,
  });
};
//通过广播id,获取广播流程
export const getNodesById = (id) => {
  return axios({
    url: "/api/broadcast/getNodesByBcastId",
    method: "post",
    params: { id },
  });
};
//通过区域id获取设备列表
export const getEpuipByregion = (targetCodes) => {
  return axios({
    url: "/api/broadcast/getDeviceMesByCodes",
    method: "post",
    params: { targetCodes },
  });
};

export const getProgramById = (id) => {
  return axios({
    url: "/api/pub/getProgramById",
    method: "post",
    params: { id },
  });
};
