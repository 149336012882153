<template>
  <div class="searchBox">
    <el-form :model="search" :label-width="searchLabelWidth" ref="searchForm">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="名称" prop="name">
            <el-input
              v-model="search.name"
              autocomplete="off"
              placeholder="请输入 名称"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <!--              <el-form-item>-->
          <el-button plain @click="searchFormFn('searchForm')" class="searchBtn"
          >查询
          </el-button
          >
          <el-button plain @click="resetForm('searchForm')" class="resetBtn"
          >清空
          </el-button
          >
          <el-button plain @click="savesql" class="resetBtn"
          >手动备份
          </el-button
          >
          <!--              </el-form-item>-->
        </el-col>
      </el-row>
    </el-form>
    <div class="divide">
      <img src="../../assets/divide.png" style="width: 100%" />
    </div>
    <!--    <div class="btnContainer">-->
    <!--      <div class="btnLeft">-->
    <!--        <el-button-->
    <!--          icon="Plus"-->
    <!--          @click="handleAdd"-->
    <!--          class="searchBtn"-->
    <!--        >新增-->
    <!--        </el-button-->
    <!--        >-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
  <basicTable
    :attrList="attrList"
    :tableData="tableData"
    ref="table"
    :page="page.current"
    :limit="page.limit"
    :total="page.total"
    @currentChange="handleCurrentChange"
    @sizeChange="handleSizeChange"
  >
  </basicTable>
</template>

<script>
import { onMounted, reactive, ref, toRefs } from "vue";
import { getList, downloadData, delData, savemysql } from "@/api/system/databaseRecords";
import { ElMessage,ElMessageBox } from "element-plus";

export default {
  name: "databaseRecords",
  setup() {
    const option = reactive({
      formLabelWidth: "22%",
      dialogFormVisible: false,
      title: "新增",
      form: {},
      searchLabelWidth: "100px",
      search: {},
      page: {
        current: 1,
        limit: 10,
        total: 0
      },
      tableData: [],
      attrList: [
        {
          label: "备份包名称",
          prop: "name",
          render: (row) => <div>{row.name}</div>
        },
        {
          label: "创建时间",
          prop: "create_time",
          render: (row) => <div>{row.create_time}</div>
        },
        {
          prop: "",
          label: "操作",
          render: (row) => {
            return (
              <div>
                <el-button
                  type="text"
                  icon="Bottom"
                  onClick={() => handleDownload(row.id, row.name)}
                >下载备份包
                </el-button>
                <el-button
                  type="text"
                  icon="Delete"
                  onClick={() => handleDelete(row.id)}
                >
                  编辑
                </el-button>
              </div>
            );
          }
        }
      ]
    });
    const searchForm = ref(null);

    function getData(page) {
      getList(page.current, page.limit, option.search).then(res => {
        if (res.data.success) {
          option.page.total = res.data.data.count;
          option.tableData = res.data.data.list;
        }
      });
    }

    function searchFormFn() {
      option.page.current = 1;
      getData(option.page);
    }

    function resetForm() {
      searchForm.value.resetFields();
      option.page.current = 1;
      getData(option.page);
    }

    function handleCurrentChange(currentPage) {
      option.page.current = currentPage;
      getData(option.page);
    }

    function handleSizeChange(pageSize) {
      option.page.limit = pageSize;
      getData(option.page);
    }
    function savesql(){
      ElMessageBox.confirm(
          "是否备份?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "Warning"
          }
      ).then(() => {
        ElMessage({
          type: "success",
          message: "已操作成功 请查看备份信息！"
        });
        savemysql().then(res=>{})
      })
    }

    function handleDownload(id, name) {
      downloadData(id).then(res => {
        console.log(res);
        if (res.data) {
          // 文件下载
          const blob = new Blob([res.data], {
            type: "application/x-tar"
          });
          let link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.setAttribute("download", name);
          link.click();
          link = null;
        } else return ElMessage({
          type: "error",
          message: "数据包为空"
        });
      });
    }

    function handleDelete(id) {
      ElMessageBox.confirm(
        "确认删除该条数据?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "Warning"
        }
      ).then(() => {
        return delData(id);
      }).then(res => {
        if (res.data.success) {
          getData(option.page);
          return ElMessage({
            type: "success",
            message: "操作成功！"
          });
        }
      });
    }

    onMounted(() => {
      getData(option.page);
    });
    return {
      searchForm,
      ...toRefs(option),
      savesql,
      getData,
      searchFormFn,
      resetForm,
      handleCurrentChange,
      handleSizeChange
    };
  }
};
</script>

<style scoped>

</style>