import axios from "@/api/axios"; // 引用axios
//输入通道信息列表
export const getInputList = (page, limit, params) => {
  const param = {
    ...params,
    page,
    limit
  };
  return axios({
    url: "/api/adapter/query/getInputChannelData",
    method: "post",
    params: param
  });
};
//输出通道信息列表
export const getOutList = (page, limit, params) => {
  const param = {
    ...params,
    page,
    limit
  };
  return axios({
    url: "/api/adapter/query/getOutChannelData",
    method: "post",
    params: param
  });
};

//故障详情
export const getFault = (page, limit, params) => {
  const param = {
    ...params,
    page,
    limit
  };
  return axios({
    url: "/api/adapter/query/faultInfoData",
    method: "post",
    params: param
  });
};
//播发记录
export const getRecordList = (page, limit,params) => {
  const param = {
    ...params,
    page,
    limit
  };
  return axios({
    url: "/api/adapter/query/getBroadRecordData",
    method: "post",
    params: param
  });
};
//获取适配器
export const getParams = (params = {}) => {
  return axios({
    url: "/api/adapter/query/input",
    method: "post",
    params: params
  });
};
//获取适配器cky
export const getadapterParams = (params = {}) => {
  return axios({
    url: "/api/adapter/query/getAllTerminalList",
    method: "post",
    params: params
  });
};
//故障查询发送指令
export const sendFault = (params) => {
  return axios({
    url: "/api/adapter/query/faultInfo",
    method: "post",
    params: params
  });
};
//输入通道发送指令
export const sendInput = (params) => {
  return axios({
    url: "/api/adapter/query/queryInput",
    method: "post",
    params: params
  });
};
//输出通道信息发送指令
export const setOut = (params) => {

  return axios({
    url: "/api/adapter/query/queryOutput",
    method: "post",
    params: params
  });
};
//播发记录发送指令
export const setRecord = (params) => {

  return axios({
    url: "/api/adapter/query/queryRecord",
    method: "post",
    params: params
  });
};