import axios from "@/api/axios"; // 引用axios

export const getData = (params) => {
    return axios({
        url: "/api/platform/system/listData",
        method: "post",
        params: params,
    });
};


export const editMac = (params) => {
    return axios({
        url: "/api/platform/system/editMac",
        method: "post",
        params: params,
    });
};

export const dataSave = (params) => {
    return axios({
        url: "/api/platform/system/addData",
        method: "post",
        params: params,
    });
};

export const getedit = (params) => {
    return axios({
        url: "/api/platform/system/edit",
        method: "post",
        params: params,
    });
};


export const getDel = (params) => {
    return axios({
        url: "/api/platform/system/del",
        method: "get",
        params: params,
    });
};


export const dataResource = (params) => {
    return axios({
        url: "/api/resource/group/resourceList",
        method: "post",
        params: params,
    });
};

export const childBind = (params) => {
    return axios({
        url: "/api/resource/group/add",
        method: "post",
        params: params,
    });
};

export const dataBind = (params) => {
    return axios({
        url: "/api/resource/group/platformResourceList",
        method: "post",
        params: params,
    });
};

export const unBind = (params) => {
    return axios({
        url: "/api/resource/group/delete",
        method: "post",
        params: params,
    });
};
