<template>
  <div>
    <Searchbar @emit-search="onSearch" @reset-form="resetForm">
      <template #SEARCH>
        <el-col :span="6">
          <el-form-item label="MAC地址">
            <el-input clearable v-model="searchForm.mac" placeholder="请输入 MAC地址"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="接入类型">
            <el-select v-model="searchForm.type" placeholder="请选择 接入类型">
              <el-option label="调频" :value=1></el-option>
              <el-option label="DVB-C" :value=2></el-option>
              <el-option label="DTMB" :value=3></el-option>
              <el-option label="IP" :value=4></el-option>
              <el-option label="4G" :value=5></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="安装状态">
            <el-select clearable v-model="searchForm.status" placeholder="请选择" size="large">
              <el-option label="未安装" :value="0"></el-option>
              <el-option label="已安装" :value="1"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="安全模块编号">
            <el-input v-model="searchForm.securitya_sn" placeholder="安全模块编号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="设备型号">
            <el-select v-model="searchForm.resource_model" placeholder="请选择 设备型号">
              <el-option v-for="item in modelList" :key="item.code" :label="item.name" :value="item.code"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="资源编码">
            <el-input v-model="searchForm.resource_code" placeholder="资源编码"></el-input>
          </el-form-item>
        </el-col>
      </template>
      <template #BTN>
        <el-button class="searchBtn" @click="add">新增</el-button>
        <el-button class="searchBtn" @click="dialogVisible = true;title = '初始配置'">初始配置</el-button>
        <el-button class="searchBtn" @click="install">预安装模板下载</el-button>
        <el-button class="searchBtn" @click="addExcel">预安装导入</el-button>
      </template>
    </Searchbar>

    <basicTable
        :attrList="attrList"
        :tableData="tableData"
        ref="table"
        :page="page.current"
        :limit="page.limit"
        :total="page.total"
        @currentChange="handleCurrentChange"
        @sizeChange="handleSizeChange"
    ></basicTable>

    <el-dialog v-model="dialogVisible" :title="title" width="90%" :before-close="handleClose" @open="dialogOpen">
      <el-form ref="ruleForm" :rules="rules" label-width="auto" label-position="right" :model="form">
        <el-row class="row-acitve" v-if="title != '初始配置'">
          <div class="title top">终端数据配置</div>
          <el-col :span="4">
            <el-form-item label="接入类型:" prop="type">
              <el-select v-model="form.type" placeholder="请选择 接入类型">
                <el-option label="调频" :value=1></el-option>
                <el-option label="DVB-C" :value=2></el-option>
                <el-option label="DTMB" :value=3></el-option>
                <el-option label="IP" :value=4></el-option>
                <el-option label="4G" :value=5></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="mac编号:" prop="mac">
              <el-input v-model="form.mac" placeholder="mac编号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="安全模块编号:" prop="securitya_sn">
              <el-input v-model="form.securitya_sn" placeholder="安全模块编号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="设备型号:" prop="resource_model">
              <el-select v-model="form.resource_model" placeholder="请选择 设备型号">
                <el-option v-for="item in modelList" :key="item.code" :label="item.name" :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="资源编码:" prop="resource_code">
              <el-input v-model="form.resource_code" placeholder="资源编码"></el-input>
            </el-form-item>
          </el-col>
        </el-row>


        <el-row class="row-acitve">
          <div class="title top">安全模块配置</div>
          <el-col :span="7">
            <el-form-item label="模块厂家:" prop="securitya_factory">
              <el-radio-group v-model="form.securitya_factory">
                <el-radio :label=0>未知</el-radio>
                <el-radio :label=1>江南天安</el-radio>
                <el-radio :label=2>江南科友</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="模块状态:" prop="securitya_status">
              <el-radio-group v-model="form.securitya_status">
                <el-radio :label=0>禁用</el-radio>
                <el-radio :label=1>启用</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>

        <section>
          <div class="title">终端IP配置</div>
          <el-row class="row-acitve">
            <el-col :span="4">
              <el-form-item label="网络协议:" prop="agreement">
                <el-radio-group v-model="form.agreement">
                  <el-radio :label=0>静态地址</el-radio>
                  <el-radio :label=1>DHCP</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="终端ip:" prop="ip_addr">
                <el-input v-model="form.ip_addr" placeholder="设置终端ip"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="子网掩码:" prop="subnet_mask">
                <el-input v-model="form.subnet_mask" placeholder="设置子网掩码"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="设备网关:" prop="gateway">
                <el-input v-model="form.gateway" placeholder="设置设备网关"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="DNS地址:" prop="dns">
                <el-input v-model="form.dns" placeholder="设置DNS地址"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </section>

        <section>
          <div class="title">基础设置</div>
          <el-row class="row-acitve">
            <el-col :span="5">
              <el-form-item label="信号超时:" prop="timeout">
                <el-input v-model="form.timeout" placeholder="设置 信号超时"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
                <el-form-item label="行政优先:" prop="priority">
                  <el-radio-group v-model="form.priority">
                    <el-radio :label=0>上级优先</el-radio>
                    <el-radio :label=1>下级优先</el-radio>
                  </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </section>

        <section>
          <div class="title">通道优先级设置</div>
          <el-row class="row-acitve">
            <el-col :span="5">
              <el-form-item label="ip广播:" prop="channel_ip">
                <el-select v-model="form.channel_ip" placeholder="请选择">
                  <el-option v-for="item in options" :disabled="item.disabled" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  <el-option label="禁用" :value="5"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="数字广播:" prop="channel_number">
                <el-select v-model="form.channel_number" placeholder="请选择">
                  <el-option v-for="item in options2" :disabled="item.disabled" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  <el-option label="禁用" :value="5"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="4G广播:" prop="channel_4G">
                <el-select v-model="form.channel_4G" placeholder="请选择">
                  <el-option v-for="item in options3" :disabled="item.disabled" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  <el-option label="禁用" :value="5"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="调频广播:" prop="channel_fm">
                <el-select v-model="form.channel_fm" placeholder="请选择">
                  <el-option v-for="item in options4" :disabled="item.disabled" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  <el-option label="禁用" :value="5"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </section>

        <section>
          <div class="title">服务管理</div>
          <el-row class="row-acitve">
            <el-col :span="5">
              <el-form-item label="IP地址:" prop="server_ip_addr">
                <el-input v-model="form.server_ip_addr" placeholder="ip服务地址"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="IP端口:" prop="server_ip_port">
                <el-input v-model="form.server_ip_port" placeholder="ip端口"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="4G地址:" prop="server_4g_addr">
                <el-input v-model="form.server_4g_addr" placeholder="4G地址"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="4G端口:" prop="server_4g_port">
                <el-input v-model="form.server_4g_port" placeholder="4G端口"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </section>

        <section>
          <div class="title">回传设置</div>
          <el-row class="row-acitve">
            <el-col :span="5">
              <el-form-item label="IP回传:" prop="pass_back_ip_addr">
                <el-input v-model="form.pass_back_ip_addr" placeholder="IP回传"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="IP回传端口:" prop="pass_back_ip_port">
                <el-input v-model="form.pass_back_ip_port" placeholder="IP回传端口"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="4G回传:" prop="pass_back_4g_addr">
                <el-input v-model="form.pass_back_4g_addr" placeholder="4G回传"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="4G回传端口:" prop="pass_back_4g_port">
                <el-input v-model="form.pass_back_4g_port" placeholder="4G回传端口"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="回传方式:" prop="pass_back_way">
                <el-radio-group v-model="form.pass_back_way">
                  <el-radio :label=0>IP</el-radio>
                  <el-radio :label=1>4G</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="回传周期:" prop="pass_back_cycle">
                <el-input v-model="form.pass_back_cycle" placeholder="回传周期(s)"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </section>

        <section>
          <div class="title">备份回传</div>
          <el-row class="row-acitve">
            <el-col :span="4">
              <el-form-item label="回传方式:" prop="backup_status">
                <el-radio-group v-model="form.backup_status">
                  <el-radio :label=0>禁用</el-radio>
                  <el-radio :label=1>启用</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="ip地址:" prop="backup_addr">
                <el-input v-model="form.backup_addr" placeholder="ip地址"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="ip端口:" prop="backup_port">
                <el-input v-model="form.backup_port" placeholder="ip端口"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </section>

        <section>
          <div class="title">数字设置</div>
          <el-row class="row-acitve">
            <el-col :span="5">
              <el-form-item label="数字频率:" prop="digital_frequency">
                <el-input v-model="form.digital_frequency" placeholder="数字频率"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="数字QAM:" prop="digital_qam">
                <el-select v-model="form.digital_qam" placeholder="请选择">
                  <el-option v-for="item in QAMList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="符号率:" prop="digital_symbol">
                <el-input v-model="form.digital_symbol" placeholder="符号率"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="数字模式:" prop="digital_pattern">
                  <el-radio-group v-model="form.digital_pattern">
                    <el-radio :label=0>DTMB</el-radio>
                    <el-radio :label=1>DVBC</el-radio>
                  </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </section>

        <section>
          <div class="title">控制设置</div>
          <el-row class="row-acitve">
            <el-col :span="5">
              <el-form-item label="PID:" prop="superior_pid">
                <el-input v-model="form.superior_pid" placeholder="PID"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="TID:" prop="superior_tid">
                <el-input v-model="form.superior_tid" placeholder="TID"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </section>

        <section>
          <div class="title">调频设置</div>
          <el-row class="row-acitve">
            <el-col :span="5">
              <el-form-item label="FM-I:" prop="fm_one">
                <el-input v-model="form.fm_one" placeholder="FM-I"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="FM-Ⅱ:" prop="fm_two">
                <el-input v-model="form.fm_two" placeholder="FM-Ⅱ"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </section>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="sendData" v-if="title != '查看'">确认</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import {ipvalidatePass, maskvalidatePass, numbervalidatePass} from './exp';
import Searchbar from '@/components/Searchbar';
import { nextTick, onMounted, reactive, ref, toRefs, watch } from "vue";
import { initAdd, initData, terminalModel, addData, listData, editData, dataDel, addPreinstallationImportExcel } from "@/api/basic/bluetooth";
import { ElMessage, ElMessageBox } from 'element-plus';

export default {
  components:{
    Searchbar
  },
  setup(){
    //弹窗逻辑
    let { dialogVisible, dialogState, rules, sendData, ruleForm, title, add, dialogOpen } = useDialog();
    function useDialog(){
      var validalength12 = (rule, value, callback) => {
        if (value.length != 12) {
          callback(new Error('请再次输入密码'));
        }else {
          callback();
        }
      };
      var validalength24 = (rule, value, callback) => {
        if (value.length != 24) {
          callback(new Error('请再次输入密码'));
        }else {
          callback();
        }
      };
      let dialogVisible =  ref(false);
      let title = ref('');
      let ruleForm = ref(null);
      const rules = reactive({
        type: [
          { required: true, message: '请选择 接入类型', trigger: 'blur' },
        ],
        mac:[
          { required: true, message: '请输入 mac编号', trigger: 'blur' },
          { validator: validalength12, message: '请输入正确的12个字符', trigger: 'blur' }
        ],
        securitya_sn:[
          { required: true, message: '请输入 安全模块编号', trigger: 'blur' },
          { validator: validalength12, message: '请输入正确的12个字符', trigger: 'blur' }
        ],
        resource_model:[
          { required: true, message: '请选择 设备型号', trigger: 'blur' },
        ],
        resource_code:[
          { required: true, message: '请输入 资源编码', trigger: 'blur' },
          { validator: validalength24, message: '请输入正确的24个字符', trigger: 'blur' }
        ],
        ip_addr: [
          { validator: ipvalidatePass, trigger: 'blur', required: true }
        ],
        subnet_mask: [
          { validator: maskvalidatePass, trigger: 'blur', required: true }
        ],
        gateway: [
          { validator: ipvalidatePass, trigger: 'blur', required: true }
        ],
        dns: [
          { validator: ipvalidatePass, trigger: 'blur', required: true }
        ],
        timeout:[
          { validator: numbervalidatePass, trigger: 'blur', required: true }
        ],
        server_ip_addr:[
          { validator: ipvalidatePass, trigger: 'blur', required: true }
        ],
        server_ip_port:[
          { validator: numbervalidatePass, trigger: 'blur', required: true }
        ],
        server_4g_addr:[
          { validator: ipvalidatePass, trigger: 'blur', required: true }
        ],
        server_4g_port:[
          { validator: numbervalidatePass, trigger: 'blur', required: true }
        ],
        pass_back_ip_addr:[
          { validator: ipvalidatePass, trigger: 'blur', required: true }
        ],
        pass_back_ip_port:[
          { validator: numbervalidatePass, trigger: 'blur', required: true }
        ],
        pass_back_4g_addr:[
          { validator: ipvalidatePass, trigger: 'blur', required: true }
        ],
        pass_back_4g_port:[
          { validator: numbervalidatePass, trigger: 'blur', required: true }
        ],
        pass_back_cycle:[
          { validator: numbervalidatePass, trigger: 'blur', required: true }
        ],
        backup_addr:[
          { validator: ipvalidatePass, trigger: 'blur', required: true }
        ],
        backup_port:[
          { validator: numbervalidatePass, trigger: 'blur', required: true }
        ],
        digital_frequency:[
          { validator: numbervalidatePass, trigger: 'blur', required: true }
        ],
        digital_qam:[
          { validator: numbervalidatePass, trigger: 'blur', required: true }
        ],
        digital_symbol:[
          { validator: numbervalidatePass, trigger: 'blur', required: true }
        ],
        superior_pid:[
          { validator: numbervalidatePass, trigger: 'blur', required: true }
        ],
        superior_tid:[
          { validator: numbervalidatePass, trigger: 'blur', required: true }
        ],
        fm_one:[
          { validator: numbervalidatePass, trigger: 'blur', required: true }
        ],
        fm_two:[
          { validator: numbervalidatePass, trigger: 'blur', required: true }
        ],
      })
      let dialogState = reactive({
        form:{
          securitya_factory:1,
          securitya_status:1,
          agreement:0,
          ip_addr:"192.150.32.8",
          subnet_mask:"255.255.255.0",
          gateway:"192.150.32.1",
          dns:"192.150.32.1",
          timeout:"20",
          priority:0,
          channel_ip:0,
          channel_number:1,
          channel_4G:2,
          channel_fm:3,
          server_ip_addr:"192.150.32.3",
          server_ip_port:"8082",
          server_4g_addr:"10.1.3.171",
          server_4g_port:"8082",
          pass_back_ip_addr:"192.150.32.2",
          pass_back_ip_port:"10000",
          pass_back_4g_addr:"10.1.3.171",
          pass_back_4g_port:"10000",
          pass_back_way:0,
          pass_back_cycle:"10",
          backup_status:1,
          backup_addr:"10.133.34.13",
          backup_port:"10014",
          digital_frequency:"123000",
          digital_qam:2,
          digital_symbol:"6875",
          digital_pattern:0,
          superior_pid:"33",
          superior_tid:"253",
          fm_one:"95000",
          fm_two:"108000"
        },
        QAMList:[
          {
            value: '0',
            label: '未定义'
          },
          {
            value: '1',
            label: '16QAM'
          },
          {
            value: '2',
            label: '32QAM'
          },
          {
            value: '3',
            label: '64QAM'
          },
          {
            value: '4',
            label: '128QAM'
          },
          {
            value: '5',
            label: '256QAM'
          }
        ],
        options:[
          {
            value: 0,
            label: 0,
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          },
        ],
        options2:[
          {
            value: 0,
            label: 0,
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          },
        ],
        options3:[
          {
            value: 0,
            label: 0,
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          },
        ],
        options4:[
          {
            value: 0,
            label: 0,
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          },
        ],
        modelList:[]
      })
      function dialogOpen(){
        let fil = ['新增', '初始配置'];
        if(fil.includes(title.value)){
          initData().then(res=>{
            if(res.data.success) {
              dialogState.form = res.data.data;
              // channel_ip channel_number channel_4G channel_fm
              let arr = [dialogState.form.channel_fm,dialogState.form.channel_4G,dialogState.form.channel_number];
              arr.forEach(item=>{
                let _index = dialogState.options.findIndex(e=>item == e.value)
                dialogState.options[_index].disabled = true;
              })

              let arr2 = [dialogState.form.channel_ip,dialogState.form.channel_4G,dialogState.form.channel_fm];
              arr2.forEach(item=>{
                let _index = dialogState.options2.findIndex(e=>item == e.value)
                dialogState.options2[_index].disabled = true;
              })

              let arr3 = [dialogState.form.channel_ip,dialogState.form.channel_number,dialogState.form.channel_fm];
              arr3.forEach(item=>{
                let _index = dialogState.options3.findIndex(e=>item == e.value)
                dialogState.options3[_index].disabled = true;
              })

              let arr4 = [dialogState.form.channel_ip,dialogState.form.channel_number,dialogState.form.channel_4G];
              arr4.forEach(item=>{
                let _index = dialogState.options4.findIndex(e=>item == e.value)
                dialogState.options4[_index].disabled = true;
              })
            }
          })
        }
      }
      function sendData(){
        ruleForm.value.validate(async (valid) => {
          if (valid) {
            let res = null;
            if(title.value == '新增'){
              res = await addData(dialogState.form);
            }else if(title.value == '初始配置'){
              res = await initAdd(dialogState.form);
            }else {
              res = await editData(dialogState.form);
            }
            if(res.data.success){
              ElMessage({message: res.data.code.message, type: "success"});
              dialogVisible.value = false;
              state.searchForm = {};
              dataState.page.current = 1;
              nextTick(()=>{
                onLoad();
                ruleForm.value.resetFields();
              })
            }
          } else {
            return false;
          }
        });
      }
      function add(){
        title.value = '新增';
        dialogVisible.value = true;
      }
      watch(()=>dialogState.form.channel_ip,(val)=>{
        if(val == 5) return;
        dialogState.options2.map(i=>i.disabled = false);
        dialogState.options3.map(i=>i.disabled = false);
        dialogState.options4.map(i=>i.disabled = false);
        let arr2 = [dialogState.form.channel_ip,dialogState.form.channel_4G,dialogState.form.channel_fm];
        arr2.forEach(item=>{
          let _index = dialogState.options2.findIndex(e=>item == e.value)
          dialogState.options2[_index].disabled = true;
        })

        let arr3 = [dialogState.form.channel_ip,dialogState.form.channel_number,dialogState.form.channel_fm];
        arr3.forEach(item=>{
          let _index = dialogState.options3.findIndex(e=>item == e.value)
          dialogState.options3[_index].disabled = true;
        })

        let arr4 = [dialogState.form.channel_ip,dialogState.form.channel_number,dialogState.form.channel_4G];
        arr4.forEach(item=>{
          let _index = dialogState.options4.findIndex(e=>item == e.value)
          dialogState.options4[_index].disabled = true;
        })
      })

      watch(()=>dialogState.form.channel_number,(val)=>{
        if(val == 5) return;
        dialogState.options.map(i=>i.disabled = false);
        dialogState.options3.map(i=>i.disabled = false);
        dialogState.options4.map(i=>i.disabled = false);
        let arr = [dialogState.form.channel_fm,dialogState.form.channel_4G,dialogState.form.channel_number];
        arr.forEach(item=>{
          let _index = dialogState.options.findIndex(e=>item == e.value)
          dialogState.options[_index].disabled = true;
        })

        let arr3 = [dialogState.form.channel_ip,dialogState.form.channel_number,dialogState.form.channel_fm];
        arr3.forEach(item=>{
          let _index = dialogState.options3.findIndex(e=>item == e.value)
          dialogState.options3[_index].disabled = true;
        })

        let arr4 = [dialogState.form.channel_ip,dialogState.form.channel_number,dialogState.form.channel_4G];
        arr4.forEach(item=>{
          let _index = dialogState.options4.findIndex(e=>item == e.value)
          dialogState.options4[_index].disabled = true;
        })
      })

      watch(()=>dialogState.form.channel_4G,(val)=>{
        if(val == 5) return;
        dialogState.options.map(i=>i.disabled = false);
        dialogState.options2.map(i=>i.disabled = false);
        dialogState.options4.map(i=>i.disabled = false);
        let arr = [dialogState.form.channel_fm,dialogState.form.channel_4G,dialogState.form.channel_number];
        arr.forEach(item=>{
          let _index = dialogState.options.findIndex(e=>item == e.value)
          dialogState.options[_index].disabled = true;
        })

        let arr2 = [dialogState.form.channel_ip,dialogState.form.channel_4G,dialogState.form.channel_fm];
        arr2.forEach(item=>{
          let _index = dialogState.options2.findIndex(e=>item == e.value)
          dialogState.options2[_index].disabled = true;
        })

        let arr4 = [dialogState.form.channel_ip,dialogState.form.channel_number,dialogState.form.channel_4G];
        arr4.forEach(item=>{
          let _index = dialogState.options4.findIndex(e=>item == e.value)
          dialogState.options4[_index].disabled = true;
        })
      })

      watch(()=>dialogState.form.channel_fm,(val)=>{
        if(val == 5) return;
        dialogState.options.map(i=>i.disabled = false);
        dialogState.options2.map(i=>i.disabled = false);
        dialogState.options3.map(i=>i.disabled = false);
        let arr = [dialogState.form.channel_fm,dialogState.form.channel_4G,dialogState.form.channel_number];
        arr.forEach(item=>{
          let _index = dialogState.options.findIndex(e=>item == e.value)
          dialogState.options[_index].disabled = true;
        })

        let arr2 = [dialogState.form.channel_ip,dialogState.form.channel_4G,dialogState.form.channel_fm];
        arr2.forEach(item=>{
          let _index = dialogState.options2.findIndex(e=>item == e.value)
          dialogState.options2[_index].disabled = true;
        })

        let arr3 = [dialogState.form.channel_ip,dialogState.form.channel_number,dialogState.form.channel_fm];
        arr3.forEach(item=>{
          let _index = dialogState.options3.findIndex(e=>item == e.value)
          dialogState.options3[_index].disabled = true;
        })
      })

      return{
        dialogOpen,
        add,
        title,
        ruleForm,
        sendData,
        dialogVisible,
        dialogState,
        rules
      }
    }

    let { state, onSearch, resetForm } =  useSearch();
    function useSearch(){
      let state = reactive({
        searchForm:{}
      })
      function onSearch(){
        onLoad()
      }

      function resetForm() {
        state.searchForm = {};
        onLoad();
      }
      return{
        onSearch,
        resetForm,
        state
      }
    }

    let dataState = reactive({
      attrList:[
        {
          prop: "type",
          label: "接入类型",
          render: (row) => {
            let op = new Map([
              [1, '调频'],
              [2, 'DVB-C'],
              [3, 'DTMB'],
              [4, 'IP'],
              [5, '4G'],
            ])
            return <div>{op.get(row.type)}</div>
          }
        },
        {
          prop: "mac",
          label: "MAC地址(序列号)",
          render: (row) => <div>{row.mac}</div>
        },
        {
          prop: "securitya_sn",
          label: "安全模块编号",
          render: (row) => <div>{row.securitya_sn}</div>
        },
        {
          prop: "resource_model",
          label: "设备型号",
          render: (row) => <div>{row.resource_model}</div>
        },
        {
          prop: "resource_code",
          label: "设备资源编码",
          render: (row) => <div>{row.resource_code}</div>
        },
        {
          prop: "status",
          label: "安装状态",
          render: (row) => <div>{row.status ? '已安装' : '未安装'}</div>
        },
        {
          prop: "region_name",
          label: "预设区域",
          render: (row) => <div>{row.region_name || '未获取到预设区域'}</div>
        },
        {
          prop: "",
          label: "操作",
          render: (row) => {
            return (
                <div>
                  <el-button
                      type="text"
                      icon="View"
                      onClick={() => handleView(row)}
                  >查看</el-button>
                  <el-button
                      type="text"
                      icon="Edit"
                      onClick={() => handleEdit(row)}
                  >编辑</el-button>
                  <el-button
                      type="text"
                      icon="Delete"
                      onClick={() => handleDelete(row)}
                  >删除</el-button>
                </div>
            );
          }
        },
      ],
      tableData:[],
      page: {
        current: 1,
        limit: 10,
        total: 0
      },
    })

    function handleEdit(row){
      title.value = '编辑';
      dialogState.form = row;
      dialogVisible.value = true;
    }

    function handleView(row){
      title.value = '查看';
      dialogVisible.value = true;
      dialogState.form = row;
    }

    function handleDelete(row){
      ElMessageBox.confirm('此操作会删除数据,确定?', '提示',
          {confirmButtonText: '确定', cancelButtonText: '取消', type: 'Warning'}).then(() => {
        dataDel({id:row.id}).then(res=>{
          if(res.data.success){
            ElMessage({message: res.data.code.message, type: 'success'});
            dataState.page.current = 1;
            onLoad();
          }
        })
          }).catch(() => {})
    }

    function onLoad() {
      listData({ limit: dataState.page.limit, page: dataState.page.current, ...state.searchForm }).then(res => {
        dataState.tableData = res.data.data.list;
        dataState.page.total = res.data.data.count;
      });
    }

    function handleCurrentChange(currentPage) {
      dataState.page.current = currentPage;
      onLoad();
    }
    function handleSizeChange(pageSize) {
      dataState.page.limit = pageSize;
      onLoad();
    }

    function install(){
      window.open('/api/resource/preinstallation/preinstallationTemplate');
    }

    function addExcel(){
      const inputObj= document.createElement('input')
      inputObj.setAttribute('type','file');
      inputObj.setAttribute("style",'visibility:hidden');
      document.body.appendChild(inputObj);
      inputObj.click();
      inputObj.onchange = function(re){
        let formData = new FormData();
        formData.append("file", re.target.files[0]);
        addPreinstallationImportExcel(formData).then(res=>{
          if(res.data.success){
            ElMessage({message: res.data.code.message, type: 'success'});
            dataState.page.current = 1;
            onLoad();
          }
        })
      }
    }

    onMounted(()=>{
      onLoad();
      terminalModel().then(res=>{
        dialogState.modelList = res.data.data;
      })
    })
    return{
      onSearch,
      resetForm,
      dialogOpen,
      add,
      sendData,
      rules,
      dialogVisible,
      ruleForm,
      title,
      addExcel,
      install,
      handleCurrentChange,
      handleSizeChange,
      ...toRefs(dataState),
      ...toRefs(state),
      ...toRefs(dialogState)
    }

  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-radio-group{
  display: flex;
  align-items: center;
}
.row-acitve{
  border-bottom: 1px solid rgba(#e6e6e6, .2);
}
.title{
  line-height: 48px;
  text-align: left;
  font-size: 20px;
  color: #0f74a8;
  &.top{
    color: red;
  }
}
</style>