<template>
  <div class="main-box">
    <div class="l">
      <div class="inbox">
        <span>摄像头列表</span>
        <section class="gjz">
          <img src="../../assets/ss.png">
          <input placeholder="请输入区域关键字" v-model="gjz"/>
        </section>
        <div class="listbox">
          <div style="margin-top: 10px" v-for="(item,index) in cameraInfo" :key="index" @click="chooseCamera(item)">
            <div class="top">
              <span>{{ item.cname }}</span>
              <span>{{ item.title }}</span>
            </div>
            <div class="bom">安装地址:{{ item.installAddress || "暂无安装位置" }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="mid">
      <div class="choose">
        <div class="li" v-for="(item,index) in chooseList" :key="index" :class="index == idx && 'active'"
             @click="changeList(index)">
          {{ item }}
        </div>
      </div>

      <div class="viode-screen" id="screen">
        <!--   单屏    -->
        <div class="frame" v-show="idx == 0" @click="nowChoose = 0">
          <div class="iframe" @click="onIframe(viodeList[0])" v-if="viodeList[0]">
            <iframe frameborder="0" style="pointer-events: auto" height="100%" width="100%" :src="viodeList[0]?.serial" allowfullscreen="true"></iframe>
          </div>
        </div>

        <div class="frame" v-show="idx == 1" v-for="(item,index) in 4" :key="index" @click="nowChoose = index">
          <div class="iframe" v-if="viodeList[index]" @click="onIframe(viodeList[index])">
            <iframe frameborder="0" style="pointer-events: auto" height="100%" width="100%" :src="viodeList[index]?.serial" allowfullscreen="true"></iframe>
          </div>
        </div>

        <div class="frame" v-show="idx == 2" v-for="(item,index) in 9" :key="index" @click="nowChoose = index">
          <div class="iframe" v-if="viodeList[index]" @click="onIframe(viodeList[index])">
            <iframe frameborder="0" style="pointer-events: auto" height="100%" width="100%" :src="viodeList[index]?.serial" allowfullscreen="true"></iframe>
          </div>
        </div>

        <!--   十六分屏    -->
        <div class="frame" v-show="idx == 3" v-for="(item,index) in 16" :key="index" @click="nowChoose = index">
          <div class="iframe" v-if="viodeList[index]" @click="onIframe(viodeList[index])">
            <iframe frameborder="0" style="pointer-events: auto" height="100%" width="100%" :src="viodeList[index]?.serial" allowfullscreen="true"></iframe>
          </div>
        </div>
      </div>
    </div>
    <div class="r">
      <div class="r-top">
        <div class="in-box">
          <div class="head">
            摄像头绑定设备信息
          </div>

          <div class="info-list" v-for="(item,index) in detailList" :key="index">
            <div>
              <span>终端名称</span>
              <span>{{ item.resourceName }}</span>
            </div>
            <div>
              <span>终端资源码</span>
              <span>{{ item.resourceCode }}</span>
            </div>
            <div>
              <span>摄像头名称</span>
              <span>{{ item.cname }}</span>
            </div>
            <div>
              <span>摄像头类型</span>
              <span>{{ item.title }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="r-bom">
        <div class="in-box">
          <div class="head">
            播控信息
            <el-button type="success" size="small" @click="sx">刷新</el-button>
          </div>
          <div>
            <div v-if="issemsg" style="font-size: 20px;color:#32B89C;text-align: left;">{{semsg}}</div>
            <div v-else style="font-size: 20px;color:red;text-align: left;">{{semsg}}</div>
          </div>
          <div v-if="viodeList2.length > 0" style="font-size: 20px;color:#32B89C;text-align: left;" >
            <div>{{viodeList2[0]?.msg}}</div>
            <div><span>设备名称:</span><div>{{viodeList2[0]?.resource_name}}</div></div>
            <div><span>资源码:</span><div>{{viodeList2[0]?.resource_code}}</div></div>
            <div><span>物理码:</span><div>{{viodeList2[0]?.resource_mac}}</div></div>
            <div><span>广播编号:</span><div style="word-break:break-all;width: 100%">{{viodeList2[0]?.ebm_id}}</div></div>

          </div>
        </div>
      </div>

      <div class="btns">
        <div class="btn1" @click="onStop">话筒关闭</div>
        <div class="btn2" @click="onStart">话筒开启</div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, ref, toRefs, onMounted, nextTick, watch } from "vue";
import { cameraList, cameraDetail, startBCast, stopBCast, videoDetail } from "@/api/viode/show";
import { ElMessage } from "element-plus";
import { IframeOnClick } from "@/util/util";

export default {
  setup() {
    let nowChoose = ref(0);
    let idx = ref(3);
    let state = reactive({
      issemsg:false,
      semsg:"",
      gjz:"",
      isplayer:false,
      nowViode: {},
      detailList: [],
      viodeList2:[],
      viodeList: [],
      cameraInfo: [],
      cameraInfo2: [],
      chooseList: ["单屏", "4分屏", "9分屏", "16分屏"]
    });

    watch(()=> state.gjz, val => {
      let arr = state.cameraInfo2.filter(item=>item.cname.indexOf(val) != -1);
      state.cameraInfo = arr;
    })

    function changeList(_index) {
      if(state.isplayer) return;
      cameraList().then(res => state.cameraInfo = state.cameraInfo2 = res.data.data);
      idx.value = _index;
      let action = new Map([
        [0, "viode-screen one"],
        [1, "viode-screen four"],
        [2, "viode-screen nine"],
        [3, "viode-screen sixteen"]
      ]);

      this.viodeList2 = [];
      this.semsg = "";
      this.detailList = [];
      var dom = document.getElementById("screen");
      dom.setAttribute("class", action.get(_index));
      nowChoose.value = 0;
      state.viodeList = [];
    }

    onMounted(() => {
      cameraList().then(res => state.cameraInfo = state.cameraInfo2 = res.data.data);
      videoDetail().then(res => {
        if(res.data.data.camera_id){
          idx.value = 0;
          var dom = document.getElementById("screen");
          dom.setAttribute("class", 'viode-screen one');
          res.data.data.serial = res.data.data.serial.replace(/&ptz=false/g,"")
          state.viodeList = [res.data.data];
          state.isplayer = true;
          state.viodeList2 = [res.data.data];
          state.nowViode.id = res.data.data.camera_id;
          cameraDetail({
            id: res.data.data.camera_id
          }).then(res => {
            state.detailList = res.data.data;
          });
        }
      });

    });

    function onIframe(item, index) {
      cameraDetail({
        id: item.id
      }).then(res => {
        state.nowViode = item;
        state.detailList = res.data.data;
      });
    }

    async function chooseCamera(row) {
      if(state.isplayer) return;
      if(idx.value == 0){
        cameraDetail({
          id: row.id
        }).then(res => {
          state.detailList = res.data.data;
        });
        row.serial = row.serial.replace(/&ptz=false/g,"");
      };

      state.viodeList[nowChoose.value] = row;
      if (idx.value == 1 && nowChoose.value < 4) {
        nowChoose.value += 1;
        if (nowChoose.value == 4) nowChoose.value = 0;
      } else if (idx.value == 2 && nowChoose.value < 9) {
        nowChoose.value += 1;
        if (nowChoose.value == 9) nowChoose.value = 0;
      } else if (idx.value == 3 && nowChoose.value < 16) {
        nowChoose.value += 1;
        if (nowChoose.value == 16) nowChoose.value = 0;
      }
      let op = new Map([
        [3,1],
        [2,2],
        [1,3],
        [0,4],
      ])
      nextTick(()=>{
        var iframedom = document.getElementsByTagName("iframe");
        IframeOnClick.track(iframedom[iframedom.length-op.get(idx.value)], function() {
          onIframe(row)
        });
      })
    }

    function onStop() {
      stopBCast({ id: state.viodeList[0].camera_id }).then(res => {
        this.semsg = res.data.code.message;
        this.issemsg = false;
        if (res.data.success) {
          ElMessage({ message: '关闭话筒成功！', type: "success" });
          state.isplayer = false;
          state.viodeList2 = [];
          this.issemsg = true;
        }
      });
    }

    function onStart() {
      // if (!state.nowViode.id) return;
      startBCast({ id: state.nowViode.id }).then(res => {
        this.semsg = res.data.code.message;
        this.issemsg = false;
        if (res.data.success) {
          this.issemsg = true;
          setTimeout(()=>{
            videoDetail().then(res => {
              if(res.data.data.camera_id){
                idx.value = 0;
                var dom = document.getElementById("screen");
                dom.setAttribute("class", 'viode-screen one');
                res.data.data.serial = res.data.data.serial.replace(/&ptz=false/g,"")
                state.viodeList = [res.data.data];
                state.viodeList2 = [res.data.data];
                state.isplayer = true;
                cameraDetail({
                  id: res.data.data.camera_id
                }).then(res => {
                  state.detailList = res.data.data;
                });
              }
            });
          },2000)
        }
      });
    }


    function sx(){
      videoDetail().then(res => {
        if(res.data.data.camera_id){
          idx.value = 0;
          var dom = document.getElementById("screen");
          dom.setAttribute("class", 'viode-screen one');
          res.data.data.serial = res.data.data.serial.replace(/&ptz=false/g,"")
          state.viodeList = [res.data.data];
          state.viodeList2 = [res.data.data];
          state.isplayer = true;
          cameraDetail({
            id: res.data.data.camera_id
          }).then(res => {
            state.detailList = res.data.data;
          });
        }
      });
    }

    return {
      idx,
      nowChoose,
      sx,
      onStart,
      onStop,
      onIframe,
      changeList,
      chooseCamera,
      ...toRefs(state)
    };
  }
};
</script>
<style lang="less" scoped>
.main-box {
  width: 1690px;
  display: grid;
  grid-template-columns: 320px 1000px 350px;
  grid-column-gap: 10px;

  .l {
    width: 100%;
    height: calc(1000px - 50px - 48px);
    background: url("../../assets/left_regionequip.png") no-repeat center;
    background-size: 100% 100%;

    .inbox {
      margin: 20px 10px;
      text-align: left;
      color: #32B89C;
      font-size: 18px;

      .gjz {
        margin-top: 23px;
        width: 300px;
        height: 40px;
        background: #072724;
        border: 1px solid #184E3F;
        display: flex;
        align-items: center;

        > img {
          margin: 11px 18px 11px 11px;
          width: 20px;
          height: 20px;
        }

        > input {
          background: #072724;
        }
      }

      .listbox {
        width: 100%;
        height: 780px;
        overflow: auto;
        > div {
          height: 67px;
          border-bottom: 1px solid #126B51;

          &:first-child {
            margin-top: 18px;
          }

          .top {
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
              &:nth-child(1) {
                color: #00FFC6;
                font-size: 18px;
              }

              &:nth-child(2) {
                display: block;
                width: 110px;
                height: 24px;
                background: #072724;
                color: #184E3F;
                font-size: 14px;
                text-align: center;
              }
            }
          }

          .bom {
            margin-top: 8px;
            color: #59B7A4;
            font-size: 14px;
          }
        }
      }
    }
  }

  .mid {
    width: 100%;
    height: calc(1000px - 50px - 54px);
    background: #0a2b28;
    border: 1px solid #178164;
    box-shadow: 0px 10px 0px #0e3d34 inset;

    .choose {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 30px;

      .li {
        width: 95px;
        height: 44px;
        background: #0A2B28;
        border: 2px solid #12483E;
        line-height: 44px;
        text-align: center;
        font-size: 20px;
        color: #266356;

        &.active {
          background: #107562;
          color: #ADFAF3;
          transition: all .5s;
        }

        &:hover {
          cursor: pointer;
        }

        &:nth-child(even) {
          border-right: none;
          border-left: none;
        }

        &:last-child {
          border-right: 2px solid #12483E;
        }
      }
    }

    .viode-screen {
      padding: 10px;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(4, 178px);
      grid-gap: 10px 10px;

      .frame {
        background: #071D1B;

        .iframe {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &.one {
        grid-template-columns: 1fr;
        grid-template-rows: (178px * 4) + 20px;
      }

      &.four {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 366px);
      }

      &.nine {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 240px);
      }

      &.sixteen {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(4, 178px);
      }
    }
  }

  .r {
    width: 100%;
    height: calc(1000px - 50px - 48px);

    .r-top, .r-bom {
      width: 100%;
      height: 449.5px;
      background: url("../../assets/left_regionequip.png") no-repeat center;
      background-size: 100% 100%;
      overflow: auto;
      .in-box {
        padding: 0 20px 10px 20px;
        width: 100%;
        height: 100px;

        .head {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 0 auto;
          width: 100%;
          height: 50px;
          border-bottom: 1px #126B51 dashed;
          line-height: 50px;
          text-align: left;
          font-size: 18px;
          font-weight: 300;
          color: #32B89C;
        }

        .info-list {
          height: auto;
          border-bottom: 1px silver dashed;

          > div {
            margin: 5px 0;
            text-align: left;
            display: flex;
            flex-direction: column;
            justify-content: center;

            span:nth-child(1) {
              font-size: 16px;
              color: #87c5b7;
            }

            span:nth-child(2) {
              font-size: 18px;
              color: #02d0a2;
            }
          }
        }
      }
    }

    .r-bom {
      height: calc(449.5px - 83px);
    }

    .btns {
      width: 100%;
      height: 83px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .btn1, .btn2 {
        width: 134px;
        height: 50px;
        background-size: 100% 100%;
        font-size: 20px;
        line-height: 50px;
        cursor: pointer;
      }

      .btn1 {
        background: url("../../assets/btn1.png") no-repeat center;
        color: #00FFC6;
      }

      .btn2 {
        background: url("../../assets/btn2.png") no-repeat center;
        color: #E8BD3B;
      }
    }
  }
}
</style>