import axios from "@/api/axios"; // 引用axios

export const getData = (params) => {
    return axios({
        url: "/api/safety/sensitive/listData",
        method: "post",
        params: params,
    });
};

export const dataSave = (params) => {
    return axios({
        url: "/api/safety/sensitive/addData",
        method: "post",
        params: params,
    });
};

export const getEdit = (params) => {
    return axios({
        url: "/api/safety/sensitive/editData",
        method: "post",
        params: params,
    });
};

export const getdel = (params) => {
    return axios({
        url: "/api/safety/sensitive/del",
        method: "get",
        params: params,
    });
};