<template>
  <div class="formContainer">
    <div class="main">
      <el-tree :data="regionList" :props="treeSelectProps" show-checkbox node-key="code" accordion ref="tree"
               default-expand-all @current-change="currentChange" @check-change="checkChange" check-on-click-node />
    </div>
    <div class="formBox">
      <div class="aimTitle">
        <el-tag class="ml-2" type="success">回传设置</el-tag>
        <span class="introduce">目的： 操作更改设备回传参数</span>
        <span class="introduce">设置： 直接对设备进行控制</span>
      </div>
      <el-form
        :model="form"
        :label-width="labelWidth"
        ref="addForm"
      >
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item
              label="回传类型"
              prop="rebackAddressType"
              :rules="[{ required: true, message: '回传类型 不能为空' }]"
            >
              <el-select
                :fit-input-width="true"
                v-model="form.rebackAddressType"
                placeholder="请选择 回传类型"
              >
                <el-option :label="item.name" :value="item.code" v-for="(item,index) in paramsList"
                           :key="index"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="phoneDis">
            <el-form-item
              label="电话"
              prop="phone"
              :rules="[{ required: true, message: '电话 不能为空' }]"
            >
              <el-input
                clearable
                autocomplete="off"
                v-model="form.phone"
                placeholder="请输入 电话"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12"  v-if="ipDis">
            <el-form-item
              label="ip"
              prop="ip"
              :rules="[{ required: true, message: 'ip 不能为空' }]"
            >
              <el-input
                clearable
                autocomplete="off"
                v-model="form.ip"
                placeholder="请输入 ip"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="ipDis">
            <el-form-item
              label="ip端口"
              prop="ip_port"
              :rules="[{ required: true, message: 'ip端口 不能为空' }]"
            >
              <el-input
                clearable
                autocomplete="off"
                v-model="form.ip_port"
                placeholder="请输入 ip端口"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12"  v-if="domainDis">
            <el-form-item
              label="域名"
              prop="domain"
              :rules="[{ required: true, message: '域名 不能为空' }]"
            >
              <el-input
                clearable
                autocomplete="off"
                v-model="form.domain"
                placeholder="请输入 域名"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="domainDis">
            <el-form-item
              label="域名端口"
              prop="domain_port"
              :rules="[{ required: true, message: '域名端口 不能为空' }]"
            >
              <el-input
                clearable
                autocomplete="off"
                v-model="form.domain_port"
                placeholder="请输入 域名端口"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!--          </el-col>-->
        <!--          <el-col :span="6">-->
        <el-form-item
          label="发送地址"
          prop="regionCodes"
          :rules="[{ required: true, message: '发送地址 不能为空' }]"
        >
          <el-input
            clearable
            type="textarea"
            autocomplete="off"
            v-model="nameStr"
            placeholder="请输入 发送地址"
          >
          </el-input>
        </el-form-item>
        <!--          </el-col>-->

        <!--          <el-col :span="6">-->
        <!--              <el-form-item>-->
        <el-button plain @click="addForm('addForm')" class="searchBtn">提交</el-button>


        <!--              </el-form-item>-->
        <!--          </el-col>-->
        <!--        </el-row>-->
      </el-form>
    </div>
  </div>
</template>

<script>
import { getRegionEquip } from "@/api/system/region";
import { passBack, passBackParams } from "@/api/terminalRegulate/generalVolume";
import { ref, reactive, watch } from "vue";

export default {
  name: "returnSet",
  setup() {
    const form = reactive({});
    const domainDis = ref(true);
    const ipDis = ref(true);
    const phoneDis = ref(true);
    watch(() => form.rebackAddressType,
      (val, oldVal) => {
        if (val == "phone") {
          console.log(phoneDis);
          phoneDis.value = true;
          domainDis.value = false;
          ipDis.value = false;
        } else if (val == "ipAndPort") {
          phoneDis.value = false;
          domainDis.value = false;
          ipDis.value = true;
        } else if (val == "domainAndPort") {
          phoneDis.value = false;
          domainDis.value = true;
          ipDis.value = false;
        }
      });
    return { form, domainDis, phoneDis, ipDis };
  },
  data() {
    return {
      code: [],
      codeList: [],
      paramsList: [],
      labelWidth: "120px",
      regionList: [],
      treeSelectProps: {
        label: "name",
        value: "id",
        key: "code",
        children: "children"
      }
    };
  },
  computed: {
    nameStr() {
      let arr = [];
      let code = [];
      this.codeList.map(item => {
        arr.push(item.name);
        code.push(item.code);
      });

      return arr.join(",");
    }
  },
  created() {
    getRegionEquip().then(res => {
      if (res.data.success) {
        this.regionList = res.data.data.list;
      }
    });
    passBackParams().then(res => {
      if (res.data.success) {
        this.paramsList = res.data.data.rebackAddressType;
      }
    });
  },
  methods: {
    addForm(formName) {
      let arr = [];
      this.codeList.map(item => {
        arr.push(item.code);
      });
      this.form.regionCodes = arr.join(",");
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.form);
          passBack(this.form).then(res => {
            if (res.data.success) {
              this.$message.success("操作成功！");
              this.$refs[formName].resetFields();
              this.$refs.tree.setCheckedKeys([]);
            }
          });
        }
      });
    },
    getSimpleCheckedNodes(store) {
      const checkedNodes = [];
      const traverse = function(node) {
        const childNodes = node.root ? node.root.childNodes : node.childNodes;
        childNodes.forEach(child => {
          if (child.checked) {
            checkedNodes.push(child.data);
          }
          if (child.indeterminate) {
            traverse(child);
          }
        });
      };
      traverse(store);
      return checkedNodes;
    },
    checkChange(node, status) {
      this.codeList = this.getSimpleCheckedNodes(this.$refs.tree.store);
    }
  }
};
</script>

<style scoped lang="scss">
.formContainer {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.formBox {
  width: 1000px;
}

.ml-2 {
  margin-bottom: 40px;
}

.introduce {
  color: #1C9C77;
}

.main {
  width:300px;
  ::-webkit-scrollbar {
    width: 0;
  }
}

.el-tree {
  height: 700px !important;
  overflow-y: scroll;
}

.el-tree-node__content {
  margin-top: 20px !important;
}


.aimTitle span {

  margin-right: 30px;
}

.aimTitle {
  text-align: left;
}
</style>