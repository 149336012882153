import axios from "@/api/axios"; // 引用axios
//获取媒体资源列表
export const getList = (page, limit, params) => {
  const param = {
    ...params,
    page,
    limit,
  };
  return axios({
    url: "/api/tar/type/listData",
    method: "post",
    params: param,
  });
};
//切换状态提示
export const changeStatus = (params) => {
  return axios({
    url: "/api/tar/type/editData",
    method: "post",
    params: params,
  });
};