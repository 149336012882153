export const ipvalidatePass = (rule, value, callback) => {
    var reg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/
    if (!reg.test(value)) {
        callback(new Error('请输入 正确的地址'));
    } else {
        callback();
    }
};

export const maskvalidatePass = (rule, value, callback) => {
    var reg= /^(254|252|248|240|224|192|128|0)\.0\.0\.0$|^(255\.(254|252|248|240|224|192|128|0)\.0\.0)$|^(255\.255\.(254|252|248|240|224|192|128|0)\.0)$|^(255\.255\.255\.(254|252|248|240|224|192|128|0))$/
    if (!reg.test(value)) {
        callback(new Error('请输入 正确的掩码'));
    } else {
        callback();
    }
};

export const numbervalidatePass = (rule, value, callback) => {
    var reg= /^[1-9]+[0-9]*]*$/
    if (!reg.test(value)) {
        callback(new Error('输入不正确'));
    } else {
        callback();
    }
};
