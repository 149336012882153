<template>
  <div class="searchBox">
    <el-form :model="search" :label-width="searchLabelWidth" ref="searchForm">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item
            label="设备类型"
            prop="code"
          >
            <el-select v-model="search.code" placeholder="请选择 设备类型" >
              <!--              <el-option-->
              <!--                v-for="item in equipType"-->
              <!--                :key="item.id"-->
              <!--                :label="item.name"-->
              <!--                :value="item.subtypeCode"-->
              <!--              >-->
              <!--              </el-option>-->
              <el-option label="适配器" :value="'02'"/>
              <el-option label="传输覆盖设备" :value="'03'"/>
              <el-option label="终端" :value="'04'"/>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <!--              <el-form-item>-->
          <el-button plain @click="searchFormFn('searchForm')" class="searchBtn" icon="Search"
          >查询
          </el-button
          >
<!--          <el-button plain @click="resetForm('searchForm')" class="resetBtn"-->
<!--          >清空-->
<!--          </el-button-->
<!--          >-->

          <!--              </el-form-item>-->
        </el-col>
      </el-row>
    </el-form>
    <div class="divide">
      <img src="../../assets/divide.png" style="width: 100%" />
    </div>
    <div class="btnContainer">
      <div class="btnLeft">
        <el-button
          @click="handleInfoUp(1)"
          class="searchBtn"
        >状态上报
        </el-button>
        <el-button
          @click="handleInfoUp(2)"
          class="searchBtn"
        >信息上报
        </el-button>
      </div>
    </div>
  </div>
  <basicTable
    :attrList="attrList"
    :tableData="tableData"
    ref="table"
    :page="page.current"
    :limit="page.limit"
    :total="page.total"
    @currentChange="handleCurrentChange"
    @sizeChange="handleSizeChange"
    @selectChange="handleSelectChange"
  >
  </basicTable>
</template>

<script>
import { onMounted, reactive, ref, toRefs } from "vue";
import { ElMessage } from "element-plus";
import { getEquipType } from "@/api/basic/equipType";
import { infoUp, statusUp,getList } from "@/api/system/equipInfo";

export default {
  name: "equipInfo",
  setup() {
    const option = reactive({
      form: {},
      searchLabelWidth: "140px",
      search: {
        code:"02"
      },
      page: {
        current: 1,
        limit: 10,
        total: 0
      },
      tableData: [],
      equipType: [],
      ids: [],
      attrList: [
        {
          label: "设备名称",
          prop: "name",
          render: (row) => (<div>{row.name}</div>)
        }, {
          label: "资源编码",
          prop: "code",
          render: (row) => (<div>{row.code}</div>)
        },
        {
          label: "经度",
          prop: "longitude",
          render: (row) => <div>{row.longitude}</div>
        }, {
          label: "纬度",
          prop: "latitude",
          render: (row) => <div>{row.latitude}</div>
        }, {
          label: "启用状态",
          prop: "isForbidden",
          render: (row) => {
            if (row.isForbidden) return <div>禁用</div>;
            else return <div>启用</div>;
          }
        }
      ]
    });
    const searchForm = ref();

    function getData(page) {
      getList(page.current, page.limit, option.search).then(res => {
        if (res.data.success) {
          option.tableData = res.data.data.list;
          option.page.total = res.data.data.count;
        }
      });
    }

    function handleSelectChange(val) {
      option.ids = val;
    }

    function searchFormFn() {
      option.page.current = 1;
      getData(option.page);
    }

    function handleCurrentChange(currentPage) {
      option.page.current = currentPage;
      getData(option.page);
    }

    function handleSizeChange(pageSize) {
      option.page.limit = pageSize;
      getData(option.page);
    }

    function resetForm() {
      searchForm.value.resetFields();
      option.page.current = 1;
      getData(this.page);
    }

    function handleInfoUp(num) {
      let post = { ...Object.assign({}, { list: [1], page: 1 }) };
      if (!option.search.code) return ElMessage({
        type: "error",
        message: "请选择 设备类型"
      });
      if (!option.ids.length) return ElMessage({
        type: "error",
        message: "请至少选择一条数据"
      });
      let arr = [];
      option.ids.forEach(item => arr.push(item.id));
      let fn;
      fn = num == 1 ? statusUp : infoUp;
      fn({ type: option.search.code, ids: arr.join(",") }).then(res => {
        if (res.data.success) {
          getData(option.page);
          ElMessage({
            type: "success",
            message: "操作成功！"
          });
        }
      });
    }

    // function handleStatus(id, status) {
    //   status = status == 1 ? 2 : 1;
    //   changeStatus({ id, status }).then(res => {
    //     if (res.data.success) {
    //       getData(option.page);
    //       ElMessage({
    //         type: "success",
    //         message: "操作成功！"
    //       });
    //     }
    //   });
    // }


    onMounted(async () => {
      getEquipType().then(res => {
        if (res.data.success) {
          option.equipType = res.data.data;
        }
      });
      getData(option.page);
    });
    return {
      ...toRefs(option),
      getData,
      searchForm,
      searchFormFn,
      resetForm,
      handleSelectChange,
      handleInfoUp,
      handleCurrentChange,
      handleSizeChange
    };
  }
};
</script>

<style scoped>

</style>