import axios from "@/api/axios"; // 引用axios
//获取定时广播分页
export const getList = (page, limit, params) => {
  const param = {
    ...params,
    page,
    limit
  };
  return axios({
    url: "/api/timing/getTimedBroadcastList",
    method: "post",
    params: param
  });
};
//新增
export const addData = (params) => {
  return axios({
    url: "/api/timing/addTimingBroadcast",
    method: "post",
    data: params
  });
};
//删除
export const delData = (id) => {
  return axios({
    url: "/api/timing/delTimingBroadcast",
    method: "post",
    params: { id }
  });
};
//启用或禁用
export const updateStatus = (type) => {
  return axios({
    url: "/api/timing/timingBroadcastDisable",
    method: "post",
    params: type
  });
};
//获取详情
export const getDetail = (id) => {
  return axios({
    url: "/api/timing/getTimingBroadcastById",
    method: "post",
    params: { id }
  });
};
