import axios from "@/api/axios"; // 引用axios
//获取分页数据
export const getList = (page, limit, params) => {
  const param = {
    ...params,
    page,
    limit,
  };
  return axios({
    url: "/api/resource/type/listData",
    method: "post",
    params: param,
  });
};
//新增
export const addData = (params) => {
  return axios({
    url: "/api/resource/type/addData",
    method: "post",
    params: params,
  });
};
//编辑
export const editData = (params) => {
  return axios({
    url: "/api/resource/type/editData",
    method: "post",
    params: params,
  });
};
//删除
export const delData = (id) => {
  return axios({
    url: "/api/resource/type/del",
    method: "post",
    params: { id },
  });
};
//详情
export const getDetail = (id) => {
  return axios({
    url: "/api/resource/type/detail",
    method: "post",
    params: { id },
  });
};
//获取资源子类型
export const getChildList = (params = {}) => {
  return axios({
    url: "/api/resource/type/codeType",
    method: "post",
    params: params,
  });
};

//获取所有设备类型
export const getEquipType = (params = {}) => {
  return axios({
    url: "/api/resource/model/resourceType",
    method: "post",
    params: params,
  });
};
