<template>
  <div class="searchBox">
    <el-form :model="search" :label-width="searchLabelWidth" ref="searchForm">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="节目名称" prop="name">
            <el-input
              v-model="search.name"
              autocomplete="off"
              placeholder="请输入 节目名称"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <!--              <el-form-item>-->
          <el-button @click="searchForm('searchForm')" class="searchBtn"
            >查询</el-button
          >
          <el-button @click="resetForm('searchForm')" class="resetBtn"
            >重置</el-button
          >

          <!--              </el-form-item>-->
        </el-col>
      </el-row>
    </el-form>
    <div class="divide">
      <img src="../../assets/divide.png" style="width: 100%" />
    </div>
    <div class="btnContainer">
      <div class="btnLeft">
        <el-button icon="Plus" @click="addFormVisible = true" class="searchBtn"
          >新增</el-button
        >
      </div>
    </div>
  </div>
  <basicTable
    tableBoxHeight="660px"
    height="570"
    :indexshow="false"
    :attrList="attrList"
    :tableData="tableData"
    ref="table"
    :page="page.current"
    :limit="page.limit"
    :total="page.total"
    @currentChange="handleCurrentChange"
    @sizeChange="handleSizeChange"
  >
  </basicTable>
  <el-dialog
    width="75%"
    v-model="addFormVisible"
    title="新增"
    :close-on-click-modal="false"
    :before-close="beforeClose"
  >
    <el-row :gutter="24">
      <el-col :span="14">
        <el-card class="box-card">
          <template #header class="card-header">
            <span>媒体资源</span>
          </template>
          <div class="topb">
            <el-select style="width: 300px !important" v-model="typeChange" class="m-2" placeholder="请选择">
              <el-option v-for="item in sourceType" :key="item.value" :label="item.name" :value="item.value"/>
            </el-select>
            <div class="addBtn">
              <el-button type="primary" @click="selectMedia">添加</el-button>
            </div>
          </div>
          <basicTable
            tableBoxHeight="540px"
            height="450"
            :indexshow="false"
            :attrList="voiceattrList"
            :tableData="voicetableData"
            ref="voicetable"
            :page="voicepage.current"
            :limit="voicepage.limit"
            :total="voicepage.total"
            @selectChange="handleSelectionChange"
            @currentChange="voicehandleCurrentChange"
            @sizeChange="voicehandleSizeChange"
          >
          </basicTable>
        </el-card>
      </el-col>
      <el-col :span="10">
        <el-card class="box-card">
          <template #header class="card-header">
            <span>新增节目</span>
          </template>
          <div class="addTable">
            <el-table
              ref="table"
              cell-class-name="tdClass"
              header-cell-class-name="HtdClass"
              header-row-class-name="tableHeader"
              :data="hastableData"
              style="width: 100%"
              align="center"
              empty-text="暂无内容"
              border
              :height="340"
            >
              <el-table-column prop="display_name" label="资源名称" align="center">
                <template #default="scope">
                  <span>{{ scope.row.display_name }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="time_length" label="播放时长" align="center">
                <template #default="scope">
                  <span>{{ scope.row.time_length }}</span>
                </template>
              </el-table-column>

              <el-table-column label="操作" align="center">
                <template #default="scope">
                  <el-button
                    type="text"
                    icon="Delete"
                    @click="handleDeletehas(scope.$index, scope.row)"
                  ></el-button>
                </template>
              </el-table-column>
            </el-table>


            <el-form
              class="addForm"
              label-position="right"
              :model="addForm"
              size="small"
              :label-width="formLabelWidth"
              ref="addForm"
            >
              <el-form-item v-if="typeChange == 3" label="滚动字幕" :rules="[{ required: true, message: '滚动字幕 不能为空' }]" prop="scrollSubtitles">
                <el-input v-model="addForm.scrollSubtitles" autocomplete="off" placeholder="请输入 滚动字幕"></el-input>
              </el-form-item>
              <!--              <el-row :gutter="20">-->
              <!--                <el-col :span="24">-->
              <el-form-item
                label="节目名称"
                :rules="[{ required: true, message: '节目名称 不能为空' }]"
                prop="name"

              >
                <el-input placeholder="请输入 节目名称" v-model="addForm.name" autocomplete="off"></el-input>
              </el-form-item>
              <!--                </el-col>-->
              <!--                <el-col :span="12">-->
              <el-form-item
                label="访问级别"
                :rules="[{ required: true, message: '访问级别 不能为空' }]"
                prop="access_level"
              >
                <el-select
                  v-model="addForm.access_level"
                  placeholder="请选择 访问级别"
                  clearable
                >
                  <el-option label="私有" value="private"></el-option>
<!--                  <el-option label="本级" value="protect"></el-option>-->
                  <el-option label="所有" value="public"></el-option>
                </el-select>
              </el-form-item>
              <!--                </el-col>-->
              <!--              </el-row> <el-row :gutter="20">-->
              <!--              <el-col :span="12">-->
              <el-form-item
                label="播放模式"
                :rules="[{ required: true, message: '播放模式 不能为空' }]"
                prop="play_mode"
              >
                <el-select
                  v-model="addForm.play_mode"
                  placeholder="请选择 播放模式"
                  clearable
                >
                  <el-option label="循环" value="loop"></el-option>
<!--                  <el-option label="一次" value="once"></el-option>-->
                </el-select>
              </el-form-item>
              <!--              </el-col>-->
              <!--            </el-row>-->
            </el-form>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <template #footer>
      <div class="dialog-footer">
        <el-button type="primary" size="small" @click="submitAddForm('addForm')"
          >提交
        </el-button>
        <el-button @click="addFormVisible = false" size="small" type="info"
          >取消
        </el-button>
      </div>
    </template>
  </el-dialog>






  <el-dialog
    width="40%"
    v-model="detailFormVisible"
    title="详情"
    :close-on-click-modal="false"
  >
    <div class="addTable">
      <el-table
        ref="detailtable"
        cell-class-name="tdClass"
        header-cell-class-name="HtdClass"
        header-row-class-name="tableHeader"
        :data="detailtableData"
        style="width: 100%"
        align="center"
        empty-text="暂无内容"
        border
        :height="370"
      >
        <el-table-column prop="mediaName" label="节目名称" align="center">
          <template #default="scope">
            <span>{{ scope.row.mediaName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="timeLength" label="播放时长" align="center">
          <template #default="scope">
            <span>{{ scope.row.timeLength }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="mediaAddr"
          label="资源"
          align="center"
          width="300"
        >
          <template #default="scope">
            <audio
              v-if="issourceType == 1"
              ref="audio"
              controls
              style="width: 100%"
              :src="scope.row.mediaAddr"
            ></audio>

            <el-button v-else-if="issourceType == 2" type="text" @click="showviode(scope.row.mediaAddr)">查看视频</el-button>
            <el-button v-else type="text" @click="showImage(scope.row.mediaAddr)">查看图片</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-form
        class="addForm"
        label-position="right"
        :model="detailForm"
        size="small"
        :label-width="formLabelWidth"
        ref="addForm"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              label="节目名称"
              :rules="[{ required: true, message: '节目名称 不能为空' }]"
              prop="name"
            >
              <el-input
                v-model="detailForm.name"
                autocomplete="off"
                readonly
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="访问级别"
              :rules="[{ required: true, message: '访问级别 不能为空' }]"
              prop="access_level"
            >
              <el-select
                v-model="detailForm.access_level"
                :disabled="true"
                placeholder="请选择 访问级别"
                clearable
                aria-readonly="true"
              >
                <el-option label="私有" value="private"></el-option>
<!--                <el-option label="本级" value="protect"></el-option>-->
                <el-option label="所有" value="public"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              label="播放模式"
              :rules="[{ required: true, message: '播放模式 不能为空' }]"
              prop="play_mode"
            >
              <el-select
                  :disabled="true"
                  v-model="detailForm.play_mode"
                  placeholder="请选择 播放模式"
                  clearable
                  aria-readonly="true"
              >
                <el-option label="循环" value="loop"></el-option>
<!--                <el-option label="一次" value="once"></el-option>-->
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12" v-if="issourceType == 3">
            <el-form-item label="滚动字幕" prop="scrollSubtitles">
              <el-input v-model="detailForm.scrollSubtitles" autocomplete="off" readonly></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </el-dialog>

  <el-dialog append-to-body v-model="viodeVisible" :title="btitle" width="30%">
    <video width="500" height="200" style="margin: 0 auto" controls :src="viodeUrl" v-if="btitle == '查看视频'"></video>
    <el-image v-else style="width: 500px; height: 200px" :src="viodeUrl" :preview-src-list="[viodeUrl]"></el-image>
  </el-dialog>
</template>

<script>
import {
  getList,
  getmediaList,
  addData,
  getDetail,
} from "@/api/dailyBroad/radioShow";
import { getSourceType } from "@/api/dailyBroad/mediaResources";
import { ref } from "vue";
import { delData } from "../../api/dailyBroad/radioShow";
import { ElMessage } from 'element-plus'
export default {
  name: "radioShow",
  data() {
    return {
      btitle:"",
      issourceType:false,
      viodeVisible:false,
      viodeUrl:"",
      typeChange:1,
      sourceType:[],
      addForm: {},
      detailForm: {},
      hastableData: [],
      detailtableData: [],
      selectionList: [],
      formLabelWidth: "140px",
      addFormVisible: false,
      detailFormVisible: false,
      title: "新增",
      form: {},
      tform: {},
      searchLabelWidth: "140px",
      search: {},
      page: {
        current: 1,
        limit: 10,
        total: 0,
      },
      voicepage: {
        current: 1,
        limit: 10,
        total: 0,
      },
      tableData: [],
      voicetableData: [],
      voiceattrList: [
        {
          label: "资源名称",
          prop: "display_name",
          render: (row) => <div>{row.display_name}</div>,
        },
        {
          label: "播放时长",
          prop: "time_length",
          render: (row) => <div>{row.time_length}</div>,
        },
        {
          width: 260,
          label: '媒体',
          prop: "file_name",
          render: (row) =>{
            if(row.sourceType == 1){
              return <audio controls style="width:100%;height:30px" src={row.file_name}></audio>
            }else if(row.sourceType == 2){
              return <el-button type="text" onClick={()=>this.showviode(row.file_name)}>查看视频</el-button>
            }else{
              return <el-button type="text" onClick={()=>this.showImage(row.file_name)}>查看图片</el-button>
            }
          }
        },
        {
          label: "访问级别",
          prop: "access_level",
          render: (row) => {
            if (row.access_level == "private") return <div>私有</div>;
            else if (row.access_level == "protect") return <div>本级</div>;
            else if (row.access_level == "public") return <div>所有</div>;
          },
        },
        {
          label: "状态",
          prop: "status",
          render: (row) => {
            if (row.status == 1) return <div>待审核</div>;
            else if (row.status == 2) return <div>审核通过</div>;
            else if (row.status == 3) return <div>审核不通过</div>;
            else if (row.status == 0) return <div>禁用</div>;
          },
        },
      ],
      attrList: [
        {
          label: "节目名称",
          prop: "name",
          render: (row) => <div>{row.name}</div>,
        },
        {
          label: "播放时长",
          prop: "time_length",
          render: (row) => <div>{row.time_length}</div>,
        },
        {
          label: "访问级别",
          prop: "access_level",
          render: (row) => {
            if (row.access_level == "private") return <div>私有</div>;
            else if (row.access_level == "protect") return <div>本级</div>;
            else if (row.access_level == "public") return <div>所有</div>;
          },
        },
        {
          label: "播放模式",
          prop: "play_mode",
          render: (row) => {
            if (row.play_mode == "loop") return <div>循环</div>;
            else if (row.play_mode == "once") return <div>一次</div>;
          },
        },
        {
          label: "生成时间",
          prop: "create_time",
          render: (row) => <div>{row.create_time}</div>,
        },
        {
          label: "状态",
          prop: "status",
          render: (row) => {
            if (row.status == 1) return <div>待审核</div>;
            else if (row.status == 2) return <div>审核通过</div>;
            else if (row.status == 3) return <div>审核不通过</div>;
            else if (row.status == 0) return <div>禁用</div>;
          },
        },
        {
          prop: "",
          label: "操作",
          render: (row) => {
            return (
              <div>
                <el-button
                  type="text"
                  icon="View"
                  onClick={() => this.handleView(row)}
                >查看</el-button>
                <el-button
                  type="text"
                  icon="Delete"
                  onClick={() => this.handleDelete(row)}
                  style="color:red"
                >删除</el-button>
              </div>
            );
          },
        },
      ],
    };
  },
  setup() {
    const voicetable = ref();

    function clearSelect() {
      voicetable.value.clearSelect();
    }

    return { voicetable, clearSelect };
  },
  // computed: {
  //   ids() {
  //     let ids = [];
  //     this.selectionList.forEach((ele) => {
  //       ids.push(ele.id);
  //     });
  //     return ids.join(",");
  //   }
  // },
  created() {
    this.getData(this.page);
    getmediaList(this.voicepage.current, this.voicepage.limit, {sourceType:this.typeChange}).then((res) => {
      if (res.data.success) {
        this.voicetableData = res.data.data.list;
        let url = window.location.hostname;
        this.voicetableData.forEach((item, index)=>{
          this.voicetableData[index].file_name = item.file_name.replace(this.extractHostname(item.file_name), url);
        })
        this.voicepage.total = res.data.data.count;
      }
    });
    getSourceType().then(res=>{
      this.sourceType =  res.data.data;
    })
  },
  watch:{
    typeChange(val){
      if(val){
        getmediaList(this.voicepage.current, this.voicepage.limit, {sourceType:val}).then((res) => {
          if (res.data.success) {
            this.hastableData = [];
            this.$nextTick(()=>{
              this.$refs['addForm'].resetFields();
            })
            this.voicetableData = res.data.data.list;
            let url = window.location.hostname;
            this.voicetableData.forEach((item, index)=>{
              this.voicetableData[index].file_name = item.file_name.replace(this.extractHostname(item.file_name), url);
            })
            this.voicepage.total = res.data.data.count;
          }
        });
      }
    }
  },
  methods: {
    extractHostname(url) {
      var hostname;
      if (url.indexOf("//") > -1) {
        hostname = url.split('/')[2];
      } else {
        hostname = url.split('/')[0];
      }
      hostname = hostname.split(':')[0];
      hostname = hostname.split('?')[0];
      return hostname;
    },

    showImage(url){
      this.btitle = '查看图片'
      this.viodeUrl = url;
      this.viodeVisible = true;
    },

    showviode(url){
      this.btitle = '查看视频'
      this.viodeUrl = url;
      this.viodeVisible = true;
    },
    voicehandleCurrentChange(currentPage) {
      this.voicepage.current = currentPage;
      getmediaList(this.voicepage.current, this.voicepage.limit, {sourceType:this.typeChange}).then((res) => {
        if (res.data.success) {
          this.voicetableData = res.data.data.list;
          let url = window.location.hostname;
          this.voicetableData.forEach((item, index)=>{
            this.voicetableData[index].file_name = item.file_name.replace(this.extractHostname(item.file_name), url);
          })
          this.voicepage.total = res.data.data.count;
        }
      });
    },

    voicehandleSizeChange(size){
      this.voicepage.limit = size;
      getmediaList(this.voicepage.current, this.voicepage.limit, {sourceType:this.typeChange}).then((res) => {
        if (res.data.success) {
          this.voicetableData = res.data.data.list;
          let url = window.location.hostname;
          this.voicetableData.forEach((item, index)=>{
            this.voicetableData[index].file_name = item.file_name.replace(this.extractHostname(item.file_name), url);
          })
          this.voicepage.total = res.data.data.count;
        }
      });
    },

    searchForm() {
      this.page.current = 1;
      this.getData(this.page);
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.page.current = 1;
      this.getData(this.page);
    },
    getData(page) {
      getList(page.current, page.limit, this.search).then((res) => {
        this.tableData = res.data.data.list;
        this.page.total = res.data.data.count;
      });
    },
    handleSelectionChange(value) {
      this.selectionList = value;
    },
    beforeClose(done){
      done();
      this.typeChange = 1;
    },
    selectMedia() {
      this.hastableData = this.hastableData.concat(this.selectionList);
      this.clearSelect();
    },
    handleView(row) {
      getDetail(row.id).then((res) => {
        if (res.data.success) {
          this.issourceType = res.data.data.sourceType;
          this.detailtableData = res.data.data.list;
          console.log(this.detailtableData);
          let url = window.location.hostname;
          this.detailtableData.forEach((item, index)=>{
            this.detailtableData[index].mediaAddr = item.mediaAddr.replace(this.extractHostname(item.mediaAddr), url);
          })
          let { access_level, play_mode, name, scrollSubtitles } = res.data.data;
          this.detailForm = { access_level, play_mode, name, scrollSubtitles };
          this.detailFormVisible = true;
        }
      });
    },
    handleDelete(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delData(row.id).then((res) => {
          if (res.data.success) {
            this.getData(this.page);
            this.$message({
              type: "success",
              message: "操作成功!",
            });
          }
        });
      });
    },
    submitAddForm(formName) {
      if(this.hastableData.length < 1) return ElMessage({message: '请先添加节目', type: 'warning'})
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let arr = [];
          this.hastableData.map((item) => {
            let obj = {
              mediaAddr: item.file_name,
              mediaName: item.display_name,
              timeLength: item.time_length,
            };
            arr.push(obj);
          });
          let { name, play_mode, access_level, scrollSubtitles } = this.addForm;
          let content = JSON.stringify(arr);
          addData({ name, play_mode, access_level, content, scrollSubtitles, sourceType:this.typeChange }).then((res) => {
            if (res.data.success) {
              this.getData(this.page);
              this.addFormVisible = false;
              this.typeChange = 1;
              this.$message.success("操作成功！");
            }
          });
        }
      });
    },
    handleDeletehas(index, row) {
      this.hastableData.splice(index, 1);
    },
    handleCurrentChange(currentPage) {
      this.page.current = currentPage;
      this.getData(this.page);
    },
    handleSizeChange(pageSize) {
      this.page.limit = pageSize;
      this.getData(this.page);
    },
  },
};
</script>

<style scoped lang="scss">
.el-card {
  border: none;
  //border-color: #24AD8E;
  //--el-card-border-color:#448D7C
}

.el-card.is-always-shadow {
  box-shadow: 1px 1px #0f4641;
}

.el-card ::v-deep .el-card__header {
  border-bottom: 1px dashed #24ad8e;
}
.topb{
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.addBtn {
}

.addForm {
  margin-top: 20px;
}

.addTable {
  border: 1px solid #178164;
  padding: 10px;
  background-color: #0a2b28;
}
</style>
