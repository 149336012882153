import axios from "@/api/axios"; // 引用axios

export const getList = (page, limit, params) => {
  const param = {
    ...params,
    page,
    limit
  };
  console.log(param);
  return axios({
    url: "/api/safety/certificate/listData",
    method: "post",
    params: param
  });
};
export const certificatedown = (id) => {
  return axios({
    url: "/api/safety/certificate/register",
    method: "post",
    params: {
      id: id
    }
  });
};
