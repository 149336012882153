<template>
  <div class="searchBox">
    <el-form :model="search" :label-width="searchLabelWidth" ref="searchForm">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="信息标题" prop="name">
            <el-input
              v-model="search.name"
              autocomplete="off"
              placeholder="请输入 信息标题"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <!--              <el-form-item>-->
          <el-button plain @click="searchForm('searchForm')" class="searchBtn"
          >查询
          </el-button
          >
          <el-button plain @click="resetForm('searchForm')" class="resetBtn"
          >清空
          </el-button
          >

          <!--              </el-form-item>-->
        </el-col>
      </el-row>
    </el-form>
    <div class="divide">
      <img src="../../assets/divide.png" style="width: 100%" />
    </div>
    <div class="btnContainer">
      <div class="btnLeft">
        <el-button
          icon="Plus"
          @click="handleAdd"
          class="searchBtn"
        >新增
        </el-button
        >
      </div>
    </div>
  </div>
  <basicTable
    :attrList="attrList"
    :tableData="tableData"
    ref="table"
    :page="page.current"
    :limit="page.limit"
    :total="page.total"
    @currentChange="handleCurrentChange"
    @sizeChange="handleSizeChange"
  >
  </basicTable>
  <el-dialog
    width="75%"
    v-model="dialogFormVisible"
    :title="title"
    :close-on-click-modal="false"
    :before-close="beforeClose"
  >
    <div class="dialog_box">
      <div class="dialog_left">
        <el-input v-model="filterText" placeholder="请输入 查找内容" />
        <el-tree :data="treeData" :props="treeSelectProps" show-checkbox node-key="code" accordion ref="tree"
                 empty-text="暂无数据"
                 default-expand-all @check-change="checkChange" :filter-node-method="filterNode" />
      </div>
      <div class="dialog_right">
        <el-form
          label-position="right"
          :model="form"
          size="small"
          :label-width="formLabelWidth"
          ref="dialogForm"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item
                label="任务名称"
                :rules="[{ required: true, message: '任务名称 不能为空' }]"
                prop="name"
              >
                <el-input
                  v-model="form.name"
                  autocomplete="off"
                  clearable
                  placeholder="请输入 任务名称"
                  :readonly="isread"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="任务类型"
                prop="parameterType"
                :rules="[
              { required: true, message: '任务类型 不能为空', trigger: 'blur' },
            ]"
              >
                <el-radio-group v-model="form.parameterType" :disabled="isread">
                  <el-radio :label="1">音源广播</el-radio>
                  <el-radio :label="2">通道广播</el-radio>
                  <el-radio :label="3">调度广播</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="广播类型" prop="bcastType" :rules="[{ required: true, message: '广播类型 不能为空', trigger: 'blur' }]">
                <el-select
                  v-model="form.bcastType"
                  placeholder="请选择 广播类型"
                  clearable
                  :disabled="isread"
                >
                  <el-option
                    :label="item.name"
                    :value="item.code"
                    v-for="(item, index) in bcastTypelist"
                    :key="index"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="事件类型" prop="eventType" :rules="[{ required: true, message: '事件类型 不能为空', trigger: 'blur' }]">
                <el-select
                  v-model="form.eventType"
                  placeholder="请选择 事件类型"
                  clearable
                  filterable
                  :disabled="isread"
                >
                  <el-option
                    :label="item.name"
                    :value="item.code"
                    v-for="(item, index) in eventTypeList"
                    :key="index"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="事件级别" prop="eventLevel" :rules="[{ required: true, message: '事件级别 不能为空', trigger: 'blur' }]">
                <el-select
                  v-model="form.eventLevel"
                  placeholder="请选择 事件级别"
                  clearable
                  :disabled="isread"
                >
                  <el-option
                    :label="item.name"
                    :value="item.code"
                    v-for="(item, index) in eventLevellist"
                    :key="index"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="频次"
                prop="type"
                :rules="[
              { required: true, message: '频次 不能为空', trigger: 'blur' },
            ]"
              >
                <el-radio-group v-model="form.type" :disabled="isread">
                  <el-radio :label="1">每天</el-radio>
                  <el-radio :label="2">每周</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="开始时间" prop="startTime" :rules="[{ required: true, message: '开始时间 不能为空' }]">
                <el-time-picker
                  value-format="HH:mm:ss"
                  v-model="form.startTime"
                  placeholder="选择 开始时间"
                  :disabled="isread"
                >
                </el-time-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="结束时间" prop="endTime" :rules="[{ required: true, message: '结束时间 不能为空' }]">
                <el-time-picker
                  value-format="HH:mm:ss"
                  v-model="form.endTime"
                  placeholder="选择 结束时间"
                  :disabled="isread"
                >
                </el-time-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item
                label="音量"
                :rules="[{ required: true, message: '音量 不能为空' }]"
                prop="volume"
              >
                <el-input-number
                  :min="1"
                  v-model="form.volume"
                  autocomplete="off"
                  placeholder="请输入 音量"
                  :disabled="isread"
                ></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="输出通道" prop="outputChannelIds" :rules="[{ required: true, message: '输出通道 不能为空' }]">
                <el-checkbox-group :disabled="isread"  v-model="form.outputChannelIds" fill="#0B9E84">
                  <el-checkbox :label="1" name="outputChannelIds" :key="1">FM</el-checkbox>
                  <el-checkbox :label="2" name="outputChannelIds" :key="2">DVB-C</el-checkbox>
                  <el-checkbox :label="3" name="outputChannelIds" :key="3">DTMB</el-checkbox>
                  <el-checkbox :label="4" name="outputChannelIds" :key="4">IP/4G</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item
            label="区域资源码集"
            :rules="[{ required: true, message: '区域资源码集 不能为空' }]"
            prop="targetCodes"
          >
            <el-input
              type="textarea"
              v-model="nameStr"
              autocomplete="off"
              clearable
              placeholder="请输入 区域资源码集"
            ></el-input>
          </el-form-item>
          <el-form-item label="发生星期" prop="weekStr" :rules="[{ required: true, message: '发生星期 不能为空' }]" v-if="form.type==2">
            <el-checkbox-group :disabled="isread" v-model="form.weekStr" fill="#0B9E84">
              <el-checkbox :label="1" name="weekStr" :key="1">星期一
              </el-checkbox>
              <el-checkbox :label="2" name="weekStr" :key="2">星期二
              </el-checkbox>
              <el-checkbox :label="3" name="weekStr" :key="3">星期三
              </el-checkbox>
              <el-checkbox :label="4" name="weekStr" :key="4">星期四
              </el-checkbox>
              <el-checkbox :label="5" name="weekStr" :key="5">星期五
              </el-checkbox>
              <el-checkbox :label="6" name="weekStr" :key="6">星期六
              </el-checkbox>
              <el-checkbox :label="7" name="weekStr" :key="7">星期日
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-divider></el-divider>
          <el-form-item label="节目列表" prop="programId" v-if="form.parameterType==1" :rules="[{ required: true, message: '节目列表 不能为空' }]">
            <el-select
              filterable
              v-model="form.programId"
              placeholder="请选择 节目列表"
              clearable
              :disabled="isread"
            >
              <el-option
                :label="item.name"
                :value="item.id"
                v-for="(item, index) in programLibList"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="输入通道" prop="inputChannelId" :rules="[{ required: true, message: '输入通道 不能为空' }]"
                        v-else-if="form.parameterType==2">
            <el-radio-group :disabled="isread" v-model="form.inputChannelId" fill="#0B9E84">
              <el-radio :label="3" name="inputChannelId">U盘</el-radio>
              <el-radio :label="4" name="inputChannelId">FM调频</el-radio>
              <el-radio :label="5" name="inputChannelId">线路2</el-radio>
              <el-radio :label="6" name="inputChannelId">话筒</el-radio>
              <el-radio :label="7" name="inputChannelId">线路1</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-row :gutter="24" v-else>
            <el-col :span="20">
              <el-form-item
                :rules="[{ required: true, message: '音频文件 不能为空' }]"
                v-if="selectType"
                label="音频文件"
                prop="file"
              >
                <el-upload
                  :file-list="fileList"
                  :limit="1"
                  accept="audio/mpeg"
                  :auto-upload="false"
                  :on-change="handleChange"
                  :before-upload="beforeUpload"
                  class="upload-demo"
                  action="/api/local/media/uploadMp3"
                  v-if="!isread"
                >
                  <el-button size="small" type="primary">点击上传</el-button>
                  <template #tip>
                    <div class="el-upload__tip">只能上传mp3文件</div>
                  </template>
                </el-upload>

                <audio v-else style="width: 100%;height: 48px" :src="fileList[0].url" controls></audio>
              </el-form-item>
              <el-form-item
                v-else
                :rules="[{ required: true, message: '消息内容 不能为空' }]"
                label="消息内容"
                prop="msgDesc"
              >
                <el-input
                  type="textarea"
                  clearable
                  autocomplete="off"
                  v-model="form.msgDesc"
                  placeholder="请输入 消息内容"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4" v-if="!isread"><span class="toggle" @click="selectType=!selectType">切换</span></el-col>
          </el-row>

          <el-row :gutter="24" v-if="form.msgDesc">
            <el-col :span="20">
              <el-form-item
                  :rules="[{ required: true, message: '消息内容 不能为空' }]"
                  label="消息内容"
                  prop="msgDesc"
              >
                <el-input
                    :readonly='isread'
                    type="textarea"
                    clearable
                    autocomplete="off"
                    v-model="form.msgDesc"
                    placeholder="请输入 消息内容"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4" v-if="!isread"><span class="toggle" @click="selectType=!selectType">切换</span></el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <template #footer v-if="title=='新增'">
      <div class="dialog-footer">
        <el-button
          type="primary"
          size="small"
          @click="handlesubmit('dialogForm')"
        >提交
        </el-button>
        <el-button @click="dialogFormVisible = false" size="small" type="info" plain
        >取消
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { getRegionEquip } from "@/api/system/region";
import {
  getbasicData
} from "@/api/basic/startBroadcast";
import { getList, addData, delData, updateStatus, getDetail } from "@/api/basic/regularRadio";
import { ref, watch } from "vue";
import { ElMessage } from "element-plus";

export default {
  name: "regularRadio",
  setup() {
    const filterText = ref("");
    const tree = ref(null);
    // const treeData = ref([]);
    watch(filterText, (val) => {
      tree.value.filter(val);
    });
    const filterNode = (value, data) => {
      console.log(value, data);
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    };
    return {
      filterText,
      filterNode,
      tree
    };
  },
  watch: {
    "form.parameterType"(val) {
      if (val) {
        if(this.isread) return;
        this.form.programId = "";
        this.form.inputChannelId = "";
        this.form.msgDesc = "";
        this.form.file = "";
      }
    }
  },
  data() {
    return {
      isread: false,
      selectType: true,
      treeSelectProps: {
        label: "name",
        value: "id",
        key: "code",
        children: "children"
      },
      fileList: [],
      codeList: [],
      eventTypeList: [],
      eventLevellist: [],
      bcastTypelist: [],
      programLibList: [],
      formLabelWidth: "140px",
      dialogFormVisible: false,
      detailVisible: false,
      title: "新增",
      form: {
        weekStr: [1,2,3],
        outputChannelIds: [4],
        parameterType: 1,
        volume: 20
      },
      searchLabelWidth: "100px",
      search: {},
      page: {
        current: 1,
        limit: 10,
        total: 0
      },
      tableData: [],
      attrList: [
        {
          label: "消息标题",
          prop: "name",
          render: (row) => <div>{row.name}</div>
        },
        {
          label: "频次",
          prop: "type",
          render: (row) => <div>{row.type == 1 ? "每天" : "每周"}</div>
        },
        {
          label: "开始时间",
          prop: "startTime",
          render: (row) => {
            return <div>{row.startTime}</div>;
          }
        }, {
          label: "结束时间",
          prop: "endTime",
          render: (row) => {
            return <div>{row.endTime}</div>;
          }
        },
        {
          label: "事件类型",
          prop: "eventType",
          render: (row) => <div>{row.eventType}</div>
        }, {
          label: "事件等级",
          prop: "eventLevelName",
          render: (row) => <div>{row.eventLevelName}</div>
        }, {
          label: "广播类型",
          prop: "bcastTypeName",
          render: (row) => <div>{row.bcastTypeName}</div>
        }, {
          label: "音量",
          prop: "volume",
          render: (row) => <div>{row.volume}</div>
        }, {
          label: "状态",
          prop: "status",
          render: (row) => <div>{row.status == 1 ? "启用" : "禁用"}</div>
        },
        {
          prop: "",
          label: "操作",
          width: '200',
          render: (row) => {
            return (
              <div>
                <el-button
                  type="text"
                  icon="Delete"
                  onClick={() => this.handleDelete(row)}
                >删除</el-button>
                <el-button
                  type="text"
                  icon="View"
                  onClick={() => this.handleView(row.id)}
                >编辑</el-button>
                <el-button
                  type="text"
                  icon="Open"
                  onClick={() => this.changeStatus(row.id, row.status)}
                >{row.status == 1 ? "禁用" : "启用"}
                </el-button>
              </div>
            );
            // else return (
            //   <div>
            //     <el-button
            //       type="text"
            //       icon="Delete"
            //       onClick={() => this.handleDelete(row)}
            //     ></el-button>
            //     <el-button
            //       type="text"
            //       icon="Open"
            //       onClick={() => this.changeStatus(row.status)}
            //     >启用
            //     </el-button>
            //   </div>
            // );
          }
        }
      ]
    };
  },
  computed: {
    nameStr() {
      let arr = [];
      this.codeList.map(item => {
        arr.push(item.name);
      });
      return arr.join(",");
    }
  },
  async created() {
    try {
      let res = await getbasicData(1);
      // let aim = await getStatus();
      // this.statusList = aim.data.data;
      if (res.data.success) {
        this.eventTypeList = res.data.data.eventType;
        this.eventLevellist = res.data.data.eventLevel;
        this.bcastTypelist = res.data.data.bcastType;
        this.programLibList = res.data.data.programLibList;
      }
      await this.getData(this.page);
    } catch (err) {
      console.log(err);
    }
  },
  mounted() {
    getRegionEquip().then(res => {
      if (res.data.success) {
        this.treeData = res.data.data.list;
      }
    });
  },
  methods: {
    beforeClose(done){
      // this.form= {};
      this.isread = false;
      this.fileList = [];
      this.$nextTick(()=>{
        this.dialogFormVisible = false;
        this.$refs['dialogForm'].resetFields();
        this.$refs.tree.setCheckedKeys([]);
      })
      done();
    },
    handleView(id) {
      this.title = "详情";
      this.dialogFormVisible = true;
      getDetail(id).then(res => {
        if (res.data.success) {
          let data = res.data.data;
          this.form.name = data.name;
          this.form.parameterType = data.parameterType;
          this.form.bcastType = data.bcastType.toString();
          this.form.eventType = data.eventType;
          this.form.eventLevel = data.eventLevel.toString();
          this.form.type = data.type;
          this.form.startTime = data.startTime;
          this.form.endTime = data.endTime;
          this.form.volume = data.volume;
          this.form.outputChannelIds = data.outputChannelIds.split(",");
          this.form.programId = data.programId;
          this.form.weekStr = data.weekStr.split(',').map(item=>Number(item));
          this.$refs.tree.setCheckedKeys(data.targetCodes.split(","));
          this.form.msgDesc = data.msgDesc;
          this.form.inputChannelId = data.inputChannelId;
          this.fileList = data.fileUrl ? [{name: data.fileUrl, url: data.fileUrl }] : [];
          this.isread = true;
        }
      });
    },
    changeStatus(id, status) {
      // console.log(id, status);
      status = status == 1 ? 0 : 1;
      updateStatus({ id, status }).then(res => {
        if (res.data.success) {
          this.getData(this.page);
          this.$message.success("操作成功");
        }
      });
    },
    handlesubmit(formName) {
      let arr = [];
      this.codeList.map(item => {
        arr.push(item.code);
      });
      this.form.targetCodes = arr.join(",");
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(this.form.outputChannelIds.length > 0) this.form.outputChannelIds = this.form.outputChannelIds.join(",");
          if(this.form.weekStr.length > 0) this.form.weekStr = this.form.weekStr.join(",");
          this.form.uuidStr = Date.now();
          let obj = new FormData();
          for(let key in this.form){
            obj.append(key,this.form[key])
          }
          addData(obj).then(res => {
            if (res.data.success) {
              this.getData(this.page);
              this.fileList = [];
              this.$message.success("操作成功！");
              this.$refs[formName].resetFields();
              this.$refs.tree.setCheckedKeys([]);
              this.form.outputChannelIds = [4];
              this.form.weekStr = [1,2];
              this.dialogFormVisible = false;
            }
          });
        } else {
          return false;
        }
      });
    },
    checkChange(node, status) {
      if(this.isread) return;
      this.codeList = this.getSimpleCheckedNodes(this.$refs.tree.store);
    },
    getSimpleCheckedNodes(store) {
      const checkedNodes = [];
      const traverse = function(node) {
        const childNodes = node.root ? node.root.childNodes : node.childNodes;
        childNodes.forEach(child => {
          if (child.checked) {
            checkedNodes.push(child.data);
          }
          if (child.indeterminate) {
            traverse(child);
          }
        });
      };
      traverse(store);
      return checkedNodes;
    },
    handleAdd() {
      this.title = "新增";
      this.dialogFormVisible = true;
    },
    changeData(data) {
      data.map(item => {
        for (let value of this.eventLevellist) {
          if (value.code == item.eventLevel) {
            item.eventLevelName = value.name;
            break;
          }
        }
        for (let val of this.bcastTypelist) {
          if (val.code == item.bcastType) {
            item.bcastTypeName = val.name;
            break;
          }
        }
      });
      return data;
    },
    beforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg == "mp3";
      if (!extension) {
        return this.$message({
          type: "error",
          message: "上传文件只能是mp3格式!"
        });
      }
    },
    handleChange(file, fileList) {
      this.form.file = file.raw;
      console.log(file.raw)
    },
    searchForm() {
      this.page.current = 1;
      this.getData(this.page);
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.page.current = 1;
      this.getData(this.page);
    },
    getData(page) {
      getList(page.current, page.limit, this.search).then(res => {
        if (res.data.success) {
          this.page.total = res.data.data.count;
          this.tableData = this.changeData(res.data.data.list);
        }
      });
    },
    handleDelete(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          return delData(row.id);
        })
        .then((res) => {
          this.getData(this.page);
          ElMessage({
            message: "操作成功",
            type: "success"
          });
        });
    },
    handleCurrentChange(currentPage) {
      this.page.current = currentPage;
      this.getData(this.page);
    },
    handleSizeChange(pageSize) {
      this.page.limit = pageSize;
      this.getData(this.page);
    }
  }
};
</script>

<style scoped lang="scss">
.dialog_box {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .dialog_left {
    width: 360px;

    ::-webkit-scrollbar {
      width: 0;
    }

    .el-tree {
      height: 600px !important;
      overflow-y: scroll;
    }

    .el-tree-node__content {
      margin-top: 20px !important;
    }
  }

  .dialog_right {
    width: 1000px;
    height: 600px;

    .toggle {
      cursor: pointer;
      display: inline-block;
      width: 150px;
      height: 60px;
      background-color: #00AD85;
      color: #062B2A;
      line-height: 60px;
      font-size: 16px;
      border-radius: 20px;
    }

    .toggle:hover {
      background-color: #1C9C77;
      color: #00FFC4;
    }

  }
}

.el-checkbox-group {
  display: inline-flex;
}

.el-divider {
  background-color: #00FFC4 !important;
}

.upload-demo {
  text-align: left;
}

::v-deep .el-upload-list__item-name {
  color: #00FFC4 !important;
}

.el-upload__tip {
  color: #00ffc0;
}
</style>