<template>
  <div class="formContainer">
    <div class="main">
      <el-tree :data="regionList" :props="treeSelectProps" show-checkbox node-key="code" accordion ref="tree"
               default-expand-all @current-change="currentChange" @check-change="checkChange" check-on-click-node />
    </div>
    <div class="formBox">
      <el-tag class="ml-2" type="success">通用音量设置</el-tag>
      <p class="introduce">兼容设备： 0~35(低音量),36~60(中音量),61~100(高音量)</p>
      <p class="introduce">设置： 可以选择区域或直接设备进行控制</p>
      <el-form

        :model="form"
        :label-width="labelWidth"
        ref="addForm"
      >
        <!--        <el-row :gutter="20">-->
        <!--          <el-col :span="6">-->
        <el-form-item
          label="音量"
          prop="volume"
          :rules="[{ required: true, message: '音量 不能为空' }]"
        >
          <el-slider v-model="form.volume"></el-slider>
        </el-form-item>
        <!--          </el-col>-->
        <!--          <el-col :span="6">-->
        <el-form-item
          label="区域码"
          prop="regionCodes"
          :rules="[{ required: true, message: '区域码 不能为空' }]"
        >
          <el-input
            clearable
            type="textarea"
            autocomplete="off"
            v-model="nameStr"
            placeholder="请输入 发送地址"
          >
          </el-input>
        </el-form-item>
        <!--          </el-col>-->

        <!--          <el-col :span="6">-->
        <!--              <el-form-item>-->
        <el-button plain @click="addForm('addForm')" class="searchBtn">提交</el-button>


        <!--              </el-form-item>-->
        <!--          </el-col>-->
        <!--        </el-row>-->
      </el-form>
    </div>
  </div>

</template>

<script>
import { getRegionEquip } from "@/api/system/region";
import { adjustVolume } from "@/api/terminalRegulate/generalVolume";

export default {
  name: "generalVolume",
  data() {
    return {
      code: [],
      codeList: [],
      form: {
        volume: 20
      },
      labelWidth: "120px",
      regionList: [],
      treeSelectProps: {
        label: "name",
        value: "id",
        key: "id",
        children: "children"
      }
    };
  },
  computed: {
    nameStr() {
      let arr = [];
      let code = [];
      this.codeList.map(item => {
        arr.push(item.name);
        code.push(item.code);
      });

      return arr.join(",");
    }
  },
  created() {
    getRegionEquip().then(res => {
      if (res.data.success) {
        this.regionList = res.data.data.list;
      }
    });
  },
  methods: {
    addForm(formName) {
      let arr = [];
      this.codeList.map(item => {
        arr.push(item.code);
      });
      this.form.regionCodes = arr.join(",");
      this.$refs[formName].validate((valid) => {
        if (valid) {
          adjustVolume(this.form).then(res => {
            if (res.data.success) {
              this.$message.success("操作成功！");
              this.$refs[formName].resetFields();
              this.$refs.tree.setCheckedKeys([]);
            }
          });
        }
      });
    },
    getSimpleCheckedNodes(store) {
      const checkedNodes = [];
      const traverse = function(node) {
        const childNodes = node.root ? node.root.childNodes : node.childNodes;
        childNodes.forEach(child => {
          if (child.checked) {
            checkedNodes.push(child.data);
          }
          if (child.indeterminate) {
            traverse(child);
          }
        });
      };
      traverse(store);
      return checkedNodes;
    },
    checkChange(node, status) {
      this.codeList = this.getSimpleCheckedNodes(this.$refs.tree.store);
    }
  }
};
</script>

<style scoped lang="scss">
.formContainer {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.formBox {
  width: 800px;
}

.ml-2 {
  margin-bottom: 40px;
}

.introduce {
  color: #1C9C77;
}

.main {
  width: 300px;

  ::-webkit-scrollbar {
    width: 0;
  }
}

.el-tree {
  height: 700px !important;
  overflow-y: scroll;
}

.el-tree-node__content {
  margin-top: 20px !important;
}

.el-slider {
  height: 100%;
}
</style>