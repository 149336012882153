<template>
  <!--  <basicContainer>-->
  <div class="content">
    <div class="leftTree">
      <el-tree
        :data="treeData"
        accordion
        :props="defaultProps"
        @node-click="handlenodeClick"
      />
    </div>
    <div class="rightTable">
      <div class="searchBox">
        <el-form
          :model="search"
          :label-width="searchLabelWidth"
          ref="searchForm"
        >
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="区域名称" prop="name">
                <el-input
                  v-model="search.name"
                  autocomplete="off"
                  placeholder="请输入 区域名称"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="区域码" prop="code">
                <el-input
                  v-model="search.code"
                  autocomplete="off"
                  placeholder="请输入 区域码"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <!--              <el-form-item>-->
              <el-button
                plain
                @click="searchForm('searchForm')"
                class="searchBtn"
              >查询
              </el-button
              >
              <el-button plain @click="resetForm('searchForm')" class="resetBtn"
              >重置
              </el-button
              >

              <!--              </el-form-item>-->
            </el-col>
          </el-row>
        </el-form>
        <div class="divide">
          <img src="../../assets/divide.png" style="width: 100%" />
        </div>
        <div class="btnContainer">
          <div class="btnLeft">
            <el-button
              icon="Plus"
              @click="dialogFormVisible = true"
              class="searchBtn"
            >新增
            </el-button
            >
            <el-button icon="Open" @click="handleState(1)" class="searchBtn"
            >启用
            </el-button
            >
            <el-button icon="TurnOff" @click="handleState(2)" class="searchBtn"
            >禁用
            </el-button
            >
          </div>
        </div>
      </div>
      <basicTable
        :attrList="attrList"
        :tableData="tableData"
        ref="table"
        :page="page.current"
        :limit="page.limit"
        :total="page.total"
        @currentChange="handleCurrentChange"
        @selectChange="handleSelectionChange"
        @sizeChange="handleSizeChange"
      >
      </basicTable>
    </div>
  </div>
  <el-dialog
    v-model="dialogFormVisible"
    :title="title"
    :close-on-click-modal="false"
    :before-close="handleClose"
  >
    <el-form
      label-position="right"
      :model="form"
      size="small"
      :label-width="formLabelWidth"
      ref="dialogForm"
    >
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            label="区域名称"
            :rules="[{ required: true, message: '区域名称' }]"
            prop="name"
          >
            <el-input v-model="form.name" placeholder="请输入 区域名称" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="区域码"
            :rules="[
              { required: true, message: '区域码 需为12位数字' },
            ]"
            prop="code"
          >
            <el-input
              v-model.number="form.code"
              autocomplete="off"
              clearable
              maxLength="12"
              placeholder="请输入 区域码"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            label="父级"
            prop="parent_id"
            :rules="[{ required: true, message: '父级 不能为空' }]"
          >
            <selectTree
              :treeData="treeData"
              @selectTreeChange="selectTreeChange"
              ref="selectTree"
            ></selectTree>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="状态"
            :rules="[{ required: true, message: '状态不能为空' }]"
            prop="status"
          >
            <el-radio-group v-model="form.status">
              <el-radio :label="1">启用</el-radio>
              <el-radio :label="2">禁用</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="经度" prop="longitude">
            <el-input
              v-model="form.longitude"
              autocomplete="off"
              placeholder="请输入 经度"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="纬度" prop="latitude">
            <el-input
                placeholder="请输入 纬度"
              v-model="form.latitude"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button
          type="primary"
          size="small"
          @click="handlesubmit('dialogForm')"
        >提交
        </el-button>
      </div>
    </template>
  </el-dialog>
  <!--  </basicContainer>-->
</template>

<script>
import {ref, defineComponent, reactive, toRefs, watch, nextTick} from "vue";
import {
  getregion,
  addData,
  editData,
  delData,
  upregionState
} from "@/api/system/region";
import { getRegionById } from "@/api/system/region";
import selectTree from "@/components/selectTree";
import { ElMessage } from "element-plus";

export default {
  name: "region",
  data() {
    return {
      selectionList: [],
      title: "新增",
      formLabelWidth: "22%",
      form: {
        status: 1
      },
      labelPosition: "right",
      dialogFormVisible: false,
      searchLabelWidth: "100px",
      search: {},
      tableData: [],
      treeData: [],
      attrList: [
        {
          label: "区域名称",
          prop: "name",
          render: (row) => <div>{row.name}</div>
        },
        {
          label: "区域码",
          prop: "code",
          render: (row) => <div>{row.code}</div>
        },
        {
          label: "经度",
          prop: "longitude",
          render: (row) => <div>{row.longitude}</div>
        },
        {
          label: "纬度",
          prop: "latitude",
          render: (row) => <div>{row.latitude}</div>
        },
        {
          label: "状态",
          prop: "status",
          render: (row) => {
            if (row.status == 1) return <div>开启</div>;
            else if (row.status == 2) return <div>禁用</div>;
          }
        },
        {
          prop: "",
          label: "操作",
          render: (row) => {
            return (
              <div>
                <el-button
                  type="text"
                  icon="Edit"
                  onClick={() => this.handleEdit(row)}
                >
                  编辑
                </el-button>
                <el-button
                  type="text"
                  icon="Delete"
                  onClick={() => this.handleDelete(row)}
                >
                  删除
                </el-button>
              </div>
            );
          }
        }
      ],
      defaultProps: {
        children: "children",
        label: "name"
      },
      page: {
        current: 1,
        limit: 10,
        total: 0
      },
      selectLabel: ""
    };
  },
  components: { selectTree },
  created() {
    this.getData(this.page);
    this.getTree();
  },
  computed: {
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    }
  },
  setup() {
    let selectTree = ref();

    function getParentId(data, id) {
      for (let item of data) {
        if (item.id == id) {
          selectTree.value.selectLabel = item.name;
          selectTree.value.selectValue = id;
          break;
        } else if (item.children && item.children.length) {
          getParentId(item.children, id);
        }
      }
    }

    return { selectTree, getParentId };
  },

  methods: {
    handleClose(done){
      nextTick(()=>{
        this.$refs['dialogForm'].resetFields();
        this.form = {}
      })
      done();
    },

    handlenodeClick(data, node, val) {
      this.search.name = data.name;
    },
    searchForm() {
      this.page.current = 1;
      this.getData(this.page);
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.page.current = 1;
      this.getData(this.page);
    },
    handleState(status) {
      upregionState({ ids: this.ids, status: status }).then((res) => {
        if (res.data.success) {
          this.getData(this.page);
          this.getTree();
          ElMessage({
            message: "操作成功",
            type: "success"
          });
        }
      });
    },
    handleSelectionChange(value) {
      console.log(value);
      this.selectionList = value;
    },
    handleEdit(row) {
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.getParentId(this.treeData, row.parent_id);
      });
      this.form = row;
      this.title = "编辑";
    },

    handleDelete(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          return delData(row.id);
        })
        .then((res) => {
          this.getData(this.page);
          this.getTree();
          ElMessage({
            message: "操作成功",
            type: "success"
          });
        });
    },
    getData(page) {
      getregion(page.current, page.limit, this.search).then((res) => {
        this.tableData = res.data.data.list;
        console.log(res.data.data.list);
        this.page.total = res.data.data.count;
      });
    },
    getTree() {
      getRegionById().then((res) => {
        this.treeData = res.data.data;
      });
    },
    handlesubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.form);
          if (this.title == "新增") {
            addData(this.form).then((res) => {
              if (res.data.success) {
                this.getData(this.page);
                this.$refs[formName].resetFields();
                this.getTree();
                ElMessage({
                  message: "操作成功",
                  type: "success"
                });
                this.dialogFormVisible = false;
              } else {
                ElMessage({
                  message: res.data.code.message,
                  type: "error"
                });
              }
            });
          } else {
            editData(this.form).then((res) => {
              if (res.data.success) {
                this.getData(this.page);
                this.$refs[formName].resetFields();
                this.getTree();
                ElMessage({
                  message: "操作成功",
                  type: "success"
                });
                this.dialogFormVisible = false;
              } else {
                ElMessage({
                  message: res.data.code.message,
                  type: "error"
                });
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    selectTreeChange(value) {
      this.form.parent_id = value;
    },
    handleCurrentChange(currentPage) {
      this.page.current = currentPage;
      this.getData(this.page);
    },
    handleSizeChange(pageSize) {
      this.page.limit = pageSize;
      this.getData(this.page);
    }
  }
};
</script>

<style scoped>
.content {
  width: 100%;
  /*height: 100%;*/
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;
}

.leftTree {
  width: 240px;
  height: 775px;
  background: #0a2b28;
  border: 1px solid #178164;
}

.rightTable {
  width: 1420px;
  height: 100%;
}
</style>
