<template>
  <div class="stepOut">
    <ul @mouseleave="activeIndex=-1">
      <li class="stepItem" v-for="(stepItem, index) in stepInfo" :key="index"
          @mouseenter="handleEnter(index)">

        <div :class="stepItem.children&&stepItem.children[0] ?.time ? 'icon active':'icon'"></div>
        <!-- 模拟步骤条连接线，动态显示  -->
        <div v-if="index != 4" :class="(stepItem.children&&stepItem.children[0] ?.time) ? 'line lineActive':'line'"
             v-show="index!==stepInfo.length"></div>
        <div v-if="index == 4"
             :class="(stepItem.children&&stepItem.children[0] ?.time)? 'lineCircle lineCircleActive':'lineCircle'"></div>
        <!-- 步骤名称      -->
        <p class="stepStatus" :class="(stepItem.children&&stepItem.children[0] ?.time)? 'stepStatusactive':''">
          {{ stepItem.name }}</p>
        <!-- 步骤时间  -->
        <p class="statusTime" :class="(stepItem.children)? '':'statusTimeactive'">
          {{ (stepItem.children && stepItem.children[0]?.time) ? stepItem.children[0].time : "等待中" }}</p>
        <!-- 模拟步骤条的节点，此处为圆圈 -->
        <div v-if="index==activeIndex&&stepItem.children" class="msg">
          <span v-for="(child,i) in stepItem.children" :key="i">{{ child.msg ? child.msg : "" }}<br></span>
        </div>
      </li>

    </ul>
  </div>
</template>

<script>
export default {
  name: "Step",
  props: {
    // 传递步骤参数
    stepInfo: {
      type: Array,
      default: function() {
        return []
          // { id: 1, name: "信息接入", children: [{ id: 1 }] },
          // { id: 1, name: "信息接入", children: [{ id: 1, time: "2022-01-05 19:22:30", msg: "成功" }] },
          // { id: 2, name: "信息验证", children: [{ id: 2, time: "2022-01-05 19:22:30", msg: "成功" }] },
          // { id: 3, name: "信息存储", children: [{ id: 3, time: "2022-01-05 19:22:30", msg: "成功" }] },
          // { id: 4, name: "调度策略", children: [{ id: 4, time: "2022-01-05 19:22:30", msg: "不比对-放行" }] },
          // { id: 5, name: "加入调度", children: [{ id: 5, time: "2022-01-05 19:22:30", msg: "加入调度成功" }] },
          // { id: 6, name: "调度执行", children: [{ id: 6, time: "2022-01-05 19:22:30", msg: "指令封装成功" }] },
          // { id: 7, name: "指令生成", children: [{ id: 7, time: "2022-01-05 19:22:31", msg: "指令发送成功" }] },
          // { id: 8, name: "开播响应", children: [{ id: 8, time: "2022-01-05 19:59:00", msg: "192.168.3.37开播响应成功" }] },
          // { id: 9, name: "关闭广播", children: [{ id: 9, time: "2022-01-06 11:19:23", msg: "指令发送成功" }] },
          // { id: 10, name: "关播响应", children: [{ id: 10, time: "2022-01-06 11:19:23", msg: "关播响应成功" }] }
        // ];
      }
    },
    step: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {
      activeIndex: -1
    };
  },
  methods: {
    handleEnter(index) {
      this.activeIndex = index;

    }
  }
};
</script>

<style scoped lang="less">
.stepOut {
  width: 100%;
  //height: 70px;
  display: flex;
  justify-content: center;

  .stepItem {
    width: 95px;
    float: left;
    //height: 105px;
    font-family: SimSun;
    font-size: 16px;
    text-align: center;
    position: relative;

    .lineCircle {
      left: 52%;
      top: 6px;
      position: absolute;
      display: block;
      border-left-color: white;
      border-left-width: 0px;
      width: 100%;
      border-color: white white white white;
      border-left-style: hidden;
      border-left: none;
      border-top: 1px dashed #11FFBF;
      border-right: 1px dashed #11FFBF;
      border-bottom: 1px dashed #11FFBF;
      border-left: 0px dashed #11FFBF;
      height: 100%;
      border-radius: 0px 10px 10px 0px;
    }

    &:nth-child(n+5) {
      float: right !important;
      right: 60px;
    }

    .lineCircleActive {
      border-bottom: 1px solid #11FFBF;
      border-top: 1px solid #11FFBF;
      border-right: 1px solid #11FFBF;
      border-bottom: 1px solid #11FFBF;
      border-left: 0px solid #11FFBF;

    }

    &:nth-child(6) {
      .line {
        display: none;
      }

    }

    .icon {
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background: #1B4239;
      margin: 0 auto;
      position: relative;
      z-index: 888;
    }

    .active {
      background-color: #00FFC4;
    }

    .line {
      position: absolute;
      top: 6px;
      left: 50%;
      border-bottom: 1px solid #1B4239;
      width: 60px;
      z-index: 111;
    }

    .lineActive {
      border-bottom: 1px solid #11FFBF;
    }

    .stepStatus {
      margin: 0;
      font-size: 16px;
      color: #2C947F;

    }

    .statusTime {
      font-size: 15px;
      color: #11FFBF;
    }
  }
}

.stepStatusactive {
  color: #11FFBF !important;
}

.statusTimeactive {
  color: #2C947F !important;
}

.msg {
  z-index: 888;
  text-align: left;
  border-radius: 10px;
  width: 150px;
  padding: 2px 10px;
  font-size: 12px;
  font-family: Source Han Sans SC;
  position: absolute;
  top: 30%;
  background-color: #148F75;
  color: #00FFC4;


}
</style>