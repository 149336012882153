import axios from "@/api/axios"; // 引用axios
//获取分页数据
export const getroles = (page, limit, params) => {
  const param = {
    ...params,
    page,
    limit,
  };
  return axios({
    url: "/api/role/listData",
    method: "post",
    params: param,
  });
};
export const addroles = (params) => {
  return axios({
    url: "/api/role/addData",
    method: "post",
    params: params,
  });
};
export const editroles = (params) => {
  return axios({
    url: "/api/role/editData",
    method: "post",
    params: params,
  });
};
export const delroles = (id) => {
  return axios({
    url: "/api/role/del",
    method: "post",
    params: { id },
  });
};
//获取所有角色下拉数据
export const getrolesList = () => {
  return axios({
    url: "/api/pub/getRoleList",
    method: "post",
    params: {},
  });
};
//获取单个角色的授权
export const getRolePerm = (id) => {
  return axios({
    url: "/api/role/getRolePermissionIds",
    method: "post",
    params: { id },
  });
}; //提交角色权限
export const updateRolePerm = (params) => {
  return axios({
    url: "/api/role/perData",
    method: "post",
    params: params,
  });
};
