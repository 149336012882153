import axios from "@/api/axios"; // 引用axios

export const getdynamicData = (params={}) => {
  return axios({
    url: "/api/dynamic/getDynamicResourceParama",
    method: "post",
    params: params,
  });
};
export const gettodayData = (params={}) => {
  return axios({
    url: "/api/dynamic/getDynamicResourceTodayMes",
    method: "post",
    params: params,
  });
};