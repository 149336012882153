<template>
  <div class="searchBox">
    <el-form :model="search" :label-width="searchLabelWidth" ref="searchForm">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="类型名称" prop="name">
            <el-input
              v-model="search.name"
              autocomplete="off"
              placeholder="请输入 类型名称"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <!--              <el-form-item>-->
          <el-button plain @click="searchForm('searchForm')" class="searchBtn"
          >查询
          </el-button
          >
          <el-button plain @click="resetForm('searchForm')" class="resetBtn"
          >清空
          </el-button
          >

          <!--              </el-form-item>-->
        </el-col>
      </el-row>
    </el-form>
    <div class="divide">
      <img src="../../assets/divide.png" style="width: 100%" />
    </div>
    <div class="btnContainer">
      <div class="btnLeft">
        <el-button
          icon="Plus"
          @click="handleAdd"
          class="searchBtn"
        >新增
        </el-button
        >
      </div>
    </div>
  </div>
  <basicTable
    :attrList="attrList"
    :tableData="tableData"
    ref="table"
    :page="page.current"
    :limit="page.limit"
    :total="page.total"
    @currentChange="handleCurrentChange"
    @sizeChange="handleSizeChange"
  >
  </basicTable>
  <el-dialog
    width="60%"
    v-model="dialogFormVisible"
    :title="title"
    :close-on-click-modal="false"
    :before-close="handleClose"
  >
    <el-form
      label-position="right"
      :model="form"
      size="small"
      :label-width="formLabelWidth"
      ref="dialogForm"
    >
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            label="类型名称"
            :rules="[{ required: true, message: '类型名称 不能为空' }]"
            prop="name"
          >
            <el-input
              v-model="form.name"
              autocomplete="off"
              clearable
              placeholder="请输入 类型名称"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="类型编码"
            prop="code"
            :rules="[
              { required: true, message: '类型编码 不能为空', trigger: 'blur' },
            ]"
          >
            <el-input
              clearable
              type="tel"
              autocomplete="off"
              v-model="form.code"
              placeholder="请输入 类型编码"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            label="子类型识别码"
            :rules="[{ required: true, message: '子类型识别码 不能为空' }]"
            prop="subtypeCode"
          >
            <el-select
              v-model="form.subtypeCode"
              placeholder="请选择 子类型识别码"
            >
              <el-option
                v-for="item in resourceType"
                :key="item.id"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="排序序号"
            :rules="[{ required: true, message: '排序序号 不能为空' }]"
            prop="orderNum"
          >
            <el-input-number
              :min="1"
              v-model="form.orderNum"
              autocomplete="off"
              placeholder="请输入 排序序号"
            ></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            label="图标路径"
            :rules="[{ required: true, message: '图标路径 不能为空' }]"
            prop="iconUrl"
          >
            <el-input
              v-model="form.iconUrl"
              autocomplete="off"
              clearable
              placeholder="请选择 图标路径"
              @click="iconVisible = true"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button
          type="primary"
          size="small"
          @click="handlesubmit('dialogForm')"
        >提交
        </el-button>
      </div>
    </template>
  </el-dialog>
  <el-dialog
    v-model="iconVisible"
    title="图标"
    width="80%"
    :close-on-click-modal="false"
  >
    <div class="icon-container">
      <div
        v-for="(item, index) in iconList"
        :key="index"
        class="itemBox"
        @click="iconselect(item)"
      >
        <el-icon class="iconItem">
          <component :is="item"></component>
          <p>{{ item }}</p>
        </el-icon>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {
  getList,
  addData,
  editData,
  delData,
  getDetail,
  getChildList
} from "@/api/basic/equipType";
import { ElMessage } from "element-plus";
import iconList from "@/config/iconList";
import {nextTick} from "vue";

export default {
  name: "equipType",
  data() {
    return {
      iconVisible: false,
      iconList: [],
      resourceType: [],
      formLabelWidth: "140px",
      dialogFormVisible: false,
      title: "新增",
      form: {},
      searchLabelWidth: "100px",
      search: {},
      page: {
        current: 1,
        limit: 10,
        total: 0
      },
      tableData: [],
      attrList: [
        {
          label: "类型名称",
          prop: "name",
          render: (row) => <div>{row.name}</div>
        },
        {
          label: "类型编码",
          prop: "code",
          render: (row) => <div>{row.code}</div>
        },
        {
          label: "子类型识别码",
          prop: "subtypeCode",
          render: (row) => {
            return <div>{row.title}</div>;
          }
        },
        {
          label: "排序序号",
          prop: "orderNum",
          render: (row) => <div>{row.orderNum}</div>
        },
        {
          prop: "",
          label: "操作",
          render: (row) => {
            return (
              <div>
                <el-button
                  type="text"
                  icon="Edit"
                  onClick={() => this.handleEdit(row)}
                >编辑</el-button>
                <el-button
                  type="text"
                  icon="Delete"
                  onClick={() => this.handleDelete(row)}
                >删除</el-button>
              </div>
            );
          }
        }
      ]
    };
  },
  created() {
    this.getData(this.page);
    getChildList().then((res) => {
      if (res.data.success) {
        this.resourceType = res.data.data;
      }
    });
    this.iconList = iconList;
  },
  methods: {
    iconselect(item) {
      this.iconVisible = false;
      this.form.iconUrl = item;
    },
    searchForm() {
      this.page.current = 1;
      this.getData(this.page);
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.page.current = 1;
      this.getData(this.page);
    },
    getData(page) {
      getList(page.current, page.limit, this.search).then((res) => {
        if (res.data.success) {
          // console.log(res);
          this.page.total = res.data.data.count;
          this.tableData = res.data.data.list;
        }
      });
    },
    handlesubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.title == "新增") {
            addData(this.form).then((res) => {
              if (res.data.success) {
                this.getData(this.page);
                this.$refs[formName].resetFields();
                ElMessage({
                  message: "操作成功",
                  type: "success"
                });
                this.dialogFormVisible = false;
              } else {
                ElMessage({
                  message: res.data.code.message,
                  type: "error"
                });
              }
            });
          } else {
            delete this.form.inputChannels;
            editData(this.form).then((res) => {
              if (res.data.success) {
                this.getData(this.page);
                this.$refs[formName].resetFields();
                ElMessage({
                  message: "操作成功",
                  type: "success"
                });
                this.dialogFormVisible = false;
              } else {
                ElMessage({
                  message: res.data.code.message,
                  type: "error"
                });
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    handleClose(done){
      nextTick(()=>{
        this.$refs['dialogForm'].resetFields();
        this.form = {}
      })
      done();
    },
    handleAdd() {
      this.title = "新增";
      this.dialogFormVisible = true;
    },
    handleEdit(row) {
      getDetail(row.id).then((res) => {
        if (res.data.success) {
          this.form = res.data.data;
          this.dialogFormVisible = true;
          this.title = "编辑";
        }
      });
    },
    handleDelete(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          return delData(row.id);
        })
        .then((res) => {
          this.getData(this.page);
          ElMessage({
            message: "操作成功",
            type: "success"
          });
        });
    },
    handleCurrentChange(currentPage) {
      this.page.current = currentPage;
      this.getData(this.page);
    },
    handleSizeChange(pageSize) {
      this.page.limit = pageSize;
      this.getData(this.page);
    }
  }
};
</script>

<style scoped>
.icon-container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.iconItem {
  width: 40px;
  height: 40px;
}

.iconItem svg {
  height: 40px;
  width: 40px;
}

.itemBox {
  margin: 10px;
}

.iconItem p {
  font-size: 14px;
}
</style>
