import axios from "@/api/axios"; // 引用axios

export const getData = () => {
    return axios({
        url: "/api/portable/intercept/unregisteredSystemData",
        method: "post",
    });
};


export const addData = (data) => {
    return axios({
        url: "/api/portable/intercept/addData",
        method: "post",
        params: data
    });
};
