<template>
  <div class="searchBox">
    <!--    <el-form :model="search" :label-width="searchLabelWidth" ref="searchForm">-->
    <!--      <el-row :gutter="20">-->
    <!--        <el-col :span="6">-->
    <!--          <el-form-item label="广播状态" prop="status">-->
    <!--            <el-select-->
    <!--              clearable-->
    <!--              :fit-input-width="true"-->
    <!--              v-model="search.status"-->
    <!--              placeholder="请选择 广播状态"-->
    <!--            >-->
    <!--              <el-option :label="item.name" :value="item.status" v-for="(item,index) in statusList"-->
    <!--                         :key="index"></el-option>-->
    <!--            </el-select>-->
    <!--          </el-form-item>-->
    <!--        </el-col>-->
    <!--        <el-col :span="6">-->
    <!--          <el-form-item label="实际开始时间" prop="startTimeFact">-->
    <!--            <el-date-picker v-model="search.startTimeFact" type="date" placeholder="请选择 实际开始时间"-->
    <!--                            value-format="YYYY-MM-DD HH:mm:ss"></el-date-picker>-->
    <!--          </el-form-item>-->
    <!--        </el-col>-->
    <!--        <el-col :span="6">-->
    <!--          <el-form-item label="实际结束时间" prop="endTimeFact">-->
    <!--            <el-date-picker v-model="search.endTimeFact" type="date" placeholder="请选择 实际结束时间"-->
    <!--                            value-format="YYYY-MM-DD HH:mm:ss"></el-date-picker>-->
    <!--          </el-form-item>-->
    <!--        </el-col>-->
    <!--        <el-col :span="6">-->
    <!--          <el-form-item label="广播编号" prop="ebmId">-->
    <!--            <el-input-->
    <!--              clearable-->
    <!--              v-model="search.ebmId"-->
    <!--              autocomplete="off"-->
    <!--              placeholder="请输入 广播编号"-->
    <!--            ></el-input>-->
    <!--          </el-form-item>-->
    <!--        </el-col>-->
    <!--        <el-col :span="6">-->
    <!--          <el-form-item label="指令来源" prop="inputChannelId">-->
    <!--            <el-select-->
    <!--              clearable-->
    <!--              :fit-input-width="true"-->
    <!--              v-model="search.inputChannelId"-->
    <!--              placeholder="请选择 指令来源"-->
    <!--            >-->
    <!--              <el-option :label="item.name" :value="item.status" v-for="(item,index) in instructList"-->
    <!--                         :key="index"></el-option>-->
    <!--            </el-select>-->
    <!--          </el-form-item>-->
    <!--        </el-col>-->
    <!--        <el-col :span="6">-->
    <!--          <el-form-item-->
    <!--            label="所属区域"-->
    <!--            prop="regionId"-->
    <!--          >-->
    <!--            <selectTree :treeData="regionList" @selectTreeChange="searchselectTree"-->
    <!--                        :propsId="searchpropsId" ref="selectTree"></selectTree>-->
    <!--          </el-form-item>-->

    <!--        </el-col>-->
    <!--        <el-col :span="6">-->
    <!--          &lt;!&ndash;              <el-form-item>&ndash;&gt;-->
    <!--          <el-button plain @click="searchForm('searchForm')" class="searchBtn"-->
    <!--          >查询-->
    <!--          </el-button-->
    <!--          >-->
    <!--          <el-button plain @click="resetForm('searchForm')" class="resetBtn"-->
    <!--          >清空-->
    <!--          </el-button-->
    <!--          >-->

    <!--          &lt;!&ndash;              </el-form-item>&ndash;&gt;-->
    <!--        </el-col>-->
    <!--      </el-row>-->
    <!--    </el-form>-->
    <!--    <div class="divide">-->
    <!--      <img src="../../assets/divide.png" style="width: 100%" />-->
    <!--    </div>-->
    <div class="btnContainer">
      <div class="btnLeft">
        <el-button
          @click="handleInfoUp"
          class="searchBtn"
        >信息上报
        </el-button
        >
      </div>
    </div>
  </div>
  <basicTable
    :attrList="attrList"
    :tableData="tableData"
    ref="table"
    :page="page.current"
    :limit="page.limit"
    :total="page.total"
    @currentChange="handleCurrentChange"
    @sizeChange="handleSizeChange"
    @selectChange="handleSelectChange"
  >
  </basicTable>
</template>

<script>
import { onMounted, reactive, ref, toRefs } from "vue";
import {
  getList
} from "@/api/basic/loudspeaker";
import {
  infoUp
} from "@/api/system/boastInfo";
import {
  getbasicData
} from "@/api/basic/startBroadcast";
import { ElMessage } from "element-plus";

export default {
  name: "boastInfo",
  setup() {
    const option = reactive({
      form: {},
      searchLabelWidth: "140px",
      search: {},
      page: {
        current: 1,
        limit: 10,
        total: 0
      },
      tableData: [],
      eventLevel: [],
      bcastType: [],
      ids: [],
      attrList: [
        {
          label: "广播编号",
          prop: "ebmId",
          render: (row) => <div>{row.ebmId}</div>
        },
        {
          label: "目标地址",
          prop: "instructionSource",
          render: (row) => <div>{row.instructionSource}</div>
        },
        {
          label: "事件级别",
          prop: "eventLevel",
          render: (row) => {
            const obj = option.eventLevel.find(item => item.code == row.eventLevel) || {};
            return <div>{obj.name}</div>;
          }
        }, {
          label: "广播类型",
          prop: "bcastType",
          render: (row) => {
            const obj = option.bcastType.find(item => item.code == row.bcastType) || {};
            return <div>{obj.name}</div>;
          }
        },
        // {
        //   label: "广播状态",
        //   prop: "status",
        //   render: (row) => {
        //     const obj = this.statusList.find(item => item.status == row.status) || {};
        //     return <div>{obj.name}</div>;
        //   }
        // },
        {
          label: "开始时间",
          prop: "createTime",
          render: (row) => {
            return <div>{row.createTime}</div>;
          }
        }

      ]
    });

    function getData(page) {
      getList(page.current, page.limit, option.search).then(res => {
        if (res.data.success) {
          option.tableData = res.data.data.list;
          option.page.total = res.data.data.count;
        }
      });
    }

    function handleSelectChange(val) {
      option.ids = val;
    }

    function handleInfoUp(num) {
      if (!option.ids.length) return ElMessage({
        type: "error",
        message: "请至少选择一条数据"
      });
      let arr = [];
      option.ids.forEach(item => arr.push(item.id));
      infoUp({ ids: arr.join(",") }).then(res => {
        if (res.data.success) {
          getData(option.page);
          ElMessage({
            type: "success",
            message: "操作成功！"
          });
        }
      });
    }

    function handleCurrentChange(currentPage) {
      option.page.current = currentPage;
      getData(option.page);
    }

    function handleSizeChange(pageSize) {
      option.page.limit = pageSize;
      getData(option.page);
    }

    onMounted(async () => {
      let res = await getbasicData(1);
      if (res.data.success) {
        option.eventLevel = res.data.data.eventLevel;
        option.bcastType = res.data.data.bcastType;
      }
      await getData(option.page);
    });
    return {
      ...toRefs(option),
      getData,
      handleSelectChange,
      handleInfoUp,
      handleCurrentChange,
      handleSizeChange
    };
  }
};
</script>

<style scoped>

</style>