import axios from "@/api/axios"; // 引用axios
// 获取列表
export const getList = (page, limit, params) => {
  const param = {
    ...params,
    page,
    limit
  };
  return axios({
    url: "/api/database/backup/listData",
    method: "post",
    params: param
  });
};
// 编辑
export const editData = (params) => {
  console.log(params);
  return axios({
    url: "/api/database/backup/editData",
    method: "post",
    params: params
  });
};
// 启用禁用
export const canUse = (params) => {
  return axios({
    url: "/api/database/backup/editStatus",
    method: "post",
    params: params
  });
};