import axios from "@/api/axios"; // 引用axios
//获取广播节目列表
export const getList = (page, limit, params = {}) => {
  const param = {
    ...params,
    page,
    limit,
  };
  return axios({
    url: "/api/local/program/listData",
    method: "post",
    params: param,
  });
};
//获取媒体资源（绑定节目时）列表
export const getmediaList = (page, limit, params) => {
  const param = {
    ...params,
    page,
    limit,
  };
  return axios({
    url: "/api/local/media/listDataDetails",
    method: "post",
    params: param,
  });
};

//新增节目
export const addData = (params) => {
  return axios({
    url: "/api/local/program/addData",
    method: "post",
    data: params,
  });
};
//编辑节目
export const editData = (params) => {
  return axios({
    url: "/api/local/program/editData",
    method: "post",
    data: params,
  });
};
//节目详情
export const getDetail = (id) => {
  return axios({
    url: "/api/local/program/detail",
    method: "post",
    params: { id },
  });
};
//删除节目
export const delData = (id) => {
  return axios({
    url: "/api/local/program/del",
    method: "post",
    params: { id },
  });
};
