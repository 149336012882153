<template>
  <div class="box">
    <el-select
      :disabled="disabled"
      ref="selectTree"
      style="width: 100%"
      size="small"
      placeholder="请选择"
      clearable
      v-model="selectLabel"
      @clear="selectClear"
    >
      <el-option class="option-style" :label="selectLabel" :value="selectValue">
        <el-input
          class="search"
          size="small"
          placeholder="输入关键字进行过滤"
          v-model="keywork"
          @click.stop.capture
        >
        </el-input>
        <el-tree
          ref="tree"
          :node-key="nodeKey"
          :data="treeData"
          :default-checked-keys="defaultExpandedKey"
          :props="treeProps"
          :show-checkbox="showCheckbox"
          :filter-node-method="filterNode"
          @node-click="nodeClick"
          @check-change="checkChange"
        >
          <template #default="{ node, data }">
            <i class="el-icon-warning-outline"></i>
            <span :class="[{ 'tree-click': treeClick == data[treeProps.key] }]">{{
                data[treeProps.label]
              }}</span>
          </template>
        </el-tree>
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: "selectTree",
  data() {
    return {
      defaultExpandedKey: [],
      keywork: null,
      treeClick: "", //点击样式
      selectLabel: "", //下拉框显示名称
      selectValue: this.propsId //下拉框显示ID
    };
  },

  props: {
    treeProps: {
      type: Object,
      default: function() {
        return {
          children: "children",
          label: "name",
          key: "id"
        };
      }
    },
    nodeKey: {
      type: String,
      default: 'id'
    },
    propsId: {
      type: Number,
      default: 0
    },
    showCheckbox: {
      //是否可选择
      type: Boolean,
      default: false
    },
    disabled: {
      //是否可选择
      type: Boolean,
      default: false
    },
    treeData: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  watch: {
    keywork(val) {
      this.$refs.tree.filter(val);
    },
    propsId(val) {
      if(!val) return this.selectLabel = "";
      this.selectValue = val;
      this.initHandle();
    }
  },
  mounted() {
    this.initHandle();
  },
  methods: {
    initHandle() {
      if (this.selectValue) {
        if(!this.$refs.tree.getNode(this.selectValue)) return
        this.selectLabel = this.$refs.tree.getNode(this.selectValue).data[
          this.treeProps.label
          ]; // 初始化显示
        this.$refs.tree.setCurrentKey(this.selectValue); // 设置默认选中
        this.defaultExpandedKey = [this.selectValue]; // 设置默认展开
      }
    },
    selectClear() {
      this.treeClick = "";
      this.selectLabel = "";
      this.selectValue = "";
      this.$emit("sclear");
    },
    filterNode(value, data) {
      if (!value) return true;
      return data[this.treeProps.label].indexOf(value) !== -1;
    },

    nodeClick(obj, node) {
      this.treeClick = obj[this.treeProps.key];
      this.selectLabel = obj[this.treeProps.label];
      this.selectValue = obj[this.treeProps.key];
      this.$emit("selectTreeChange", obj[this.treeProps.key] , obj[this.treeProps.label]);
      this.$refs.selectTree.blur();
    },
    checkChange(obj, node) {
      console.log(obj, node);
    }
  }
};
</script>

<style scoped>
.box {
  width: 100%;
  height: 100%;
}

.option-style {
  padding: 0;
  width: 100%;
  height: 100%;
}

.search {
  height: 60px;
  margin: 0 0.5rem;
  padding: 10px;
  margin: 0;
}

.tree-click {
  font-weight: 600;
}

::v-deep .el-tree-node__content {
  padding: 5px;
}

::v-deep .el-icon-caret-right {
  font-size: 0;
}
</style>
