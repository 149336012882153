<template>
  <div class="formContainer">
    <div class="main">
      <el-tree :data="regionList" :props="treeSelectProps" show-checkbox node-key="code" accordion ref="tree"
               default-expand-all @current-change="currentChange" @check-change="checkChange" check-on-click-node />
    </div>
    <div class="formBox">
      <el-tag class="ml-2" type="success">网络参数设置</el-tag>
      <p class="introduce">目的： 操作更改设备网络参数</p>
      <p class="introduce">设置： 直接对设备进行控制</p>
      <el-form

        :model="form"
        :label-width="labelWidth"
        ref="addForm"
      >
        <!--        <el-row :gutter="20">-->
        <!--          <el-col :span="6">-->
        <el-form-item
          label="ip地址"
          prop="ip"
          :rules="[{ required: true, message: 'ip地址 不能为空' }]"
        >
          <el-input
            clearable
            autocomplete="off"
            v-model="form.ip"
            placeholder="请输入 ip地址"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="子网掩码"
          prop="subnetMask"
          :rules="[{ required: true, message: '子网掩码 不能为空' }]"
        >
          <el-input
            clearable
            autocomplete="off"
            v-model="form.subnetMask"
            placeholder="请输入 子网掩码"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="网关"
          prop="gateway"
          :rules="[{ required: true, message: '网关 不能为空' }]"
        >
          <el-input
            clearable
            autocomplete="off"
            v-model="form.gateway"
            placeholder="请输入 网关"
          >
          </el-input>
        </el-form-item>
        <!--          </el-col>-->
        <!--          <el-col :span="6">-->
        <el-form-item
          label="选中设备"
          prop="regionCodes"
          :rules="[{ required: true, message: '选中设备 不能为空' }]"
        >
          <el-input
            clearable
            type="textarea"
            autocomplete="off"
            v-model="form.regionCodes"
            placeholder="请选择 选中设备"
          >
          </el-input>

        </el-form-item>
        <!--          </el-col>-->

        <!--          <el-col :span="6">-->
        <!--              <el-form-item>-->
        <el-button plain @click="addForm('addForm')" class="searchBtn">提交</el-button>


        <!--              </el-form-item>-->
        <!--          </el-col>-->
        <!--        </el-row>-->
      </el-form>
    </div>
  </div>
</template>

<script>
import { regionTreeToDisabled } from "@/api/system/region";
import { netSet } from "@/api/terminalRegulate/generalVolume";

export default {
  name: "tminalNetSet",
  data() {
    return {
      code: [],
      codeList: [],
      form: {},
      labelWidth: "120px",
      regionList: [],
      treeSelectProps: {
        label: "name",
        value: "id",
        key: "id",
        children: "children"
      }
    };
  },
  computed: {
    nameStr() {
      let arr = [];
      let code = [];
      this.codeList.map(item => {
        arr.push(item.name);
        code.push(item.code);
      });

      return arr.join(",");
    }
  },
  created() {
    regionTreeToDisabled().then(res => {
      if (res.data.success) {
        this.regionList = res.data.data.list;
      }
    });
  },
  methods: {
    addForm(formName) {
      let arr = [];
      this.codeList.map(item => {
        arr.push(item.code);
      });
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(this.codeList.length < 1) return this.$message({message: '请选择 区域码', type: 'warning'});
          netSet({...this.form, regionCodes: arr.join(",")}).then(res => {
            if (res.data.success) {
              this.$message.success("操作成功！");
              this.$refs[formName].resetFields();
              this.$refs.tree.setCheckedKeys([]);
            }
          });
        }
      });
    },
    getSimpleCheckedNodes(store) {
      const checkedNodes = [];
      const traverse = function(node) {
        const childNodes = node.root ? node.root.childNodes : node.childNodes;
        childNodes.forEach(child => {
          if (child.checked) {
            checkedNodes.push(child.data);
          }
          if (child.indeterminate) {
            traverse(child);
          }
        });
      };
      traverse(store);
      return checkedNodes;
    },
    checkChange(node, status) {
      let arr = [];
      this.codeList = this.getSimpleCheckedNodes(this.$refs.tree.store);
      this.codeList.map(item => {
        arr.push(item.name);
      });
      this.form.regionCodes = arr.join(",");
      this.$refs.tree.setCheckedKeys([node.code])
    }
  }
};
</script>

<style scoped lang="scss">
.formContainer {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.formBox {
  width: 800px;
}

.ml-2 {
  margin-bottom: 40px;
}

.introduce {
  color: #1C9C77;
}

.main {
  width:300px;
  ::-webkit-scrollbar {
    width: 0;
  }
}

.el-tree {
  height: 700px !important;
  overflow-y: scroll;
}

.el-tree-node__content {
  margin-top: 20px !important;
}

.el-slider {
  height: 100%;
}
</style>