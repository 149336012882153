import axios from "@/api/axios"; // 引用axios
//信息上报
export const getList = (page, limit,params) => {
  const param = {
    ...params,
    page,
    limit
  };
  return axios({
    url: "/api/resource/equipment/listDataNew",
    method: "post",
    params: param
  });
};//信息上报
export const infoUp = (params) => {
  return axios({
    url: "/api/resource/equipment/equipmentToReportInfo",
    method: "post",
    params: params
  });
};
//状态上报
export const statusUp = (params) => {
  return axios({
    url: "/api/resource/equipment/equipmentToReportState",
    method: "post",
    params: params
  });
};