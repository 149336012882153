import axios from "@/api/axios"; // 引用axios

export const getList = (page, limit, params) => {
  const param = {
    ...params,
    page,
    limit
  };
  return axios({
    url: "/api/log/listData",
    method: "post",
    params: param
  });
};