<template>

  <div class="toggle">
    <div class="ml-2" :class="{selected:selectenum==0}" @click="handledynamic(0)">动态通道</div>
    <div :class="{selected:selectenum==1}" @click="handledynamic(1)">启用通道</div>
  </div>
  <div class="searchBox" v-show="selectenum==1">
    <el-form
      :model="search"
      :label-width="searchLabelWidth"
      ref="searchForm"
    >
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item
            label="embId"
            prop="name"
          >
            <el-input v-model="search.embId" autocomplete="off" placeholder="请输入 embId"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <!--              <el-form-item>-->
          <el-button plain @click="handlesearchForm('searchForm')" class="searchBtn">查询</el-button>
          <el-button plain @click="resetForm('searchForm')" class="resetBtn">清空</el-button>
          <!--              </el-form-item>-->
        </el-col>
      </el-row>
    </el-form>
  </div>
  <div v-show="selectenum==0">
    <basicTable
      :indexshow="false"
      :isPage="false"
      :attrList="dynamicattr"
      :tableData="tableData"
      ref="table">
    </basicTable>
  </div>
  <div v-show="selectenum==1">
    <basicTable
      :indexshow="false"
      :isPage="false"
      :attrList="todayattr"
      :tableData="videoData"
      ref="table">
    </basicTable>
  </div>


</template>

<script>
import { getdynamicData, gettodayData } from "@/api/resourceMana/platform";
import { onMounted, reactive, toRefs, ref } from "vue";

export default {
  name: "platform",
  setup() {
    const searchForm = ref();
    var option = reactive({
      searchLabelWidth: "120px",
      search: {},
      selectenum: 0,
      tableData: [],
      videoData: [],
      dynamicattr: [
        {
          label: "id",
          prop: "id",
          render: (row) => (<div>{row.id}</div>)
        }, {
          label: "ip",
          prop: "ip",
          render: (row) => (<div>{row.ip}</div>)
        }, {
          label: "端口",
          prop: "port",
          render: (row) => (<div>{row.port}</div>)
        }, {
          label: "pcrPID",
          prop: "pcrPID",
          render: (row) => (<div>{row.pcrPID}</div>)
        }, {
          label: "节目id",
          prop: "programPID",
          render: (row) => (<div>{row.programPID}</div>)
        }
        // {
        //   prop: "",
        //   label: "操作",
        //   render: (row) => {
        //     return <div>
        //       <el-button type="text" icon="Edit" onClick={() => this.handleEdit(row)}></el-button>
        //       <el-button type="text" icon="Delete" onClick={() => this.handleDelete(row)}></el-button>
        //       <el-button type="text" icon="Finished" onClick={() => this.handleUpdate(row)}>证书更新</el-button>
        //     </div>;
        //   }
        // }
      ],
      todayattr: [
        {
          label: "广播编号",
          prop: "ebmId",
          render: (row) => (<div>{row.ebmId}</div>)
        }, {
          label: "接收地址",
          prop: "audioReceiveAddr",
          render: (row) => (<div>{row.audioReceiveAddr}</div>)
        }, {
          label: "服务端接收端口",
          prop: "serverReceivePort",
          render: (row) => (<div>{row.serverReceivePort}</div>)
        }, {
          label: "http地址",
          prop: "serverHttpUrl",
          render: (row) => (<div>{row.serverHttpUrl}</div>)
        }, {
          label: "音频存储服务端口",
          prop: "storePort",
          render: (row) => (<div>{row.storePort}</div>)
        }, {
          label: "TS通道服务端口",
          prop: "tsReceivePort",
          render: (row) => (<div>{row.tsReceivePort}</div>)
        }, {
          label: "TS流端口",
          prop: "tsSendAddr",
          render: (row) => (<div>{row.tsSendAddr}</div>)
        }, {
          label: "TS音频流的节目PID",
          prop: "tsProgramPid",
          render: (row) => (<div>{row.tsProgramPid}</div>)
        }, {
          label: "rtsp地址",
          prop: "rtspServerUrl",
          render: (row) => (<div>{row.rtspServerUrl}</div>)
        }, {
          label: "rtmp地址",
          prop: "rtmpServerUrl",
          render: (row) => (<div>{row.rtmpServerUrl}</div>)
        }
        // {
        //   prop: "",
        //   label: "操作",
        //   render: (row) => {
        //     return <div>
        //       <el-button type="text" icon="Edit" onClick={() => this.handleEdit(row)}></el-button>
        //       <el-button type="text" icon="Delete" onClick={() => this.handleDelete(row)}></el-button>
        //       <el-button type="text" icon="Finished" onClick={() => this.handleUpdate(row)}>证书更新</el-button>
        //     </div>;
        //   }
        // }
      ]
    });

    function getData() {
      getdynamicData().then(res => {
        if (res.data.success) {
          option.tableData = res.data.data.tsParamList;
        }
      });
    }

    function resetForm() {
      option.search={}
      todayData();
    }
    function handlesearchForm() {
      todayData();
    }

    function handledynamic(num) {
      option.selectenum = num;
      if (num == 0) {
        return getData();
      } else {
        return todayData();
      }
    }

    function todayData() {
      gettodayData(option.search).then(res => {
        if (res.data.success) {
          option.videoData = res.data.data.todayList;
        }
      });
    }

    onMounted(() => {
      getData();
    });
    return {
      handlesearchForm,
      resetForm,
      searchForm,
      ...toRefs(option),
      handledynamic
    };
  }
};
</script>

<style scoped lang="scss">
.ml-2 {
  margin-right: 20px;
}

.toggle {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  div {
    padding: 10px;
    font-size: 16px;
    width: 100px;
    border: #87c5b7 1px solid;
    border-radius: 10px;
    background-color: #FFFFFF;
    cursor: pointer;

    &.selected {
      background-color: #e1f3d8;
      color: #67c23a;
    }
  }

}
</style>