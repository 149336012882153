<template>
  <div>
    <div style="position: relative">
      <el-input v-model="points" :readonly="isreadonly" autocomplete="off" @input="input" placeholder="选择坐标点" @click="show"></el-input>
      <el-button class="change" type="text" @click="changeType">{{isreadonly ? '切换手动输入' : '切换选择坐标'}}</el-button>
    </div>



    <el-dialog append-to-body v-model="mapVisible" title="地图" width="60%" :before-close="handleClose">
      <el-input id="tipinput" v-model="keyWord" style="width: 300px;margin-bottom: 10px" placeholder="输入关键字搜索"/>
      <div id="container" v-if="mapVisible" style="width: 100%;height: 400px"></div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="setPoint">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {ElMessage} from "element-plus";
export default {
  data(){
    return{
      keyWord:"",
      wz:"",
      isreadonly:true,
      mapVisible:false,
      map: null,
      marker: null,
      point: "",
      points: ""
    }
  },

  methods:{
    changeType(){
      this.isreadonly = !this.isreadonly;
      this.points = '';
      this.$emit('CHANGE_POINT');
    },

    input(){
      this.$emit('INPUT_POINT', this.points);
    },
    setPoint(){
      this.mapVisible = false;
      this.points = this.point;
      this.$emit('SET_POINT', this.point);
    },

    show(){
      if(!this.isreadonly) return;
      this.mapVisible = true;

      this.$nextTick(()=>{
        this.map = new AMap.Map("container", {
          resizeEnable: true,
          mapStyle: "amap://styles/924a0b80c02969b8a905eab4dd9f5431",
        });
        if(this.points){
          let int = this.points.split(',')
          this.map.setCenter([int[0], int[1]]);
          this.map.setZoom(16);
          let icon = new AMap.Icon({
            image: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
            size: new AMap.Size(25, 34),
            imageSize: new AMap.Size(25,34),
          })
          this.marker = new AMap.Marker({
            icon: icon,
            position: [int[0], int[1]],
            offset: new AMap.Pixel(-13, -30)
          });
          this.marker.setMap(this.map);
        }


        this.map.on('click', (e)=>{
          let lng = e.lnglat.getLng();
          let lat = e.lnglat.getLat();
          if (this.marker) {
            this.marker.setMap(null);
            this.marker = null;
          }
          let icon = new AMap.Icon({
            image: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
            size: new AMap.Size(25, 34),
            imageSize: new AMap.Size(25,34)
          })
          this.marker = new AMap.Marker({
            icon: icon,
            position: [lng, lat],
            offset: new AMap.Pixel(-13, -30)
          });
          this.marker.setMap(this.map);
          this.point = `${lng},${lat}`;
        })

        AMap.plugin(['AMap.PlaceSearch','AMap.AutoComplete'], ()=>{
          var auto = new AMap.AutoComplete({
            input: "tipinput"
          });

          auto.on("select", (e)=>{
            let data = e.poi.location;
            if(!data) return ElMessage({
              message: '暂无此地点坐标',
              type: 'warning',
            })


            if (this.marker) {
              this.marker.setMap(null);
              this.marker = null;
            }

            let icon = new AMap.Icon({
              image: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
              size: new AMap.Size(25, 34),
              imageSize: new AMap.Size(25,34)
            })
            this.marker = new AMap.Marker({
              icon: icon,
              position: [data.lng, data.lat],
              offset: new AMap.Pixel(-13, -30)
            });
            this.marker.setMap(this.map);
            this.point = `${data.lng},${data.lat}`;
            this.map.setCenter([data.lng, data.lat]);
            this.map.setZoom(16);
          });
        });
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.change{
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translate(0, -50%);
}

</style>