<template>
  <div class="searchBox">
    <div class="btnContainer">
      <div class="btnLeft">
        <el-button
          icon="Plus"
          @click="dialogFormVisible = true"
          class="searchBtn"
        >新增
        </el-button>
        <el-button
          type="success"
          icon="Check"
          @click="updateCalibrate"
        >白名单校准
        </el-button>
      </div>
    </div>
  </div>
  <basicTable
    :attrList="attrList"
    :tableData="tableData"
    ref="table"
    :page="page.current"
    :limit="page.limit"
    :total="page.total"
    @currentChange="handleCurrentChange"
    @sizeChange="handleSizeChange"
  >
  </basicTable>
  <el-dialog
    width="60%"
    v-model="dialogFormVisible"
    :title="title"
    :close-on-click-modal="false"
  >
    <el-row :gutter="20">
      <el-col :span="8">
          <el-tree :data="adapterList" :props="treeSelectProps" show-checkbox node-key="code" accordion ref="tree" @check-change="checkChange" check-on-click-node />
      </el-col>
      <el-col :span="16">
        <el-form label-position="right" :model="form" size="small" :label-width="formLabelWidth" ref="dialogForm">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="名称" :rules="[{ required: true, message: '名称 不能为空' }]" prop="name">
                <el-input v-model="form.name" autocomplete="off" clearable placeholder="请输入 名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="手机号" prop="tel" :rules="[{ required: true, message: '手机号码 不能为空', trigger: 'blur' }]">
                <el-input clearable type="tel" autocomplete="off" v-model="form.tel" placeholder="请输入 手机号码"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="适配器" :rules="[{ required: true, message: '适配器 不能为空' }]" prop="regionCodes">
                <el-input type="textarea" readonly autocomplete="off" v-model="form.regionCodes" placeholder="请输入 适配器"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="类型" :rules="[{ required: true, message: '许可类型 不能为空' }]" prop="permission_type">
                <el-select v-model="form.permission_type" placeholder="请选择 许可类型" clearable>
                  <el-option v-for="item in permissionType" :key="item.id" :label="item.name" :value="item.code"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-col>
    </el-row>
    <template #footer>
      <div class="dialog-footer">
        <el-button
          type="primary"
          size="small"
          @click="handlesubmit('dialogForm')"
        >提交
        </el-button>
        <el-button @click="dialogFormVisible = false" size="small"
        >取消
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import {
  getList,
  addEditData,
  delData,
  getParams,
  upCalibrate
} from "@/api/orchestrate/whiteList";
import { getRegionById } from "@/api/system/region";
export default {
  name: "whiteList",
  data() {
    return {
      treeSelectProps: {
        label: "name",
        value: "id",
        key: "id",
        children: "children"
      },
      codeList:[],
      permissionType: [],
      adapterList: [],
      formLabelWidth: "22%",
      dialogFormVisible: false,
      title: "新增",
      form: {},
      searchLabelWidth: "100px",
      search: {},
      page: {
        current: 1,
        limit: 10,
        total: 0
      },
      tableData: [],
      attrList: [
        {
          label: "名称",
          prop: "name",
          render: (row) => <div>{row.name}</div>
        },
        {
          label: "许可类型",
          prop: "permission_name",
          render: (row) => <div>{row.permission_name}</div>
        },
        {
          label: "电话号码",
          prop: "tel",
          render: (row) => <div>{row.tel}</div>
        },
        {
          label: "区域",
          prop: "area_name",
          render: (row) => <div>{row.area_name}</div>
        }, {
          label: "验证",
          prop: "response_status",
          render: (row) => {
            if (row.response_status == 0) return <div>验证成功</div>;
            else if (row.response_status == 1) return <div>新增未响应</div>;
            else if (row.response_status == 2) return <div>删除未响应</div>;
          }
        },
        {
          prop: "",
          label: "操作",
          render: (row) => {
            if (row.response_status == 0) return <div>
              <el-button
                type="text"
                icon="Edit"
                onClick={() => this.handleEdit(row)}
              >编辑</el-button>
              <el-button
                type="text"
                icon="Delete"
                onClick={() => this.handleDelete(row)}
              >删除</el-button>
            </div>;
            else return;
            // "img", {
            //   attrs: {
            //     //2、使用
            //     src: "../../static/images/edit.png"
            //   },
            //   style: {
            //     width: "17px",
            //     verticalAlign: "middle"
            //   }
            // }
            // <img
            //   src="../../static/images/edit.png"
            //   onClick={() => this.handleEdit(row)}
            // ></img>

          }
        }
      ]
    };
  },
  created() {
    this.getData(this.page);
    getRegionById({id:null}).then(res=>{
      this.adapterList =  res.data.data;
    })
    getParams().then(res => {
      if (res.data.success) {
        this.permissionType = res.data.data.permissionType;
      }
    });
  },
  methods: {
    getSimpleCheckedNodes(store) {
      const checkedNodes = [];
      const traverse = function(node) {
        const childNodes = node.root ? node.root.childNodes : node.childNodes;
        childNodes.forEach(child => {
          if (child.checked) {
            checkedNodes.push(child.data);
          }
          if (child.indeterminate) {
            traverse(child);
          }
        });
      };
      traverse(store);
      return checkedNodes;
    },
    checkChange(node, status) {
      this.form.regionCodes = this.getSimpleCheckedNodes(this.$refs.tree.store).map(item=>item.name).join(',');
      this.codeList = this.getSimpleCheckedNodes(this.$refs.tree.store);
    },

    updateCalibrate() {
      this.$confirm("确定更新校准?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          return upCalibrate();
        })
        .then((res) => {
          if (res.data.success) {
            this.getData(this.page);
            this.$message({
              message: res.data.code.message,
              type: "success"
            });
          }

        });
    },
    searchForm() {
      this.page.current = 1;
      this.getData(this.page);
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.page.current = 1;
      this.getData(this.page);
    },
    getData(page) {
      getList(page.current, page.limit, this.search).then((res) => {
        if (res.data.success) {
          console.log(res);
          this.page.total = res.data.data.count;
          this.tableData = res.data.data.list;
        }
      });
    },
    handlesubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.form.type = this.title == "新增" ? "add" : "update";
          addEditData({...this.form,regionCodes:this.codeList.map(item=>item.code).join(',')}).then((res) => {
            if (res.data.success) {
              this.getData(this.page);
              this.$refs[formName].resetFields();
              this.$message({
                message: "操作成功",
                type: "success"
              });
              this.dialogFormVisible = false;
            } else {
              this.$message({
                message: res.data.code.message,
                type: "error"
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    handleEdit(row) {
      this.dialogFormVisible = true;
      this.title = "编辑";
      let { name, tel, permission_type, regionCodes } = row;
      this.form = { name, tel, permission_type, regionCodes };
      // getDetail(row.id).then((res) => {
      //   if (res.data.success) {
      //     this.form = res.data.data;
      //
      //   }
      // });
    },
    handleDelete(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
         return  delData(row.id);
        })
        .then((res) => {
          this.getData(this.page);
          ElMessage({
            message: "操作成功",
            type: "success"
          });
        });
    },
    handleCurrentChange(currentPage) {
      this.page.current = currentPage;
      this.getData(this.page);
    },
    handleSizeChange(pageSize) {
      this.page.limit = pageSize;
      this.getData(this.page);
    }
  }
};
</script>

<style scoped>

</style>