<template>
  <div class="searchBox">
    <el-form :model="search" :label-width="searchLabelWidth" ref="searchForm">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="账号/姓名" prop="ac_name">
            <el-input
              v-model="search.ac_name"
              autocomplete="off"
              placeholder="请输入 账号/姓名"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="日志类型" prop="type">
            <el-select
              v-model="search.type"
              placeholder="请选择 日志类型"
              clearable
            >
              <el-option label="错误日志" value="1"></el-option>
              <el-option label="登陆日志" value="2"></el-option>
              <el-option label="操作日志" value="3"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!--        <el-col :span="6">-->
        <!--          <el-form-item label="日志时间" prop="type">-->
        <!--            <el-select-->
        <!--              v-model="search.type"-->
        <!--              placeholder="请选择 日志类型"-->
        <!--              clearable-->
        <!--            >-->
        <!--              <el-option label="错误日志" value="1"></el-option>-->
        <!--              <el-option label="登陆日志" value="2"></el-option>-->
        <!--              <el-option label="操作日志" value="3"></el-option>-->
        <!--            </el-select>-->
        <!--          </el-form-item>-->
        <!--        </el-col>-->

        <el-col :span="6">
          <!--              <el-form-item>-->
          <el-button plain @click="searchFormFn('searchForm')" class="searchBtn"
          >查询
          </el-button
          >
          <el-button plain @click="resetForm('searchForm')" class="resetBtn"
          >清空
          </el-button
          >

          <!--              </el-form-item>-->
        </el-col>
      </el-row>
    </el-form>
    <div class="divide">
      <img src="../../assets/divide.png" style="width: 100%" />
    </div>
    <!--    <div class="btnContainer">-->
    <!--      <div class="btnLeft">-->
    <!--        <el-button-->
    <!--          icon="Plus"-->
    <!--          @click="handleAdd"-->
    <!--          class="searchBtn"-->
    <!--        >新增-->
    <!--        </el-button-->
    <!--        >-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
  <basicTable
    :attrList="attrList"
    :tableData="tableData"
    ref="table"
    :page="page.current"
    :limit="page.limit"
    :total="page.total"
    @currentChange="handleCurrentChange"
    @sizeChange="handleSizeChange"
  >
  </basicTable>
</template>

<script>
import { getList } from "@/api/system/blog";
import { ref, reactive, toRefs, onMounted } from "vue";

export default {
  name: "blog",
  setup() {
    const option = reactive({
      formLabelWidth: "22%",
      dialogFormVisible: false,
      title: "新增",
      form: {},
      searchLabelWidth: "100px",
      search: {},
      page: {
        current: 1,
        limit: 10,
        total: 0
      },
      tableData: [],
      attrList: [
        {
          label: "账号/姓名",
          prop: "ac_name",
          render: (row) => <div>{row.ac_name}</div>
        },
        {
          label: "日志类型",
          prop: "type",
          render: (row) => <div>{row.type == 1 ? "错误日志" : (row.type == 2 ? "登陆日志" : "操作日志")}</div>
        },
        {
          label: "日志参数",
          prop: "params",
          render: (row) => <div>{row.params}</div>
        }, {
          label: "服务ip",
          prop: "client_ip",
          render: (row) => <div>{row.client_ip}</div>
        }, {
          label: "方法",
          prop: "method",
          render: (row) => <div>{row.method}</div>
        }, {
          label: "创建时间",
          prop: "create_time",
          render: (row) => <div>{row.create_time}</div>
        }
        // {
        //   prop: "",
        //   label: "操作",
        //   render: (row) => {
        //     return (
        //       <div>
        //         <el-button
        //           type="text"
        //           icon="Edit"
        //           onClick={() => handleEdit(row)}
        //         ></el-button>
        //         <el-button
        //           type="text"
        //           icon="Delete"
        //           onClick={() => handleDelete(row)}
        //         ></el-button>
        //       </div>
        //     );
        //   }
        // }
      ]
    });
    const searchForm = ref(null);

    function getData(page) {
      getList(page.current, page.limit, option.search).then(res => {
        if (res.data.success) {
          option.page.total = res.data.data.count;
          option.tableData = res.data.data.list;
        }
      });
    }

    function searchFormFn() {
      option.page.current = 1;
      getData(option.page);
    }

    function resetForm() {
      searchForm.value.resetFields();
      option.page.current = 1;
      getData(option.page);
    }

    function handleCurrentChange(currentPage) {
      option.page.current = currentPage;
      getData(option.page);
    }

    function handleSizeChange(pageSize) {
      option.page.limit = pageSize;
      getData(option.page);
    }

    onMounted(() => {
      getData(option.page);
    });
    return {
      searchForm,
      ...toRefs(option),
      getData,
      searchFormFn,
      resetForm,
      handleCurrentChange,
      handleSizeChange
    };
  }
};
</script>

<style scoped>

</style>