<template>
  <div class="formContainer">
    <div class="formBox">
      <el-form
        :model="form"
        :label-width="labelWidth"
        ref="addForm"
      >
        <!--        <el-row :gutter="24">-->

        <!--          <el-col :span="12" >-->
        <el-form-item
          label="平台名称"
          prop="name"
        >
          <el-input
            readonly
            autocomplete="off"
            v-model="form.name"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="平台访问地址"
          prop="url"
        >
          <el-input
            readonly
            autocomplete="off"
            v-model="form.url"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="平台联系电话"
          prop="phone"
        >
          <el-input
            readonly
            autocomplete="off"
            v-model="form.phone"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="平台联系人"
          prop="linkman"
        >
          <el-input
            readonly
            autocomplete="off"
            v-model="form.linkman"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="服务器地址"
          prop="address"
        >
          <el-input
            readonly
            autocomplete="off"
            v-model="form.address"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="平台纬度"
          prop="latitude"
        >
          <el-input
            readonly
            autocomplete="off"
            v-model="form.latitude"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="平台经度"
          prop="longitude"
        >
          <el-input
            readonly
            autocomplete="off"
            v-model="form.longitude"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="平台状态"
          prop="code"
        >
          <el-radio-group
            v-model="form.work_state">
            <el-radio :label="1">开机/运行正常</el-radio>
            <el-radio :label="2">关机/停止运行</el-radio>
            <el-radio :label="3">故障</el-radio>
            <el-radio :label="4">故障恢复</el-radio>
            <el-radio :label="5">播发中</el-radio>
          </el-radio-group>
        </el-form-item>
        <!--        <el-form-item-->
        <!--          label="各平台连接状态"-->
        <!--          prop="link_state"-->
        <!--        >-->
        <!--          <el-radio-group-->
        <!--            v-model="form.dataStatus"-->
        <!--            clearable-->
        <!--          >-->
        <!--            <el-radio :label="1">未连接</el-radio>-->
        <!--            <el-radio :label="2">连接</el-radio>-->
        <!--          </el-radio-group>-->
        <!--        </el-form-item>-->
        <div class="plate">
          <el-radio-group v-model="form.id" size="large">
            <el-radio-button v-for="(item,index) in form.list" :key="index" :label="item.id">{{ item.name
              }}：{{ item.link_state == 1 ? "未连接" : "已连接" }}
            </el-radio-button>
          </el-radio-group>
        </div>

        <!--          </el-col>-->

        <!--        </el-row>-->
        <!--          </el-col>-->
        <!--          <el-col :span="6">-->


        <!--          </el-col>-->
        <!--          <el-col :span="6">-->
        <!--              <el-form-item>-->
        <el-button plain @click="handleInfo('addForm')" class="searchBtn">信息上报</el-button>
        <el-button plain @click="handleState('addForm')" class="searchBtn">状态上报</el-button>
        <!--              </el-form-item>-->
        <!--          </el-col>-->
        <!--        </el-row>-->
      </el-form>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref, toRefs } from "vue";
import { ElMessage } from "element-plus";
import { getData, infoUp, statusUp } from "@/api/system/plateInfo";

export default {
  name: "plateInfo",
  setup() {
    const option = reactive({
      form: {
        list: []
      }
    });

    function handleData() {
      getData().then(res => {
        if (res.data.success) {
          console.log(res);
          option.form = res.data.data;
        }
      });
    }

    function handleInfo() {
      let { id } = option.form;
      infoUp({ id }).then(res => {
        if (res.data.success) {
          handleData();
          ElMessage({
            type: "success",
            message: "操作成功！"
          });
        }
      });
    }

    function handleState() {
      let { id, work_state } = option.form;
      statusUp({ id, code: work_state }).then(res => {
        if (res.data.success) {
          handleData();
          ElMessage({
            type: "success",
            message: "操作成功！"
          });
        }
      });
    }

    onMounted(async () => {
      handleData();
    });
    return {
      ...toRefs(option),
      handleInfo,
      handleState
    };
  }
};
</script>

<style scoped>
.formContainer {
  width: 100%;
  display: flex;
  justify-content: center;;
}

.formBox {
  width: 800px;
}

::v-deep .el-radio-button.el-radio-button--large.is-focus > .el-radio-button__inner {
  background-color: #148F75 !important;
  color: #00ffc6 !important;
}

.plate {
  margin-bottom: 20px;
}
</style>