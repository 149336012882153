<template>
  <div class="searchBox">
    <el-form :model="search" :label-width="searchLabelWidth" ref="searchForm">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="节目名称" prop="display_name">
            <el-input
              v-model="search.name"
              autocomplete="off"
              placeholder="请输入 节目名称"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="状态" prop="status">
            <el-select
              v-model="search.status"
              placeholder="请选择 状态"
              clearable
            >
              <el-option label="待审核" value="1"></el-option>
              <el-option label="审核通过" value="2"></el-option>
              <el-option label="审核不通过" value="3"></el-option>
              <el-option label="禁用" value="0"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <!--              <el-form-item>-->
          <el-button @click="searchForm('searchForm')" class="searchBtn"
            >查询</el-button
          >
          <el-button @click="resetForm('searchForm')" class="resetBtn"
            >重置</el-button
          >

          <!--              </el-form-item>-->
        </el-col>
      </el-row>
    </el-form>
    <!--    <div class="divide">-->
    <!--      <img src="../../assets/divide.png" style="width: 100%">-->
    <!--    </div>-->
    <!--    <div class="btnContainer">-->
    <!--      <div class="btnLeft">-->
    <!--        <el-button icon="Plus" @click="dialogFormVisible = true" class="searchBtn">新增</el-button>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
  <basicTable
    :height="525"
    :indexshow="false"
    :attrList="attrList"
    :tableData="tableData"
    ref="table"
    :page="page.current"
    :limit="page.limit"
    :total="page.total"
    @currentChange="handleCurrentChange"
    @sizeChange="handleSizeChange"
  >
  </basicTable>
  <el-dialog
    v-model="rejectFormVisible"
    title="审核否决"
    :close-on-click-modal="false"
  >
    <el-form
      label-position="right"
      :model="rejectForm"
      size="small"
      :label-width="formLabelWidth"
      ref="rejectForm"
    >
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            label="审核意见"
            :rules="[{ required: true, message: '审核意见 不能为空' }]"
            prop="check_opinion"
          >
            <el-input
              v-model="rejectForm.check_opinion"
              autocomplete="off"
              type="textarea"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="审核方联系电话"
            :rules="[{ required: true, message: '审核方联系电话 不能为空' }]"
            prop="check_phone"
          >
            <el-input
              v-model="rejectForm.check_phone"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button
          type="primary"
          size="small"
          @click="submitrejectForm('rejectForm')"
          >提交
        </el-button>
        <el-button @click="rejectFormVisible = false" size="small" type="info"
          >取消
        </el-button>
      </div>
    </template>
  </el-dialog>
  <el-dialog
    width="40%"
    v-model="detailFormVisible"
    title="详情"
    :close-on-click-modal="false"
  >
    <div class="addTable">
      <el-table
        ref="detailtable"
        cell-class-name="tdClass"
        header-cell-class-name="HtdClass"
        header-row-class-name="tableHeader"
        :data="detailtableData"
        style="width: 100%"
        align="center"
        empty-text="暂无内容"
        border
        :height="370"
      >
        <el-table-column prop="mediaName" label="节目名称" align="center" />
        <el-table-column prop="timeLength" label="播放时长" align="center" />
        <el-table-column
          prop="mediaAddr"
          label="资源"
          align="center"
          width="300"
        >
          <template #default="scope">
            <audio
                v-if="issourceType == 1"
                ref="audio"
                controls
                style="width: 100%"
                :src="scope.row.mediaAddr"
            ></audio>

            <el-button v-else-if="issourceType == 2" type="text" @click="showviode(scope.row.mediaAddr)">查看视频</el-button>
            <el-button v-else type="text"  @click="showImage(scope.row.mediaAddr)">查看图片</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-form
        class="addForm"
        label-position="right"
        :model="detailForm"
        size="small"
        label-width="130px"
        ref="detailForm"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="媒体资源名称" prop="name">
              <el-input v-model="detailForm.name" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="播放时长" prop="time_length">
              <el-input
                v-model="detailForm.time_length"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="播放模式" prop="play_mode">
              <el-select
                v-model="detailForm.play_mode"
                placeholder="请选择 播放模式"
                clearable
              >
                <el-option label="循环" value="loop"></el-option>
                <el-option label="一次" value="once"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="访问级别" prop="access_level">
              <el-select
                v-model="detailForm.access_level"
                placeholder="请选择 访问级别"
                clearable
              >
                <el-option label="私有" value="private"></el-option>
                <el-option label="本级" value="protect"></el-option>
                <el-option label="所有" value="public"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12" v-if="detailForm.scrollSubtitles">
            <el-form-item label="滚动字幕" prop="scrollSubtitles">
              <el-input v-model="detailForm.scrollSubtitles" autocomplete="off" readonly></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </el-dialog>

  <el-dialog append-to-body v-model="viodeVisible" :title="dtitle" width="30%">
    <video width="500" height="200" style="margin: 0 auto" controls :src="viodeUrl" v-if="dtitle == '查看视频'"></video>
    <el-image v-else style="width: 500px; height: 200px" :src="viodeUrl" :preview-src-list="[viodeUrl]"></el-image>
  </el-dialog>
</template>

<script>
import {
  getList,
  handlePass,
  handleReject,
} from "@/api/dailyBroad/programReview";
import { ref, reactive } from "vue";

export default {
  name: "programReview",
  setup() {
    const detailFormVisible = ref(false);
    const detailtableData = ref([]);
    // let detailForm = reactive({ data: {} });

    // function handleView(row) {
    //   detailFormVisible.value = true;
    //   detailtableData.value = JSON.parse(row.content);
    //   let { name, time_length, access_level, play_mode } = row;
    //   detailForm.data = { name, time_length, access_level, play_mode };
    // }

    return { detailFormVisible, detailtableData };
  },
  data() {
    return {
      dtitle:"",
      detailForm: {},
      issourceType:0,
      viodeVisible:false,
      viodeUrl:"",
      formLabelWidth: "150px",
      rejectFormVisible: false,
      rejectForm: {},
      form: {},
      searchLabelWidth: "140px",
      search: {},
      page: {
        current: 1,
        limit: 10,
        total: 0,
      },
      tableData: [],
      attrList: [
        {
          label: "节目名称",
          prop: "name",
          render: (row) => <div>{row.name}</div>,
        },
        {
          label: "播放时长",
          prop: "time_length",
          render: (row) => <div>{row.time_length}</div>,
        },
        // {
        //   width: 300,
        //   label: "音频",
        //   prop: "file_name",
        //   render: (row) => (
        //     <audio
        //       ref="audio"
        //       controls
        //       style="width:100%;height:30px"
        //       src={row.file_name}
        //       onPlay={() => this.play(row)}
        //     ></audio>
        //   ),
        // },
        {
          label: "播放模式",
          prop: "play_mode",
          render: (row) => {
            if (row.play_mode == "loop") return <div>循环</div>;
            else if (row.play_mode == "once") return <div>一次</div>;
          },
        },
        {
          label: "访问级别",
          prop: "access_level",
          render: (row) => {
            if (row.access_level == "private") return <div>私有</div>;
            else if (row.access_level == "protect") return <div>本级</div>;
            else if (row.access_level == "public") return <div>所有</div>;
          },
        },
        {
          label: "状态",
          prop: "status",
          render: (row) => {
            if (row.status == 1) return <div>待审核</div>;
            else if (row.status == 2) return <div>审核通过</div>;
            else if (row.status == 3) return <div>审核不通过</div>;
            else if (row.status == 0) return <div>禁用</div>;
          },
        },
        {
          prop: "",
          label: "操作",
          render: (row) => {
            if (row.status == 1)
              return (
                <div>
                  <el-button
                    type="text"
                    icon="Check"
                    onClick={() => this.pass(row)}
                  >
                    通过
                  </el-button>
                  <el-button
                    type="text"
                    icon="CircleClose"
                    onClick={() => this.reject(row)}
                    style="color: red"
                  >
                    不通过
                  </el-button>
                  <el-button
                    type="text"
                    icon="View"
                    onClick={() => this.handleView(row)}
                  >
                    查看
                  </el-button>
                </div>
              );
            else
              return (
                <div>
                  <el-button
                    type="text"
                    icon="View"
                    onClick={() => this.handleView(row)}
                  >
                    查看
                  </el-button>
                </div>
              );
          },
        },
      ],
    };
  },
  created() {
    this.getData(this.page);
  },
  methods: {
    showImage(url){
      this.dtitle = '查看图片';
      this.viodeUrl = url;
      this.viodeVisible = true;
    },

    showviode(url){
      this.dtitle = '查看视频';
      this.viodeUrl = url;
      this.viodeVisible = true;
    },

    extractHostname(url) {
      var hostname;
      if (url.indexOf("//") > -1) {
        hostname = url.split('/')[2];
      } else {
        hostname = url.split('/')[0];
      }
      hostname = hostname.split(':')[0];
      hostname = hostname.split('?')[0];
      return hostname;
    },

    handleView(row) {
      this.detailFormVisible = true;
      this.detailtableData = JSON.parse(row.content);
      let url = window.location.hostname;
      this.detailtableData.forEach((item, index)=>{
        this.detailtableData[index].mediaAddr = item.mediaAddr.replace(this.extractHostname(item.mediaAddr), url);
      })
      this.issourceType = row.sourceType;
      let { name, time_length, access_level, play_mode, scrollSubtitles } = row;
      this.detailForm = { name, time_length, access_level, play_mode, scrollSubtitles };
    },
    resetForm(formName) {
      this.search = {};
      this.page.current = 1;
      this.getData(this.page);
    },
    searchForm() {
      this.page.current = 1;
      this.getData(this.page);
    },
    getData(page) {
      getList(page.current, page.limit, this.search).then((res) => {
        this.tableData = res.data.data.list;
        this.page.total = res.data.data.count;
      });
    },
    pass(row) {
      handlePass(row.id).then((res) => {
        if (res.data.success) {
          this.getData(this.page);
          this.$message.success("操作成功");
        }
      });
    },
    reject(row) {
      this.rejectFormVisible = true;
      this.rejectForm = {
        id: row.id,
      };
    },
    submitrejectForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          handleReject(this.rejectForm).then((res) => {
            if (res.data.success) {
              this.getData(this.page);
              this.$message.success("操作成功");
              this.rejectFormVisible = false;
            }
          });
        }
      });
    },
    handleCurrentChange(currentPage) {
      this.page.current = currentPage;
      this.getData(this.page);
    },
    handleSizeChange(pageSize) {
      this.page.limit = pageSize;
      this.getData(this.page);
    },
  },
};
</script>

<style scoped>
.addForm {
  margin-top: 20px;
}
</style>
