<template>
  <div class="mainbox">
    <el-form ref="ruleFormRef" label-position="right" label-width="100px" :model="form" style="width: 660px">
      <el-form-item label="标题" prop="title" :rules="[{ required: true, message: '标题不能为空' }]">
        <el-input v-model="form.title" placeholder="请输入 标题" />
      </el-form-item>
      <el-form-item label="内容" prop="context" :rules="[{ required: true, message: '内容不能为空' }]">
        <el-input type="textarea" placeholder="请输入 内容" style="min-height: 210px" v-model="form.context" />
      </el-form-item>
    </el-form>
  </div>

  <div class="btnbox">
    <el-button class="searchBtn" @click="send">发送</el-button>
    <el-button class="searchBtn" @click="close">关闭</el-button>
    <el-button class="searchBtn" @click="cz">重置</el-button>
  </div>
</template>
<script>
import { sendData, closeData} from "@/api/basic/tvsubtitle";
import {ElMessage} from "element-plus";
export default {
  data(){
    return{
      form:{}
    }
  },
  methods:{
    send(){
      this.$refs['ruleFormRef'].validate((valid) => {
        if (valid) {
          let { title, context } = this.form;
          sendData({
            title, context
          }).then(res=>{
            if(res.data.success){
              ElMessage({message: `发送${res.data.code.message}`, type: 'success'});
            }
          })
        } else {
          return false
        }
      })
    },
    close(){
      closeData().then(res=>{
        if(res.data.success){
          ElMessage({message: `关闭${res.data.code.message}`, type: 'success'});
        }
      })
    },
    cz(){
      this.$nextTick(()=>{
        this.$refs['ruleFormRef'].resetFields();
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.mainbox{
  display: flex;
  align-items: center;
  justify-content: center;
}
.btnbox{
  margin: 0px 615px;
  display: flex;
  width: 560px;
  align-items: center;
  justify-content: space-between;
}
</style>