import axios from "@/api/axios"; // 引用axios
//获取分页数据
export const getList = (page, limit, params) => {
  const param = {
    ...params,
    page,
    limit,
  };
  return axios({
    url: "/api/manufacturer/listData",
    method: "post",
    params: param,
  });
};
//新增
export const addVendor = (params) => {
  return axios({
    url: "/api/manufacturer/addData",
    method: "post",
    params: params,
  });
};
//编辑
export const editVendor = (params) => {
  return axios({
    url: "/api/manufacturer/editData",
    method: "post",
    params: params,
  });
};
//删除
export const delVendor = (id) => {
  return axios({
    url: "/api/manufacturer/del",
    method: "post",
    params: { id },
  });
};
//详情
export const getVendorDetail = (id) => {
  return axios({
    url: "/api/manufacturer/detail",
    method: "post",
    params: { id },
  });
};
//获取所有厂家
export const getVendor = (params = {}) => {
  return axios({
    url: "/api/resource/model/manufacturer",
    method: "post",
    params: params,
  });
};
