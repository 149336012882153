<template>
  <div class="formContainer">
    <div class="formBox">
      <div class="aimTitle">
        <el-tag class="ml-2" type="success">网络参数设置</el-tag>
      </div>
      <el-form
        :model="form"
        :label-width="labelWidth"
        ref="addForm"
      >
        <!--        <el-row :gutter="24">-->

        <!--          <el-col :span="12" >-->
        <el-form-item
          label="适配器选择"
          prop="code"
          :rules="[{ required: true, message: '适配器 不能为空' }]"
        >
          <el-select
            :fit-input-width="true"
            v-model="form.code"
            placeholder="请选择 适配器"
          >
            <el-option :label="item.name" :value="item.code" v-for="(item,index) in adapterList"
                       :key="index"></el-option>
          </el-select>
        </el-form-item>
        <!--          </el-col>-->

        <!--        </el-row>-->
        <!--          </el-col>-->
        <!--          <el-col :span="6">-->
        <el-form-item
          label="IP地址"
          prop="ip"
          :rules="[{ required: true, message: 'IP地址 不能为空' }]"
        >
          <el-input
            clearable
            autocomplete="off"
            v-model="form.ip"
            placeholder="请输入 IP地址"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="子网掩码"
          prop="subnetMask"
          :rules="[{ required: true, message: '子网掩码 不能为空' }]"
        >
          <el-input
            clearable
            autocomplete="off"
            v-model="form.subnetMask"
            placeholder="请输入 子网掩码"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="网关"
          prop="gateway"
          :rules="[{ required: true, message: '网关 不能为空' }]"
        >
          <el-input
            clearable
            autocomplete="off"
            v-model="form.gateway"
            placeholder="请输入 网关"
          >
          </el-input>
        </el-form-item>
        <!--          </el-col>-->
        <!--          <el-col :span="6">-->
        <!--              <el-form-item>-->
        <el-button plain @click="addForm('addForm')" class="searchBtn">提交</el-button>
        <!--              </el-form-item>-->
        <!--          </el-col>-->
        <!--        </el-row>-->
      </el-form>
    </div>
  </div>
</template>

<script>
import { getAdapterList, adapterSet } from "@/api/orchestrate/manage";
export default {
  name: "mana",
  data() {
    return {
      form: {},
      adapterList: [],
      paramsList: [],
      labelWidth: "200px",
      regionList: [],
      treeSelectProps: {
        label: "name",
        value: "id",
        key: "code",
        children: "children"
      }
    };
  },
  created() {
    getAdapterList().then(res => {
      if (res.data.success) {
        this.adapterList = res.data.data.adapter;
      }
    });
  },

  methods: {
    addForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          adapterSet(this.form).then(res => {
            if (res.data.success) {
              this.$message.success("操作成功！");
              this.$refs[formName].resetFields();
              this.$refs.tree.setCheckedKeys([]);
            }
          });
        }
      });
    }
    // checkboxChange(value) {
    //   console.log(value);
    //   if (value.includes("all")) {
    //     this.form.param = ["all", "TERMINAL_VOLUME", "FM_KEEP_CMD_MODE", "FM_CURRENT_HZ_VALUE", "FM_HZ_VALUE_SCAN_LIST", "DVB_T_HZ", "DVB_C_HZ", "DVB_T_SIGNAL_STATUS",
    //       "DVB_C_SIGNAL_STATUS", "FM_SIGNAL_STATUS", "SOFTWARE_VERSION", "HARDWARE_VERSION", "DEVICE_TYPE", "FAULT_TYPE", "WORK_STATUS", "PHYSICAL_ADDRESS", "TERMINAL_RESOURCE_CODE", "REBACK_ADDRESS", "LOCAL_ADDRESS"];
    //   }
    // }
    // getSimpleCheckedNodes(store) {
    //   const checkedNodes = [];
    //   const traverse = function(node) {
    //     const childNodes = node.root ? node.root.childNodes : node.childNodes;
    //     childNodes.forEach(child => {
    //       if (child.checked) {
    //         checkedNodes.push(child.data);
    //       }
    //       if (child.indeterminate) {
    //         traverse(child);
    //       }
    //     });
    //   };
    //   traverse(store);
    //   return checkedNodes;
    // },
    // checkChange(node, status) {
    //   this.codeList = this.getSimpleCheckedNodes(this.$refs.tree.store);
    // }
  }
};
</script>

<style scoped>
.formContainer {
  width: 100%;
  display: flex;
  justify-content: center;;
}

.formBox {
  width: 800px;
}
.aimTitle {
  text-align: left;
  margin-bottom: 30px;
}
</style>