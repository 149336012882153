import axios from "@/api/axios"; // 引用axios
//获取分页数据
export const getList = (page, limit, params) => {
  const param = {
    ...params,
    page,
    limit
  };
  return axios({
    url: "/api/resource/equipment/listData",
    method: "post",
    params: param
  });
};
//新增
export const addData = (params) => {
  return axios({
    url: "/api/resource/equipment/addData",
    method: "post",
    params: params
  });
};
//编辑
export const editData = (params) => {
  return axios({
    url: "/api/resource/equipment/editData",
    method: "post",
    params: params
  });
};
//删除
export const delData = (id) => {
  return axios({
    url: "/api/resource/equipment/del",
    method: "get",
    params: { id }
  });
};
//详情
export const getDetail = (id) => {
  return axios({
    url: "/api/resource/equipment/detail",
    method: "get",
    params: { id }
  });
};
//根据选择区域 regionId和设备型号 model生成资源编码
export const getresourceCode = (params) => {
  return axios({
    url: "/api/resource/equipment/generateCode",
    method: "post",
    params: params
  });
};
//下载模板
export const getTemplete = () => {
  return axios({
    // headers:{
    //   responseType: "blob",
    // },
    url: "/api/resource/equipment/equipmentDownloadTem",
    method: "post",
    params: {}
  });
};
//证书更新
export const ertificateUpdate = (ids) => {
  return axios({
    url: "/api/resource/equipment/register",
    method: "post",
    params: { ids }
  });
};
//导入文件
export const importFile = (file) => {
  return axios({
    url: "/api/resource/equipment/addRegisterExcel",
    method: "post",
    data: file
  });
};

export const importFile2 = (file) => {
  return axios({
    url: "/api/resource/equipment/uploadRegister",
    method: "post",
    data: file
  });
};
