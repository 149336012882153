<template>
  <div>
    <div class="search-bar">
      <el-form class="search-form" :model="form" label-width="120px" ref="searchForm">
        <el-row :gutter="40">
          <el-col :span="6">
            <el-form-item prop="startTime" label="开始时间">
              <el-date-picker v-model="form.startTime" type="datetime" placeholder="请选择开始时间"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="endTime" label="结束时间">
              <el-date-picker v-model="form.endTime" type="datetime" placeholder="请选择结束时间"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="ebdType" label="运维数据类型">
              <el-select v-model="form.ebdType" clearable placeholder="请选择" size="large">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item prop="rptType" label="数据操作类型">
              <el-select v-model="form.rptType" clearable placeholder="请选择" size="large">
                <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col> <el-col :span="6">
            <el-form-item prop="rptType" label="目标系统">
              <el-select v-model="form.code" clearable placeholder="请选择" size="large">
                <el-option v-for="item in options3" :key="item.code" :label="item.name" :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" style="display: flex;">
            <el-button class="resetBtn" @click="sousuo">搜索</el-button>
            <el-button class="resetBtn" @click="chongzhi">重置</el-button>
            <el-button class="resetBtn" @click="sendOMDR">下发</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <basicTable
        :tableData="tableData"
        :attrList="attrList"
        :page="page.current"
        @currentChange="handleCurrentChange"
        @sizeChange="handleSizeChange"
        :limit="page.limit"
        :total="page.total">
    </basicTable>
  </div>
</template>

<script>
import { ref, reactive, toRefs, onMounted } from 'vue';
import { ElMessage } from 'element-plus';
import { getData, downloadTar, sendOMDRequest,getLower } from "@/api/devops/control";
export default {
  setup(){
    let searchForm = ref(null);
    onMounted(()=>{
      onLoad();
      getLower().then(res=>{
        if(res.data.success){
          state.options3=res.data.data;
        }
      })
    })

    function onLoad(parm = {}){
      getData({
        page:state.page.current,
        limit:state.page.limit,
        ...parm
      }).then(res=>{
        state.tableData = res.data.data.list;
        state.page.total = res.data.data.count;
      })
    }

    function chongzhi(){
      searchForm.value.resetFields();
      state.page.current = 1;
      state.page.limit = 10;
      onLoad();
    }

    function sousuo(){
      state.page.current = 1;
      state.page.limit = 10;
      onLoad(state.form);
    }

    function sendOMDR(){
      if(!state.form.ebdType) return ElMessage({
        message: '运维数据类型 不能为空',
        type: 'warning',
      })
      console.log(state.form)
      sendOMDRequest({ ...state.form}).then(res=>{
        if(res.data.code.status == 1) {
          ElMessage({message: '下发成功!', type: 'success'});
          searchForm.value.resetFields();
          state.page.current = 1;
          state.page.limit = 10;
          onLoad();
        }
      })
    }
    let state = reactive({
      form:{},
      tableData:[],
      attrList:[
        {
          prop: "sendEbdId",
          label: "发送数据包编号",
          render: (row) => <div>{row.sendEbdId}</div>,
        },
        {
          prop: "receiveEbdId",
          label: "响应数据包编号",
          render: (row) => <div>{row.receiveEbdId}</div>,
        },
        {
          prop: "omdDetail",
          label: "运维类型名称",
          render: (row) => <div>{row.omdDetail}</div>,
        },
        {
          prop: "omdType",
          label: "运维数据类型",
          render: (row) => <div>{row.omdType}</div>,
        },
        {
          prop: "receiveDesc",
          label: "tar包处理反馈",
          render: (row) => <div>{row.receiveDesc}</div>,
        },
        {
          prop: "receiveTime",
          label: "平台响应tar包时间",
          render: (row) => <div>{row.receiveTime}</div>,
        },
        {
          prop: "sendDesc",
          label: "接受反馈",
          render: (row) => <div>{row.sendDesc}</div>,
        },
        {
          prop: "sendTime",
          label: "发送tar时间",
          render: (row) => <div>{row.sendTime}</div>,
        },
        {
          prop: "targetName",
          label: "目标平台名称",
          render: (row) => <div>{row.targetName}</div>,
        },
        {
          prop: "",
          label: "下载发包",
          render: (row) => row.sendEbdId ? <el-button type="text" icon="Download" onClick={()=>download(row)}>下载</el-button> : <span></span>,
        },
        {
          prop: "",
          label: "下载收包",
          render: (row) => row.receiveEbdId ? <el-button type="text" icon="Download" onClick={()=>shoubao(row)}>下载</el-button> : <span></span>,
        },
      ],
      page:{
        current: 1,
        limit: 10,
        total: 0,
      },
      options2:[
        {
          value: 'Full',
          label: '全量数据',
        },
        {
          value: 'Incremental',
          label: '增量数据',
        }
      ],
      options3:[],
      options:[
        {
          value: 'EBRPSInfo',
          label: '应急广播平台信息',
        },
        {
          value: 'EBRSTInfo',
          label: '台站（前端）信息',
        },
        {
          value: 'EBRASInfo',
          label: '应急广播适配器信息',
        },
        {
          value: 'EBRBSInfo',
          label: '传输覆盖播出设备信息',
        },
        {
          value: 'EBRDTInfo',
          label: '平台设备及终端信息',
        },
        {
          value: 'EBMBrdLog',
          label: '播发记录',
        },
        {
          value: 'EBRPSState',
          label: '应急广播平台状态',
        },
        {
          value: 'EBRASState',
          label: '应急广播适配器状态',
        },
        {
          value: 'EBRBSState',
          label: '传输覆盖播出设备状态',
        },
        {
          value: 'EBRDTState',
          label: '平台设备及终端状态',
        },
      ]
    })

    function shoubao(row){
      downloadTar({ type:2, ebdId:row.receiveEbdId }).then(res=>{
        const blob = new Blob([res.data], {
          type: "application/x-tar"
        });
        let link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "数据包.tar");
        link.click();
        link = null;
      })
    }

    function download(row){
      downloadTar({ type:1, ebdId:row.sendEbdId }).then(res=>{
        const blob = new Blob([res.data], {
          type: "application/x-tar"
        });
        let link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "数据包.tar");
        link.click();
        link = null;
      })
    }

    function handleCurrentChange(currentPage) {
      state.page.current = currentPage;
      onLoad(state.form);
    }

    function handleSizeChange(pageSize) {
      state.page.limit = pageSize;
      onLoad(state.form);
    }
    return{
      handleCurrentChange,
      handleSizeChange,
      searchForm,
      chongzhi,
      sousuo,
      sendOMDR,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
.search-bar{
  width: 1690px;
  background: #0A2B28;
  border: 1px solid #178164;
  margin-bottom: 10px;
  .search-form{
    padding:20px 10px;
  }
  .operate{
    height: 100px;
    margin:0 10px;
    border-top:2px dashed #747474 ;
    .operate-btn{
      display: flex;
      align-items: center;
      height: 100%;
    }
  }
}
</style>