import axios from "@/api/axios"; // 引用axios

export const getData = (params) => {
    return axios({
        url: "/api/upgrade/listData",
        method: "post",
        params: params,
    });
};

export const getParam = (params) => {
    return axios({
        url: "/api/upgrade/param",
        method: "post",
        params: params,
    });
};


export const addParams = (params) => {
    return axios({
        url: "/api/upgrade/addParams",
        method: "post",
        params: params,
    });
}
export const submitData = (params) => {
    return axios({
        url: "/api/upgrade/upgradeDeviceToIpAll",
        method: "post",
        params: params,
    });
}

export const deviceOneIp = (params) => {
    return axios({
        url: "/api/upgrade/upgradeDeviceToDeviceOneIp",
        method: "post",
        params: params,
    });
}
export const upgradeOne = (deviceId) => {
    return axios({
        url: "/api/upgrade/getDeviceVersionOne",
        method: "post",
        params: {
            deviceId
        },
    });
}
