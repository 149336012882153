<template>
  <div>
    <el-form :model="form" label-width="160px" ref="datafom">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="服务证书编号">
            <el-input readonly v-model="form.certSn" placeholder="请输入 设备名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="服务IP">
            <el-input readonly v-model="form.ip" placeholder="请输入 设备编号"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="服务端口">
            <el-input readonly v-model="form.port" placeholder="请输入 选择区域"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="提供商">
            <el-input readonly v-model="form.provider" placeholder="请输入 选择区域"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="安全服务代理接口">
            <el-input readonly v-model="form.url" placeholder="请输入 选择区域"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <el-form label-width="160px">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="是否开启指令签名">
            <el-switch v-model="form.signature" size="large" active-text="开启" inactive-text="关闭" @change="changeSignature"/>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="是否开启指令验签">
            <el-switch v-model="form.attestation" size="large" active-text="开启" inactive-text="关闭" @change="changeAttestation"/>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="签名服务启动状态">
            <el-switch v-model="form.status" size="large" active-text="开启" inactive-text="关闭" @change="changeStatus" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { reactive, onMounted, toRefs } from 'vue';
import { ElMessage } from 'element-plus';
import { editData, getDetail } from '@/api/safe/pwd'
export default {
  setup(){
    let state = reactive({
      form:{}
    })

    onMounted(()=>{
      getDetail().then(res=>state.form = res.data.data)
    })

    function statusChange(type,status){
      editData({
        type,
        status
      }).then(res=>{
        if(res.data.success){
          ElMessage({message: res.data.code.message, type: 'success'})
        }
      })
    }

    function changeSignature(status){
      statusChange(1,status);
    }

    function changeAttestation(status){
      statusChange(2,status);
    }

    function changeStatus(status){
      statusChange(3,status);
    }
    return{
      changeSignature,
      changeAttestation,
      changeStatus,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>

</style>