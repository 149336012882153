<template>
  <div class="searchBox">
    <el-form :model="search" :label-width="searchLabelWidth" ref="searchForm">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="媒体资源名称" prop="display_name">
            <el-input
              v-model="search.display_name"
              autocomplete="off"
              placeholder="请输入 媒体资源名称"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="状态" prop="status">
            <el-select
              v-model="search.status"
              placeholder="请选择 状态"
              clearable
            >
              <el-option label="待审核" value="1"></el-option>
              <el-option label="审核通过" value="2"></el-option>
              <el-option label="审核不通过" value="3"></el-option>
              <el-option label="禁用" value="0"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <!--              <el-form-item>-->
          <el-button @click="searchForm('searchForm')" class="searchBtn"
            >查询</el-button
          >
          <el-button @click="resetForm('searchForm')" class="resetBtn"
            >重置</el-button
          >

          <!--              </el-form-item>-->
        </el-col>
      </el-row>
    </el-form>
    <!--    <div class="divide">-->
    <!--      <img src="../../assets/divide.png" style="width: 100%">-->
    <!--    </div>-->
    <!--    <div class="btnContainer">-->
    <!--      <div class="btnLeft">-->
    <!--        <el-button icon="Plus" @click="dialogFormVisible = true" class="searchBtn">新增</el-button>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
  <basicTable
    :height="525"
    :indexshow="false"
    :attrList="attrList"
    :tableData="tableData"
    ref="table"
    :page="page.current"
    :limit="page.limit"
    :total="page.total"
    @currentChange="handleCurrentChange"
    @sizeChange="handleSizeChange"
  >
  </basicTable>
  <el-dialog
    v-model="rejectFormVisible"
    title="审核否决"
    :close-on-click-modal="false"
  >
    <el-form
      label-position="right"
      :model="rejectForm"
      size="small"
      :label-width="formLabelWidth"
      ref="rejectForm"
    >
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            label="审核意见"
            :rules="[{ required: true, message: '审核意见 不能为空' }]"
            prop="check_opinion"
          >
            <el-input
              v-model="rejectForm.check_opinion"
              autocomplete="off"
              type="textarea"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="审核方联系电话"
            :rules="[{ required: true, message: '审核方联系电话 不能为空' }]"
            prop="check_phone"
          >
            <el-input
              v-model="rejectForm.check_phone"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button
          type="primary"
          size="small"
          @click="submitrejectForm('rejectForm')"
          >提交
        </el-button>
        <el-button @click="rejectFormVisible = false" size="small" type="info"
          >取消
        </el-button>
      </div>
    </template>
  </el-dialog>
  <el-dialog append-to-body v-model="viodeVisible" :title="dtitle" width="30%">
    <video width="500" height="200" style="margin: 0 auto" controls :src="viodeUrl" v-if="dtitle == '查看视频'"></video>
    <el-image v-else style="width: 500px; height: 200px" :src="viodeUrl" :preview-src-list="[viodeUrl]"></el-image>
  </el-dialog>

</template>

<script>
import {
  getList,
  handlePass,
  handleReject,
} from "@/api/dailyBroad/mediaRreview";

export default {
  name: "mediaRreview",
  data() {
    return {
      dtitle:"",
      viodeVisible:false,
      viodeUrl:"",
      formLabelWidth: "150px",
      rejectFormVisible: false,
      rejectForm: {},
      form: {},
      searchLabelWidth: "140px",
      search: {},
      page: {
        current: 1,
        limit: 10,
        total: 0,
      },
      tableData: [],
      attrList: [
        {
          label: "媒体资源名称",
          prop: "display_name",
          render: (row) => <div>{row.display_name}</div>,
        },
        {
          label: "播放时长",
          prop: "time_length",
          render: (row) => <div>{row.time_length}</div>,
        },
        {
          label: "资源",
          prop: "file_name",
          render: (row) => {
            if (row.sourceType == 1) {
              return <audio
                  ref="audio"
                  controls
                  style="width:100%"
                  src={row.file_name}
                  onPlay={() => this.play(row)}
              ></audio>
            } else if(row.sourceType == 2) {
              return <el-button type="text" onClick={() => this.showviode(row.file_name)}>查看视频</el-button>
            }else {
              return <el-button type="text" onClick={()=>this.showImage(row.file_name)}>查看图片</el-button>
            }
          }
        },
        {
          label: "所属区域",
          prop: "region_name",
          render: (row) => <div>{row.region_name}</div>,
        },
        {
          label: "访问级别",
          prop: "access_level",
          render: (row) => {
            if (row.access_level == "private") return <div>私有</div>;
            else if (row.access_level == "protect") return <div>本级</div>;
            else if (row.access_level == "public") return <div>所有</div>;
          },
        },
        {
          label: "状态",
          prop: "status",
          render: (row) => {
            if (row.status == 1) return <div>待审核</div>;
            else if (row.status == 2) return <div>审核通过</div>;
            else if (row.status == 3) return <div>审核不通过</div>;
            else if (row.status == 0) return <div>禁用</div>;
          },
        },
        {
          prop: "",
          label: "操作",
          render: (row) => {
            if (row.status == 1)
              return (
                <div>
                  <el-button
                    type="text"
                    icon="Check"
                    onClick={() => this.pass(row)}
                  >
                    通过
                  </el-button>
                  <el-button
                    type="text"
                    icon="CircleClose"
                    onClick={() => this.reject(row)}
                    style="color: red"
                  >
                    不通过
                  </el-button>
                </div>
              );
            else return <el-tag type="success">已操作</el-tag>;
          },
        },
      ],
    };
  },
  created() {
    this.getData(this.page);
  },
  methods: {
    showImage(url){
      this.dtitle = '查看图片';
      this.viodeUrl = url;
      this.viodeVisible = true;
    },

    showviode(url){
      this.dtitle = '查看视频';
      this.viodeUrl = url;
      this.viodeVisible = true;
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.page.current = 1;
      this.getData(this.page);
    },
    searchForm() {
      this.page.current = 1;
      this.getData(this.page);
    },

    extractHostname(url) {
      var hostname;
      if (url.indexOf("//") > -1) {
        hostname = url.split('/')[2];
      } else {
        hostname = url.split('/')[0];
      }
      hostname = hostname.split(':')[0];
      hostname = hostname.split('?')[0];
      return hostname;
    },

    getData(page) {
      getList(page.current, page.limit, this.search).then((res) => {
        this.tableData = res.data.data.list;
        let url = window.location.hostname;
        this.tableData.forEach((item, index)=>{
          this.tableData[index].file_name = item.file_name.replace(this.extractHostname(item.file_name), url);
        })
        this.page.total = res.data.data.count;
      });
    },
    play(row) {
      console.log(row);
    },
    pass(row) {
      handlePass(row.id).then((res) => {
        if (res.data.success) {
          this.getData(this.page);
          this.$message.success("操作成功");
        }
      });
    },
    reject(row) {
      this.rejectFormVisible = true;
      this.rejectForm = {
        id: row.id,
      };
    },
    submitrejectForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          handleReject(this.rejectForm).then((res) => {
            if (res.data.success) {
              this.getData(this.page);
              this.$message.success("操作成功");
              this.rejectFormVisible = false;
            }
          });
        }
      });
    },
    handleCurrentChange(currentPage) {
      this.page.current = currentPage;
      this.getData(this.page);
    },
    handleSizeChange(pageSize) {
      this.page.limit = pageSize;
      this.getData(this.page);
    },
  },
};
</script>

<style scoped></style>
