import axios from "@/api/axios"; // 引用axios
//获取待处理列表
export const release = (params) => {
  return axios({
    headers: {
      "Content-Type": "multipart/form-data"
    },
    url: "/api/send/gdj/startBCastEbm",
    method: "post",
    data: params
  });
};