<template>
  <div class="tableBox">
    <el-table
      ref="table"
      :height="height"
      @selection-change="handleSelection"
      cell-class-name="tdClass"
      header-cell-class-name="HtdClass"
      header-row-class-name="tableHeader"
      :data="tableData"
      style="width: 100%"
      align="center"
      empty-text="暂无内容"
      row-key="id"
      border
    >
      <el-table-column
        type="index"
        width="80"
        align="center"
        v-if="indexshow"
      />
      <el-table-column
        v-for="(item, index) in attrList"
        :key="index"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
        show-overflow-tooltip
      >
        <template #default="scope">
          <dataContent :data="scope.row" :render="item.render" />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "tableNoPage",
  props: {
    tableData: {
      type: Array,
      default: function() {
        return [];
      }
    },
    attrList: {
      type: Array,
      default: function() {
        return [];
      }
    },

    indexshow: {
      type: Boolean,
      default: true
    },
    pageShow: {
      type: Boolean,
      default: true
    },
    height: {
      type: Number,
      default: 400
    }
    // height: {
    //   type: Number,
    //   default: 525,
    // },
    // tableBoxHeight: {
    //   type: String,
    //   default: "615PX",
    // },
  },
  components: {
    dataContent: {
      functional: true,
      props: {
        data: {
          type: Object,
          default: () => {
          }
        },
        render: {
          type: Function
        }
      },
      render(ctx) {
        return ctx.render(ctx.data);
      }
    }
  },
  data() {
    return {};
  },
  methods: {
    handleSelection(value) {
      this.$emit("selectChange", value);
    },
    clearSelect() {
      this.$refs.table.clearSelection();
    }
  }
};
</script>

<style scoped>

</style>