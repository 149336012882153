<template>
  <div class="searchBox">
    <el-form :model="search" :label-width="searchLabelWidth" ref="searchForm">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item
            label="文件类型"
            prop="code"
          >
            <el-select v-model="search.type" placeholder="请选择 文件类型">
              <el-option label="视频" :value="0" />
              <el-option label="图片" :value="1" />
              <el-option label="文字" :value="2" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            label="状态"
            prop="code"
          >
            <el-select v-model="search.status" placeholder="请选择 状态">
              <el-option label="待审核" :value="0" />
              <el-option label="通过" :value="1" />
              <el-option label="未通过" :value="2" />
              <el-option label="禁用" :value="3" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            label="文件名称"
            prop="fileName"
          >
            <el-input v-model="search.fileName" placeholder="请输入  文件名称" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <!--              <el-form-item>-->
          <el-button plain @click="searchFormFn('searchForm')" class="searchBtn" icon="Search"
          >查询
          </el-button
          >
          <el-button plain @click="resetForm('searchForm')" class="resetBtn"
          >清空
          </el-button
          >

          <!--              </el-form-item>-->
        </el-col>
      </el-row>
    </el-form>
    <!--    <div class="divide">-->
    <!--      <img src="../../assets/divide.png" style="width: 100%" />-->
    <!--    </div>-->
    <!--    <div class="btnContainer">-->
    <!--      <div class="btnLeft">-->
    <!--        <el-button-->
    <!--          @click="handleInfoUp(1)"-->
    <!--          class="searchBtn"-->
    <!--        >状态上报-->
    <!--        </el-button>-->
    <!--        <el-button-->
    <!--          @click="handleInfoUp(2)"-->
    <!--          class="searchBtn"-->
    <!--        >信息上报-->
    <!--        </el-button>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
  <basicTable
    :attrList="attrList"
    :tableData="tableData"
    ref="table"
    :page="page.current"
    :limit="page.limit"
    :total="page.total"
    @currentChange="handleCurrentChange"
    @sizeChange="handleSizeChange"
  >
  </basicTable>
  <el-dialog
    width="60%"
    v-model="dialogFormVisible"
    :title="title"
    :close-on-click-modal="false"
  >
    <el-form
      label-position="right"
      :model="form"
      size="small"
      :label-width="formLabelWidth"
      ref="dialogForm"
    >
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            label="型号名称"
            :rules="[{ required: true, message: '型号名称 不能为空' }]"
            prop="name"
          >
            <el-input
              v-model="form.name"
              autocomplete="off"
              clearable
              placeholder="请输入 型号名称"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="型号编码"
            prop="code"
            :rules="[
              { required: true, message: '型号编码 不能为空', trigger: 'blur' },
            ]"
          >
            <el-input
              clearable
              type="tel"
              autocomplete="off"
              v-model="form.code"
              placeholder="请输入 型号编码"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            label="厂家"
            :rules="[{ required: true, message: '厂家 不能为空' }]"
            prop="manufacturerId"
          >
            <el-select
              v-model="form.manufacturerId"
              placeholder="请选择 厂家"
              clearable
            >
              <el-option
                v-for="item in vendorList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="设备类型"
            :rules="[{ required: true, message: '设备类型 不能为空' }]"
            prop="resourceType"
          >
            <el-select
              v-model="form.resourceType"
              placeholder="请选择 设备类型"
              clearable
            >
              <el-option
                v-for="item in deviceType"
                :key="item.id"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button
          type="primary"
          size="small"
          @click="handlesubmit('dialogForm')"
        >提交
        </el-button>
        <el-button @click="dialogFormVisible = false" size="small"
        >取消
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { reactive, ref, toRefs, onMounted } from "vue";
import { getData } from "@/api/profile/localProfile";

export default {
  name: "localProfile",
  setup() {
    const option = reactive({
      title: "新增",
      dialogFormVisible: false,
      form: {},
      searchLabelWidth: "140px",
      search: {
        code: "02"
      },
      page: {
        current: 1,
        limit: 10,
        total: 0
      },
      tableData: [],
      equipType: [],
      ids: [],
      attrList: [
        {
          label: "文件名",
          prop: "fileName",
          render: (row) => (<div>{row.fileName}</div>)
        }, {
          label: "映射路径",
          prop: "fileUrl",
          render: (row) => (<div>{row.fileUrl}</div>)
        },
        {
          label: "地址",
          prop: "diskUrl",
          render: (row) => <div>{row.diskUrl}</div>
        }, {
          label: "状态",
          prop: "status",
          render: (row) => <div>{row.status == 0 ? "未通过" : "通过"}</div>
        }, {
          label: "级别",
          prop: "level",
          render: (row) => <div>{row.level}</div>
        }, {
          label: "类型",
          prop: "type",
          render: (row) => {
            if (row.type == 0) return <div>视频</div>;
            if (row.type == 1) return <div>图片</div>;
            if (row.type == 2) return <div>文字</div>;
          }
        }, {
          label: "启用状态",
          prop: "isForbidden",
          render: (row) => {
            if (row.isForbidden) return <div>禁用</div>;
            else return <div>启用</div>;
          }
        }
      ]
    });
    const searchForm = ref();

    function getPaging(page) {
      getData(page.current, page.limit, option.search).then(res => {
        if (res.data.success) {
          option.tableData = res.data.data.list;
          option.page.total = res.data.data.count;
        }
      });
    }

    function searchFormFn() {
      option.page.current = 1;
      getPaging(option.page);
    }

    function handleCurrentChange(currentPage) {
      option.page.current = currentPage;
      getPaging(option.page);
    }

    function handleSizeChange(pageSize) {
      option.page.limit = pageSize;
      getPaging(option.page);
    }

    function resetForm() {
      option.search = {};
      option.page.current = 1;
      getPaging(option.page);
    }

    onMounted(() => {
      getPaging(option.page);
    });
    return {
      searchForm,
      getPaging,
      resetForm,
      handleSizeChange,
      handleCurrentChange,
      searchFormFn,
      ...toRefs(option)
    };
  }
};
</script>

<style scoped>

</style>