import axios from "@/api/axios"; // 引用axios

export const getData = (params) => {
  return axios({
    url: "/api/camera/listData",
    method: "post",
    params: params,
  });
};


export const disOrEnb = (params) => {
  return axios({
    url: "/api/camera/disOrEnb",
    method: "post",
    params: params,
  });
};

export const dataMedia = (params) => {
  return axios({
    url: "/api/camera/getMedia",
    method: "post",
    params: params,
  });
};

export const dataResource = (params) => {
  return axios({
    url: "/api/camera/resourceData",
    method: "post",
    params: params,
  });
};

export const dataBind = (params) => {
  return axios({
    url: "/api/camera/bindListData",
    method: "post",
    params: params,
  });
};

export const dataDel = (params) => {
  return axios({
    url: "/api/camera/del",
    method: "post",
    params: params,
  });
};

export const dataType = (params) => {
  return axios({
    url: "/api/camera/codeType",
    method: "post",
    params: params,
  });
};

export const dataSave = (params) => {
  return axios({
    url: "/api/camera/addData",
    method: "post",
    params: params,
  });
};

export const dataEdit = (params) => {
  return axios({
    url: "/api/camera/editData",
    method: "post",
    params: params,
  });
};

export const dataTree = (params) => {
  return axios({
    url: "/api/region/regionTree",
    method: "post",
    params: params,
  });
};

export const childBind = (params) => {
  return axios({
    url: "/api/camera/bind",
    method: "post",
    params: params,
  });
};

export const unBind = (params) => {
  return axios({
    url: "/api/camera/unBind",
    method: "post",
    params: params,
  });
};

export const mediaData = (params) => {
  return axios({
    url: "/api/camera/mediaData",
    method: "post",
    params: params,
  });
};

export const companyList = (params) => {
  return axios({
    url: "/api/camera/companyList",
    method: "post",
    params: params,
  });
};



