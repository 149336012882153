import axios from "@/api/axios"; // 引用axios

export const cameraList = (params) => {
    return axios({
        url: "/api/camera/getUserCameraList",
        method: "post",
        params: params,
    });
};

export const cameraDetail = (params) => {
    return axios({
        url: "/api/camera/getCameraDetail",
        method: "post",
        params: params,
    });
};

export const startBCast = (params) => {
    return axios({
        url: "/api/web/video/startBCast",
        method: "post",
        params: params,
    });
};

export const stopBCast = (params) => {
    return axios({
        url: "/api/web/video/stopBCast",
        method: "post",
        params: params,
    });
};

export const videoDetail = (params) => {
    return axios({
        url: "/api/camera/getWebVideoDetail",
        method: "post",
        params: params,
    });
};
