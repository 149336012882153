import axios from "@/api/axios"; // 引用axios
// 获取列表
export const getList = (page, limit, params) => {
  const param = {
    ...params,
    page,
    limit
  };
  return axios({
    url: "/api/database/record/listData",
    method: "post",
    params: param
  });
};
// 删除数据
export const delData = (id) => {
  return axios({
    url: "/api/database/record/del",
    method: "post",
    params: {
      id
    }
  });
};// 下载备份包
export const downloadData = (id) => {
  return axios({
    url: "/api/database/record/download",
    method: "get",
    params: {
      id
    }
  });
};

export const savemysql = ()=>{
  return axios({
    url: "/api/database/record/saveMysql",
    method: "post",
    timeout: 90000,
  })
}



