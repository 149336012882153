import axios from "@/api/axios"; // 引用axios
//获取分页数据
export const getList = (page, limit, params) => {
  const param = {
    ...params,
    page,
    limit
  };
  return axios({
    url: "/api/adapter/white/listData",
    method: "post",
    params: param
  });
};
//新增或修改
export const addEditData = (params) => {
  return axios({
    url: "/api/adapter/white/addOrEdit",
    method: "post",
    params: params
  });
};
//删除
export const delData = (id) => {
  return axios({
    url: "/api/adapter/white/del",
    method: "post",
    params: {
      id
    }
  });
};
//获取白名单页面参数
export const getParams = (params={}) => {
  return axios({
    url: "/api/adapter/white/params",
    method: "post",
    params: params
  });
};//获取白名单页面参数
export const upCalibrate = (params={}) => {
  return axios({
    url: "/api/adapter/white/calibration",
    method: "post",
    params: params
  });
};