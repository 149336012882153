<template>
  <!--  <div class="bigBox">-->
  <div class="searchBox">
    <el-form :model="search" :label-width="searchLabelWidth" ref="searchForm">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="菜单名称" prop="name">
            <el-input
              v-model="search.name"
              autocomplete="off"
              placeholder="请输入 菜单名称"
            ></el-input>
          </el-form-item>
        </el-col>
<!--        <el-col :span="6">-->
<!--          <el-form-item label="菜单路径" prop="url">-->
<!--            <el-input-->
<!--              v-model="search.url"-->
<!--              autocomplete="off"-->
<!--              placeholder="请输入 菜单路径"-->
<!--            ></el-input>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
        <el-col :span="6">
          <el-button @click="searchForm('searchForm')" class="searchBtn"
          >查询
          </el-button
          >
          <el-button @click="resetForm('searchForm')" class="resetBtn"
          >重置
          </el-button
          >
        </el-col>
      </el-row>
    </el-form>
    <div class="divide">
      <img src="../../assets/divide.png" style="width: 100%" />
    </div>
    <div class="btnContainer">
      <div class="btnLeft">
        <el-button icon="Plus" @click="handleAdd" class="searchBtn"
        >新增
        </el-button
        >
      </div>
    </div>
  </div>
  <div class="tableBox">
    <!--    <div class="addBtn">-->
    <!--      <el-button-->
    <!--        type="primary"-->
    <!--        size="small"-->
    <!--        icon="ZoomIn"-->
    <!--        @click="dialogFormVisible = true"-->
    <!--      >新增-->
    <!--      </el-button-->
    <!--      >-->
    <!--    </div>-->
    <el-table
      cell-class-name="tdClass"
      header-cell-class-name="HtdClass"
      header-row-class-name="tableHeader"
      :data="tableData"
      :expand-row-keys="expands"
      style="width: 100%; margin-bottom: 20px"
      rowKey="id"
      :border="true"
    >
      <el-table-column prop="name" label="菜单名称" />
      <el-table-column prop="url" label="菜单路径" />
      <el-table-column prop="perms" label="权限" />
      <el-table-column prop="type" label="类型">
        <template #default="scope">
          {{ typearr[scope.row.type] }}
        </template>
      </el-table-column>

      <el-table-column prop="orderNum" label="排序" />
      <el-table-column label="操作" align="center">
        <template #default="scope">
          <el-button
            type="text"
            size="mini"
            @click="handleEdit(scope.$index, scope.row)"
            icon="Edit"
          >
            编辑
          </el-button>
          <el-button
            icon="Delete"
            size="mini"
            type="text"
            @click="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      ref="dialog"
      @close="close"
      destroy-on-close
      v-model="dialogFormVisible"
      :title="title"
      :close-on-click-modal="false"
    >
      <el-form
        :model="form"
        size="small"
        :label-width="formLabelWidth"
        ref="dialogForm"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              label="菜单名称"
              :rules="[{ required: true, message: '菜单名称不能为空' }]"
              prop="name"
            >
              <el-input placeholder="请输入 菜单名称" v-model="form.name" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="菜单路径"
              :rules="[{ required: true, message: '菜单路径不能为空' }]"
              prop="url"
            >
              <el-input placeholder="请输入 菜单路径" v-model="form.url" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
                label="菜单排序"
                :rules="[{ required: true, message: '排序不能为空' }]"
                prop="orderNum"
            >
              <el-input
                  v-model.number="form.orderNum"
                  type="number"
                  autocomplete="off"
                  placeholder="请输入 菜单排序"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="类型"
              :rules="[{ required: true, message: '菜单类型不能为空' }]"
              prop="type"
            >
              <el-select
                :fit-input-width="true"
                v-model="form.type"
                placeholder="请选择 菜单类型"
              >
                <el-option label="一级菜单" :value="0"></el-option>
                <el-option label="二级菜单" :value="1"></el-option>
                <el-option label="菜单 / 接口" :value="2"></el-option>
                <el-option label="按钮" :value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="上级菜单" prop="parentId">
              <selectTree
                  :treeData="tableData"
                  @selectTreeChange="selectTreeChange"
                  :propsId="propsId"
                  :disabled="disabled"
              ></selectTree>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="菜单图标" prop="icon">
              <el-input
                v-model.number="form.icon"
                autocomplete="off"
                @click="iconVisible = true"
                readonly
                placeholder="请选择 菜单图标"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row
        >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="权限标识" prop="perms">
              <el-input
                v-model.number="form.perms"
                autocomplete="off"
                type="number"
                placeholder="请选择 权限标识"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" size="small" @click="submit('dialogForm')"
          >提交
          </el-button>
          <el-button @click="dialogFormVisible = false" size="small" type="info"
          >取消
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog
      v-model="iconVisible"
      title="图标"
      width="80%"
      :close-on-click-modal="false"
    >
      <div class="icon-container">
        <div
          v-for="(item, index) in iconList"
          :key="index"
          class="itemBox"
          @click="iconselect(item)"
        >
          <el-icon class="iconItem">
            <component :is="item"></component>
            <p>{{ item }}</p>
          </el-icon>
        </div>
      </div>
    </el-dialog>
  </div>
  <!--  </div>-->
</template>

<script>
import { ref, defineComponent, reactive, toRefs, watch } from "vue";
import { getMenuMana, addMenu, editMenu, delMenu } from "@/api/system/menu";
import { ElMessage } from "element-plus";
import iconList from "@/config/iconList";
import selectTree from "@/components/selectTree";

export default {
  name: "menu",
  components: { selectTree },
  setup() {
    const form = ref({});
    function close() {
      for (let key in form.value) {
        form.value[key] = "";
      }
    }
    const typearr = reactive(["一级目录", "二级目录", "菜单/接口", "按钮"]);
    return { typearr, close, form };
  },
  data() {
    return {
      expands:[],
      disabled: false,
      propsId: "",
      search: {},
      searchLabelWidth: "100px",
      formLabelWidth: "22%",
      tableData: [],
      title: "新增",
      dialogFormVisible: false,
      iconVisible: false,

      iconList: []
    };
  },
  created() {
    this.iconList = iconList;
    this.getData();
  },
  methods: {
    handleAdd() {
      this.propsId=''
      this.title = "新增";
      this.dialogFormVisible = true;
      this.disabled = false;
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.search = {};
      this.getData(this.search);
    },
    searchForm() {
      this.expands = [];
      let { name } = this.search;
      for(let i =0,len=this.tableData.length;i<len;i++){
        if(name == this.tableData[i].name){
          this.$nextTick(()=>{
            this.expands[0] = (this.tableData[i].id).toString();
          })
        }
        if(this.tableData[i].children.length > 0){
          for(let j =0,len2=this.tableData[i].children.length;j<len2;j++){
            if(name == this.tableData[i].children[j].name){
              this.$nextTick(()=>{
                this.expands[0] = (this.tableData[i].id).toString();
                this.expands[1] = (this.tableData[i].children[j].id).toString();
              })
            }
            if(this.tableData[i].children[j].children.length > 0){
              for(let k =0,len3=this.tableData[i].children[j].children.length;k<len3;k++){
                if(name == this.tableData[i].children[j].children[k].name){
                  this.$nextTick(()=>{
                    this.expands[0] = (this.tableData[i].id).toString();
                    this.expands[1] = (this.tableData[i].children[j].id).toString();
                    this.expands[2] = (this.tableData[i].children[j].children[k].id).toString();
                  })
                }
              }
            }
          }
        }
      }
    },
    getData(params = {}) {
      getMenuMana(params).then((res) => {
        if (res.data.success) {
          this.tableData = res.data.data;
        } else {
          ElMessage({
            message: res.data.message,
            type: "error"
          });
        }
      });
    },
    iconselect(item) {
      this.iconVisible = false;
      this.form.icon = item;
    },
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.form.parentId = this.form.parentId ? this.form.parentId : 0;
          console.log(this.form);
          if (this.title == "新增") {
            addMenu(this.form).then((res) => {
              if (res.data.success) {
                this.getData();
                this.$refs[formName].resetFields();
                ElMessage({
                  message: "操作成功",
                  type: "success"
                });
                this.dialogFormVisible = false;
              } else {
                ElMessage({
                  message: res.data.code.message,
                  type: "error"
                });
              }
            });
          } else {
            editMenu(this.form).then((res) => {
              if (res.data.success) {
                this.getData();
                this.$refs[formName].resetFields();
                ElMessage({
                  message: "操作成功",
                  type: "success"
                });
                this.dialogFormVisible = false;
              } else {
                ElMessage({
                  message: res.data.code.message,
                  type: "error"
                });
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    handleEdit(index, row) {
      let { parentId, id, icon, name, perms, type, orderNum, url } = row;
      this.propsId = parentId;
      this.form = { parentId, id, icon, name, perms, type, orderNum, url };
      this.dialogFormVisible = true;
      this.title = "编辑";
      this.disabled = true;
    },
    handleDelete(index, row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        delMenu({ id: row.id }).then((res) => {
          this.getData();
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        });
      });
    },
    selectTreeChange(value) {
      this.form.parentId = value;
    }
  }
};
</script>

<style scoped>
.addBtn {
  text-align: left;
}

.dialog-footer {
  display: flex;
  justify-content: center;
}

.icon-container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.iconItem {
  width: 40px;
  height: 40px;
}

.iconItem svg {
  height: 40px;
  width: 40px;
}

.itemBox {
  margin: 10px;
}

.iconItem p {
  font-size: 14px;
}

.bigBox {
  height: 875px;
  overflow-y: scroll;
}
</style>
