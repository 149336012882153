import axios from "@/api/axios"; // 引用axios

export const getregion = (page, limit, params) => {
  const param = {
    ...params,
    page,
    limit
  };
  return axios({
    url: "/api/region/listData",
    method: "post",
    params: param
  });
};
//获取树形结构
export const getTreeData = () => {
  return axios({
    url: "/api/region/regionTree",
    method: "post",
    params: {}
  });
};
//新增区域
export const addData = (params) => {
  return axios({
    url: "/api/region/addData",
    method: "post",
    params: params
  });
};
//编辑区域
export const editData = (params) => {
  return axios({
    url: "/api/region/editData",
    method: "post",
    params: params
  });
};
//删除区域
export const delData = (id) => {
  return axios({
    url: "/api/region/del",
    method: "post",
    params: { id }
  });
};
//启用禁用
export const upregionState = (params) => {
  return axios({
    url: "/api/region/status",
    method: "post",
    params: params
  });
};
//根据区域Id (id)获取下级区域
export const getRegionById = (params) => {
  return axios({
    url: "/api/pub/regionTreeById",
    method: "post",
    params: params
  });
};
//设备区域关联树
export const getRegionEquip = (params={}) => {
  return axios({
    url: "/api/pub/regionTree",
    method: "post",
    params: params
  });
};

export const regionTreeByIdAndCode = (params={}) => {
  return axios({
    url: "/api/pub/regionTreeByIdAndCode",
    method: "post",
    params: params
  });
};

export const regionTreeToDisabled = (params={}) => {
  return axios({
    url: "/api/pub/regionTreeToDisabled",
    method: "post",
    params: params
  });
};