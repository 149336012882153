import axios from "@/api/axios"; // 引用axios

export const getData = (params) => {
    return axios({
        url: "/api/bigScreen/config/listData",
        method: "post",
        params: params,
    });
};

export const typeList = (params) => {
    return axios({
        url: "/api/bigScreen/config/typeList",
        method: "post",
        params: params,
    });
};

export const urlList = (params) => {
    return axios({
        url: "/api/bigScreen/config/urlList",
        method: "post",
        params: params,
    });
};

export const dataSave = (params) => {
    return axios({
        url: "/api/bigScreen/config/addData",
        method: "post",
        data: params,
    });
};

export const onAudit = (params) => {
    return axios({
        url: "/api/bigScreen/config/audit",
        method: "post",
        params: params,
    });
};

export const listDel = (params) => {
    return axios({
        url: "/api/bigScreen/config/del",
        method: "post",
        params: params,
    });
};
