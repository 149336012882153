import axios from "@/api/axios"; // 引用axios
//获取分页数据
export const getList = (page, limit, params) => {
  const param = {
    ...params,
    page,
    limit,
  };
  return axios({
    url: "/api/resource/model/listData",
    method: "post",
    params: param,
  });
};
//新增
export const addData = (params) => {
  return axios({
    url: "/api/resource/model/addData",
    method: "post",
    params: params,
  });
};
//编辑
export const editData = (params) => {
  return axios({
    url: "/api/resource/model/editData",
    method: "post",
    params: params,
  });
};
//删除
export const delData = (id) => {
  return axios({
    url: "/api/resource/model/del",
    method: "post",
    params: { id },
  });
};
//详情
export const getDetail = (id) => {
  return axios({
    url: "/api/resource/model/detail",
    method: "post",
    params: { id },
  });
};
//获取所有设备型号
export const getEquipModel = (params = {}) => {
  return axios({
    url: "/api/resource/model/getAll",
    method: "post",
    params: params,
  });
};
