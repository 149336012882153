<template>
  <div>
    <Searchbar @emit-search="onSearch" @reset-form="resetForm">
      <template #SEARCH>
        <el-col :span="6">
          <el-form-item label="设备名称">
            <el-input clearable v-model="searchForm.name" placeholder="请输入 设备名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="资源编码">
            <el-input clearable v-model="searchForm.code" placeholder="请输入 资源编码"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="设备编号">
            <el-input clearable v-model="searchForm.macAddr" placeholder="请输入 设备编号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="设备类型">
            <el-select clearable v-model="searchForm.type" placeholder="请选择" size="large">
              <el-option v-for="item in options" :key="item.code" :label="item.name" :value="item.code">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </template>

      <template #BTN>
        <el-button @click="handleUpgrade" class="searchBtn">整体升级</el-button>
      </template>
    </Searchbar>

    <basicTable
      :tableData="tableData"
      :attrList="attrList"
      :page="page.current"
      :limit="page.limit"
      @currentChange="handleCurrentChange"
      @sizeChange="handleSizeChange"
      :total="page.total">
    </basicTable>

    <el-dialog
      width="60%"
      v-model="dialogFormVisible"
      title="升级版本信息"
      :close-on-click-modal="false"
      ref="dialogForm"
      :before-close="dialogClose"
    >
      <div class="formContainer">
        <div class="main">
          <el-input v-model="filterText" placeholder="请输入区域关键字" />
          <el-tree :data="regionList" :props="defaultProps" show-checkbox node-key="code" accordion ref="tree"
                   default-expand-all @check-change="checkChange" check-on-click-node
                   :filter-node-method="filterNode" />
        </div>
        <div class="formBox">
          <el-form label-position="right" size="small" label-width="120px" :model="form" ref="dialogForm">
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="升级型号" prop="type" :rules="{required: true,message: '请选择 升级型号',trigger: 'blur'}">
                  <el-select clearable v-model="form.type" placeholder="请选择" size="large">
                    <el-option v-for="item in addOptions" :key="item.code" :label="`${item.name}(${item.code})`"
                               :value="item.code">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="最新版本信息">
                  <basicTable
                    :isPage="false"
                    :indexshow="false"
                    :tableData="diatableData"
                    :attrList="diaattrList">
                  </basicTable>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="选择区域" prop="regionCodes"
                              :rules="{required: true,message: '请选择 选择区域',trigger: 'blur'}">
                  <el-input
                    readonly
                    v-model="nameStr"
                    autocomplete="off"
                    type="textarea"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button
            type="primary"
            size="small"
            @click="handlesubmit('dialogForm')"
          >提交
          </el-button>
        </div>
      </template>
    </el-dialog>

    <!--  升级信息弹窗  -->
    <el-dialog v-model="dialogVisible" title="信息" width="30%" :before-close="handleClose">
      <div style="font-size: 20px">
        <div>确认升级{{ dataOne.row.name }}({{ dataOne.row.code }})</div>
        <div>为最新版本{{ dataOne.versionName }}</div>
        <div class="tableOne">
          <li>
            <span>类型</span>
            <span>{{ dataOne.versionModel }}</span>
          </li>
          <li>
            <span>型号</span>
            <span>{{ dataOne.versionType }}</span>
          </li>
          <li>
            <span>版本号</span>
            <span>{{ dataOne.versionNum }}</span>
          </li>
          <li>
            <span style="border-bottom: 1px solid #a4a4a4;">包大小</span>
            <span style="border-bottom: 1px solid #a4a4a4;">{{ dataOne.fileSize }}</span>
          </li>
        </div>
      </div>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="onOneIp">确定</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { ref, toRefs, reactive, onMounted, watch, computed } from "vue";
import Searchbar from "@/components/Searchbar";
import { getData, getParam, addParams, submitData, upgradeOne, deviceOneIp } from "@/api/editionupgrade/upgrade";
import { getRegionById } from "@/api/system/region";
import { ElMessage, ElMessageBox } from "element-plus";

export default {
  components: {
    Searchbar
  },
  setup() {
    function onSearch() {
      onLoad(state.searchForm);
    }

    const tree = ref("");
    const dialogVisible = ref(false);
    const dialogForm = ref(null);
    const filterText = ref("");
    watch(filterText, (val) => {
      tree.value.filter(val);
    });

    const filterNode = (value, data) => {
      console.log(value, data);
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    };
    let state = reactive({
      dataOne: {},
      obj: {},
      form: {},
      dialogFormVisible: false,
      options: [],
      addOptions: [],
      searchForm: {},
      page: {
        current: 1,
        limit: 10,
        total: 0
      },
      regionList: [],
      attrList: [
        {
          prop: "name",
          label: "设备名称",
          render: (row) => <div>{row.name}</div>
        },
        {
          prop: "code",
          label: "资源编码",
          render: (row) => <div>{row.code}</div>
        },
        {
          prop: "model",
          label: "资源型号",
          render: (row) => <div>{row.model}</div>
        },
        {
          prop: "macAddr",
          label: "物理地址",
          render: (row) => <div>{row.macAddr}</div>
        },
        {
          prop: "rebackFlag",
          label: "是否具有回传功能",
          render: (row) => <div>{row.rebackFlag ? "是" : "否"}</div>
        },
        {
          prop: "creatTime",
          label: "工作状态",
          render: (row) =>
            <div>{row.workStatus == 0 ? "离线" : row.workStatus == 1 ? "空闲" : row.workStatus == 2 ? "工作" : row.workStatus == 3 ? "故障" : row.workStatus == 4 ? "故障恢复" : "未知"}</div>
        },
        {
          prop: "sysTime",
          label: "系统时间",
          render: (row) => <div>{row.sysTime}</div>
        },
        {
          prop: "lastOnlineTime",
          label: "上次在线时间",
          render: (row) => <div>{row.lastOnlineTime}</div>
        },
        {
          prop: "ipAddr",
          label: "在线时使用ip",
          render: (row) => <div>{row.ipAddr}</div>
        },
        {
          prop: "regionName",
          label: "所属区域",
          render: (row) => <div>{row.regionName}</div>
        },
        {
          prop: "softVersion",
          label: "软件版本",
          render: (row) => <div>{row.softVersion}</div>
        },
        {
          prop: "hardVersion",
          label: "硬件版本",
          render: (row) => <div>{row.hardVersion}</div>
        },
        {
          prop: "",
          label: "操作",
          render: (row) => <el-button type="text" icon="Download" onClick={() => handleUpone(row)}>点击升级</el-button>
        }
      ],
      defaultProps: {
        key: "id",
        label: "name",
        children: "children"
      },
      diaattrList: [
        {
          prop: "versionType",
          label: "类型",
          render: (row) => <div>{row.versionType}</div>
        }, {
          prop: "versionModel",
          label: "型号",
          render: (row) => <div>{row.versionModel}</div>
        },
        {
          prop: "versionName",
          label: "版本描述",
          render: (row) => <div>{row.versionName}</div>
        },
        {
          prop: "versionNum",
          label: "版本号",
          render: (row) => <div>{row.versionNum}</div>
        }, {
          prop: "fileSize",
          label: "包大小",
          render: (row) => <div>{row.fileSize}</div>
        }
      ],
      codeList: [],
      tableData: [],
      diatableData: []
    });
    const nameStr = computed(() => {
        let name = [];
        state.codeList.map(item => {
          name.push(item.name);
        });
        return name.join(",");
      }
    );
    watch(() => state.form.type, (val) => {
        if(!val) return
        let obj = state.addOptions.find(item => item.code == val);
        state.obj = obj.deviceVersion ? obj.deviceVersion : {};
        let arr = [];
        arr.push(state.obj);
        state.diatableData = arr;
      }
    );

    function handleUpone(row) {
      upgradeOne(row.id).then(res => {
        if (res.data.success) {
          dialogVisible.value = true;
          state.dataOne = res.data.data;
          state.dataOne["row"] = row;
        }
      });
    }

    function getSimpleCheckedNodes(store) {
      const checkedNodes = [];
      const traverse = function(node) {
        const childNodes = node.root ? node.root.childNodes : node.childNodes;
        childNodes.forEach(child => {
          if (child.checked) {
            checkedNodes.push(child.data);
          }
          if (child.indeterminate) {
            traverse(child);
          }
        });
      };
      traverse(store);
      return checkedNodes;
    }

    function checkChange(node, status) {
      state.codeList = getSimpleCheckedNodes(tree.value.store);
    }

    function handlesubmit() {
      let code = [];
      state.codeList.map(item => {
        code.push(item.code);
      });
      state.form.regionCodes = code.join(",");
      let { versionModel, versionType, id: versionId } = state.obj;
      dialogForm.value.validate((valid) => {
        if (valid) {
          submitData({ versionModel, versionType, versionId, regionCodes: state.form.regionCodes }).then(res => {
            if (res.data.success) {
              ElMessage({
                type: "success",
                message: "操作成功！"
              });
              tree.value.setCheckedKeys([]);
              state.diatableData = [];
              state.codeList = [];
              state.form.type = "";
              state.dialogFormVisible = false;
            }
          });
        }
      });
    }

    function onLoad(form = {}) {
      getData({ page: state.page.current, limit: state.page.limit, ...form }).then(res => {
        state.tableData = res.data.data.list;
        state.page.total = res.data.data.count;
      });
    }

    function onOneIp() {
      deviceOneIp({ deviceId: state.dataOne.row.id }).then(res => {
        if (res.data.success) {
          ElMessage({
            type: "success",
            message: res.data.code.message
          });
          dialogVisible.value = false;
        }
      });
    }

    function resetForm() {
      state.searchForm = {};
      onLoad(state.searchForm);
    }

    function handleCurrentChange(currentPage) {
      state.page.current = currentPage;
      onLoad(state.searchForm);
    }

    function handleSizeChange(pageSize) {
      state.page.limit = pageSize;
      onLoad(state.searchForm);
    }

    function handleUpgrade() {
      state.dialogFormVisible = true;
    }

    function dialogClose(done){
      tree.value.setCheckedKeys([]);
      state.diatableData = [];
      state.codeList = [];
      state.form.type = "";
      done();
    }

    onMounted(() => {
      getParam().then(res => {
        state.options = res.data.data.models;
      });
      addParams().then(res => {
        state.addOptions = res.data.data.models;
      });
      getRegionById().then(res => {
        state.regionList = res.data.data;
      });
      onLoad();
    });

    return {
      dialogClose,
      handleUpgrade,
      handleSizeChange,
      handleCurrentChange,
      dialogForm,
      handlesubmit,
      handleUpone,
      dialogVisible,
      nameStr,
      onSearch,
      resetForm,
      ...toRefs(state),
      tree,
      filterText,
      filterNode,
      onOneIp,
      checkChange
    };
  },
  data() {
    return {};
  },
  methods: {

  }
};
</script>
<style lang="less" scoped>
.main {
  width: 30%;

  ::-webkit-scrollbar {
    width: 0;
  }
}

.formBox {
  width: 70%;
}

.el-tree {
  height: 500px !important;
  overflow-y: scroll;
}

.el-tree-node__content {
  margin-top: 20px !important;
}

.formContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.tableOne {
  display: flex;
  flex-direction: column;
  align-items: center;

  li {
    height: 50px;
    line-height: 50px;
    display: flex;
    width: 100%;
    justify-content: center;

    span {
      &:nth-child(1) {
        flex-basis: 100px;
        border-top: 1px solid #a4a4a4;
        border-left: 1px solid #a4a4a4;
        border-right: 1px solid #a4a4a4;
      }

      &:nth-child(2) {
        flex-basis: 150px;
        border-top: 1px solid #a4a4a4;
        border-right: 1px solid #a4a4a4;
      }
    }
  }
}
</style>