<template>
  <div class="searchBox">
    <el-form :model="search" :label-width="searchLabelWidth" ref="searchForm">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="广播状态" prop="status">
            <el-select
              clearable
              :fit-input-width="true"
              v-model="search.status"
              placeholder="请选择 广播状态"
            >
              <el-option :label="item.name" :value="item.status" v-for="(item,index) in statusList"
                         :key="index"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="实际开始时间" prop="startTimeFact">
            <el-date-picker v-model="search.startTimeFact" type="datetime" placeholder="请选择 实际开始时间"
                            value-format="YYYY-MM-DD HH:mm:ss"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="实际结束时间" prop="endTimeFact">
            <el-date-picker v-model="search.endTimeFact" type="datetime" placeholder="请选择 实际结束时间"
                            value-format="YYYY-MM-DD HH:mm:ss"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="广播编号" prop="ebmId">
            <el-input
              clearable
              v-model="search.ebmId"
              autocomplete="off"
              placeholder="请输入 广播编号"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="指令来源" prop="inputChannelId">
            <el-select
              clearable
              :fit-input-width="true"
              v-model="search.inputChannelId"
              placeholder="请选择 指令来源"
            >
              <el-option :label="item.name" :value="item.status" v-for="(item,index) in instructList"
                         :key="index"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="类型" prop="reservePlan">
            <el-select
              clearable
              v-model="search.reservePlan"
              placeholder="请选择 类型"
            >
              <el-option label="预案广播" :value="1"></el-option>
              <el-option label="其他广播" :value="2"></el-option>
              <el-option label="全部" :value="null"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            label="所属区域"
            prop="regionId"
          >
            <selectTree :treeData="regionList" @selectTreeChange="searchselectTree" :propsId="searchpropsId" ref="selectTree"></selectTree>
          </el-form-item>

        </el-col>
        <el-col :span="6">
          <!--              <el-form-item>-->
          <el-button plain @click="searchForm('searchForm')" class="searchBtn"
          >查询
          </el-button
          >
          <el-button plain @click="resetForm('searchForm')" class="resetBtn"
          >清空
          </el-button
          >

          <!--              </el-form-item>-->
        </el-col>
      </el-row>
    </el-form>
    <div class="divide">
      <img src="../../assets/divide.png" style="width: 100%" />
    </div>

    <download-excel :data="tableData" :fields="json_fields" style="width: 130px"  :name="getName">
      <el-button style="display: block;" class="searchBtn">导出excel</el-button>
    </download-excel>
    <!--    <div class="btnContainer">-->
    <!--      <div class="btnLeft">-->
    <!--        <el-button-->
    <!--          icon="Plus"-->
    <!--          @click="handleAdd"-->
    <!--          class="searchBtn"-->
    <!--        >新增-->
    <!--        </el-button-->
    <!--        >-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
  <basicTable
    :attrList="attrList"
    :tableData="tableData"
    ref="table"
    :page="page.current"
    :limit="page.limit"
    :indexshow="false"
    :total="page.total"
    @currentChange="handleCurrentChange"
    @sizeChange="handleSizeChange"
  >
  </basicTable>
  <el-dialog
    width="60%"
    v-model="dialogFormVisible"
    :close-on-click-modal="false"
  >
    <div class="detailBox">
      <div class="detailHeader">
        <el-icon :size="25">
          <CaretRight />
        </el-icon>
        广播详情
      </div>
      <div class="detailBody">
        <div class="detailLeft">
          <div class="title" ref="title">
            <p> {{ form.instructionSource }}</p>
<!--            <el-icon color="#11FFBF" :size="25"><location /></el-icon>-->
            <span class="targetNames" v-html="form.targetNames"></span>
          </div>
          <div class="detailList" ref="detailList">
            <div class="descriptions">
              <span>广播编号</span>
              <div class="maindescriptions">{{ form.ebmId }}</div>
            </div>
            <div class="descriptions">
              <span>发布人</span>
              <div class="maindescriptions">{{ form.createUserName }}</div>
            </div>
            <div class="descriptions">
              <span>广播状态</span>
              <div class="maindescriptions">{{ statusList.filter(item=>item.status == form.status)[0]?.name }}</div>
            </div>
            <div class="descriptions">
              <span>广播类型</span>
              <div class="maindescriptions">{{ form.bcastTypeName }}</div>
            </div>
            <div class="descriptions">
              <span>事件类型</span>
              <div class="maindescriptions">{{ form.eventType }}</div>
            </div>
            <div class="descriptions">
              <span>广播阶段</span>
              <div class="maindescriptions">{{ form.processStateName }}</div>
            </div>
            <div class="descriptions">
              <span>事件等级</span>
              <div class="maindescriptions eventLevelName">{{ form.eventLevelName }}</div>
            </div>
            <div class="descriptions">
              <span>输出通道</span>
              <div class="maindescriptions">{{ form.outChannelIds }}</div>
            </div>
            <div class="descriptions">
              <span>开始时间</span>
              <div class="maindescriptions" style="font-size: 14px">{{ form.startTime }}(计划)/{{ form.startTimeFact }}(实际)</div>
            </div>
            <div class="descriptions">
              <span>结束时间</span>
              <div class="maindescriptions" style="font-size: 14px">{{ form.endTime }}(计划)/{{ form.endTimeFact }}(实际)</div>
            </div>
            <div class="descriptions">
              <span>审核方式</span>
              <div class="maindescriptions">{{ form.verify == 1 ? "自动审核" : "策略配置" }}</div>
            </div>
            <div class="descriptions">
              <span>备注</span>
              <div class="maindescriptions">{{ form.note ? form.note : "无" }}</div>
            </div>
            <div class="descriptions">
              <span>音量</span>
              <div class="maindescriptions">{{ form.volume }}</div>
            </div>
            <div class="descriptions">
              <span>广播描述</span>
              <div class="maindescriptions">{{ form.explain ? form.explain : "无" }}</div>
            </div>
            <div class="descriptions">
              <span>发起设备</span>
              <div class="maindescriptions">{{form.sourceCode}}</div>
            </div>
            <div class="descriptions" v-if="form.homeResourceStr">
              <span>家庭终端地址集</span>
              <div class="maindescriptions">{{form.homeResourceStr}}</div>
            </div>
            <div class="descriptions" v-if="form.cameraAddressStr">
              <span>摄像头安装位置</span>
              <div class="maindescriptions">{{form.cameraAddressStr}}</div>
            </div>
            <div class="descriptions" v-if="form.propagandaSourceStr">
              <span>喊话来源</span>
              <div class="maindescriptions">{{form.propagandaSourceStr}}</div>
            </div>
          </div>
        </div>
        <div class="detailRight">
          <div class="videoBox">

            <div v-if="tableText" class="showImage">
              <div class="showImage-text">
                <vue-seamless-scroll :data="programContents" :class-option="classOption" class="seamless-warp">
                  <span>{{tableText}}</span>
                </vue-seamless-scroll>
              </div>

              <div class="showImage-image">
                <div class="showImage-btn" v-show="contentSize > 1">
                  <div class="btn-l" @click="xz"> 《 </div>
                  <div class="btn-r" @click="xy"> 》 </div>
                </div>
                <el-image v-for="(item, index) in programContents.slice(num * 4, num * 4 == 0 ? 4 : (num + 1) * 4)" :key="index" :src="item.mediaAddr" :preview-src-list="programContents.map(item2=>item2.mediaAddr)"></el-image>
              </div>
            </div>

            <div v-else>
              <div class="rightTop">
                <div class="rightTitle">
                  <span @click="active=1" :class="{activeSpan:active==1}">历史播发</span>
                  <span @click="active=2" :class="{activeSpan:active!==1}">播发媒体</span>
                </div>
              </div>
              <div class="videoContainer">
                <div class="videoList" v-show="active==1">
                  <div class="video">
                    <span class="music">音频</span>
                    <video
                        id="video"
                        ref="video"
                        controls
                        style="width:300px;height: 180px"
                    ></video>
                  </div>
                  <div class="videotext" v-if="form.msgDesc" @mouseenter="textActive=true" @mouseleave="textActive=false">
                    <span class="music">文本</span>
                    <span class="text">{{ form.msgDesc.substr(0, 35) + "....." }}</span>
                    <p v-if="textActive" class="textAll">{{ form.msgDesc }}</p>
                  </div>
                </div>
                <div class="videoList" v-show="active==2">
                  <div class="video" v-for="(item,index) in programContents" :key="index">
                    <span class="music">音频</span>
                    <audio
                        ref="audio"
                        controls
                        style="width:300px;height: 30px"
                        :src="item.mediaAddr"
                    ></audio>
                    <span class="videoName">{{ item.mediaName }}</span>
                    <!--                  <span class="action" @click="downloadVedio(item.mediaAddr)"><el-icon><download /></el-icon>下载</span>-->
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="step">
            <div class="bottomTitle">
              <span>执行情况</span>
            </div>
            <div class="stepInfo">
              <Step :stepInfo="stepInfo"></Step>
            </div>
          </div>
        </div>
      </div>
    </div>

  </el-dialog>
</template>

<script>
import { ref, watch } from "vue";
import flvjs from "flv.js";
import {
  getList,
  getStatus,
  getinstruct,
  getDetail,
  offRadio
} from "@/api/basic/loudspeaker";
import {
  getbasicData
} from "@/api/basic/startBroadcast";
import selectTree from "@/components/selectTree";
import Step from "@/components/Step";
import { getTreeData } from "@/api/system/region";

export default {
  name: "loudspeaker",
  components: { selectTree, Step },
  data() {
    return {
      num:0,
      timer: null,
      contentSize:0,
      textActive: false,
      active: 1,
      tableText:"",
      programContents: [],
      stepInfo: [],
      statusList: [],
      instructList: [],
      eventLevel: [],
      bcastType: [],
      searchpropsId: 0,
      regionList: [],
      formLabelWidth: "22%",
      dialogFormVisible: false,
      title: "新增",
      form: {},
      searchLabelWidth: "130px",
      search: {},
      page: {
        current: 1,
        limit: 10,
        total: 0
      },
      tableData: [],
      json_fields:{
        "消息标题":"msgTitle",
        "广播编号":"ebmId",
        "事件类型":"eventType",
        "目标地址":"instructionSource",
        "事件级别":{
          field:"eventLevel",
          callback:(value)=>{
            const obj = this.eventLevel.find(item => item.code == value) || {};
            return obj.name
          }
        },
        "预计开播时间":"startTime",
        "实际开播时间":"startTimeFact",
        "预计结束时间":"endTime",
        "实际结束时间":"endTimeFact",
        "开播音量":"volume",
        "发起广播单位编码":"sourceCode",
        "目标接收单位编码":{
          field:"targetCodes",
          callback:(value)=>{
            return `${value};`
          }
        },
        "覆盖区域编码":"regionId",
        "广播描述":"explain",
        "消息文本内容":"msgDesc",
        "广播类型":{
          field:"bcastType",
          callback:(value)=>{
            console.log(1111, value)
            const obj = this.bcastType.find(item => item.code == value) || {};
            return obj.name
          }
        },
        "广播状态":{
          field:"status",
          callback:(value)=>{
            const obj = this.statusList.find(item => item.status == value) || {};
            return obj.name
          }
        },
        "音频码率":"bitValue",
        "播发时效":"effectTime",
        "设备覆盖率":"deviceCoverageRate",
      },
      attrList: [
        {
          label: "广播编号",
          prop: "ebmId",
          render: (row) => <div>{row.ebmId}</div>
        },
        {
          label: "标题",
          prop: "msgTitle",
          render: (row) => <div>{row.msgTitle}</div>
        },
        {
          label: "目标地址",
          prop: "instructionSource",
          render: (row) => <div>{row.instructionSource}</div>
        },
        {
          label: "事件级别",
          prop: "eventLevel",
          render: (row) => {
            const obj = this.eventLevel.find(item => item.code == row.eventLevel) || {};
            return <div>{obj.name}</div>;
          }
        }, {
          label: "广播类型",
          prop: "bcastType",
          render: (row) => {
            const obj = this.bcastType.find(item => item.code == row.bcastType) || {};
            return <div>{obj.name}</div>;
          }
        }, {
          label: "广播状态",
          prop: "status",
          render: (row) => {
            const obj = this.statusList.find(item => item.status == row.status) || {};
            return <div>{obj.name}</div>;
          }
        },
        {
          label: "预案广播",
          prop: "planId",
          render: (row) => {
            return <div>{row.planId ? "是" : "否"}</div>;
          }
        },
        {
          label: "覆盖率",
          prop: "deviceCoverageRate",
          render: (row) => <div>{row.deviceCoverageRate}</div>
        },
        {
          label: "发布时效",
          prop: "prescription",
          render: (row) => <div>{row.prescription}</div>
        },
        {
          label: "实际开始时间",
          prop: "startTimeFact",
          render: (row) => {
            return <div>{row.startTimeFact}</div>;
          }
        },
        {
          prop: "",
          label: "操作",
          render: (row) => {
            if (row.status == 2) {
              return (
                <div>
                  <el-button
                    type="text"
                    icon="View"
                    onClick={() => this.handleView(row)}
                  >查看
                  </el-button>
                  <el-button
                    type="text"
                    icon="Open"
                    onClick={() => this.handleOff(row.ebmId)}
                  >关闭广播
                  </el-button>
                </div>
              );
            } else return (
              <div>
                <el-button
                  type="text"
                  icon="View"
                  onClick={() => this.handleView(row)}
                >查看
                </el-button>
              </div>
            );
          }
        }
      ]
    };
  },
  setup() {
    const selectTree = ref();

    function clearRegion() {
      selectTree.value.selectClear();
    }

    return { clearRegion, selectTree };
  },
  computed: {
    classOption () {
      return {
        limitMoveNum: 1
      }
    },

    getName(){
      function addZero(s) {
        return s < 10 ? ('0' + s) : s;
      }
      var myDate = new Date();
      var year = myDate.getFullYear();    //获取完整的年份(4位,1970-????)
      var month = myDate.getMonth() + 1;       //获取当前月份(0-11,0代表1月)
      var date = myDate.getDate();        //获取当前日(1-31)

      return `广播详情_${year}${addZero(month)}${addZero(date)}.xls`
    },
  },
  created() {
    getbasicData(1).then((res) => {
      if (res.data.success) {
        this.eventLevel = res.data.data.eventLevel;
        this.bcastType = res.data.data.bcastType;
      }
    });
    this.getData(this.page);
    getTreeData().then(res => {
      if (res.data.success) {
        this.regionList = res.data.data;
      }
    });

    getStatus().then(res => {
      if (res.data.success) {
        this.statusList = res.data.data;
      }
    });
    getinstruct().then(res => {
      if (res.data.success) {
        this.instructList = res.data.data;
      }
    });
    this.timer = setInterval(this.getData, 10000);
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
  methods: {
    playVideo(url) {
      // this.videoList.forEach((item, index) => {
      if (flvjs.isSupported()) {
        let player = null;
        let videoElement = document.getElementById("video");
        let str = window.location.href.split(":");
        player = flvjs.createPlayer({
          cors: true,
          type: "flv", //=> 媒体类型 flv 或 mp4
          hasAudio: true, //=> 是否开启声音;
          // url: "http://192.168.3.37:9109/F45107220000000101010101202204210016video.flv" //=> 视频流地址
          url: `${str[0]}:${str[1]}:${url}` //=> 视频流地址
          // url:"http://192.168.3.37:9109/167.flv",
        });

        player.attachMediaElement(videoElement); //=> 绑DOM
        player.load();
        // player.play();
      } else {
        this.$message.error("不支持flv格式视频");
      }
      // });
    },
    searchselectTree(value) {
      this.search.regionId = value;
    },

    searchForm() {
      this.page.current = 1;
      if(new Date(this.search.startTimeFact).getTime() - new Date(this.search.endTimeFact).getTime() > 0 && this.search.startTimeFact && this.search.endTimeFact){
        this.$message.error("开始时间不能大于结束时间");
        return;
      }
      this.getData(this.page);
    },
    resetForm(formName) {
      this.clearRegion();
      this.$refs[formName].resetFields();
      this.page.current = 1;
      this.getData(this.page);
    },
    getData(page = this.page) {
      getList(page.current, page.limit, this.search).then((res) => {
        if (res.data.success) {
          this.page.total = res.data.data.count;
          this.tableData = res.data.data.list;
        }
      });
    },

    extractHostname(url) {
      var hostname;
      if (url.indexOf("//") > -1) {
        hostname = url.split('/')[2];
      } else {
        hostname = url.split('/')[0];
      }
      hostname = hostname.split(':')[0];
      hostname = hostname.split('?')[0];
      return hostname;
    },

    xz(){
      if(this.num == 0) return this.num = this.contentSize - 1;
      this.num--;
    },
    xy(){
      if(this.num == this.contentSize - 1) return this.num = 0;
      this.num++;
    },

    handleView(row) {
      this.dialogFormVisible = true;
      this.active = 1;

      getDetail(row.id).then(res => {
        if (res.data.success) {
          this.form = res.data.data.bcastVO;
          this.form.bcastTypeName = this.bcastType.find(item => item.code == this.form.bcastType).name;
          this.form.eventLevelName = this.eventLevel.find(item => item.code == this.form.eventLevel).name;
          this.programContents = res.data.data.programContents || [];
          let url = window.location.hostname;
          this.programContents.forEach((item, index)=>{
            this.programContents[index].mediaAddr = item.mediaAddr.replace(this.extractHostname(item.mediaAddr), url);
          })
          this.stepInfo = res.data.data.list;
          this.tableText = res.data.data.scrollSubtitles;
          this.contentSize = Math.ceil(this.programContents.length / 4);
          console.log(this.contentSize)
          this.playVideo(res.data.data.bcastVO.audioAddress || "");
          this.$nextTick(()=>{
            let dom1 = this.$refs.title;
            let dom2 = this.$refs.detailList;
            dom2.setAttribute('style',`height:${630 - dom1.getBoundingClientRect().height}px`)
          })
        }
      });
    },
    handleDelete(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          return delVendor(row.id);
        })
        .then((res) => {
          this.getData(this.page);
          ElMessage({
            message: "操作成功",
            type: "success"
          });
        });
    },
    handleCurrentChange(currentPage) {
      this.page.current = currentPage;
      this.getData(this.page);
    },
    handleSizeChange(pageSize) {
      this.page.limit = pageSize;
      this.getData(this.page);
    },
    handleOff(ebmId) {
      this.$confirm("确定关闭广播?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        offRadio(ebmId).then(res => {
          if (res.data.success) {
            this.getData(this.page);
            this.$message.success("操作成功");
          }
        });
      })

    }
  }
};
</script>

<style scoped lang="scss">
.detailBox {
  font-family: Source Han Sans SC;
  width: 1120px;
  height: 704px;
  border: 1px solid #154C43;
  background: linear-gradient(0deg, #062E2B 8%, #041D1D 91%);
}

.detailHeader {
  font-size: 26px;
  font-weight: 400;
  color: #0DF5BF;
  line-height: 48px;
  text-align: left;
  height: 60px;
  background: #0A2B28;
  border-bottom: 1px solid #154C43;
}

.detailHeader svg {
  font-size: 26px;
  font-weight: 400;
  color: #0DF5BF;

}

.detailBody {
  text-align: left;
  display: flex;
  justify-content: space-between;
  padding: 8px 13px;
}

.detailLeft {
  width: 460px;
}

.detailRight {
  width: 613px;
  //display: flex;
  //flex-direction: column;
  //justify-content: space-between;
}

.title {
  padding-bottom: 10px;
  border-bottom: 1px solid #1B4239;

  p {
    height: 21px;
    font-size: 22px;
    font-weight: 400;
    color: #F4DF0A;
    line-height: 48px;
  }
}

.eventLevelName {
  color: red !important;
}

.descriptions {
  padding: 10px;
  width: 100%;
  display: flex;
  text-align: left;
  justify-content: space-between;
  align-items: center;

  span {
    color: #50C9B1;
  }

  .maindescriptions {

    width: 361px;
    font-size: 16px;
    font-weight: 300;
    color: #11FFBF;
  }
}

.bottomTitle {
  border-bottom: 1px dashed #126B51;
  font-size: 18px;
  font-weight: 300;
  color: #00FFC4;
  padding-bottom: 10px;
  margin-bottom: 10px;

  span {
    border-bottom: 1px solid #E4D421;
  }
}

.activeSpan {
  color: #00FFC4 !important;
  border-bottom: 1px solid #E4D421;
}

.rightTitle {
  border-bottom: 1px dashed #126B51;
  font-size: 18px;
  font-weight: 300;
  color: #16705A;
  line-height: 48px;

  span {
    margin-right: 30px;
  }
}

.videoBox {
  padding: 0 10px 10px 10px;
  width: 613px;
  height: 311px;
  border: 1px solid #154C43;
  .showImage{
    width: 100%;
    height: 300px;
    .showImage-text{
      text-align: left;
      color: #59B7A4;
      font-size: 18px;
      font-weight: 400;
      width: 100%;
      max-height: 170px;
      .seamless-warp{
        height: 170px;
        overflow: hidden;
      }
    }
    .showImage-image{
      margin-top: 10px;
      height: calc(100% - 180px);
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 20px;
      position: relative;
      .el-image{
        height:120px !important;
      }
      .showImage-btn{
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: space-between;
        z-index: 9999;
        .btn-l,.btn-r{
          width: 15px;
          height: 30px;
          background: rgba(#696969cc, .8);
          line-height: 30px;
          color: #FFFFFF;
          text-align: center;
          font-size: 20px;
        }
      }
    }
  }
}

.videotext {
  position: relative;
  padding: 10px 0;
  border-bottom: 1px solid #113633;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.textAll {
  width: 500px;
  border-radius: 10px;
  padding: 8px;
  width: 500px;
  position: absolute;
  top: 100%;
  right: 0;
  font-size: 14px;
  background-color: #072F2B;
  color: #95D7C8;
}

.music {
  color: #00AD85;
  text-align: center;
  line-height: 28px;
  display: inline-block;
  width: 65px;
  height: 28px;
  border: 1px solid #18876E;

}

.video {
  padding: 10px 0;
  border-bottom: 1px solid #113633;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .videoName {
    color: #95D7C8;
  }
}

::v-deep .action {
  color: #00FFC6;
  font-size: 16px;

  .el-icon {
    color: #00FFC6 !important;
    font-size: 16px;
  }
}

.step {
  padding: 10px;
  margin-top: 8px;
  width: 613px;
  height: 304px;
  border: 1px solid #154C43;
}

::v-deep .point .el-step__line {
  bottom: 0;
}

.detailList {
  overflow: auto;
  height: 194px;

  //::-webkit-scrollbar {
  //  width: 0;
  //}
}

.targetNames {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 300;
  color: #11FFBF;
  line-height: 48px;
}

.videoList {
  height: 250px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 0;
  }
}

.stepInfo {
  height: 230px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 0;
  }
}

.text {
  color: #00ffc6;
}


</style>