<template>
  <div>
    <Searchbar @emit-search="onSearch" @reset-form="resetForm">
      <template #SEARCH>
        <el-col :span="6">
          <el-form-item label="MAC编号">
            <el-input clearable v-model="searchForm.mac_addr" placeholder="请输入 MAC编号"></el-input>
          </el-form-item>
        </el-col>
      </template>

<!--      <template #BTN>-->
<!--        <el-button class="searchBtn" @click="dialogFormVisible = true">新增</el-button>-->
<!--      </template>-->
    </Searchbar>

    <basicTable
        :tableData="tableData"
        :isPage="false"
        :attrList="attrList">
    </basicTable>

    <el-dialog
        v-model="dialogFormVisible"
        title="新增"
        :close-on-click-modal="false"
    >
      <el-form
          label-position="right"
          :model="form"
          size="small"
          label-width="140px"
          ref="dialogForm"
          :rules="rules"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="系统名称" prop="name" :rules="[{ required: true, message: '系统名称 不能为空' }]">
              <el-input placeholder="请输入 系统名称" clearable v-model="form.name" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="资源编码" prop="code">
              <el-input placeholder="请输入 资源编码" clearable v-model="form.code" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="行政区域码" prop="region_code">
              <el-input placeholder="请输入 行政区域码" clearable v-model="form.region_code" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="接入访问地址" prop="url" :rules="[{ required: true, message: '接入访问地址 不能为空' }]">
              <el-input placeholder="请输入 接入访问地址" clearable v-model="form.url" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="平台访问地址" prop="address" :rules="[{ required: true, message: '平台访问地址 不能为空' }]">
              <el-input placeholder="请输入 平台访问地址" clearable v-model="form.address" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="经纬度" prop="location">
              <Getnpoint ref="point" @SET_POINT="form.location = $event" @INPUT_POINT="form.location = $event" @CHANGE_POINT="form.location = ''"/>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="联系人" prop="linkman">
              <el-input placeholder="请输入 联系人" clearable v-model="form.linkman" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="联系电话" prop="phone">
              <el-input placeholder="请输入 联系电话" clearable v-model="form.phone" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>


          <el-col :span="12">
            <el-form-item label="覆盖面积(平方公里)" prop="square">
              <el-input placeholder="请输入 覆盖面积" clearable v-model="form.square" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="覆盖人口(万)" prop="population">
              <el-input placeholder="请输入 覆盖人口" clearable v-model="form.population" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>

<!--          <el-col :span="12">-->
<!--            <el-form-item label="设备编号" prop="population" :rules="[{ required: true, message: '设备编号 不能为空' }]">-->
<!--              <el-input placeholder="设备编号" clearable v-model="form.mac_addr" autocomplete="off"></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->

          <el-col :span="12">
            <el-form-item label="状态" prop="status" :rules="[{ required: true, message: '状态 不能为空' }]">
              <el-select  clearable v-model="form.status" placeholder="请选择" size="large">
                <el-option v-for="item in statuslList" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" size="small" @click="onSubmit">提交</el-button>
          <el-button size="small" type="info">取消</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import Searchbar from '@/components/Searchbar';
import Getnpoint from '@/components/Getnpoint';
import { getData, addData } from "../../api/equipment/portable";

const regionPass = (rule, value, callback) => {
  if (value.length != 12) {
    callback(new Error('请输入12位行政区域码'))
  } else {
    callback()
  }
}

const codePass = (rule, value, callback) => {
  if (!/^F\d{23}$/.test(value)) {
    callback(new Error('请输入正确的资源编码'))
  } else {
    callback()
  }
}

const locationPass = (rule, value, callback) => {
  let location = value.split(',');
  if (location.length < 2) {
    callback(new Error('非法经纬度'))
  } else {
    callback()
  }
}

export default {
  components:{
    Searchbar,
    Getnpoint
  },


  data(){
    return{
      rules:{
        code: [{ validator: codePass, required: true, trigger: 'blur' }],
        region_code:[{ validator: regionPass, required: true, trigger: 'blur' }],
        location:[{ validator: locationPass, required: true, trigger: 'blur' }]
      },
      mac: "",
      statuslList:[
        {
          value:1,
          label:"启用"
        },
        {
          value:2,
          label:"禁用"
        }
      ],
      form:{},
      dialogFormVisible:false,
      searchForm:{},
      tableData:[],
      attrList:[
        {
          prop: "mac_addr",
          label: "MAC编号",
          render: (row) => <div>{row.mac_addr}</div>,
        },
        {
          prop: "create_time",
          label: "创建时间",
          render: (row) => <div>{row.create_time}</div>,
        },
        {
          prop: "",
          label: "操作",
          render: (row) => (
              <div>
                <el-button type="text" icon="Pointer" onClick={()=>this.zhuce(row)}>注册</el-button>
              </div>
          )
        },
      ],
    }
  },

  methods:{
    zhuce(row){
      this.mac = row.mac_addr;
      this.dialogFormVisible = true;
    },

    onSubmit(){
      let [longitude, latitude] = this.form.location.split(',');
      addData({
        mac_addr: this.mac,
        longitude,
        latitude,
        ...this.form
      }).then(res=>{
        if (res.data.success) {
          this.$message.success("操作成功");
          this.dialogFormVisible = false;

          this.$nextTick(()=>{
            this.$refs('dialogForm').resetFields();
            this.form = {};
          })

          getData().then(res=>{
            this.tableData = res.data.data;
          })
        }
      })
    },
  },

  created() {
    getData().then(res=>{
      this.tableData = res.data.data;
    })
  }
}
</script>

<style lang="scss" scoped>

</style>