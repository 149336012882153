<template>
  <div class="searchBox">
    <el-form :model="search" :label-width="searchLabelWidth" ref="searchForm">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="厂商名称" prop="name">
            <el-input
              v-model="search.name"
              autocomplete="off"
              placeholder="请输入 区域名称"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <!--              <el-form-item>-->
          <el-button plain @click="searchForm('searchForm')" class="searchBtn"
          >查询
          </el-button
          >
          <el-button plain @click="resetForm('searchForm')" class="resetBtn"
          >清空
          </el-button
          >

          <!--              </el-form-item>-->
        </el-col>
      </el-row>
    </el-form>
    <div class="divide">
      <img src="../../assets/divide.png" style="width: 100%" />
    </div>
    <div class="btnContainer">
      <div class="btnLeft">
        <el-button
          icon="Plus"
          @click="handleAdd"
          class="searchBtn"
        >新增
        </el-button
        >
      </div>
    </div>
  </div>
  <basicTable
    :attrList="attrList"
    :tableData="tableData"
    ref="table"
    :page="page.current"
    :limit="page.limit"
    :total="page.total"
    @currentChange="handleCurrentChange"
    @sizeChange="handleSizeChange"
  >
  </basicTable>
  <el-dialog
    width="60%"
    v-model="dialogFormVisible"
    :title="title"
    :close-on-click-modal="false"
    :before-close="handleClose"
  >
    <el-form
      label-position="right"
      :model="form"
      size="small"
      :label-width="formLabelWidth"
      ref="dialogForm"
    >
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            label="厂商名称"
            :rules="[{ required: true, message: '厂商名称 不能为空' }]"
            prop="name"
          >
            <el-input
              v-model="form.name"
              autocomplete="off"
              clearable
              placeholder="请输入 厂商名称"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="厂商电话"
            prop="tel"
            :rules="[
              {
                required: true,
                message: '格式不正确',
                min: 11,
                max: 11,
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              clearable
              type="tel"
              autocomplete="off"
              v-model="form.tel"
              placeholder="请输入 厂商电话"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            label="厂商编码"
            :rules="[{ required: true, message: '厂商编码 不能为空' }]"
            prop="code"
          >
            <el-input
              clearable
              autocomplete="off"
              v-model="form.code"
              placeholder="请输入 厂商编码"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button
          type="primary"
          size="small"
          @click="handlesubmit('dialogForm')"
        >提交
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import {
  getList,
  addVendor,
  editVendor,
  delVendor,
  getVendorDetail
} from "@/api/basic/vendor";
import { ElMessage } from "element-plus";
import {nextTick} from "vue";

export default {
  name: "vender",
  data() {
    return {
      formLabelWidth: "22%",
      dialogFormVisible: false,
      title: "新增",
      form: {},
      searchLabelWidth: "100px",
      search: {},
      page: {
        current: 1,
        limit: 10,
        total: 0
      },
      tableData: [],
      attrList: [
        {
          label: "厂商名称",
          prop: "name",
          render: (row) => <div>{row.name}</div>
        },
        {
          label: "厂商电话",
          prop: "tel",
          render: (row) => <div>{row.tel}</div>
        },
        {
          label: "厂商编码",
          prop: "code",
          render: (row) => <div>{row.code}</div>
        },
        {
          prop: "",
          label: "操作",
          render: (row) => {
            return (
              <div>
                <el-button
                  type="text"
                  icon="Edit"
                  onClick={() => this.handleEdit(row)}
                >编辑</el-button>
                <el-button
                  type="text"
                  icon="Delete"
                  onClick={() => this.handleDelete(row)}
                >删除</el-button>
              </div>
            );
          }
        }
      ]
    };
  },
  created() {
    this.getData(this.page);
  },
  methods: {
    searchForm() {
      this.page.current = 1;
      this.getData(this.page);
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.page.current = 1;
      this.getData(this.page);
    },
    getData(page) {
      getList(page.current, page.limit, this.search).then((res) => {
        if (res.data.success) {
          console.log(res);
          this.page.total = res.data.data.count;
          this.tableData = res.data.data.list;
        }
      });
    },
    handlesubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.form);
          if (this.title == "新增") {
            addVendor(this.form).then((res) => {
              if (res.data.success) {
                this.getData(this.page);
                this.$refs[formName].resetFields();
                ElMessage({
                  message: "操作成功",
                  type: "success"
                });
                this.dialogFormVisible = false;
              } else {
                ElMessage({
                  message: res.data.code.message,
                  type: "error"
                });
              }
            });
          } else {
            editVendor(this.form).then((res) => {
              if (res.data.success) {
                this.getData(this.page);
                this.$refs[formName].resetFields();
                ElMessage({
                  message: "操作成功",
                  type: "success"
                });
                this.dialogFormVisible = false;
              } else {
                ElMessage({
                  message: res.data.code.message,
                  type: "error"
                });
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    handleClose(done){
      nextTick(()=>{
        this.$refs['dialogForm'].resetFields();
        this.form = {}
      })
      done();
    },
    handleEdit(row) {
      let { name, id, tel, code } = row;
      this.form = { name, id, tel, code };
      this.dialogFormVisible = true;
      this.title = "编辑";
    },
    handleAdd() {
      this.dialogFormVisible = true;
      this.title = "新增";
    },
    handleDelete(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          return delVendor(row.id);
        })
        .then((res) => {
          this.getData(this.page);
          ElMessage({
            message: "操作成功",
            type: "success"
          });
        });
    },
    handleCurrentChange(currentPage) {
      this.page.current = currentPage;
      this.getData(this.page);
    },
    handleSizeChange(pageSize) {
      this.page.limit = pageSize;
      this.getData(this.page);
    }
  }
};
</script>

<style scoped></style>
