<template>
  <basicTable
    :indexshow="false"
    :isPage="false"
    :attrList="attrList"
    :tableData="tableData"
    ref="table">
  </basicTable>
  <el-dialog
    width="60%"
    v-model="dialogFormVisible"
    title="注册"
    :close-on-click-modal="false"
    :before-close="handleClose"
  >
    <el-form
      label-position="right"
      :model="form"
      size="small"
      :label-width="formLabelWidth"
      ref="dialogForm"
    >
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            label="设备名称"
            :rules="[{ required: true, message: '设备名称 不能为空' }]"
            prop="name"
          >
            <el-input v-model="form.name" autocomplete="off" clearable placeholder="请输入 设备名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="所属区域"
            :rules="[{ required: true, message: '所属区域 不能为空' }]"
            prop="regionId"
          >
            <selectTree :treeProps="treeProps" :disabled="true" :treeData="regiontree" @selectTreeChange="selectTreeChange" nodeKey="code" :propsId="propsId"></selectTree>
            <!--                        <TreeSelect placeholder="请选择 所属区域"  :tree-data="treeData" multiple  allowClear v-model="form.regionId"  style="width: 100%"  :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" >-->
            <!--                        </TreeSelect>-->
          </el-form-item>

        </el-col>

      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            label="设备型号"
            :rules="[{ required: true, message: '设备型号 不能为空' }]"
            prop="model"
          >
            <el-select v-model="form.model" placeholder="请选择 设备型号" clearable>
              <el-option
                v-for="item in equipModel"
                :key="item.id"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="设备MAC编号"
            :rules="[{ required: true, message: '设备MAC编号 不能为空' }]"
            prop="macAddr"
          >
            <el-input
                readonly
                clearable
                type="tel"
                autocomplete="off"
                v-model="form.macAddr"
                placeholder="请输入 设备MAC编号"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            label="安全模块编号"
            :rules="[{ required: true, message: '安全模块编号为12位字符串，如没有填写12个0' }]"
            prop="securityModuleNo"
          >
            <el-input
              maxlength="12"
              clearable
              autocomplete="off"
              v-model="form.securityModuleNo"
              placeholder="请输入 安全模块编号"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="经纬度" prop="latitude" :rules="[{ required: true, message: '经纬度格式错误（例）xxxxx,xxxxx' }]">
            <Getnpoint ref="point" @SET_POINT="setpoint" @INPUT_POINT="inputpoint"/>
          </el-form-item>
        </el-col>
<!--        <el-col :span="12">-->
<!--          <el-form-item-->
<!--            label="经度"-->
<!--            :rules="[{ required: true, message: '经度 不能为空' }]"-->
<!--            prop="longitude"-->
<!--          >-->
<!--            <el-input-->
<!--              clearable-->
<!--              autocomplete="off"-->
<!--              v-model="form.longitude"-->
<!--              placeholder="请输入 经度"-->
<!--            >-->
<!--            </el-input>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
      </el-row>
      <el-row :gutter="20">
<!--        <el-col :span="12">-->
<!--          <el-form-item-->
<!--            label="纬度"-->
<!--            :rules="[{ required: true, message: '纬度 不能为空' }]"-->
<!--            prop="latitude"-->
<!--          >-->
<!--            <el-input-->
<!--              clearable-->
<!--              autocomplete="off"-->
<!--              v-model="form.latitude"-->
<!--              placeholder="请输入 纬度"-->
<!--            >-->
<!--            </el-input>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
        <el-col :span="12">
          <el-form-item
            label="启用状态"
            :rules="[{ required: true, message: '启用状态 不能为空' }]"
            prop="isForbidden"
          >
            <el-radio-group v-model="form.isForbidden">
              <el-radio :label="1">禁用</el-radio>
              <el-radio :label="0">启用</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            label="是否具有回传功能"
            :rules="[{ required: true, message: '是否具有回传功能 不能为空' }]"
            prop="rebackFlag"
          >
            <el-radio-group v-model="form.rebackFlag">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="通信方式"
            :rules="[{ required: true, message: '通信方式 不能为空' }]"
            prop="communicationMode"
          >
            <el-select v-model="form.communicationMode" placeholder="请选择 通信方式" clearable>
              <el-option
                v-for="item in communicateList"
                :key="item.id"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            class="code"
            label="资源编码"
            prop="code"
            :rules="[{ required: true, message: '资源编码为24位',min:24,max:24, trigger: 'blur' }]"
          >
            <el-input
              readonly
              clearable
              type="tel"
              autocomplete="off"
              v-model="form.code"
              placeholder="请输入 资源编码"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
              label="高度"
              prop="altitude"
          >
            <el-input
                clearable
                autocomplete="off"
                v-model="form.altitude"
                placeholder="请输入 高度"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            label="设备编号"
            prop="resourceNo"
          >
            <el-input
              clearable
              autocomplete="off"
              v-model="form.resourceNo"
              placeholder="请输入 设备编号"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="负责人"
            prop="charger"
          >
            <el-input
              clearable
              autocomplete="off"
              v-model="form.charger"
              placeholder="请输入 负责人"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            label="负责人电话"
            prop="chargerPhone"
          >
            <el-input
              clearable
              autocomplete="off"
              v-model="form.chargerPhone"
              placeholder="请输入 负责人电话"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="安装地址"
            prop="installAddr"
          >
            <el-input
              clearable
              autocomplete="off"
              v-model="form.installAddr"
              placeholder="请输入 安装地址"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            label="安装时间"
            prop="installTime"
          >
            <el-date-picker
              value-format="YYYY-MM-DD HH:mm:ss"
              v-model="form.installTime"
              type="datetime"
              placeholder="请选择 安装时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button type="primary" size="small" @click="handlesubmit('dialogForm')"
        >提交
        </el-button>
        <el-button @click="dialogFormVisible = false" size="small" type="info"
        >取消
        </el-button
        >
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { getData } from "@/api/basic/unregisteredDevice";
import { onMounted, reactive, toRefs, ref } from "vue";
import { getcommunicateList } from "@/api/dataDictionary";
import { getRegionById } from "@/api/system/region";
import selectTree from "@/components/selectTree";
import Getnpoint from '@/components/Getnpoint';
import { getEquipModel } from "@/api/basic/deviceModel";
import { changeTime } from "../../util/date";
import {
  addData
} from "@/api/basic/equipmanage";
import { ElMessage } from "element-plus";

export default {
  name: "unregisteredDevice",
  components: { selectTree, Getnpoint },
  setup() {
    var option = reactive({
      treeProps:{
        children: "children",
        label: "name",
        key: "code"
      },
      dialogFormVisible: false,
      tableData: [],
      attrList: [
        {
          label: "发起资源编码",
          prop: "resourceCode",
          render: (row) => (<div>{row.resourceCode}</div>)
        }, {
          label: "物理码",
          prop: "physicalAdrress",
          render: (row) => (<div>{row.physicalAdrress}</div>)
        }, {
          label: "指令类型",
          prop: "rebackDataType",
          render: (row) => (<div>{row.rebackDataType}</div>)
        }, {
          label: "名称",
          prop: "rebackDataTypeName",
          render: (row) => (<div>{row.rebackDataTypeName}</div>)
        }, {
          label: "广播编号",
          prop: "ip",
          render: (row) => (<div>{row.ip}</div>)
        }, {
          label: "内容",
          prop: "dataBody",
          render: (row) => (<div>{row.dataBody}</div>)
        }, {
          label: "回传状态",
          prop: "rebackState",
          render: (row) => (<div>{!row.rebackState ? "成功" : "失败"}</div>)
        }, {
          label: "上报类型",
          prop: "rebackType",
          render: (row) => (<div>{row.rebackType}</div>)
        },
        {
          label: "时间",
          prop: "time",
          render: (row) => (<div>{changeTime(row.time / 1000)}</div>)
        },
        {
          prop: "",
          label: "操作",
          render: (row) => {
            return <div>
              <el-button type="text" icon="Finished" onClick={() => handleregister(row)}>注册</el-button>
            </div>;
          }
        }
      ],
      form: {},
      formLabelWidth: "170px",
      communicateList: [],
      regiontree: [],
      equipModel: [],
      propsId: 0
    });
    var dialogForm = ref();

    function selectTreeChange(value) {
      option.form.regionId = value;
    }

    function handlesubmit() {
      dialogForm.value.validate((valid) => {
        if (valid) {
          addData(option.form).then(res => {
            if (res.data.success) {
              getList();
              dialogForm.value.resetFields();
              ElMessage({
                message: "操作成功",
                type: "success"
              });
              option.dialogFormVisible = false;
              dialogForm.value.resetFields();
              option.propsId = 0;
            } else {
              ElMessage({
                message: res.data.code.message,
                type: "error"
              });
            }
          });
        }
      });
    }

    function findP(list,parentId){
      const result = []
      let forFn = function (arr,parentId){
        for (let i = 0; i < arr.length; i++) {
          let item = arr[i];
          if (item.code === parentId){
            result.push(item)
            forFn(list,item.parentId)
            break
          }else {
            if (item.children){
              forFn(item.children,parentId)
            }
          }
        }
      }
      forFn(list,parentId);
      return result;
    }

    function handleregister(row) {
      option.dialogFormVisible = true;
      let { physicalAdrress, resourceCode, rebackState } = row;
      option.form = { macAddr: physicalAdrress, rebackFlag: rebackState, code: resourceCode, isForbidden: 0 };
      option.propsId = resourceCode.substring(2, 14);
      option.form.regionId = findP(option.regiontree, resourceCode.substring(2, 14))[0].id;
    }

    function getList() {
      getData().then(res => {
        if (res.data.success) {
          option.tableData = res.data.data;
        }
      });
    }


    function setpoint(val){
      let point = val.split(',');
      option.form.longitude = point[0];
      option.form.latitude = point[1];
    }

    function inputpoint(val){
      let point = val.split(',');
      option.form.longitude = point[0];
      option.form.latitude = point[1];
    }

    function handleClose(done){
      dialogForm.value.resetFields();
      option.propsId = 0;
      done();
    }

    onMounted(() => {
      getList()
      getcommunicateList().then(res => {
        if (res.data.success) {
          option.communicateList = res.data.data;
        }
      });
      getRegionById().then(res => {
        if (res.data.success) {
          option.regiontree = res.data.data;
        }
      });

      getEquipModel().then(res => {
        if (res.data.success) {
          option.equipModel = res.data.data;
        }
      });
    });
    return {
      dialogForm,
      handleClose,
      setpoint,
      inputpoint,
      ...toRefs(option),
      handlesubmit,
      selectTreeChange
    };
  }
};
</script>

<style scoped>

</style>