import axios from "@/api/axios"; // 引用axios
//获取本级媒体资源审核列表
export const getList = (page, limit, params) => {
  const param = {
    ...params,
    page,
    limit,
  };
  return axios({
    url: "/api/local/programCheck/sameProgramLibListData",
    method: "post",
    params: param,
  });
};
//本级资源审核通过
export const handlePass = (params) => {
  return axios({
    url: "/api/local/programCheck/sameProgramLibPass",
    method: "post",
    params: params,
  });
};
//本级资源审核未通过
export const handleNoPass = (params) => {
  return axios({
    url: "/api/local/programCheck/sameProgramLibNoPass",
    method: "post",
    params: params,
  });
};
