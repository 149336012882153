<template>
  <el-row :gutter="24">
    <el-col :span="6">
      <div class="instruction">
        <el-form :model="instruction" :label-width="searchLabelWidth" ref="instructForm">
          <!--        <el-row :gutter="20">-->
          <!--          <el-col :span="6">-->

          <el-form-item label="通道状态" prop="type" :rules="[{ required: true, message: '通道状态 不能为空' }]">
            <el-select
              clearable
              :fit-input-width="true"
              v-model="instruction.type"
              placeholder="请选择 通道状态"
            >
              <el-option label="全部" :value="0"></el-option>
              <el-option label="空闲" :value="1"></el-option>
              <el-option label="占用" :value="2"></el-option>
              <el-option label="故障" :value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="适配器" prop="adapter" :rules="[{ required: true, message: '适配器 不能为空' }]">
            <el-select
              :fit-input-width="true"
              v-model="instruction.adapter"
              placeholder="请选择 适配器"
            >
              <el-option :label="item.name" :value="item.code" v-for="(item ,index) in adapterList"
                         :key="index"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="资源码" prop="code" :rules="[{ required: true, message: '资源码 不能为空' }]">
            <el-input
              v-model="instruction.code"
              autocomplete="off"
              readonly
            ></el-input>
          </el-form-item>
          <el-form-item label="物理码" prop="mac" :rules="[{ required: true, message: '物理码 不能为空' }]">
            <el-input
              v-model="instruction.mac"
              autocomplete="off"
              readonly
            ></el-input>
          </el-form-item>
          <el-form-item label="传输通道号" prop="pointId">
            <el-input-number
              v-model="instruction.pointId"
              autocomplete="off"
              placeholder="请输入 传输通道号"
              :min="0"
            ></el-input-number>
          </el-form-item>
          <!--          </el-col>-->
          <!--          <el-col :span="6">-->
          <!--              <el-form-item>-->
          <el-button @click="sendInstruct('instructForm')" class="searchBtn"
          >发送指令
          </el-button
          >

        </el-form>
      </div>
    </el-col>
    <el-col :span="18">
      <div class="searchBox">
        <el-form :model="search" :label-width="searchLabelWidth" ref="searchForm">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="资源码" prop="code">
                <el-input
                  v-model="search.code"
                  autocomplete="off"
                  placeholder="请输入 资源码"
                ></el-input>
              </el-form-item>
            </el-col> <el-col :span="8">
              <el-form-item label="物理码" prop="mac">
                <el-input
                  v-model="search.mac"
                  autocomplete="off"
                  placeholder="请输入 物理码"
                ></el-input>
              </el-form-item>
            </el-col>
<!--            <el-col :span="8">-->
<!--              <el-form-item label="通道状态" prop="type">-->
<!--                <el-select-->
<!--                  clearable-->
<!--                  :fit-input-width="true"-->
<!--                  v-model="search.type"-->
<!--                  placeholder="请选择 通道状态"-->
<!--                >-->
<!--                  <el-option label="全部" :value="0"></el-option>-->
<!--                  <el-option label="空闲" :value="1"></el-option>-->
<!--                  <el-option label="占用" :value="2"></el-option>-->
<!--                  <el-option label="故障" :value="3"></el-option>-->
<!--                </el-select>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
<!--            <el-col :span="8">-->
<!--              <el-form-item label="适配器" prop="resourceCode">-->
<!--                <el-select-->
<!--                  :fit-input-width="true"-->
<!--                  v-model="search.resourceCode"-->
<!--                  placeholder="请选择 适配器"-->
<!--                >-->
<!--                  <el-option :label="item.name" :value="item.code" v-for="(item ,index) in adapterList"-->
<!--                             :key="index"></el-option>-->
<!--                </el-select>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
            <el-col :span="8">
              <!--              <el-form-item>-->
              <el-button @click="searchForm('searchForm')" class="searchBtn"
              >查询
              </el-button
              >
              <el-button @click="resetForm('searchForm')" class="resetBtn"
              >重置
              </el-button
              >

              <!--              </el-form-item>-->
            </el-col>
          </el-row>
        </el-form>
        <!--    <div class="divide">-->
        <!--      <img src="../../assets/divide.png" style="width: 100%" />-->
        <!--    </div>-->
        <!--    <div class="btnContainer">-->
        <!--      <div class="btnLeft">-->
        <!--        <el-button-->
        <!--          icon="Plus"-->
        <!--          @click="dialogFormVisible = true"-->
        <!--          class="searchBtn"-->
        <!--        >新增-->
        <!--        </el-button-->
        <!--        >-->
        <!--      </div>-->
        <!--    </div>-->
      </div>
      <basicTable
        :attrList="attrList"
        :tableData="tableData"
        ref="table"
        :indexshow="false"
        :page="page.current"
        :limit="page.limit"
        :total="page.total"
        :pageShow="false"
        @currentChange="handleCurrentChange"
        @sizeChange="handleSizeChange"
      >
      </basicTable>
    </el-col>
  </el-row>

</template>

<script>
import { getInputList, getadapterParams, sendInput } from "@/api/orchestrate/adapterQuery";

export default {
  name: "InputChannelQuery",
  data() {
    return {
      adapterList: [],
      formLabelWidth: "22%",
      dialogFormVisible: false,
      title: "新增",
      form: {
        roleName: ""
      },
      instruction: {
        pointId:0
      },
      searchLabelWidth: "120px",
      search: {
        type: 0
      },
      page: {
        current: 1,
        limit: 10,
        total: 0
      },
      tableData: [],
      attrList: [
        {
          label: "资源编码",
          prop: "code",
          render: (row) => <div>{row.code}</div>
        }, {
          label: "设备物理码",
          prop: "mac",
          render: (row) => <div>{row.mac}</div>
        }, {
          label: "指令发送信息",
          prop: "sendDetail",
          render: (row) => <div>{row.sendDetail}</div>
        }, {
          label: "指令回复信息",
          prop: "returnDetail",
          render: (row) => <div>{row.returnDetail}</div>
        }, {
          label: "发送指令时间",
          prop: "startTime",
          render: (row) => <div>{row.startTime}</div>
        }, {
          label: "回复指令时间",
          prop: "endTime",
          render: (row) => <div>{row.endTime}</div>
        }, {
          label: "指令描述",
          prop: "msg",
          render: (row) => <div>{row.msg}</div>
        },
        // {
        //   prop: "",
        //   label: "操作",
        //   render: (row) => {
        //     let str = row.state == 1 ? "授权web" : "授权app";
        //     return (
        //       <div>
        //         <el-button
        //           type="text"
        //           icon="Edit"
        //           onClick={() => this.handleEdit(row)}
        //         ></el-button>
        //         <el-button
        //           type="text"
        //           icon="Delete"
        //           onClick={() => this.handleDelete(row)}
        //         ></el-button>
        //         <el-button
        //           type="text"
        //           icon="CircleCheck"
        //           onClick={() => this.handleAuthorization(row)}
        //         >
        //           {str}
        //         </el-button>
        //       </div>
        //     );
        //   }
        // }
      ]
    };
  },
  watch: {
    "instruction.adapter"(value) {
      if (value) {
        let obj = this.adapterList.find(item => item.code == value);
        this.instruction.mac = obj.macAddr;
        this.instruction.code = obj.code;
      }

    }
  },
  created() {
    this.getData(this.page);
    getadapterParams().then(res => {
      if (res.data.success) {
        this.adapterList = res.data.data;
      }
    });
  },
  methods: {
    sendInstruct(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let { code, mac, type, pointId } = this.instruction;
          sendInput({ code, mac, type, pointId }).then(res => {
            if (res.data.success) {
              // console.log(res);
              this.$message.success("操作成功！");
            }
          });
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.page.current = 1;
      this.getData(this.page);
    },
    searchForm() {
      this.page.current = 1;
      this.getData(this.page);
    },

    handleDelete(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          return delroles(row.id);
        })
        .then((res) => {
          console.log(res);
          if (res.data.success) {
            this.getData(this.page);
            this.$message({
              message: "操作成功",
              type: "success"
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getData(page) {

      getInputList(page.current, page.limit, this.search).then((res) => {
        this.tableData = res.data.data.list;
        this.page.total = res.data.data.count;
      });
    },
    // submitForm(formName) {
    //   this.$refs[formName].validate((valid) => {
    //     if (valid) {
    //       console.log(this.form);
    //       if (this.title == "新增") {
    //         addroles(this.form).then((res) => {
    //           if (res.data.success) {
    //             this.getData(this.page);
    //             this.$refs[formName].resetFields();
    //             ElMessage({
    //               message: "操作成功",
    //               type: "success"
    //             });
    //             this.dialogFormVisible = false;
    //           } else {
    //             ElMessage({
    //               message: res.data.code.message,
    //               type: "error"
    //             });
    //           }
    //         });
    //       } else {
    //         editroles(this.form).then((res) => {
    //           if (res.data.success) {
    //             this.getData(this.page);
    //             this.$refs[formName].resetFields();
    //             ElMessage({
    //               message: "操作成功",
    //               type: "success"
    //             });
    //             this.dialogFormVisible = false;
    //           } else {
    //             ElMessage({
    //               message: res.data.code.message,
    //               type: "error"
    //             });
    //           }
    //         });
    //       }
    //     } else {
    //       return false;
    //     }
    //   });
    // },

    handleCurrentChange(currentPage) {
      this.page.current = currentPage;
      this.getData(this.page);
    },
    handleSizeChange(pageSize) {
      this.page.limit = pageSize;
      this.getData(this.page);
    }
  }
};
</script>

<style scoped>

</style>