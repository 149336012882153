<template>
  <div class="allow">
    <div class="drawer">
      <el-icon :size="20" color="#F4DF0A" @click="drawerShow=true" v-if="!drawerShow">
        <caret-right />
      </el-icon>
      <div v-if="drawerShow" class="drawerSearch">
        <el-input
          v-model="search.content"
          class="w-50 m-2"
          placeholder="请输入"
        >
          <template #suffix>
            <el-icon class="el-input__icon" @click="searchForm">
              <search />
            </el-icon>
          </template>
        </el-input>
        <el-radio-group v-model="search.name">
          <el-radio label="msgTitle">事件名称</el-radio>
          <el-radio label="ebmId">广播编号</el-radio>
        </el-radio-group>
        <div class="closeIcon">
          <el-icon :size="25" @click="drawerShow=false">
            <close />
          </el-icon>
        </div>
      </div>
    </div>
    <div id="container"></div>
    <div class="leftBox">
      <div class="tingList" v-for="(item,index) in tableData" :key="index" @click="handleDetail(item.id)">
        <div>
          <span class="column">I </span>
          <span class="tingTitle"> {{ item.msgTitle ? item.msgTitle : "无" }}</span>
        </div>
        <p class="tingType">
          <span class="tingTypeList">
            <el-icon :size="15"><lightning /></el-icon>
            {{ item.eventLevelName }}
          </span>
          <span class="tingTypeList">
            <el-icon :size="15"><InfoFilled /></el-icon>
            {{ item.eventType }}
          </span>
        </p>
        <section class="thingInfo">接入单位: {{ item.sourceName }}</section>
        <section class="detail_left"><span>广播编号:</span> <span class="thingEmbId"> {{ item.ebmId ? item.ebmId : "无" }}</span></section>
        <section>接入时间: {{ item.createTime }}</section>
        <div class="actionBox">
          <span class="action" @click.stop="auditDetails(item.id)">审核详情</span>
          <span class="action" @click="handleMap(item.id)">地图评估</span>
<!--          <span class="action" @click.stop="handleSchedule(item.id)">执行调度</span>-->
<!--          <span class="action" @click.stop="handleStop(item.id)">中止调度</span>-->
        </div>
      </div>
      <div class="page">
        <el-pagination
          small
          :pager-count="5"
          v-model:currentPage="page.current"
          :page-size="page.limit"
          layout=" prev, pager, next,total"
          :total="page.total"
          @size-change="handleSize"
          @current-change="handleCurrent"
        >
        </el-pagination>
      </div>
    </div>
    <div class="rightBox" v-if="detailShow">
      <div class="detailHeader">
        <span>调度详情</span>
        <el-icon :size="30" color="#0C7959" @click="detailShow=false">
          <close />
        </el-icon>
      </div>
      <div class="detailContent">
        <p class="detailTitle">I {{form.msgTitle}}</p>
        <div class="detailList">
          <span class="detailName">广播编号：</span>
          <div>{{ form.ebmId ? form.ebmId : "无" }}</div>
        </div>
        <div class="detailList">
          <span class="detailName">发布人：</span>
          <div>{{ form.createUserName }}</div>
        </div>
        <div class="detailList">
          <span class="detailName">广播类型：</span>
          <div>{{ form.bcastTypeName }}</div>
        </div>
        <div class="detailList">
          <span class="detailName">事件类型：</span>
          <div>{{ form.eventType }}</div>
        </div>
        <div class="detailList">
          <span class="detailName">广播阶段：</span>
          <div>{{ form.processStateName }}</div>
        </div>
        <div class="detailList">
          <span class="detailName">事件等级：</span>
          <div class="eventLevel">{{ form.eventLevelName }}</div>
        </div>
        <div class="detailList">
          <span class="detailName">开始时间：</span>
          <div>{{ form.startTime }}(计划)/{{ form.startTimeFact }}(实际)</div>
        </div>
        <div class="detailList">
          <span class="detailName">结束时间：</span>
          <div>{{ form.endTime }}(计划)/{{ form.endTimeFact }}(实际)</div>
        </div>
        <div class="detailList">
          <span class="detailName">审核方式：</span>
          <div>{{ form.verify == 1 ? "自动审核" : "策略配置" }}</div>
        </div>
        <div class="detailList">
          <span class="detailName">回传设备总数：</span>
          <div>{{ totalForm.returnNum }}</div>
        </div>
        <div class="detailList">
          <span class="detailName">非回传设备总数：</span>
          <div>{{ totalForm.unreturnNum }}</div>
        </div>
        <div class="detailList">
          <span class="detailName">实际回传终端数：</span>
          <div>{{ totalForm.numFact }}</div>
        </div>
        <div class="detailList">
          <span class="detailName">实际非回传终端数：</span>
          <div>{{ totalForm.unnumFact }}</div>
        </div>
        <div class="detailList">
          <span class="detailName">实际回传响应数：</span>
          <div>{{ totalForm.deviceNumFact ? totalForm.deviceNumFact : 0 }}</div>
        </div>
        <div class="detailList">
          <span class="detailName">覆盖率：</span>
          <div>{{ ((totalForm.numFact + totalForm.unnumFact) / (totalForm.returnNum + totalForm.unreturnNum)) *100+"%"|| "" }}</div>
        </div>
      </div>
    </div>
  </div>
  <el-dialog
    width="30%"
    v-model="dialogFormVisible"
    :close-on-click-modal="false"
  >
    <el-form
      class="scheduleForm"
      label-position="right"
      :model="scheduleForm"
      size="small"
      ref="scheduleForm"
    >
      <el-form-item
        label="调度"
        :rules="[{ required: true, message: '调度 不能为空' }]"
        prop="scheduls"
      >
        <el-checkbox-group v-model="scheduleForm.scheduls" :disabled="disabled">
          <el-checkbox :label="item.code" v-for="(item, index) in treeData" :key="index">{{ item.name }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button
          type="primary"
          size="small"
          @click="handlesubmit('scheduleForm')"
        >提交
        </el-button>
        <el-button @click="dialogFormVisible = false" size="small" type="info" plain
        >取消
        </el-button>
      </div>
    </template>
  </el-dialog>
  <el-drawer
    size="55%"
    v-model="aduitDrawer"
    title="审核详情"
    direction="rtl"
  >
    <div class="textMsg">
      <div class="textMsg_left">
        <section>
          <span class="textName">预警消息标题：</span>
          <span>{{ textForm.msgTitle }}</span>
        </section>
        <section>
          <span class="textName">消息发送编号：</span>
          <span>{{ textForm.ebmId }}</span>
        </section>
        <section>
          <span class="textName">事件类型：</span>
          <span>{{ textForm.eventType }}</span>
        </section>
      </div>
      <div class="textMsg_right">
        <span class="textName">预警消息内容:</span>
        <div class="content">
          {{ form.msgDesc ? form.msgDesc : "无" }}
        </div>
      </div>
    </div>
    <div class="drawer_table">
      <div class="btn_box">
        <span :class="{btn_table:activeBtn==1}" class="btnItem" @click="handleactiveBtn(1)">音频文件</span>
        <span :class="{btn_table:activeBtn==2}" class="btnItem" @click="handleactiveBtn(2)">发送报文</span>
        <span :class="{btn_table:activeBtn==3}" class="btnItem" @click="handleactiveBtn(3)">播发状态反馈</span>
      </div>
      <TableNoPage
        :attrList="attrList"
        :tableData="drawerData"
        ref="table"
      >
      </TableNoPage>
    </div>
  </el-drawer>
</template>

<script>
import {
  getCancelList,
  getDetail,
  executeSchedul,
  submitSchedul,
  stopSchedul,
  audioDetails,
  getVideoList,
  sendMessage,
  getfeedback,
  mapEvaluate
} from "@/api/schedule/pendSchedule";
import {
  getbasicData
} from "@/api/basic/startBroadcast";
import TableNoPage from "@/components/tableNoPage";
export default {
  name: "scheduleCancel",
  components: {
    TableNoPage
  },
  data() {
    return {
      totalForm:{},
      map:null,
      idx: "",
      textForm: {},
      activeBtn: 1,
      drawerShow: false,
      aduitDrawer: false,
      disabled: false,
      choiceType: "",
      dialogFormVisible: false,
      detailShow: false,
      form: {},
      scheduleForm: {
        scheduls: []
      },
      attrList: [],
      videoList: [
        {
          label: "文件名称",
          prop: "name",
          render: (row) => <div>{row.name}</div>
        },
        {
          label: "访问路径",
          prop: "path",
          render: (row) => <audio controls style="width:100%" src={row.path}></audio>
        }
      ],
      messageList: [
        {
          label: "发送数据包编号",
          prop: "sendEbdId",
          render: (row) => <div>{row.sendEbdId}</div>
        },
        {
          label: "目标平台名称",
          prop: "targetName",
          render: (row) => (<div>{row.targetName}</div>)
        }, {
          label: "向目标平台tar时间",
          prop: "sendTime",
          render: (row) => (<div>{row.sendTime}</div>)
        }, {
          label: "目标平台接收回执状态描述",
          prop: "targetDesc",
          render: (row) => (<div>{row.targetDesc}</div>)
        }
      ],
      feedbackList: [
        {
          label: "接收数据包Id",
          prop: "receiveEbdId",
          render: (row) => <div>{row.receiveEbdId}</div>
        },
        {
          label: "数据来源平台名称",
          prop: "sourceName",
          render: (row) => (<div>{row.sourceName}</div>)
        }, {
          label: "数据来源生成时间",
          prop: "receiveTime",
          render: (row) => (<div>{row.receiveTime}</div>)
        }, {
          label: "播发状态描述",
          prop: "stateDesc",
          render: (row) => (<div>{row.stateDesc}</div>)
        }
      ],
      eventLevellist: [],
      bcastTypelist: [],
      tableData: [],
      drawerData: [],
      treeData: [],
      search: {
        name: "msgTitle"
      },
      page: {
        current: 1,
        limit: 4,
        total: 0
      }, drawerpage: {
        current: 1,
        limit: 10,
        total: 0
      }
    };
  },
  mounted() {
    let that = this;
    // document.addEventListener("click", function() {
    //   that.drawerShow = false;
    // });
   this.map = new AMap.Map("container", {
      resizeEnable: true,
      dragEnable: true,
      doubleClickZoom: true,
      rotateEnable: true,
      mapStyle: "amap://styles/924a0b80c02969b8a905eab4dd9f5431",
      center: [104.065735, 30.659462]
    });
  },
  async created() {
    try {
      let res = await getbasicData(4);
      if (res.data.success) {
        this.eventLevellist = res.data.data.eventLevel;
        this.bcastTypelist = res.data.data.bcastType;
      }
      await this.getData(this.page);
    } catch (err) {
      console.log(err);
    }
  },
  methods: {
    handleMap(id) {
      this.map.clearMap();
      mapEvaluate(id).then(res => {
        if (res.data.success) {
          let data = res.data.data;
          data.map(item => {
            let arr = eval(item.code);
            arr.map(val => {
              let code = eval(val);
              console.log(code);
              let polygon = new AMap.Polygon({
                map: this.map,
                path: code,
                strokeColor: "#00FFC6", //线颜色
                strokeOpacity: 1, //线透明度
                strokeWeight: 2,    //线宽
                fillColor: "#0D6854", //填充色
                fillOpacity: 0.35//填充透明度
              });
              this.map.add(polygon);
            });

          });
          this.map.setFitView();
        }
      });
    },
    searchForm() {
      this.page.current = 1;
      let obj = {};
      obj[this.search.name] = this.search.content;
      this.getData(this.page, obj);
    },
    handleactiveBtn(index) {
      this.activeBtn = index;
      let fn;
      if (index == 1) {
        this.attrList = this.videoList;
        fn = getVideoList;
      } else if (index == 2) {
        this.attrList = this.messageList;
        fn = sendMessage;
      } else if (index == 3) {
        this.attrList = this.feedbackList;
        fn = getfeedback;
      }
      fn(this.idx).then(res => {
        if (res.data.success) {
          this.drawerData = res.data.data;
        }
      });
    },

    extractHostname(url) {
      var hostname;
      if (url.indexOf("//") > -1) {
        hostname = url.split('/')[2];
      } else {
        hostname = url.split('/')[0];
      }
      hostname = hostname.split(':')[0];
      hostname = hostname.split('?')[0];
      return hostname;
    },

    auditDetails(id) {
      this.idx = id;
      this.activeBtn = 1;
      this.aduitDrawer = true;
      this.attrList = this.videoList;
      audioDetails(id).then(res => {
        if (res.data.success) {
          this.textForm = res.data.data;
        }
      });
      getVideoList(id).then(res => {
        if (res.data.success) {
          this.drawerData = res.data.data;
          let url = window.location.hostname;
          this.drawerData.forEach((item, index)=>{
            this.drawerData[index].path = item.path.replace(this.extractHostname(item.path), url);
          })
        }
      });
    },
    handlesubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let { id, scheduls } = this.scheduleForm;
          scheduls = scheduls.join(",");
          let params;
          if (this.choiceType == 1) params = { id, scheduls };
          else params = { id };
          submitSchedul(params).then(res => {
            if (res.data.success) {
              this.getData(this.page);
              this.$message.success("操作成功！");
              this.dialogFormVisible = false;
            }
          });
        }
      });
    },

    changeData(data) {
      data.map(item => {
        let obj;
        for (let value of this.eventLevellist) {
          if (value.code == item.eventLevel) {
            obj = value;
            break;
          }
        }
        item.eventLevelName = obj.name;
      });
      return data;
    },
    getData(page, params = {}) {
      getCancelList(page.current, page.limit, params).then((res) => {
        if (res.data.success) {
          console.log(res);
          this.page.total = res.data.data.count;
          this.tableData = this.changeData(res.data.data.list);
        }
      });
    },
    handleCurrent(currentPage) {
      this.page.current = currentPage;
      this.getData(this.page);
    },
    handleSize(pageSize) {
      this.page.limit = pageSize;
      this.getData(this.page);
    },
    handleDetail(id) {
      this.detailShow = true;
      getDetail(id).then(res => {
        if (res.data.success) {
          this.form = res.data.data.bcastVO;
          this.form.bcastTypeName = this.bcastTypelist.find(item => item.code == this.form.bcastType).name;
          this.form.eventLevelName = this.eventLevellist.find(item => item.code == this.form.eventLevel).name;
          this.totalForm = res.data.data.deviceTotal;
        }
      });
    },
  }
};
</script>

<style scoped lang="scss">
.detail_left{
  display: flex;
  justify-content: space-between;
}

.thingEmbId{
  width: 280px;
}
.allow {
  width: 100%;
  height: 900px;
  position: relative;
  font-family: Source Han Sans SC;
  text-align: left;
  word-wrap: break-word;
}

#container {
  width: 100%;
  height: 100%;
}

.leftBox {

  padding: 10px;
  width: 400px;
  height: 880px;
  background: rgba(9, 24, 23, 0.9);
  border: 1px solid #0C7959;
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 0;
  }
}

.tingList {
  margin-bottom: 7px;
  padding: 0 10px 10px 10px;
  border-bottom: 2px solid #0C7959;
  text-align: left;
  font-size: 16px;
  font-weight: 300;
  color: #95D7C8;
}

.tingType {
  display: flex;
  justify-content: flex-start;
}

.tingTitle {
  font-size: 20px;
  font-weight: 400;
  text-decoration: underline;
  color: #00FFC6;
}

.tingTypeList {
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.thingInfo {
  /*margin-bottom: 5px;*/
}

.column {
  font-size: 20px;
  color: #F4DF0A;
}

.actionBox {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.action {
  color: #00FFD5;
  padding: 0px 10px;
  border: 1px solid #126B51;
  background: linear-gradient(0deg, #18453B 0%, #091915 77%);
}

.action:hover {
  color: #d1a930;
  background: linear-gradient(360deg, #091915, #18453B);
}

::v-deep .page .el-pager li.active {
  width: 30px;
  height: 30px;
  background: #0d3734;
  border: 1px solid #146354;
}

::v-deep .page .el-pager li.number {
  color: #48a28d;
}

::v-deep .page .el-pagination button:disabled {
  background: none;
}

::v-deep .el-table .cell {
  font-size: 18px !important;
}

.page {
  /*position: absolute;*/
  /*bottom: 10px;*/
  /*right: 0;*/
  width: 100%;
  text-align: right;
}

::v-deep .page .el-pagination span:not([class*="suffix"]) {
  vertical-align: baseline;
}

::v-deep .el-pagination .el-input__inner {
  width: 110px !important;
  height: 30px !important;
  font-size: 16px;
  font-family: Source Han Sans SC !important;
  font-weight: 300 !important;
  color: #48a28d !important;
}

::v-deep.el-pagination span {
  color: #48a28d !important;
}

::v-deep .page .el-pagination .el-select .el-input {
  width: auto;
  margin: 0;
}

::v-deep .el-pagination {
  --el-pagination-bg-color: none;
}

.drawer {
  //display: flex;
  //justify-content: space-between;
  //width: 400px;
  line-height: 41px;
  z-index: 888;
  position: absolute;
  top: 0;
  left: 0;

  el-icon {
    width: 19px;
    height: 41px;
  }
}

.rightBox {
  width: 470px;
  height: 630px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 0;
  }

  background: rgba(9, 24, 23, 0.9);
  border: 1px solid #0C7959;
  position: absolute;
  top: 0;
  right: 0;
}

.detailHeader {
  padding: 5px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  background: rgba(10, 43, 40, 0.9);
  border-bottom: 1px solid #0C7959;
  font-size: 24px;
  font-weight: 300;
  color: #00FFD5;
  line-height: 36px;
}

.detailList {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 8px;
}

.detailContent {
  font-size: 16px;
  font-weight: 300;
  color: #11FFBF;
  padding: 10px 10px;
}

.detailName {
  width: 150px;
  color: #50C9B1;
}

.detailList > div {
  width: 300px;
}

.detailTitle {
  font-size: 24px;
  font-weight: 300;
  color: #F4DF0A;
  line-height: 36px;
}

.eventLevel {
  font-weight: bolder;
  color: red;
}

.tingList:hover {
  background-color: rgba(13, 104, 84, 0.2);
}

.el-checkbox-group {
}

.scheduleForm {
  max-height: 500px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 0;
  }
}

.drawerSearch {
  padding: 15px 15px 0 15px;
  display: inline-block;
  width: 371px;
  //height: 293px;
  background: #081413;
  border: 1px solid #2A5E53;
}

.closeIcon {
  text-align: right;
}

.textMsg {
  margin-bottom: 10px;
  padding: 10px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  font-family: Source Han Sans SC;
  font-size: 16px;
  font-weight: 300;
  color: #00FFC6;
  width: 100%;
  //height: 210px;
  background: #0A2B28;
  border: 1px solid #178164;

  .textName {
    color: #32B89C;
  }
}

.textMsg_left {
  width: 45%;

  section {
    margin-bottom: 10px;
  }
}

.textMsg_right {
  width: 55%;
  display: flex;
  justify-content: space-between;

  .content {
    width: 80%;
  }
}

.drawer_table {
  width: 100%;
  padding: 10px;
  background-color: #0A2B28;
  border: 1px solid #178164;
}

.btnItem {
  font-size: 16px;
  font-weight: 300;
  color: #59B7A4;
  line-height: 44px;
  display: inline-block;
  width: 169px;
  height: 44px;
  margin-right: 15px;
}

.btn_box {
  margin-bottom: 20px;
}

.btn_table {
  color: #F4DF0A;
  background: url("../../assets/activeitem.png") no-repeat center;
}
</style>