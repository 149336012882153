<template>
  <div class="headers">
    <div class="top_left">
      <img src="../assets/leftlogo.png">
      <div class="text">
        <span class="title">{{title}}</span>
      </div>
    </div>
    <div class="top_center" ref="topCenter">

      <ul class="menuUl" ref="menuUl" :style="{ left: positionLeft + 'px' }">
        <li
          class="menuliCl"
          :class="{
            menuLiSelected: activeIndex == 0,
            menuLi: activeIndex !== 0,
          }"
        >首页
        </li>
        <li
          v-for="(item, index) in menuList"
          :key="index"
          class="menuliCl"
          :style="{transform: `translate(${(index+1) * -35}px, 0)`}"
          :class="{
            menuLiSelected: activeIndex == index+1,
            menuLi: activeIndex !== index+1,
          }"
          @click="tosecondPage(item.id, index + 1)"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>
    <div class="top_right">
      <div class="timer">
        <div class="timertop">{{ timeArr[1] }}</div>
        <span>{{ timeArr[0] + " " }}{{ formatweek }}</span>
      </div>
      <el-dropdown>
        <img src="../assets/userImg.png" />
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item  @click="getMyInfo">个人信息</el-dropdown-item>
            <el-dropdown-item  @click="reset">修改密码</el-dropdown-item>
            <el-dropdown-item  @click="handleLogout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>

    </div>
  </div>
</template>

<script>
import { getMenuPerm } from "@/api/system/menu";
import { dateFormat, getWeek } from "../util/date";
import { mapGetters, mapMutations } from 'vuex';
import { ref } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { useRouter } from "vue-router";
import { logout } from "@/api/system/user";
import { clearCookie } from "@/util/auth";
import middle from "./middle";

export default {
  name: "pageHeader",
  setup() {
    const router = useRouter();
    let menuUl = ref();
    let rightBtn = ref(true);
    let topCenter = ref();
    let positionLeft = ref(0);
    let count = ref(0);

    function toRight() {
      let menuUlWidth = menuUl.value.offsetWidth;
      let topCenterWidth = topCenter.value.offsetWidth;
      count.value++;
      positionLeft.value = -count.value * (topCenterWidth * 0.8);
      if (positionLeft.value + menuUlWidth <= topCenterWidth) {
        return (rightBtn.value = false);
      }
      // menuUl.value.style.lef =  'translateX(-306px)';
    }

    function toLeft() {
      count.value--;
      // let menuUlWidth = menuUl.value.offsetWidth;
      let topCenterWidth = topCenter.value.offsetWidth;
      positionLeft.value = -count.value * (topCenterWidth * 0.8);
      if (positionLeft.value == 0) {
        return (rightBtn.value = true);
      }
    }
    function handleLogout() {
      ElMessageBox.confirm(
        "确认退出登陆?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "Warning"
        }
      )
        .then(() => {
          clearCookie();
          router.replace("/login");
          ElMessage({
            type: "success",
            message: "操作成功！"
          });
          logout();
        })
    }

    function getMyInfo() {
      router.push("/content/userinfo")
    }
    return { toLeft, toRight, menuUl, topCenter, positionLeft, rightBtn, count,handleLogout,getMyInfo };
  },
  data() {
    return {
      activeIndex: 0,
      timeArr: [],
      formatweek: "",
      menuList: [],
      menuAll: []
    };
  },
  computed:{
    ...mapGetters(["title"]),
  },
  created() {
    this.$store.dispatch("GetPermMenu").then((res) => {
      // console.log(res);
      if (res.data.success) {
        const data = res.data.data.menuLists;
        this.menuAll = data;
        let arr = [];
        data.map((item) => {
          if (item.type == 0) {
            let { icon, name, id, url } = item;
            arr.push({ icon, name, id, url });
          }
        });
        //获取一级菜单
        this.menuList = arr;
      }
    });
    this.gettime();
    setInterval(this.gettime, 1000);
  },

  methods: {
    ...mapMutations(["MUTATIONA_PWD_DIALOG"]),
    reset(){
      this.MUTATIONA_PWD_DIALOG(true)
    },

    gettime() {
      let time = new Date();
      let formattime = dateFormat(time, "yyyy年MM月dd日 hh:mm:ss");
      this.timeArr = formattime.split(" ");
      this.formatweek = getWeek(time);
    },
    tosecondPage(id, index) {
      window.sessionStorage.setItem("count", this.count);
      this.$store.commit("SET_activemenuInfo", { index, id });
      this.activeIndex = index;
      let obj = this.menuAll[index - 1].children[0];
      if (obj.type == 1) {
        obj = obj.children[0];
      }
      this.$router.push(obj.url);
    }
  }
};
</script>

<style scoped lang="scss">
.headers {
  background: url("../assets/headerBg.png") no-repeat center;
  width: 100%;
  height: 100%;
  display: flex;
  //justify-content: space-between;
  align-items: center;
}

.top_left {
  width: 24%;
  height: 100%;
  display: flex;
  align-items: center;
  img{
    display: block;
    width: 55px;
    height: 49px;
    margin: 0 20px;
  }
  .text{
    display: flex;
    flex-direction: column;
    .title{
      font-weight: 600;
      font-size: 30px;
      color: #FFFFFF;
    }
  }
  /*background: url("../assets/headerTitle2.png") no-repeat center;*/
}

.top_center {
  overflow: hidden;
  position: relative;
  width: 62%;
  height: 100%;
}

.menuUl {
  margin: 0;
  transition: all 1s;
  padding: 0;
  position: absolute;
  top: 0;
  /*width: 100%;*/
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: hidden;
}

.menuLi {
  background: url("../assets/menuBg.png") no-repeat center;
}

.menuliCl {
  cursor: pointer;
  line-height: 41px;
  margin: 0 5px;
  width: 143px;
  /*list-style: none;*/
  height: 39px;
  font-size: 20px;
  font-family: Source Han Sans SC;
  font-weight: 300;
  color: #32B89C;
}

.menuLiSelected {
  color: #ffffff;
  background: url("../assets/menuSelected.png") no-repeat center;
}

.menuliCl:hover {
  color: #00ffc6;
}

.top_right {
  position: absolute;
  right: 0;
  width: 14%;
  height: 100%;
  display: flex;
  /*justify-content: space-around;*/
  align-items: center;
}

.timer {
  width: 70%;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.timertop {
  text-align: right;
  font-size: 18px;
  font-family: Dotted Songti Circle;
  font-weight: 400;
  color: #00ffc0;
}

.timer span {
  text-align: right;
  font-size: 14px;
  font-family: Dotted Songti Circle;
  font-weight: 400;
  color: #00ffc0;
}

.el-dropdown-menu {
  background-color: #0a2b28;
}
</style>
