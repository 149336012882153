<template>
  <div>
    <div class="top">
      <el-form :model="form" style="margin: 0 40px">
        <div class="top-box">
          <el-form-item label="方案名称:">
            <el-input v-model="form.name" placeholder="请输入方案名称"></el-input>
          </el-form-item>
          <el-form-item label="分辨率:">
            <el-select placeholder="请选择分辨率" v-model="form.resolution">
              <el-option v-for="item in resoList" :key="item.id" :label="`${item.resolutionOne}*${item.resolutionTwo}`" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="屏幕拼接方式:">
            <el-select @change="change" v-model="form.region" placeholder="请选择屏幕拼接方式">
              <el-option v-for="(item,index) in resoList" :key="index" :label="`${item.screenOne}*${item.screenTwo}`" :value="`${item.screenOne},${item.screenTwo}`"></el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
    </div>

    <div class="mid">
      <div class="line"></div>
      <div class="viodes" id="viodes" @mousedown="isDown=true;arr=[]" @mouseup="onmouseup">
        <div class="c_viode" :id="`c_viode${index}`" v-for="(item,index) in splitNum" :key="index" @mousemove="onmousemove(index)">
          <el-select v-model="address[index]" placeholder="请选择地址" class="c_viode_select">
            <el-option v-for="(item2,index2) in urls" :key="index2" :label="item2.name" :value="item2.address"></el-option>
          </el-select>
        </div>
      </div>

      <div class="bom">
        <div class="btn">
          <div @click="reset">重置表单</div>
          <div @click="onsave">新增</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { typeList, urlList, dataSave } from "@/api/screen/deploy";
import { useRouter } from "vue-router";
import { reactive, ref, toRefs, onMounted, nextTick } from "vue";
import {ElMessage} from "element-plus";
export default {
  setup(){
    const router = useRouter();
    let splitNum = ref(0);
    const scale = ref(0);
    let isDown = ref(false);
    let state = reactive({
      form:{},
      resoList:{},
      urls:{},
      address:[],
      arr:[],//合并
      resarr:[],
      fromData:[],//最后提交的conten数据处理集合
    })

    //分隔数组
    function group(array, subGroupLength) {
      let index = 0;
      let newArray = [];
      while(index < array.length) {
        newArray.push(array.slice(index, index += subGroupLength));
      }
      return newArray;
    }

    function change(val){
      let _v = val.split(',');
      scale.value = _v[0];
      state.resarr = []; //每次切换要先清空原先的格式
      state.address = []; //每次切换要先清空原先的下拉框选择
      let _areas = "";
      for(let i=0,len=_v[1];i<len;i++){
        let _e = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n'];
        state.resarr[i] = _e.splice(i*_v[0],_v[0]);
      };
      state.resarr.forEach(item=>{
        _areas += `'${item.join(' ')}'`;
      })
      splitNum.value = _v.reduce((prev, cur) => prev * cur);
      for(let i=0,len2=splitNum.value;i<len2;i++){
        nextTick(()=>{
          document.getElementById(`c_viode${i}`).style.gridArea = null;
        })
      }
      document.body.style.setProperty('--columns', `repeat(${_v[0]},1fr)`);
      document.body.style.setProperty('--rows', `repeat(${_v[1]},1fr)`);
      document.body.style.setProperty('--areas', _areas);
    }

    function onmousemove(event){
      if (isDown.value == false) return;
      if (state.arr.indexOf(event) == -1) state.arr.push(event);
    }

    function onmouseup(){
      let result = [];
      let _ar = "";
      let _ramdom = (Math.random()*1000).toFixed(0);
      isDown.value = false;//取消拖拽选择
      state.resarr.forEach(item=>result.push(...item));
      state.arr.forEach(item=>result[item] = `areas${_ramdom}`);
      if(state.arr.length > 0) state.fromData.push(state.arr);

      group(result,Number(scale.value)).forEach(item=>{
        _ar += `'${item.join(' ')}'`;
      })
      state.resarr = group(result,Number(scale.value));
      for(let i=0, len=splitNum.value;i<len;i++){
        nextTick(()=>{
          if(state.arr.includes(i)) document.getElementById(`c_viode${i}`).style.gridArea = `areas${_ramdom}`;
          document.body.style.setProperty('--areas', _ar);
        })
      }
    }

    async function onsave(){
      if(!state.form.name){
        ElMessage({
          type: "warning",
          message: "请输入方案名称"
        });
        return
      }
      if(!state.form.resolution){
        ElMessage({
          type: "warning",
          message: "请选择分辨率"
        });
        return
      }
      if(!state.form.region){
        ElMessage({
          type: "warning",
          message: "请选择屏幕拼接方式"
        });
        return
      }
      let nums = [];
      let addr = [];
      let _flat = state.fromData.flat();
      for(let i=0;i<splitNum.value;i++){
        nums.push(i)
      }
      for(let i = 0;i<nums.length;i++){
        if(!_flat.includes(nums[i])){
          state.fromData.push([nums[i]])
        }
      }
      state.address.forEach((item,index)=>{
        state.fromData.forEach(e=>{
          if(e.includes(index)){
            addr.push({
              k:e,
              url:item
            })
          }
        })
      })

      let { name, resolution, region } = state.form;
      let resolutionOne = state.resoList.filter(item=>item.id == resolution)[0].resolutionOne;
      let resolutionTwo = state.resoList.filter(item=>item.id == resolution)[0].resolutionTwo;
      let screenOne = region.split(',')[0];
      let screenTwo = region.split(',')[1];
      await dataSave({
        name, resolutionOne, resolutionTwo, screenOne, screenTwo, content:JSON.stringify(addr)
      });
      router.replace("/content/screen_deploy");
      ElMessage({
        type: "success",
        message: "操作成功！"
      });
    }


    onMounted(()=>{
      typeList().then(res=>{ state.resoList = res.data.data });
      urlList().then( res=>{ state.urls = res.data.data });
    })

    return{
      onsave,
      change,
      onmousemove,
      onmouseup,
      splitNum,
      scale,
      isDown,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="scss" scoped>
.el-form-item{
  margin-bottom: 0;
  ::v-deep .el-form-item__content{
    width: 300px !important;
  }
}
.top{
  width: 100%;
  height: 88px;
  background: #0C3734;
  border: 1px solid #2D6156;
  .top-box{
    height: 88px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.mid{
  height: 772px;
  background: #0A2B28;
  border: 1px solid #2D6156;
  margin-top: 10px;
  .line{
    width: 100%;
    height: 10px;
    background: #0E3D34;
  }
  .viodes{
    margin:20px 10px 0 10px;
    height: 620px;
    display: grid;
    grid-template-columns: var(--columns);
    grid-template-rows: var(--rows);
    grid-template-areas:  var(--areas);
    grid-gap: 10px 10px;
    .c_viode{
      background: #12433B;
      border: 1px solid #19705C;
      display: flex;
      align-items: center;
      justify-content: center;
      .c_viode_select{
        height: 48px !important;
        width: 300px !important;
      }
    }
  }
  .bom{
    margin-top: 30px;
    height: 50px;
    .btn{
      margin: 0 auto;
      width: calc(134px*2 + 34px);
      display: flex;
      justify-content: space-between;
      > div{
        width: 134px;
        height: 50px;
        background-size: 100% 100%;
        line-height: 50px;
        font-size: 20px;
        text-align: center;
        cursor: pointer;
        &:nth-child(1){
          background: url("../../assets/menuBg.png") no-repeat center;
          color: #02daa9;
        }
        &:nth-child(2){
          background: url("../../assets/menuSelected.png") no-repeat center;
          color: #b9972d;
        }
      }
    }
  }
}

</style>