import axios from "@/api/axios"; // 引用axios
//音量调节
export const adjustVolume = (params) => {
  return axios({
    url: "/api/terminal/setVolume",
    method: "post",
    params: params,
  });
};
//编码设置
export const encodeSetting = (params) => {
  return axios({
    url: "/api/terminal/setResourceCode",
    method: "post",
    params: params,
  });
};
//终端网络设置
export const netSet = (params) => {
  return axios({
    url: "/api/terminal/setNetworkParam",
    method: "post",
    params: params,
  });
};
//回传设置
export const passBack = (params) => {
  return axios({
    url: "/api/terminal/passBack/configSet",
    method: "post",
    params: params,
  });
};
//回传参数设置
export const passBackParams = (params = {}) => {
  return axios({
    url: "/api/terminal/passBack/config",
    method: "post",
    params: params
  });
};
//回传周期设置
export const passBackcycle = (params = {}) => {
  return axios({
    url: "/api/terminal/passBack/setPeriod",
    method: "post",
    params: params
  });
};
//时间校准
export const timeCalibrate = (params = {}) => {
  return axios({
    url: "/api/terminal/time/timeCalibration",
    method: "post",
    params: params
  });
};
//功放设置
export const switchConfig = (params = {}) => {
  return axios({
    url: "/api/terminal/power/powerAmplifier",
    method: "post",
    params: params
  });
};
//功放页面开关设置（参数）
export const switchOpenConfig = (params = {}) => {
  return axios({
    url: "/api/terminal/power/amplifier",
    method: "post",
    params: params
  });
};
//ts频率设置
export const lockFrequency = (params = {}) => {
  return axios({
    url: "/api/terminal/ts/setTS",
    method: "post",
    params: params
  });
};
//ts频率参数
export const lockFrequencyType = (params = {}) => {
  return axios({
    url: "/api/terminal/ts/tsPage",
    method: "post",
    params: params
  });
};
//RDS维持参数
export const maintainParams = (params = {}) => {
  return axios({
    url: "/api/terminal/rds/maintain",
    method: "post",
    params: params
  });
};
//RDS维持设置
export const maintainset = (params = {}) => {
  return axios({
    url: "/api/terminal/rds/setRDS",
    method: "post",
    params: params
  });
};
//参数状态查询（获取参数）
export const getparamsStatus = (params = {}) => {
  return axios({
    url: "/api/terminal/query/query",
    method: "post",
    params: params
  });
};
//参数状态查询（获取参数）
export const paramsStatus = (params = {}) => {
  return axios({
    url: "/api/terminal/query/paramAndStatus",
    method: "post",
    params: params
  });
};

export const setRdsFreqPoint = (params = {}) => {
  return axios({
    url: "/api/terminal/rds/setRdsFreqPoint",
    method: "post",
    data: params
  });
};

export const resetDevice = (params = {}) => {
  return axios({
    url: "/api/terminal/rds/resetDevice",
    method: "post",
    data: params
  });
};

export const restoreFactory = (params = {}) => {
  return axios({
    url: "/api/terminal/rds/restoreFactory",
    method: "post",
    data: params
  });
};
export const setInstruction = (params = {}) => {
  return axios({
    url: "/api/terminal/rds/setInstruction",
    method: "post",
    data: params
  });
};

