<template>
  <div class="regionform">
    <el-form :model="form" :label-width="searchLabelWidth" ref="searchForm">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="广播类型" prop="bcastType" :rules="[{ required: true, message: '广播类型 不能为空' }]">
            <el-select
              v-model="form.bcastType"
              placeholder="请选择 广播类型"
              clearable
            >
              <el-option
                :label="item.name"
                :value="item.code"
                v-for="(item, index) in basicData.bcastType"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="事件类型" prop="eventType" :rules="[{ required: true, message: '事件类型 不能为空' }]">
            <el-select
              v-model="form.eventType"
              placeholder="请选择 事件类型"
              clearable
              filterable
            >
              <el-option
                :label="item.name"
                :value="item.code"
                v-for="(item, index) in basicData.eventType"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="事件级别" prop="eventLevel" :rules="[{ required: true, message: '事件级别 不能为空' }]">
            <el-select
              v-model="form.eventLevel"
              placeholder="请选择 事件级别"
              clearable
            >
              <el-option
                :label="item.name"
                :value="item.code"
                v-for="(item, index) in basicData.eventLevel"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="开始时间" prop="startTime" :rules="[{ required: true, message: '开始时间 不能为空' }]">
            <el-date-picker
              type="datetime"
              value-format="YYYY-MM-DD HH:mm:ss"
              v-model="form.startTime"
              placeholder="选择 开始时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="结束时间" prop="endTime" :rules="[{ required: true, message: '结束时间 不能为空' }]">
            <el-date-picker
              value-format="YYYY-MM-DD HH:mm:ss"
              v-model="form.endTime"
              type="datetime"
              placeholder="选择 结束时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="适配器" prop="scheduls" :rules="[{ required: true, message: '县适配器 不能为空' }]">
            <el-select
              v-model="form.scheduls"
              placeholder="请选择 县适配器"
              clearable
            >
              <el-option
                :label="item.name"
                :value="item.code"
                v-for="(item, index) in basicData.dispatchList"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="输出通道" prop="outChannelIds" :rules="[{ required: true, message: '输出通道 不能为空' }]">
            <el-checkbox-group v-model="form.outChannelIds" fill="#0B9E84">
              <el-checkbox :label="1" name="outChannelIds" :key="1"
              >FM
              </el-checkbox
              >
              <el-checkbox :label="2" name="outChannelIds" :key="2"
              >DVB-C
              </el-checkbox
              >
              <el-checkbox :label="3" name="outChannelIds" :key="3"
              >DTMB
              </el-checkbox
              >
              <el-checkbox :label="4" name="outChannelIds" :key="4" checked
              >IP/4G
              </el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="输入通道" prop="inputChannelId" :rules="[{ required: true, message: '输入通道 不能为空' }]">
            <el-radio-group v-model="form.inputChannelId" fill="#0B9E84">
              <el-radio v-for="(item,index) in basicData.audioSrcChannel" :label="item.code" name="inputChannelId"
                        :key="index">
                {{ item.name }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
  <div class="regionEquip">
    <div class="left_regionEquip">
      <div class="regionTitle">区域设备</div>
      <el-input v-model="filterText" placeholder="请输入区域关键字">
        <template #prefix>
          <el-icon class="el-input__icon">
            <search />
          </el-icon>
        </template>
      </el-input>
      <div class="region">
        <el-tree
          @check-change="checkChange"
          show-checkbox
          accordion
          node-key="code"
          ref="tree"
          class="filter-tree"
          :data="regiontreedata"
          :props="defaultProps"
          :filter-node-method="filterNode"
        />
      </div>
    </div>
    <div class="center_regionEquip">
      <div class="regionTitle">选中区域下设备</div>
      <div class="selectEquip">
        <TableNoPage :tableData="tableData" :attrList="attrList" :height="380"></TableNoPage>
      </div>
      <div class="volume">
        <span class="volumeName"> 音量</span>
        <div class="delvolume" @click="form.volume--"></div>
        <el-slider v-model="form.volume" :show-tooltip="false"></el-slider>
        <div class="addvolume" @click="form.volume++"></div>
        <div class="numbervolume">{{ form.volume }}</div>
      </div>
      <div class="btn">
        <el-button @click="resetForm('searchForm')" class="resetBtn leftBtn"
        >重置
        </el-button
        >
        <el-button @click="handleStart('searchForm')" class="emphasizeBtn" type="warning" :disabled="disabled"
        >提交
        </el-button
        >
      </div>
    </div>
    <div class="right_regionEquip">
      <div class="regionTitle right_title">播发进程</div>
      <div class="nodeBox">
        <div class="nodeList" v-for="(item, index) in nodeInfo"
             :key="index">
          <span>{{ item.name }}</span>
          <div v-if="item.children&&item.children.length" class="hasChild">
            <div v-for="( child,i) in item.children" :key="i" class="detailNode">
              <span>{{ child.msg ? child.msg : "..." }}</span>
              <div :class="{ done: child.time }">
                {{ child.time ? child.time : "等待中" }}
              </div>

            </div>
          </div>
          <div v-else class="detailNode">
            <span>{{ item.msg ? item.msg : "..." }}</span>
            <div :class="{ done: item.time }">
              {{ item.time ? item.time : "等待中" }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getRegionEquip } from "@/api/system/region";
import TableNoPage from "@/components/tableNoPage";
import {
  getbasicData,
  getNodesById,
  getEpuipByregion
} from "@/api/basic/startBroadcast";
import {
  startadapterBCast
} from "@/api/basic/adapterBroadcast";
import { ref, watch } from "vue";

export default {
  name: "adapterBroadcast",
  setup() {
    const tree = ref("");
    const filterText = ref("");
    watch(filterText, (val) => {
      tree.value.filter(val);
    });
    const filterNode = (value, data) => {
      console.log(value, data);
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    };
    // function getkeys() {
    //   let data = tree.value.getCheckedKeys();
    //   return data;
    // }

    return {
      tree,
      filterText,
      filterNode
      // getkeys
    };
  },
  data() {
    return {
      disabled: false,
      nodeTimer: null,
      timer: null,
      codeList: [],
      nodeInfo: [],
      // page: {
      //   current: 1,
      //   limit: 10,
      //   total: 0
      // },
      tableData: [],
      attrList: [
        {
          label: "设备名称",
          prop: "name",
          render: (row) => <div>{row.name}</div>
        },
        {
          label: "所属区域",
          prop: "regionName",
          render: (row) => <div>{row.regionName}</div>
        },
        {
          label: "资源编码",
          prop: "code",
          render: (row) => <div>{row.code}</div>
        },
        {
          width: 140,
          label: "设备类型",
          prop: "realName",
          render: (row) => <div>{row.realName}</div>
        },
        {
          width: 90,
          label: "状态",
          prop: "workStatus",
          render: (row) => {
            if (row.workStatus == 1) return <div>空闲</div>;
            else if (row.workStatus == 2) return <div>工作</div>;
            else if (row.workStatus == 0) return <div>离线</div>;
            else if (row.workStatus == 9) return <div>未知</div>;
            else if (row.workStatus == 3) return <div>故障</div>;
          }
        }
      ],
      searchLabelWidth: "100px",
      form: {
        outChannelIds: [1,2,3,4],
        volume: 80,
        bcastType: "5",
        eventType: "11000",
        eventLevel: '4',
      },
      regiontreedata: [],
      defaultProps: {
        key: "id",
        label: "name",
        children: "children"
      },
      basicData: {}
    };
  },
  components: { TableNoPage },
  computed: {
    codeStr() {
      let code = [];
      this.codeList.map(item => {
        code.push(item.code);
      });
      return code.join(",");
    }
  },
  watch: {
    codeStr(value) {
      if (value) {
        let that = this;
        clearTimeout(this.timer);
        this.timer = setTimeout(function() {
          getEpuipByregion(value).then(res => {
            if (res.data.success) {
              that.tableData = res.data.data;
            }
          });
        }, 3000);
      }

    }
  },
  created() {
    getRegionEquip().then((res) => {
      if (res.data.success) {
        console.log(res);
        this.regiontreedata = res.data.data.list;
      }
    });
    getbasicData(4).then((res) => {
      if (res.data.success) {
        this.basicData = res.data.data;
      }
    });
  },
  mounted() {
    function addZero(s) {
      return s < 10 ? ('0' + s) : s;
    }

    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var hour = date.getHours();
    var ehour = (date.getHours() + 1) == 24 ? 0 : date.getHours() + 1;
    var eminute = date.getMinutes();
    var minute = date.getMinutes() - 1;
    var second = date.getSeconds();

    this.form.startTime = year + '-' + addZero(month) + '-' + addZero(day) + ' ' + addZero(hour) + ':' + addZero(minute) + ':' + addZero(second);
    this.form.endTime = year + '-' + addZero(month) + '-' + addZero(day) + ' ' + addZero(ehour) + ':' + addZero(eminute) + ':' + addZero(second);
  },
  methods: {
    getSimpleCheckedNodes(store) {
      const checkedNodes = [];
      const traverse = function(node) {
        const childNodes = node.root ? node.root.childNodes : node.childNodes;
        childNodes.forEach(child => {
          if (child.checked) {
            checkedNodes.push(child.data);
          }
          if (child.indeterminate) {
            traverse(child);
          }
        });
      };
      traverse(store);
      return checkedNodes;
    },
    checkChange(node, status) {
      this.codeList = this.getSimpleCheckedNodes(this.$refs.tree.store);
    },
    resetForm() {
      this.$refs.searchForm.resetFields();
      this.$refs.tree.setCheckedKeys([]);
      this.tableData = [];
      this.disabled = false;
    },
    handleStart(formName) {
      clearTimeout(this.nodeTimer);
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (!this.codeStr) return this.$message.error("请选择 区域设备");
          // this.form.outChannelIds = this.form.outChannelIds.join(",");
          this.form.targetCodes = this.codeStr;

          this.form.uuidStr = Date.now();
          try {
            let res = await startadapterBCast({...this.form , outChannelIds :this.form.outChannelIds.join(",")});
            this.getNodes(res.data.data);
            if (res.data.success) {
              this.disabled = true;
              this.$message({
                type: "success",
                message: "操作成功"
              });
            }
          } catch (err) {
            console.log(err);
          }
        } else {
          return false;
        }
      });
    },
    getNodes(id) {
      let that = this;
      function timer(){
        that.nodeTimer = setTimeout(async ()=>{
          let ret = await getNodesById(id);
          that.nodeInfo = ret.data.data;
          timer()
        },3000)
      }
      timer();
    }
  },
  beforeUnmount (){
    this.nodeTimer = null;
    clearTimeout(this.nodeTimer);
  },
};
</script>

<style scoped lang="scss">
.regionEquip {
  width: 100%;
  height: 665px;
  display: flex;
  justify-content: space-between;
}

.regionTitle {
  text-align: left;
  width: 100%;
  font-size: 18px;
  font-family: Source Han Sans SC;
  font-weight: 300;
  color: #32b89c;
  padding-bottom: 8px;
}

.left_regionEquip {
  ::-webkit-scrollbar {
    width: 0;
  }

  padding: 10px;
  width: 320px;
  height: 100%;
  background: url("../../assets/left_regionequip.png") no-repeat center;
  background-size: contain;

  .region {
    width: 100%;
    height: 560px;
    overflow-y: scroll;
  }
}

.center_regionEquip {
  padding: 10px;
  width: 1000px;
  height: 100%;
  background: url("../../assets/center_regionEquip.png") no-repeat center;
  background-size: contain;

  .selectEquip {
    width: 100%;
  }

  .volume {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 26px;
    width: 100%;
    height: 67px;
    background: #0b3532;
  }
}

.right_regionEquip {
  ::-webkit-scrollbar {
    width: 0;
  }

  padding: 10px;
  width: 350px;
  height: 100%;
  background: url("../../assets/right_regionEquip.png") no-repeat left top;
  background-size: contain;
}

::v-deep .regionHeader {
  font-size: 20px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #00ffc6;
  line-height: 48px;
  background: #10433a !important;
}


.regionform {
  box-sizing: border-box;
  //height: 200px;
  padding: 20px 10px;
  background: #0a2b28;
  border: 1px solid #178164;
  margin-bottom: 10px;
}

.el-slider {
  width: 635px;
  --el-slider-runway-bg-color: #12443f !important;
}

.el-slider__runway {
  height: 8px;
  //background: #12443F;
  border-radius: 4px;
}

.delvolume,
.addvolume {
  width: 24px;
  height: 24px;
}

.delvolume {
  background: url("../../assets/delvolume.png");
}

.addvolume {
  background: url("../../assets/addvolume.png");
}

.volumeName {
  font-size: 18px;
  font-family: Source Han Sans SC;
  font-weight: 300;
  color: #95d7c8;
  line-height: 48px;
}

.numbervolume {
  width: 109px;
  height: 40px;
  background: #062c29;
  border: 1px solid #0f4641;
  font-size: 18px;
  font-family: Source Han Sans SC;
  font-weight: 300;
  color: #95d7c8;
  line-height: 40px;
}

.el-popper.is-dark {
  background-color: #24ad8e !important;
}

.btn {
  margin-top: 20px;
}

.leftBtn {
  margin-right: 50px;
}

.nodeList {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nodeList > span {
  font-size: 16px;
  font-family: Source Han Sans SC;
  font-weight: 300;
  color: #59b7a4;
}

.nodeList > div {
  color: #d1a930;
}

.nodeList {
  padding: 6px 0;
  border-bottom: 1px dashed #00ffc6;
}

.detailNode > div.done {
  font-size: 16px;
  font-family: Source Han Sans SC;
  color: #59b7a4;
  font-size: 10px;
}

.nodeBox {
  height: 605px;
  overflow-y: scroll;
}

.el-button.emphasizeBtn {
  --el-button-hover-text-color: #e6a23c !important;
}

.right_title {
  border-bottom: 1px dashed #126b51;
}

.nodeBox {
  padding-top: 15px;
}

.detailNode {
  width: 225px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>